import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Spin, notification } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import boltLogo from "../assets/bolt-logo-dark.svg";
import { register } from "../util/APIUtils";
import "./Register.css";

const FormItem = Form.Item;

export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      registerSuccess: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFinish = async (values) => {
    this.setState({
      loading: true,
    });
    const { email, password, name } = this.state;
    // Attempt to Register
    let promise = register({
      email: email,
      password: password,
      name: name,
    });
    try {
      let response = await promise;
      notification.success({
        placement: "topRight",
        duration: null,
        message: "Registration Success",
        description: `Congrats ${name}! You have successfully registered.`,
      });
      this.setState({
        loading: false,
        registerSuccess: true,
      });
    } catch (err) {
      console.log(err);
      notification.error({
        placement: "topRight",
        duration: null,
        message: "Registration Error",
        description: err.message,
      });
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    if (this.props.isAuthenticated === true) {
      if (this.props.location.state != null) {
        return <Redirect to={this.props.location.state.from} />;
      } else {
        return <Redirect to={{ pathname: "/dashboard" }} />;
      }
    }
    if (this.state.registerSuccess === true) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    return (
      <div className={"login-container"}>
        <Row style={{ minHeight: "85vh" }}>
          <Col
            sm={{ span: 16, offset: 4 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
            style={{ marginTop: "15vh", minHeight: "55vh" }}
          >
            <Row type={"flex"} justify={"center"} align={"middle"} className={"logo-row"}>
              <Card style={{ width: "100vh" }}>
                <Row type={"flex"} justify={"center"} align={"middle"}>
                  <Col>
                    <img
                      src={boltLogo}
                      alt="logo"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                    />
                  </Col>
                </Row>
                <Spin spinning={this.state.loading}>
                  <Form name={"register"} onFinish={this.onFinish}>
                    <Row type={"flex"} justify={"center"} align={"middle"}>
                      <FormItem>
                        <Input
                          className={"login-input"}
                          prefix={<MailOutlined />}
                          size="large"
                          name="email"
                          placeholder="Email"
                          onChange={this.onChange}
                        />
                      </FormItem>
                    </Row>
                    <Row type={"flex"} justify={"center"} align={"middle"}>
                      <FormItem>
                        <Input
                          className={"login-input"}
                          prefix={<UserOutlined />}
                          size="large"
                          name="name"
                          placeholder="Name"
                          onChange={this.onChange}
                        />
                      </FormItem>
                    </Row>
                    <Row type={"flex"} justify={"center"} align={"middle"}>
                      <FormItem>
                        <Input.Password
                          className={"login-input"}
                          prefix={<LockOutlined />}
                          size="large"
                          type="password"
                          name="password"
                          onChange={this.onChange}
                          placeholder="Password"
                        />
                      </FormItem>
                    </Row>
                    <Row type={"flex"} justify={"center"}>
                      <FormItem>
                        <Button
                          className={"login-button"}
                          type={"primary"}
                          shape={"round"}
                          size={"large"}
                          htmlType="submit"
                        >
                          Register
                        </Button>
                      </FormItem>
                    </Row>
                    <Row type={"flex"} justify={"center"} align={"middle"}>
                      <span>
                        Already have an account? <Link to="/"> Log in</Link>
                      </span>
                    </Row>
                  </Form>
                </Spin>
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

Register.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAuthenticating: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
});

export default connect(mapStateToProps)(Register);
