// datadog
import { datadogRum } from "@datadog/browser-rum";
import packageJson from "../package.json";

export const initAndRunDataDog = () => {
  if (process?.env?.REACT_APP_ENABLE_DATADOG_RUM !== "true") return;
  const { REACT_APP_DATADOG_ENV, REACT_APP_DATADOG_APP_ID, REACT_APP_DATADOG_CLIENT_TOKEN } = process.env || {};

  if (!(REACT_APP_DATADOG_ENV && REACT_APP_DATADOG_APP_ID && REACT_APP_DATADOG_CLIENT_TOKEN)) {
    return;
  }
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APP_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "hubble-admin-dashboard",
    env: REACT_APP_DATADOG_ENV,
    version: packageJson?.version || "0.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
  datadogRum.startSessionReplayRecording();
};
