import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Row, Space, Tooltip } from "antd";
import { default as React } from "react";
import { Link, useHistory } from "react-router-dom";
import { LIGHT_BLUE } from "../../util/Colors";

const renderTooltip = () => (
  <Tooltip
    title="Create users for merchant employees and service users for merchants to connect their apps to GoBolt."
    trigger="hover"
    defaultOpen
  >
    <QuestionCircleFilled style={{ fontSize: 18, color: LIGHT_BLUE }} />
  </Tooltip>
);

const renderAddUserButtonGroup = ({ isLoading, onCreateServiceUser, merchantId }) => {
  return (
    <Space size={18}>
      {renderTooltip()}
      <Button disabled={isLoading} type="primary">
        <Link disabled={isLoading} to={`/merchants/${merchantId}/user/create`}>
          Add New User
        </Link>
      </Button>
      <Button disabled={isLoading} type="primary" onClick={onCreateServiceUser}>
        Add Service User
      </Button>
    </Space>
  );
};

const DashboardHeader = ({ merchantId = "", isLoading = false, merchantName = "", onCreateServiceUser = () => {} }) => {
  const history = useHistory();

  return (
    <Row justify="space-between" style={{ paddingBottom: 30 }}>
      <h1>
        <Link onClick={() => history.goBack()} style={{ color: LIGHT_BLUE }}>
          Merchants
        </Link>{" "}
        / {merchantName}
      </h1>
      {renderAddUserButtonGroup({ isLoading, onCreateServiceUser, merchantId })}
    </Row>
  );
};

export default DashboardHeader;
