import { Button, Card, Col, Input, Row, Select, Spin, Table, Tabs, Tag, Typography, notification } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  getMultipleDrivers,
  getPayrollReportById,
  markDriversInReportAsPaid,
  markDriversInReportAsUnpaid,
} from "../util/APIUtils";
import "./Profiles.css";
import "./SinglePayrollReport.css";
const { Paragraph } = Typography;
const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;

const setPathProperty = (object, path, value) =>
  path.split(".").reduce((o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}), object);

export class SinglePayrollReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payrollReport: null,
      loadingPayrollReport: false,
      selectedRowKeys: [],
      selectedRows: [],
      loadingMark: false,
      loadingMarkAsUnpaid: false,
    };
  }

  componentDidMount() {
    this.loadEverything();
  }

  loadEverything = () => {
    const values = queryString.parse(this.props.location.search);
    console.log(`values`);
    console.log(JSON.stringify(values));
    if (values.reportId) this.setState({ reportIdSearch: values.reportId });
    this.setState({ loadingPayrollReport: true });
    let promise = getPayrollReportById(values.reportId);
    promise
      .then((response) => {
        let driversPromise = getMultipleDrivers(
          response.drivers
            .map((x) => x.driverId)
            .join()
            .concat(response.failedList ? response.failedList : []),
        );
        driversPromise
          .then((driversResponse) => {
            this.setState({ fullDrivers: driversResponse });
            let finalList = [];

            // create adjustments datasource
            let finalAdjustments = [];

            for (const d of response.drivers) {
              console.log(d);
              let found = driversResponse.find((e) => e.id === d.driverId);
              console.log(found);

              let totalAdjustments = null;

              let newElem = {
                ...d,
                name: found ? found.firstname + " " + found.lastname : "",
                email: found ? found.email : "",
              };
              // if (totalAdjustments != null) newElem.totalAdjustments = totalAdjustments
              // finalList.push(newElem)

              if (d.driverPay != null && d.driverPay.lineItems != null) {
                for (const lItem of d.driverPay.lineItems) {
                  if (lItem.isAdjustment === true) {
                    finalAdjustments.push({
                      ...lItem,
                      driverName: found ? found.firstname + " " + found.lastname : null,
                      driverId: found ? found.id : null,
                    });

                    if (totalAdjustments == null) {
                      totalAdjustments = Number(lItem.totalPrice);
                    } else {
                      totalAdjustments = totalAdjustments + Number(lItem.totalPrice);
                    }
                  }
                }
              }

              if (totalAdjustments != null && newElem.driverPay != null)
                newElem.driverPay.totalAdjustments = totalAdjustments;
              finalList.push(newElem);
            }
            let finalPayrollReport = {
              ...response,
              drivers: finalList,
            };

            console.log(JSON.stringify(finalPayrollReport));

            this.setState({ adjustmentsData: finalAdjustments });

            this.setState({ payrollReport: finalPayrollReport });
            this.setState({ loadingPayrollReport: false });
          })
          .catch((error) => {
            const args = {
              message: "Error!",
              description: "Could not load driver names.",
              duration: 5,
              type: "error",
              placement: "topRight",
            };
            notification.open(args);
            this.setState({ loadingPayrollReport: false });
          });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get payroll report",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingPayrollReport: false });
      });
    // this.setState({loadingPayrollReport: false})
  };

  getStatusColor = (status) => {
    if (status === "CREATED" || status === "IN_PROGRESS") {
      return "blue";
    } else if (status === "COMPLETED") {
      return "green";
    }
  };
  getApprovalStatusColor = (approvalStatus) => {
    if (approvalStatus === "APPROVED") {
      return "green";
    } else if (approvalStatus === "NOT_APPROVED") {
      return "red";
    } else return "blue";
  };

  handleMarkDriversInReportAsPaid = async () => {
    console.log(this.state.payrollReport.id);
    console.log(this.state.selectedRowKeys);
    console.log("rows");
    console.log(this.state.selectedRows);
    // return
    let found = this.state.selectedRows.filter((x) => x.driverPay.paid === true).length > 0;
    if (found) {
      const args = {
        message: "Error!",
        description: "Please only select drivers that have not already been marked as paid",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    if (this.state.payrollReport) {
      // return
      this.setState({ loadingMark: true });
      let promise = markDriversInReportAsPaid(this.state.payrollReport.id, { driverIds: this.state.selectedRowKeys });
      promise
        .then((response) => {
          this.setState({ selectedRowKeys: [] });
          this.loadEverything();
          const args = {
            message: "Success!",
            description: "Done!",
            duration: 3,
            type: "success",
            placement: "topRight",
          };
          notification.open(args);
          this.setState({ loadingMark: false });
        })
        .catch((error) => {
          console.log(error);
          const args = {
            message: "Error!",
            description: error.message,
            duration: 5,
            type: "error",
            placement: "topRight",
          };
          notification.open(args);
          this.setState({ loadingMark: false });
        });
    }
  };

  handleMarkDriversInReportAsUnpaid = async () => {
    console.log(this.state.payrollReport.id);
    console.log(this.state.selectedRowKeys);

    let found = this.state.selectedRows.filter((x) => !x.driverPay.paid).length > 0;
    if (found) {
      const args = {
        message: "Error!",
        description: "Please only select drivers that have been marked as paid",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }
    if (this.state.payrollReport) {
      // return
      this.setState({ loadingMarkAsUnpaid: true });
      let promise = markDriversInReportAsUnpaid(this.state.payrollReport.id, { driverIds: this.state.selectedRowKeys });
      promise
        .then((response) => {
          this.setState({ selectedRowKeys: [] });
          this.loadEverything();
          const args = {
            message: "Success!",
            description: "Done!",
            duration: 3,
            type: "success",
            placement: "topRight",
          };
          notification.open(args);
          this.setState({ loadingMarkAsUnpaid: false });
        })
        .catch((error) => {
          console.log(error);
          const args = {
            message: "Error!",
            description: error.message,
            duration: 5,
            type: "error",
            placement: "topRight",
          };
          notification.open(args);
          this.setState({ loadingMarkAsUnpaid: false });
        });
    }
  };

  render() {
    let headers = [
      { label: "Driver Id", key: "driverId" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Paid", key: "driverPay.paid" },
    ];

    let columns = [
      {
        title: "Driver Id",
        dataIndex: "driverId",
        key: "driverId",
        render: (text, record) => (record.driverId != null ? record.driverId : ""),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (record.name != null ? record.name : ""),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => (record.email != null ? record.email : ""),
      },
      {
        title: "Paid",
        dataIndex: "paid",
        key: "paid",
        render: (text, record) =>
          record?.driverPay?.paid != null && record.driverPay.paid === true ? (
            <Tag color="green">yes</Tag>
          ) : (
            <Tag color="red">no</Tag>
          ),
      },
    ];

    if (this.state.payrollReport) {
      for (const driver of this.state.payrollReport.drivers) {
        for (let [index, lineItem] of driver.driverPay.lineItems.entries()) {
          for (let [propName, value] of Object.entries(lineItem)) {
            // console.log(propName)
            if (propName !== "name" && !lineItem.isAdjustment) {
              let newKey = lineItem.name + "-" + propName;
              if (columns.find((x) => x.key === newKey) == null) {
                headers.push({ label: newKey, key: `driverPay.lineItems[${index}][${propName}]` });

                columns.push({
                  title: newKey,
                  dataIndex: newKey,
                  key: newKey,
                  align: "right",
                  render: (text, record) =>
                    record?.driverPay?.lineItems?.find((x) => x.name === lineItem.name) != null &&
                    record?.driverPay?.lineItems?.find((x) => x.name === lineItem.name)[propName] != null
                      ? Number(record.driverPay.lineItems.find((x) => x.name === lineItem.name)[propName]).toFixed(2)
                      : "",
                });
              }
            }
          }
        }

        if (columns.find((x) => x.key === "totalAdjustments") == null) {
          headers.push({ label: "totalAdjustments", key: `driverPay.totalAdjustments` });
          columns.push({
            title: "Total Adjustments",
            dataIndex: "totalAdjustments",
            key: "totalAdjustments",
            align: "right",
            render: (text, record) =>
              record.driverPay.totalAdjustments != null ? Number(record.driverPay.totalAdjustments).toFixed(2) : "",
          });
        }

        if (columns.find((x) => x.key === "totalPay") == null) {
          headers.push({ label: "totalPay", key: `driverPay.totalPay` });
          columns.push({
            title: "Total Pay",
            dataIndex: "totalPay",
            key: "totalPay",
            align: "right",
            render: (text, record) =>
              record.driverPay.totalPay != null ? Number(record.driverPay.totalPay).toFixed(2) : "",
          });
        }
      }
    }

    let adjustmentsColumns = [
      {
        title: "Driver Id",
        dataIndex: "driverId",
        key: "driverId",
        render: (text, record) => (record.driverId != null ? record.driverId : ""),
      },
      {
        title: "Driver Name",
        dataIndex: "driverName",
        key: "driverName",
        render: (text, record) => (record.driverName != null ? record.driverName : ""),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (record.name != null ? record.name : ""),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => (record.quantity != null ? record.quantity : ""),
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (text, record) => (record.unitPrice != null ? record.unitPrice : ""),
      },
      {
        title: "Total Price",
        dataIndex: "totalPrice",
        key: "totalPrice",
        render: (text, record) => (record.totalPrice != null ? record.totalPrice : ""),
      },
    ];

    let adjustmentHeaders = adjustmentsColumns.map((x) => {
      return {
        label: x.title,
        key: x.key,
      };
    });

    const getSummary = (total) => {
      return (
        <Table.Summary.Row>
          {columns.map((x, i) => {
            if (i === columns.length - 1) {
              return (
                <Table.Summary.Cell align="right">
                  <Text type="danger">{total}</Text>
                </Table.Summary.Cell>
              );
            } else return <Table.Summary.Cell></Table.Summary.Cell>;
          })}
        </Table.Summary.Row>
      );
    };

    const getExportName = () => {
      if (this.state.payrollReport == null) {
        return "payrollReport.csv";
      } else if (this.state.payrollReport.name == null) {
        return `${this.state.payrollReport.id}.csv`;
      } else {
        return `${this.state.payrollReport.id}_${this.state.payrollReport.name}.csv`;
      }
    };

    const getExportAdjustmentsName = () => {
      if (this.state.payrollReport == null) {
        return "payrollReport_adjustments.csv";
      } else if (this.state.payrollReport.name == null) {
        return `${this.state.payrollReport.id}_adjustments.csv`;
      } else {
        return `${this.state.payrollReport.id}_${this.state.payrollReport.name}_adjustments.csv`;
      }
    };

    const rowSelection = {
      fixed: true,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys: selectedRowKeys, selectedRows: selectedRows });
      },
      selectedRowKeys: this.state.selectedRowKeys,
      getCheckboxProps: (record) => ({
        // disabled: record.driverPay.paid, // Column configuration not to be checked
        name: record.name,
      }),
    };

    console.log(this.state.selectedRowKeys);

    return (
      <Spin spinning={this.state.loadingPayrollReport}>
        <div style={{ background: "#ECECEC", padding: "3vmin" }}>
          <div style={{ marginBottom: 50 }}>
            <Row gutter={16} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Card title="Payroll report Information">
                  {this.state.payrollReport != null && (
                    <Row>
                      <Col span={8}>
                        <span className={"info-content-key"}>Id:</span>
                      </Col>
                      <Col span={16}>
                        <span>{this.state.payrollReport.id}</span>
                      </Col>
                      {this.state.payrollReport.name && (
                        <>
                          <Col span={8}>
                            <span className={"info-content-key"}>Name:</span>
                          </Col>
                          <Col span={16}>
                            <span>{this.state.payrollReport.name}</span>
                          </Col>
                        </>
                      )}
                      <Col span={8}>
                        <span className={"info-content-key"}>Created At:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {this.state.payrollReport.createdAt != null ? (
                            <>
                              {new Date(this.state.payrollReport.createdAt).toDateString()}{" "}
                              {new Date(this.state.payrollReport.createdAt).toLocaleTimeString()}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </Col>
                      <Col span={8}>
                        <span className={"info-content-key"}>Period Start:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {this.state.payrollReport.periodStart != null ? (
                            <>{new Date(this.state.payrollReport.periodStart).toDateString()}</>
                          ) : (
                            ""
                          )}
                        </span>
                      </Col>
                      <Col span={8}>
                        <span className={"info-content-key"}>Period End:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {this.state.payrollReport.periodEnd != null ? (
                            <>{new Date(this.state.payrollReport.periodEnd).toDateString()}</>
                          ) : (
                            ""
                          )}
                        </span>
                      </Col>
                      <Col span={8}>
                        <span className={"info-content-key"}>Total Drivers:</span>
                      </Col>
                      <Col span={16}>
                        <span>{this.state.payrollReport.totalDrivers}</span>
                      </Col>
                      <Col span={8}>
                        <span className={"info-content-key"}>Total Amount:</span>
                      </Col>
                      <Col span={16}>
                        <span>{this.state.payrollReport.totalAmount}</span>
                      </Col>
                      <Col span={8}>
                        <span className={"info-content-key"}>Status:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          <Tag color={this.getStatusColor(this.state.payrollReport.status)}>
                            {this.state.payrollReport.status}
                          </Tag>
                        </span>
                      </Col>
                      <Col span={8}>
                        <span className={"info-content-key"}>Approval Status:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          <Tag color={this.getApprovalStatusColor(this.state.payrollReport.approvalStatus)}>
                            {this.state.payrollReport.approvalStatus}
                          </Tag>
                        </span>
                      </Col>
                      {this.state.payrollReport.approvalBy && (
                        <>
                          <Col span={8}>
                            <span className={"info-content-key"}>Approved By:</span>
                          </Col>
                          <Col span={16}>
                            <span>{this.state.payrollReport.approvalBy}</span>
                          </Col>
                        </>
                      )}
                      {this.state.payrollReport.createdBy && (
                        <>
                          <Col span={8}>
                            <span className={"info-content-key"}>Created By:</span>
                          </Col>
                          <Col span={16}>
                            <span>{this.state.payrollReport.createdBy}</span>
                          </Col>
                        </>
                      )}
                      <Col span={8}>
                        <span className={"info-content-key"}>Total Pay:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          {this.state.payrollReport && this.state.payrollReport.drivers
                            ? Number(
                                this.state.payrollReport.drivers
                                  .map((x) => x.driverPay.totalPay)
                                  .reduce((a, b) => a + b, 0),
                              ).toFixed(2)
                            : 0}
                        </span>
                      </Col>

                      {this.state.payrollReport.comments && (
                        <>
                          <Col span={8}>
                            <span className={"info-content-key"}>Comments:</span>
                          </Col>
                          <Col span={16}>
                            <span>{this.state.payrollReport.comments}</span>
                          </Col>
                        </>
                      )}
                      {this.state.payrollReport.failedList && (
                        <>
                          <Col span={8}>
                            <span className={"info-content-key"}>Failed List:</span>
                          </Col>
                          <Col span={16}>
                            <span>
                              {this.state.payrollReport.failedList.map((x) => {
                                let myDriver =
                                  this.state.fullDrivers && this.state.fullDrivers.find((d) => d.id === x)
                                    ? this.state.fullDrivers.find((d) => d.id === x)
                                    : null;
                                let myName = myDriver ? myDriver.firstname + " " + myDriver.lastname : x;
                                return (
                                  <Row>
                                    <Col>{myName}</Col>
                                  </Row>
                                );
                              })}
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 5 }}>
              <Col span={24}>
                <Card title="Drivers">
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Report" key="1">
                      <Row gutter={16} style={{ marginTop: 5 }}>
                        <Col span={4}>
                          <Button type="primary">
                            <CSVLink
                              filename={getExportName()}
                              headers={headers}
                              // data={data}
                              data={this.state.payrollReport ? this.state.payrollReport.drivers : []}
                              className="btn btn-primary"
                              // onClick={()=>{
                              //   alert('success')
                              // }}
                            >
                              Export to CSV
                            </CSVLink>
                          </Button>
                        </Col>
                        {this.state.payrollReport && this.state.payrollReport.status === "COMPLETED" && (
                          <Col span={3}>
                            <Button
                              type="primary"
                              loading={this.state.loadingMarkAsUnpaid || this.state.loadingMark}
                              onClick={this.handleMarkDriversInReportAsPaid}
                              disabled={this.state.selectedRowKeys.length === 0}
                            >
                              Mark As Paid
                            </Button>
                          </Col>
                        )}

                        <Col span={3}>
                          <Button
                            type="primary"
                            loading={this.state.loadingMarkAsUnpaid || this.state.loadingMark}
                            onClick={this.handleMarkDriversInReportAsUnpaid}
                            disabled={this.state.selectedRowKeys.length === 0}
                          >
                            Mark As Not Paid
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ marginTop: 5 }}>
                        <Col span={24}>
                          <Table
                            columns={columns}
                            rowKey={(d) => d.driverId}
                            dataSource={
                              this.state.payrollReport && this.state.payrollReport.drivers
                                ? this.state.payrollReport.drivers
                                : []
                            }
                            // loading={this.state.loadingPayrollReport}
                            size={"small"}
                            pagination={false}
                            scroll={{ y: 300, x: false }}
                            // summary={() => getSummary(this.state.payrollReport && this.state.payrollReport.drivers? Number(this.state.payrollReport.drivers.map(x=>x.driverPay.totalPay).reduce((a, b) => a + b, 0)).toFixed(2) : 0)}
                            rowSelection={rowSelection}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Adjustments" key="2">
                      <Row gutter={16} style={{ marginTop: 5 }}>
                        <Col span={24}>
                          <Row gutter={16} style={{ marginTop: 5 }}>
                            <Col span={24}>
                              <Button type="primary">
                                <CSVLink
                                  filename={getExportAdjustmentsName()}
                                  headers={adjustmentHeaders}
                                  data={this.state.adjustmentsData ? this.state.adjustmentsData : []}
                                  className="btn btn-primary"
                                >
                                  Export to CSV
                                </CSVLink>
                              </Button>
                            </Col>
                          </Row>
                          <Row gutter={16} style={{ marginTop: 5 }}>
                            <Col span={24}>
                              <Table
                                columns={adjustmentsColumns}
                                dataSource={this.state.adjustmentsData ? this.state.adjustmentsData : []}
                                size={"small"}
                                pagination={false}
                                scroll={{ y: 375, x: false }}
                                // summary={() => getSummary(this.state.payrollReport && this.state.payrollReport.drivers? Number(this.state.payrollReport.drivers.map(x=>x.driverPay.totalPay).reduce((a, b) => a + b, 0)).toFixed(2) : 0)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
            {/* <Row gutter={16} style={{marginTop: 5}}>
                  <Col span={24}>
                    <Card title="Adjustments">
                      <Row gutter={16} style={{marginTop: 5}}>
                        <Col span={24}>
                          <Button type="primary">
                            <CSVLink
                              filename={getExportAdjustmentsName()}
                              headers={adjustmentHeaders}
                              data={this.state.adjustmentsData? this.state.adjustmentsData : []}
                              className="btn btn-primary"
                            >
                              Export to CSV
                            </CSVLink> 
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{marginTop: 5}}>
                        <Col span={24}>
                          <Table
                            columns={adjustmentsColumns}
                            dataSource={this.state.adjustmentsData? this.state.adjustmentsData : []}
                            size={"small"}
                            pagination={false}
                            scroll={{y: 375, x: false}}
                            // summary={() => getSummary(this.state.payrollReport && this.state.payrollReport.drivers? Number(this.state.payrollReport.drivers.map(x=>x.driverPay.totalPay).reduce((a, b) => a + b, 0)).toFixed(2) : 0)}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row> */}
          </div>
        </div>
      </Spin>
    );
  }
}

SinglePayrollReport.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SinglePayrollReport);
