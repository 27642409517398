import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  USER_LOAD_ERROR,
} from "../actions/types";
import { notification } from "antd";
import jwtDecode from "jwt-decode";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isAuthenticating: false,
  user: null,
  roles: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isAuthenticating: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        user: action.payload["sub"],
        roles: action.payload["roles"],
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      const decodedToken = jwtDecode(action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        token: action.payload.token,
        user: decodedToken["sub"],
        roles: decodedToken["roles"],
      };
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      notification.destroy();
      return {
        ...state,
        token: null,
        user: null,
        roles: null,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case USER_LOAD_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        roles: null,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    default:
      return state;
  }
}
