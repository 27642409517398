import { Card, Input, Row, Space, Typography } from "antd";
import React from "react";

const MerchantInfoCard = ({ merchant = {} }) => {
  return (
    <Card
      title="Merchant Information"
      bordered={false}
      className="card-header"
      extra={<Typography.Text type="secondary">Read Only</Typography.Text>}
    >
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <Input placeholder={merchant.name} disabled />
        <Row>
          <Typography.Text type="secondary">Merchant ID</Typography.Text>
          <Input placeholder={merchant.id} disabled />
        </Row>
        <Row>
          <Typography.Text type="secondary">GoBolt ID</Typography.Text>
          <Input placeholder={merchant.goboltOrgId} disabled />
        </Row>
      </Space>
    </Card>
  );
};

export default MerchantInfoCard;
