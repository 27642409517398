export const ratesDescriptionList = [
  {
    description: "Get your package within a 3 hour window of your choice.",
    locales: [
      {
        description: "Votre colis sera livré dans une plage de livraison (3H) de votre choix.",
        locale: "FR",
        name: "GoBolt Livraison Planifiée",
      },
    ],
    name: "GoBolt Scheduled Delivery",
    price: 0,
    speedPricingType: "SCHEDULED",
  },
  {
    description: "Get your package within a 3 hour window of your choice.",
    locales: [
      {
        description: "Votre colis sera livré dans une plage de livraison (3H) de votre choix.",
        locale: "FR",
        name: "GoBolt Réservation Client",
      },
    ],
    name: "GoBolt Customer Booking",
    price: 0,
    speedPricingType: "CUSTOMER_BOOKING",
  },
  {
    description: "Get your package within a flexible timeframe.",
    locales: [
      {
        description: "Recevez votre colis dans un délai flexible.",
        locale: "FR",
        name: "GoBolt Flex",
      },
    ],
    name: "GoBolt Flex",
    price: 0,
    speedPricingType: "FLEX",
  },
  {
    description: "Get your package today if ordered before 1pm!",
    locales: [
      {
        description: "Votre colis sera livré aujourd'hui si c'est commandé avant 1pm!",
        locale: "FR",
        name: "GoBolt Livraison Jour-Même",
      },
    ],
    name: "GoBolt Same-Day/One-Day Delivery",
    price: 0,
    speedPricingType: "SAMEDAY",
  },
  {
    description: "Get your package the next opening day of the merchant.",
    locales: [
      {
        description: "Votre colis sera livré le prochain jour d'ouverture du détaillant",
        locale: "FR",
        name: "GoBolt Livraison Prochaine Journée",
      },
    ],
    name: "GoBolt Next-Day Delivery",
    price: 0,
    speedPricingType: "NEXTDAY",
  },
  {
    description: "Standard Delivery.",
    locales: [
      {
        description: "GoBolt Livraison Standard",
        locale: "FR",
        name: "GoBolt Livraison Standard",
      },
    ],
    name: "GoBolt Standard Delivery",
    price: 0,
    speedPricingType: "STANDARD",
  },
  {
    description: "Get your fresh package the next opening day of the merchant.",
    locales: [
      {
        description: "Votre colis frais sera livré le prochain jour d'ouverture du détaillant",
        locale: "FR",
        name: "GoBolt Livraison Prochaine Journée Plus",
      },
    ],
    name: "GoBolt Next-Day Plus Delivery",
    price: 0,
    speedPricingType: "NEXTDAYPLUS",
  },
];
