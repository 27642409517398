import { Col, Row, Steps, Typography } from "antd";
import React from "react";
import { chooseIcon, fixEventTitle } from "../util/Helpers";
const { Step } = Steps;

const OrderTimelineRow = ({ events, index }) => {
  const convertToImages = (urls) => {
    return urls.map((url) => {
      return (
        <a href={url} target={"_blank"}>
          <img width={"100%"} src={url} alt={"Photo Url"} />
        </a>
      );
    });
  };

  const buildStepDescription = ({ timestamp, verificationPhotosUrls, signaturePhotosUrls }) => {
    let eventDate = new Date(timestamp.toString().length === 10 ? timestamp * 1000 : timestamp);
    let eventTimestampString = `${eventDate.toDateString()} ${eventDate.toLocaleTimeString()}`;

    let allVerificationImages = convertToImages(verificationPhotosUrls || []);
    let allSignatureImages = convertToImages(signaturePhotosUrls || []);

    if (allVerificationImages.length > 0 || allSignatureImages.length > 0) {
      return (
        <div>
          <Row>{eventTimestampString}</Row>
          {allVerificationImages.length > 0 && <Row>{allVerificationImages}</Row>}
          {allSignatureImages.length > 0 && <Row>{allSignatureImages}</Row>}
        </div>
      );
    } else {
      return eventTimestampString;
    }
  };

  // latest 5 steps. shown directly on the page
  const numberOfSteps = events.length >= 5 ? 5 : events.length;
  const slicedSteps = events.slice(-numberOfSteps);
  const latestSteps = slicedSteps.map((x, i) => {
    return (
      <Step
        key={i}
        title={fixEventTitle(x.event)}
        description={buildStepDescription(x)}
        icon={chooseIcon(x.event, i === slicedSteps.length - 1)}
        status="process"
      />
    );
  });
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: 12 }}>
        <Typography.Text strong style={{ marginRight: 12 }}>
          Order
        </Typography.Text>
        <Typography.Text strong style={{ color: "#58AFC3" }}>
          {events?.[0]?.trackingNumber}
        </Typography.Text>
      </div>
      <Col span={latestSteps.length < 3 ? 14 : 24}>
        <Steps size="small" direction="horizontal">
          {latestSteps}
        </Steps>
      </Col>
    </div>
  );
};
export default OrderTimelineRow;
