import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Spin, notification } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import ReactDataSheet from "react-datasheet";
import { connect } from "react-redux";
import { ratesDescriptionList } from "../RatesDescription";
import { addShppingRule, getMerchantById } from "../util/APIUtils";
import { areNumbersSequential } from "../util/common";
import { isValidateWeightBandsBody } from "../util/shippingRules";
import "./AddShippingRules.css";

const WindowCard = ({
  addRows,
  title,
  dataSource,
  sheetRenderer,
  rowRenderer,
  cellRenderer,
  onCellsChanged,
  onChangeNumRowsToAdd,
  numRowsToAdd,
}) => {
  return (
    <Card bordered={false} title={title}>
      <Row gutter={16} style={{ marginBottom: 18, alignContent: "flex-end", justifyContent: "flex-end" }}>
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <InputNumber
              min={0}
              onChange={onChangeNumRowsToAdd}
              value={numRowsToAdd}
              style={{ width: 80, marginRight: 4 }}
            />
            <Button onClick={addRows}>Add rows</Button>
          </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 12 }}>
        <Col span={24}>
          <ReactDataSheet
            data={dataSource}
            className="custom-sheet"
            sheetRenderer={sheetRenderer}
            rowRenderer={rowRenderer}
            cellRenderer={cellRenderer}
            onCellsChanged={onCellsChanged}
            valueRenderer={(cell) => cell.value}
          />
        </Col>
      </Row>
    </Card>
  );
};

const setPathProperty = (object, path, value) =>
  path.split(".").reduce((o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}), object);

const SheetRenderer = (props) => {
  const { as: Tag, headerAs: Header, bodyAs: Body, rowAs: Row, cellAs: Cell, className, columns } = props;
  return (
    <Tag className={className}>
      <Header className="data-header">
        <Row>
          {columns.map((column) => (
            <Cell className="cell" style={{ width: column.width, textAlign: column.textAlign }} key={column.label}>
              {column.label}
            </Cell>
          ))}
        </Row>
      </Header>
      <Body className="data-body">{props.children}</Body>
    </Tag>
  );
};

const RowRenderer = (props) => {
  const { as: Tag, className } = props;
  return <Tag className={className}>{props.children}</Tag>;
};

const CellRenderer = (props) => {
  const { as: Tag, cell, row, col, columns, attributesRenderer, selected, editing, updated, style, ...rest } = props;
  const attributes = cell.attributes || {};
  attributes.style = { width: columns[col].width, textAlign: columns[col].textAlign, height: 26 };
  if (col === 0) {
    attributes.title = cell.label;
  }

  return (
    <Tag {...rest} {...attributes}>
      {props.children}
    </Tag>
  );
};

const baseColumns = [
  { label: "Day", width: "25%", textAlign: "right" },
  { label: "Start", width: "25%", textAlign: "right" },
  { label: "End", width: "25%", textAlign: "right" },
  { label: "Cutoff", width: "25%", textAlign: "right" },
];

const scheduledColumns = [
  { label: "Day", width: "20%", textAlign: "right" },
  { label: "Delivery Start", width: "16%", textAlign: "right" },
  { label: "Delivery End", width: "16%", textAlign: "right" },
  { label: "Pickup Start", width: "16%", textAlign: "right" },
  { label: "Pickup End", width: "16%", textAlign: "right" },
  { label: "Cutoff", width: "16%", textAlign: "right" },
];

const weightPricingColumns = [
  { label: "Weight (lbs)", width: "50%", textAlign: "right" },
  { label: "Surcharge ($)", width: "50%", textAlign: "right" },
];

const speedPricingTypes = [
  { label: "NEXTDAY", value: "NEXTDAY" },
  { label: "SAMEDAY", value: "SAMEDAY" },
  { label: "SCHEDULED", value: "SCHEDULED" },
  { label: "CUSTOMER_BOOKING", value: "CUSTOMER_BOOKING" },
  { label: "FLEX", value: "FLEX" },
];

export class AddShippingRules extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCellsChanged = this.handleCellsChanged.bind(this);
    this.sheetRenderer = this.sheetRenderer.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);
    this.handleNumRowsToAdd = this.handleNumRowsToAdd.bind(this);
    this.state = {
      loading: false,
      merchant: null,
      deliveryWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }]],
      pickupWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }]],
      scheduledWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }]],
      weightPricingWindowGrid: [[{ value: "" }, { value: "" }]],
      dispatchers: [
        { label: "Onfleet", value: "ONFLEET" }, // TODO ANNIE: remove onfleet?
        { label: "GoBolt", value: "GOBOLT" },
      ],
      zones: [],
      speedPricing: [],
      currentSearchParam: null,
      numRowsToAdd: {
        deliveryWindowGrid: 1,
        pickupWindowGrid: 1,
        scheduledWindowGrid: 1,
        weightPricingWindowGrid: 1,
      },
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.searchParam) this.setState({ currentSearchParam: values.searchParam });
    let promise = getMerchantById(values.merchantId);
    promise
      .then((response) => {
        this.setState({ merchant: response });
        if (response.zones != null)
          this.setState({
            zones: response.zones.map((z) => {
              return { label: z.name, value: z.name };
            }),
          });
        this.setState({ loadingMerchant: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get merchant",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
  }

  handleSelect(e) {
    this.setState({ as: e.target.value });
  }

  handleAddRows = (grid) => {
    if (!grid || !this.state.numRowsToAdd?.[grid] || !this.state[grid]?.[0]) {
      return;
    }

    const numColumns = this.state[grid][0].length;
    const newGrid = [...this.state[grid]].filter((band) => band[0].value !== "");
    const rowTemplate = [];

    // Build columns for one row
    for (let i = 0; i < numColumns; i++) {
      let val = "";
      rowTemplate.push({ value: val });
    }

    // Take the built row and add X number of rows to grid
    for (let i = 0; i < this.state.numRowsToAdd[grid]; i++) {
      const newRow = [...rowTemplate];
      if (grid === "weightPricingWindowGrid") {
        const lastSetNumber = Number(newGrid?.[newGrid.length - 1]?.[0]?.value) || 0;
        newRow.splice(0, 1, { value: `${lastSetNumber + 1}` });
      }
      newGrid.push(newRow);
    }

    this.setState({ [grid]: newGrid });
  };

  handleCellsChanged(myGrid, changes, additions, numColumns = null) {
    let arr = [];
    if (numColumns != null) {
      for (let i = 0; i < numColumns; i++) {
        arr.push({ value: "" });
      }
    }
    const myColumns = arr.length > 0 ? arr : [{ value: "" }, { value: "" }, { value: "" }, { value: "" }];
    const grid = this.state[myGrid].map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      grid[row][col] = { ...grid[row][col], value };
    });

    additions &&
      additions.forEach(({ cell, row, col, value }) => {
        if (!grid[row]) {
          grid[row] = myColumns;
        }
        if (grid[row][col]) {
          grid[row][col] = { ...grid[row][col], value };
        }
      });
    this.setState({ [myGrid]: grid });
  }

  sheetRenderer(props) {
    const columns = props.selectedColumns || baseColumns;
    return (
      <SheetRenderer
        columns={columns}
        onSelectAllChanged={this.handleSelectAllChanged}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  rowRenderer(props) {
    return <RowRenderer as="tr" cellAs="td" className="data-row" {...props} />;
  }

  cellRenderer(props) {
    const columns = props.selectedColumns || baseColumns;
    return <CellRenderer as="td" columns={columns} {...props} style={{ float: "left" }} />;
  }

  handleNumRowsToAdd = (grid, val) => {
    const newRowsData = { ...this.state.numRowsToAdd };
    newRowsData[grid] = val;
    this.setState({ numRowsToAdd: newRowsData });
  };

  onChange = (e) => {
    const { formValues } = this.state;
    // clean it if it is a phone number
    const value = e.target.name === "phone" ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
    setPathProperty(formValues, e.target.name, value);
    this.setState({ formValues });
  };

  addShippingRule = (values) => {
    // STEP 1: DELIVERY WINDOWS
    // check if any rows are incomplete (some empty values but NOT all)
    let deliveryComplete = true;
    for (const e of this.state.deliveryWindowGrid) {
      if (e.some((x) => x.value.replace(/\s/g, "") === "") && !e.every((x) => x.value.replace(/\s/g, "") === "")) {
        deliveryComplete = false;
      }
    }
    if (!deliveryComplete) {
      const args = {
        message: "Error!",
        description: "Please make sure to fill out all fields of rows in Delivery Windows",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    if (
      this.state.deliveryWindowGrid.filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "",
      ).length === 0 ||
      this.state.pickupWindowGrid.filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "",
      ).length === 0
    ) {
      const args = {
        message: "Error!",
        description: "Please add at least one DeliveryConstraint and one Pickup Constraint",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let mappedDeliveryConstraints = this.state.deliveryWindowGrid
      .filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "",
      )
      .map((e) => {
        return {
          day: e[0].value.replace(/\s/g, ""),
          timeStart: e[1].value.replace(/\s/g, ""),
          timeEnd: e[2].value.replace(/\s/g, ""),
          cutoffTime: e[3].value.replace(/\s/g, ""),
        };
      });

    let deliveryConstraints = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      exceptions: [],
    };
    let mappedDelivery = mappedDeliveryConstraints.every((e) => {
      if (deliveryConstraints[e.day] != null) {
        deliveryConstraints[e.day].push({
          deliveryWindow: {
            timeStart: e.timeStart,
            timeEnd: e.timeEnd,
          },
          pickupWindow: {
            timeStart: "00:00",
            timeEnd: "00:00",
          },
          cutoffTime: e.cutoffTime,
        });
      } else {
        return false;
      }
      return true;
    });

    if (mappedDelivery === false) {
      const args = {
        message: "Error!",
        description: "Please add make sure all weekdays in Delivery Windows are valid (format: monday, tuesday, etc.)",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    // STEP 2: PICKUP WINDOWS
    // check if any rows are incomplete (some empty values but NOT all)
    let pickupComplete = true;
    for (const e of this.state.pickupWindowGrid) {
      if (e.some((x) => x.value.replace(/\s/g, "") === "") && !e.every((x) => x.value.replace(/\s/g, "") === "")) {
        pickupComplete = false;
      }
    }
    if (!pickupComplete) {
      const args = {
        message: "Error!",
        description: "Please make sure to fill out all fields of rows in Pickup Windows",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let mappedPickupConstraints = this.state.pickupWindowGrid
      .filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "",
      )
      .map((e) => {
        return {
          day: e[0].value.replace(/\s/g, ""),
          timeStart: e[1].value.replace(/\s/g, ""),
          timeEnd: e[2].value.replace(/\s/g, ""),
          cutoffTime: e[3].value.replace(/\s/g, ""),
        };
      });

    let pickupConstraints = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      exceptions: [],
    };
    let mappedPickup = mappedPickupConstraints.every((e) => {
      if (pickupConstraints[e.day] != null) {
        pickupConstraints[e.day].push({
          pickupWindow: {
            timeStart: e.timeStart,
            timeEnd: e.timeEnd,
          },
          cutoffTime: e.cutoffTime,
        });
      } else {
        return false;
      }
      return true;
    });

    if (mappedPickup === false) {
      const args = {
        message: "Error!",
        description:
          "Please add make sure all weekdays in the Pickup Windows are valid (format: monday, tuesday, etc.)",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    // STEP 3: SCHEDULED WINDOWS
    // check if any rows are incomplete (some empty values but NOT all)
    let scheduledComplete = true;
    for (const e of this.state.scheduledWindowGrid) {
      if (e.some((x) => x.value.replace(/\s/g, "") === "") && !e.every((x) => x.value.replace(/\s/g, "") === "")) {
        scheduledComplete = false;
      }
    }
    if (!scheduledComplete) {
      const args = {
        message: "Error!",
        description:
          "In you are adding/modifying scheduled windows, please make sure to fill out all fields of rows in Scheduled Windows",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let mappedScheduledDeliveryPickupConstraints = this.state.scheduledWindowGrid
      .filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "" &&
          x[4].value.replace(/\s/g, "") !== "" &&
          x[4].value.replace(/\s/g, "") !== "",
      )
      .map((e) => {
        return {
          day: e[0].value.replace(/\s/g, ""),
          deliveryTimeStart: e[1].value.replace(/\s/g, ""),
          deliveryTimeEnd: e[2].value.replace(/\s/g, ""),
          pickupTimeStart: e[3].value.replace(/\s/g, ""),
          pickupTimeEnd: e[4].value.replace(/\s/g, ""),
          cutoffTime: e[5].value.replace(/\s/g, ""),
        };
      });

    let scheduledDeliveryExceptions = [
      {
        date: "2022-01-01",
        deliveryWindow: {
          timeStart: "00:00",
          timeEnd: "00:00",
        },
        pickupWindow: {
          timeStart: "00:00",
          timeEnd: "00:00",
        },
        cutoffTime: "00:00",
      },
    ];
    let scheduledDeliveryPickupConstraints = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      exceptions: scheduledDeliveryExceptions,
    };
    let mappedScheduledDeliveryPickup = mappedScheduledDeliveryPickupConstraints.every((e) => {
      if (scheduledDeliveryPickupConstraints[e.day] != null) {
        scheduledDeliveryPickupConstraints[e.day].push({
          deliveryWindow: {
            timeStart: e.deliveryTimeStart,
            timeEnd: e.deliveryTimeEnd,
          },
          pickupWindow: {
            timeStart: e.pickupTimeStart,
            timeEnd: e.pickupTimeEnd,
          },
          cutoffTime: e.cutoffTime,
        });
      } else {
        return false;
      }
      return true;
    });

    if (mappedScheduledDeliveryPickup === false) {
      const args = {
        message: "Error!",
        description:
          "Please add make sure all weekdays in the Scheduled Windows are valid (format: monday, tuesday, etc.)",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let includeScheduledDeliveryPickup = false;
    for (const day in scheduledDeliveryPickupConstraints) {
      // if at least one day is not empty than we need to send this to the backend
      if (day != "exceptions" && scheduledDeliveryPickupConstraints[day].length > 0) {
        includeScheduledDeliveryPickup = true;
        break;
      }
    }

    let validSpeedPricing = this.state.speedPricing.every((sp) => {
      let localesGood = true;
      if (sp.locales != null) {
        localesGood = sp.locales.every((x) => {
          if (x.name == null || x.description == null || x.name == "" || x.description == "") return false;
          return true;
        });
      }
      if (
        sp.speedPricingType == null ||
        sp.price == null ||
        sp.name == null ||
        sp.description == null ||
        sp.speedPricingType === "" ||
        sp.price === "" ||
        sp.name === "" ||
        sp.description === "" ||
        !localesGood
      )
        return false;
      return true;
    });

    if (validSpeedPricing === false) {
      const args = {
        message: "Error!",
        description: "Please make sure to fill out type, price, name, description for each entry in speed pricing",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }
    // STEP 4: WEIGHT BAND PRICING
    const filteredWeightBandsGrid = this.state.weightPricingWindowGrid
      .map((band) => [{ value: band[0].value.trim() }, { value: band[1].value.trim() }])
      .filter((band) => band[0].value || band[1].value);
    const weightIndices = filteredWeightBandsGrid.map((band) => Number(band[0].value));

    if (!areNumbersSequential(weightIndices)) {
      const args = {
        message: "Error!",
        description: "Weight bands must be in ascending order without duplicate bands.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    const isWeightsValid = isValidateWeightBandsBody(filteredWeightBandsGrid);
    if (!isWeightsValid) return;

    const body = {
      originZone: values.originZone,
      destinationZone: values.destinationZone,
      inTransitDays: Number(values.inTransitDays),
      surcharge: Number(values.surcharge),
      deliveryConstraintsByWeek: deliveryConstraints,
      pickupConstraintsByWeek: pickupConstraints,
    };

    if (filteredWeightBandsGrid.length) {
      const weightPricingBody = filteredWeightBandsGrid.map((arr) => {
        return { weightInPounds: Number(arr[0].value), price: Number(arr[1].value) };
      });

      body.weightPricing = { pricingRows: weightPricingBody };
    }

    if (includeScheduledDeliveryPickup)
      body.scheduledDeliveryPickupConstraintsByWeek = scheduledDeliveryPickupConstraints;

    if (this.state.speedPricing?.length) {
      body.speedPricing = {
        pricingRows: this.state.speedPricing.map((e) => {
          return { ...e, price: Number(e.price) };
        }),
      };
    }

    this.setState({ loading: true });
    let promise = addShppingRule(body, this.state.merchant.id);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Shipping Rule Added.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loading: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not add shipping rule. (${error.message})`,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loading: false });
      });
  };

  speedPricingSelectType = (type, index) => {
    if (this.state.speedPricing[index] != null) {
      let newSpeedPricing = this.state.speedPricing;
      if (ratesDescriptionList.find((e) => e.speedPricingType === type)) {
        let myObj = JSON.parse(JSON.stringify(ratesDescriptionList.find((e) => e.speedPricingType === type)));
        if (newSpeedPricing != null) {
          myObj.price = newSpeedPricing[index]["price"];
        } else {
          delete myObj.price;
        }
        newSpeedPricing[index] = myObj;
        this.setState({ speedPricing: newSpeedPricing });
      }
    }
  };

  handleTextChange = (index, fieldName, e, locale = null) => {
    if (locale == null) {
      let newSpeedPricing = this.state.speedPricing;
      newSpeedPricing[index][fieldName] = e?.target?.value ? e.target.value : e;
      this.setState({ speedPricing: newSpeedPricing });
    } else {
      let newSpeedPricing = this.state.speedPricing;
      let foundIndex = newSpeedPricing[index]["locales"].findIndex((x) => x.locale === locale);
      newSpeedPricing[index]["locales"][foundIndex][fieldName] = e.target.value;
      this.setState({ speedPricing: newSpeedPricing });
    }
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    link.href =
      `/merchants/${this.state.merchant.name}/viewShippingRules?merchantId=${this.state.merchant.id}` +
      (this.state.currentSearchParam ? `&searchParam=${this.state.currentSearchParam}` : "");
    document.body.appendChild(link);
    link.click();
  };

  render() {
    return this.state.merchant == null ? (
      <div className={"addShippingRules-container"}>
        <Spin />
      </div>
    ) : (
      <div className={"addShippingRules-container"}>
        <h1>
          <Button type="primary" onClick={this.clickBackButton}>
            <ArrowLeftOutlined />
          </Button>{" "}
          Merchants/{this.state.merchant.name}/Add Shipping Rules
        </h1>
        <Form onFinish={(e) => this.addShippingRule(e)} layout="vertical" name="Add a Driver">
          <Row gutter={16} style={{ marginBottom: 10 }}>
            <Col span={8}>
              <Card bordered={false} title="Zone Info">
                <Form.Item
                  name="originZone"
                  rules={[{ required: true, message: "Origin Zone is a required field" }]}
                  label="Origin Zone"
                >
                  <Select
                    showSearch
                    name="originZone"
                    placeholder="*Origin Zone"
                    options={this.state.zones}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="destinationZone"
                  rules={[{ required: true, message: "Destination Zone is a required field" }]}
                  label="Destination Zone"
                >
                  <Select
                    showSearch
                    name="destinationZone"
                    placeholder="*Origin Zone"
                    options={this.state.zones}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="inTransitDays"
                  rules={[{ required: true, message: "In Transit Days is a required field" }]}
                  label="In Transit Days"
                >
                  <Input type="number" name="inTransitDays" ref={(e) => (this.inTransitDays = e)} />
                </Form.Item>
                <Form.Item
                  name="surcharge"
                  rules={[{ required: true, message: "Surcharge is a required field" }]}
                  label="Surcharge"
                >
                  <Input type="number" name="surcharge" ref={(e) => (this.surcharge = e)} />
                </Form.Item>
              </Card>
            </Col>
            <Col span={16}>
              <Row gutter={16} style={{ marginBottom: 10 }}>
                <Col span={12}>
                  <WindowCard
                    title="Delivery Windows"
                    dataSource={this.state.deliveryWindowGrid}
                    className="custom-sheet"
                    sheetRenderer={this.sheetRenderer}
                    rowRenderer={this.rowRenderer}
                    cellRenderer={this.cellRenderer}
                    onCellsChanged={(changes, additions) => {
                      this.handleCellsChanged("deliveryWindowGrid", changes, additions);
                    }}
                    addRows={() => this.handleAddRows("deliveryWindowGrid")}
                    onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("deliveryWindowGrid", num)}
                    numRowsToAdd={this.state.numRowsToAdd["deliveryWindowGrid"]}
                  />
                </Col>
                <Col span={12}>
                  <WindowCard
                    title="Pickup Windows"
                    dataSource={this.state.pickupWindowGrid}
                    className="custom-sheet"
                    sheetRenderer={this.sheetRenderer}
                    rowRenderer={this.rowRenderer}
                    cellRenderer={this.cellRenderer}
                    onCellsChanged={(changes, additions) => {
                      this.handleCellsChanged("pickupWindowGrid", changes, additions);
                    }}
                    addRows={() => this.handleAddRows("pickupWindowGrid")}
                    onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("pickupWindowGrid", num)}
                    numRowsToAdd={this.state.numRowsToAdd["pickupWindowGrid"]}
                  />
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <WindowCard
                    title="Scheduled Windows"
                    dataSource={this.state.scheduledWindowGrid}
                    className="custom-sheet"
                    sheetRenderer={(props) => this.sheetRenderer({ ...props, selectedColumns: scheduledColumns })}
                    rowRenderer={(props) => this.rowRenderer({ ...props, selectedColumns: scheduledColumns })}
                    cellRenderer={(props) => this.cellRenderer({ ...props, selectedColumns: scheduledColumns })}
                    onCellsChanged={(changes, additions) => {
                      this.handleCellsChanged("scheduledWindowGrid", changes, additions, 6);
                    }}
                    addRows={() => this.handleAddRows("scheduledWindowGrid")}
                    onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("scheduledWindowGrid", num)}
                    numRowsToAdd={this.state.numRowsToAdd["scheduledWindowGrid"]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={14} style={{ marginBottom: 10 }}>
            <Col span={8}>
              <WindowCard
                title="Weight Bands"
                dataSource={this.state.weightPricingWindowGrid}
                className="custom-sheet"
                sheetRenderer={(props) => this.sheetRenderer({ ...props, selectedColumns: weightPricingColumns })}
                rowRenderer={(props) => this.rowRenderer({ ...props, selectedColumns: weightPricingColumns })}
                cellRenderer={this.cellRenderer}
                onCellsChanged={(changes, additions) => {
                  this.handleCellsChanged("weightPricingWindowGrid", changes, additions);
                }}
                addRows={() => this.handleAddRows("weightPricingWindowGrid")}
                onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("weightPricingWindowGrid", num)}
                numRowsToAdd={this.state.numRowsToAdd["weightPricingWindowGrid"]}
              />
            </Col>
            <Col span={16}>
              <Card bordered={false} title={"Services"}>
                <Form.List name={"speedPricing"}>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <>
                          <Row gutter={24} style={{ marginBottom: 10 }}>
                            <Col span={4}>
                              <Form.Item
                                {...restField}
                                validateTrigger={["onChange", "onBlur"]}
                                fieldKey={[fieldKey, `speedPricingType`]}
                                name={[name, `speedPricingType`]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please choose a type",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  name="type"
                                  placeholder="*Type"
                                  options={speedPricingTypes}
                                  filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(e) => this.speedPricingSelectType(e, name)}
                                  value={this.state.speedPricing[name].speedPricingType}
                                />
                              </Form.Item>
                            </Col>

                            {this.state.speedPricing[name].speedPricingType && (
                              <>
                                <Col span={4}>
                                  <Form.Item
                                    {...restField}
                                    validateTrigger={["onChange", "onBlur"]}
                                    fieldKey={[fieldKey, "price"]}
                                    name={[name, "price"]}
                                  >
                                    <Input
                                      type="number"
                                      placeholder="*Price"
                                      value={this.state.speedPricing[name].price}
                                      onChange={(e) => this.handleTextChange(name, "price", e)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item
                                    {...restField}
                                    validateTrigger={["onChange", "onBlur"]}
                                    fieldKey={[fieldKey, "name"]}
                                    name={[name, "name"]}
                                  >
                                    En:{" "}
                                    <Input
                                      type="text"
                                      placeholder="*Name"
                                      value={this.state.speedPricing[name].name}
                                      style={{ width: "80%" }}
                                      onChange={(e) => this.handleTextChange(name, "name", e)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={10}>
                                  <Form.Item
                                    {...restField}
                                    validateTrigger={["onChange", "onBlur"]}
                                    fieldKey={[fieldKey, "description"]}
                                    name={[name, "description"]}
                                  >
                                    {""}
                                    <Input
                                      type="text"
                                      placeholder="*Description"
                                      value={this.state.speedPricing[name].description}
                                      onChange={(e) => this.handleTextChange(name, "description", e)}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                            {fields.length > 0 ? (
                              <Col span={2}>
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => {
                                    remove(name);
                                    let newArr = this.state.speedPricing;
                                    newArr.splice(name, 1);
                                    this.setState({ speedPricing: newArr });
                                  }}
                                />
                              </Col>
                            ) : null}
                          </Row>
                          {this.state.speedPricing[name] != null &&
                            this.state.speedPricing[name].speedPricingType != null &&
                            this.state.speedPricing[name].locales.map((x) => {
                              return (
                                <>
                                  <Row gutter={24} style={{ marginBottom: 10 }}>
                                    <Col span={4}></Col>
                                    <Col span={4}></Col>

                                    <Col span={4}>
                                      <Form.Item
                                        {...restField}
                                        validateTrigger={["onChange", "onBlur"]}
                                        fieldKey={[fieldKey, `${x.locale}Name`]}
                                        name={[name, `${x.locale}Name`]}
                                      >
                                        {x.locale}:{" "}
                                        <Input
                                          type="text"
                                          placeholder="*Name"
                                          value={x.name}
                                          style={{ width: "80%" }}
                                          onChange={(e) => this.handleTextChange(name, `name`, e, x.locale)}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col span={10}>
                                      <Form.Item
                                        {...restField}
                                        validateTrigger={["onChange", "onBlur"]}
                                        fieldKey={[fieldKey, `${x.locale}Description`]}
                                        name={[name, `${x.locale}Description`]}
                                      >
                                        {""}
                                        <Input
                                          type="text"
                                          placeholder="*Description"
                                          value={x.description}
                                          onChange={(e) => this.handleTextChange(name, `description`, e, x.locale)}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={24} style={{ marginBottom: 10 }}>
                                    <Col span={4}></Col>
                                    <Col span={4}></Col>

                                    <Col span={4}>
                                      <Form.Item
                                        {...restField}
                                        validateTrigger={["onChange", "onBlur"]}
                                        fieldKey={[fieldKey, `dispatcher`]}
                                        name={[name, `dispatcher`]}
                                      >
                                        <Select
                                          options={this.state.dispatchers}
                                          placeholder="Dispatcher"
                                          onChange={(e) => this.handleTextChange(name, `dispatcher`, e)}
                                          name="dispatcher"
                                          value={this.state.speedPricing[name].dispatcher}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                            let newArr = this.state.speedPricing;
                            newArr.push({});
                            this.setState({ speedPricing: newArr });
                          }}
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 10 }}>
            <Col span={8}>
              <Card bordered={false} title="Add Shipping Rule">
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={this.state.loading}>
                    Add Shipping Rule
                  </Button>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

AddShippingRules.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AddShippingRules);
