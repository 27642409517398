import {
  AlertOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CompassOutlined,
  PlusCircleOutlined,
  RightCircleOutlined,
  ScanOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import flatMap from "lodash/flatMap";
import isEmpty from "lodash/isEmpty";
import Papa from "papaparse";

export const mapDriverApplicantStatusToColour = (orderStatus) => {
  switch (orderStatus) {
    case "APPLICATION_STARTED":
      return "volcano";
    case "APPLICATION_COMPLETED":
    case "INTERVIEW_LINK_SENT":
    case "INTERVIEW_SUCCESS":
    case "BACKGROUND_CHECK_SUCCESS":
    case "CONTRACT_SIGNED":
    case "HIRED":
      return "green";
    case "REJECTED":
      return "red";
    default:
      return "blue";
  }
};

export const mapDriverApplicantHoursPerWeek = (hoursPerWeek) => {
  switch (hoursPerWeek) {
    case "4To10":
      return "4 to 10 hours";
    case "8To12":
      return "8 to 12 hours";
    case "10To20":
      return "10 to 20 hours";
    case "20To25":
      return "20 to 25 hours";
    case "moreThan25":
      return "More than 25 hours";
    default:
      return "N/A";
  }
};

export function updateQueryStringParameter(uri, key, value) {
  value = encodeURIComponent(value);
  // remove the hash part before operating on the uri
  var i = uri.indexOf("#");
  var hash = i === -1 ? "" : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";

  if (value === null) {
    // remove key-value pair if value is specifically null
    uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), "");
    if (uri.slice(-1) === "?") {
      uri = uri.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (uri.indexOf("?") === -1) uri = uri.replace(/&/, "?");
  } else if (uri.match(re)) {
    uri = uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash;
}

export function mapStatusToColour(orderStatus) {
  switch (orderStatus) {
    case "CREATED":
    case "ARCHIVED":
      return "";
    case "GEOCODED":
    case "PICKUP_READY":
    case "CARRIER_PICKED_UP":
      return "blue";
    case "INVALIDTIME":
    case "GEOCODEDFAILED":
    case "OUT_OF_FSA":
    case "RATE_FAILED":
    case "UNSUPPORTED_WEIGHT":
      return "volcano";
    case "DELIVERY_EN_ROUTE":
      return "cyan";
    case "DELIVERY_COMPLETED":
      return "green";
    case "DELIVERY_FAILED":
    case "FAILED":
      return "red";
    case "DELIVERED":
      return "green";
    default:
      return "blue";
  }
}

export function mapStatusToShortName(orderStatus) {
  switch (orderStatus) {
    case "CREATED":
      return "CREATED";
    case "GEOCODED":
      return "GEOCODED";
    case "PICKUP_READY":
      return "PICKUP READY";
    case "CARRIER_PICKED_UP":
      return "CARRIER PICKED UP";
    case "INVALIDTIME":
      return "INVALID TIME";
    case "GEOCODEDFAILED":
      return "GEOCODED FAILED";
    case "OUT_OF_FSA":
      return "OUT OF FSA";
    case "RATE_FAILED":
      return "RATE FAILED";
    case "DELIVERY_EN_ROUTE":
      return "ACTIVE";
    case "DELIVERY_COMPLETED":
      return "COMPLETED";
    case "DELIVERY_FAILED":
      return "FAILED";
    case "ARCHIVED":
      return "ARCHIVED";
    case "SHIPMENT_CREATED":
      return "SHIPMENT CREATED";
    case "SHIPMENT_PROCESSING":
      return "SHIPMENT PROCESSING";
    case "RECEIVED_AT_DESTINATION":
      return "RECEIVED AT DESTINATION";
    case "OUT_FOR_DELIVERY":
      return "OUT FOR DELIVERY";
    case "DELIVERED":
      return "DELIVERED";
    case "UNSUPPORTED_WEIGHT":
      return "UNSUPPORTED WEIGHT";

    default:
      return orderStatus;
  }
}

export const ORDER_STATUSES = [
  "CREATED",
  "GEOCODED",
  "PICKUP_READY",
  "CARRIER_PICKED_UP",
  "INVALIDTIME",
  "GEOCODEDFAILED",
  "OUT_OF_FSA",
  "RATE_FAILED",
  "DELIVERY_COMPLETED",
  "DELIVERY_FAILED",
  "ARCHIVED",
  "ACTIVE",
  "SHIPMENT_CREATED",
  "SHIPMENT_PROCESSING",
  "RECEIVED_AT_DESTINATION",
  "OUT_FOR_DELIVERY",
  "DELIVERED",
  "UNSUPPORTED_WEIGHT",
];

export function mapOrganizationToTDP(organizationId) {
  switch (organizationId) {
    case "L7gQTgefK7~sp9w6MVEXMF7Q":
      return "BoxKnight";
    case "G4Ul5Nm7Xn5dm0yI2KD2LDaS":
      return "Livrapide";
    case "JurSUQlJaglQtEjEyko9D1b":
      return "Go Colis";
    case "8RU2rlqK79vRfcC1hRIJH5jd":
      return "GTA Courrier";
    case "*c~FlO4xXqJOw1vFUPl7oGaA":
      return "Interactive Behaviour";
    default:
      return "";
  }
}

export const formatAvailabilties = (availabilities) => {
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  let finalRes = [];
  for (const d of days) {
    let currentAv = availabilities.filter((x) => x.day === d);
    if (currentAv.length > 0) {
      let final = d + ":";

      let am = currentAv.find((x) => x.time === "AM");
      if (am) final = final + " " + am.time;

      let pm = currentAv.find((x) => x.time === "PM");
      if (pm) final = final + " " + pm.time;

      finalRes.push(final);
    }
  }
  return finalRes;
};

export const formatRequirements = (requirements) => {
  let passingRequirements = {
    onePlusYearsDrivingInNorthAmerica: true,
    eighteenPlus: true,
    cleanDrivingRecordAndInsurance: true,
    consentToBackgroundCheckAndDriverAbstract: true,
    customerServiceSkills: true,
    smartphoneWithData: true,
    vehicleWithInsurance: true,
  };

  let passingKeys = Object.keys(passingRequirements);
  let requirementsKeys = Object.keys(requirements);
  let requirementsValues = Object.values(requirements);

  let requirementsSuccess =
    requirementsKeys.length === passingKeys.length &&
    passingKeys.every((key) => requirementsKeys.includes(key)) &&
    requirementsValues.every((x) => x === true);
  return requirementsSuccess; // <Tag color='green'>Passed</Tag> : <Tag color='red'>Failed</Tag>
};

export const validateOrderCsv = (csvData) => {
  if (!csvData.length) {
    return ["File cannot be empty"];
  }

  const errors = [];

  for (let i = 0; i < csvData.length; i++) {
    const { ids, ...rest } = csvData[i];
    // This Order ID CSV template should only have one column with `ids` as header
    // so if we get any other columns, we want to error out
    const hasUnexpectedTemplate = !!Object.values(rest).length || !!Object.keys(rest).length;
    if (hasUnexpectedTemplate) {
      errors.push("Bad format CSV. Please do not adjust the template.");
      return errors;
    }
    if (!ids) {
      errors.push(`Line ${i + 1}: order ID: '${ids}' must has a value`);
    }
  }

  return errors;
};

const MAX_FILE_SIZE = 100000;

export const parseCSV = (props) => {
  const { csvfile, onComplete, onError } = props;

  if (csvfile.size > MAX_FILE_SIZE) {
    return onError?.(`CSV file exceeds maximum limit of ${MAX_FILE_SIZE / 1000} KB`);
  }

  Papa.parse(csvfile, {
    complete: async (results) => {
      const data = results?.data || [];
      onComplete && onComplete(data);
    },
    error: (error) => {
      onError && onError(error.message);
    },
    header: true,
    skipEmptyLines: true,
  });
};

export const getDetailFromAddressComponent = (key, addressComponents) => {
  let segmentName = "long_name";
  if (key === "administrative_area_level_1") segmentName = "short_name";

  return (addressComponents.filter((component) => component.types.includes(key))[0] || {})[segmentName];
};

export const getAddressComponentValue = (field, addressComponents) => {
  let addressComponentValue;
  switch (field) {
    case "street":
      addressComponentValue = `${getDetailFromAddressComponent("street_number", addressComponents) || ""} ${
        getDetailFromAddressComponent("route", addressComponents) || ""
      }`;
      break;
    case "city":
      addressComponentValue = getDetailFromAddressComponent("locality", addressComponents);
      // attempt to get the sublocality
      if (isEmpty(addressComponentValue)) {
        addressComponentValue = getDetailFromAddressComponent("sublocality", addressComponents);
      }
      break;
    case "province":
      addressComponentValue = getDetailFromAddressComponent("administrative_area_level_1", addressComponents);
      break;
    case "postalCode":
      addressComponentValue = getDetailFromAddressComponent("postal_code", addressComponents);
      break;
    case "country":
      addressComponentValue = getDetailFromAddressComponent("country", addressComponents);
      break;
  }
  return addressComponentValue;
};

export const findDuplicatePostalCodes = (newPostalCodes = [], existingZones = []) => {
  const allExistingPostalCodes = flatMap(existingZones.map((zone) => zone.locationPricing.pricingRows)).map(
    (pricingRow) => pricingRow.postalPrefix,
  );
  const duplicatePostalCodes = [];

  for (const newPostalCode of newPostalCodes) {
    const foundExistingPostalCodeForMerchant = allExistingPostalCodes.find(
      (existingCode) => existingCode.trim().toUpperCase() === newPostalCode.trim().toUpperCase(),
    );
    if (foundExistingPostalCodeForMerchant) {
      duplicatePostalCodes.push(newPostalCode);
    }
  }
  return duplicatePostalCodes;
};

export const findDuplicateZoneName = (newZoneName = "", existingZones = []) => {
  const foundExistingZoneName = existingZones.find(
    (zone) => zone.name.trim().toUpperCase() === newZoneName.trim().toUpperCase(),
  );
  return foundExistingZoneName;
};

export const fixEventTitle = (ev) =>
  ev
    .toLocaleLowerCase()
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const chooseIcon = (eventName) => {
  switch (eventName) {
    case "DELIVERY_FAILED":
    case "CANCELLED":
    case "FAILED":
    case "ARCHIVED":
      return <WarningOutlined style={{ color: "#FF3353" }} />;
    case "GEOCODEDFAILED":
    case "OUT_OF_FSA":
    case "RATE_FAILED":
    case "UNSUPPORTED_WEIGHT":
      return <AlertOutlined style={{ color: "#FF904E" }} />;
    case "GEOCODED":
      return <CompassOutlined style={{ color: "#30606B" }} />;
    case "CREATED":
      return <PlusCircleOutlined style={{ color: "#30606B" }} />;
    case "DELIVERED":
      return <CheckCircleOutlined style={{ color: "#00875A" }} />;
    case "OUT_FOR_DELIVERY":
      return <CarOutlined style={{ color: "#30606B" }} />;
    case "CARRIER_PICKED_UP":
      return <ScanOutlined style={{ color: "#30606B" }} />;
    default:
      return <RightCircleOutlined style={{ color: "#30606B" }} />;
  }
};

export const getCorrectDateFormat = (timestamp) => {
  return new Date(timestamp.toString().length === 10 ? timestamp * 1000 : timestamp);
};
