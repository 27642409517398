import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Progress,
  Row,
  Select,
  Table,
  TimePicker,
  notification,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { patchWindows } from "../util/APIUtils";
import "./Dashboard.css";

export class ChangeWindow extends Component {
  constructor(props) {
    super(props);
    this.notes = React.createRef();
    this.externalNotes = React.createRef();
    this.state = {
      orders: null,
      selectedOrder: null,
      loading: false,
      hasMore: true,
      selectedOrders: [],
      loadingTimeWindowChange: false,
      formValues: {
        fromDate: null,
        fromTime: null,
        toDate: null,
        toTime: null,
        category: null,
        checked: false,
      },
      checkDisabled: false,
      showForm: false,
      errorList: [],
      successList: [],
      showWarningList: false,
      status: "active",
      categoryOptions: [
        { label: "DRIVER_SHORTAGE", value: "DRIVER_SHORTAGE" },
        { label: "DRIVER_NO_SHOW", value: "DRIVER_NO_SHOW" },
        { label: "PACKAGE_NOT_FOUND", value: "PACKAGE_NOT_FOUND" },
        { label: "NOT_GIVEN_BY_RETAILER", value: "NOT_GIVEN_BY_RETAILER" },
        { label: "PRODUCTION_DELAY", value: "PRODUCTION_DELAY" },
        { label: "NO_PREVIOUS_WINDOW_ASSIGNED", value: "NO_PREVIOUS_WINDOW_ASSIGNED" },
        { label: "TO_BETTER_OPTIMIZE", value: "TO_BETTER_OPTIMIZE" },
        { label: "SET_TO_PICK_UP_READY", value: "SET_TO_PICK_UP_READY" },
        { label: "CX_DX_ACTION", value: "CX_DX_ACTION" },
        { label: "CITY_TO_CITY_MISSED_TRUCK_CUTOFF", value: "CITY_TO_CITY_MISSED_TRUCK_CUTOFF" },
        { label: "SENT_TO_WRONG_CITY", value: "SENT_TO_WRONG_CITY" },
      ],
    };
  }

  // mapIdToMerchant = (merchantId) => {
  //     let merchant = this.props.merchants.find(obj => {
  //         return obj.id === merchantId
  //     });
  //     if (merchant != null) {
  //         return merchant.name;
  //     } else {
  //         return ""
  //     }
  // };

  handleTimeWindowChange = (e) => {
    this.setState({ errorList: [] });
    this.setState({ successList: [] });
    this.setState({ status: "active" });

    if (this.props.selectedRowKeys.length === 0) {
      const args = {
        message: "Error!",
        description: "Please select at least one order.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ loadingTimeWindowChange: true });

    const myFromDateTime = new Date(this.state.formValues.fromDate);
    myFromDateTime.setHours(0, 0, 0, 0);
    myFromDateTime.setMinutes(0, 0, 0, 0);
    myFromDateTime.setHours(this.state.formValues.fromTime.hours());
    myFromDateTime.setMinutes(this.state.formValues.fromTime.minutes());
    const finalFromDateTime = moment(myFromDateTime).valueOf();

    const myToDateTime = new Date(this.state.formValues.toDate);
    myToDateTime.setHours(0, 0, 0, 0);
    myToDateTime.setMinutes(0, 0, 0, 0);
    myToDateTime.setHours(this.state.formValues.toTime.hours());
    myToDateTime.setMinutes(this.state.formValues.toTime.minutes());
    const finalToDateTime = moment(myToDateTime).valueOf();

    const body = {
      completeAfter: finalFromDateTime,
      completeBefore: finalToDateTime,
      changedBy: this.props.user,
      category: this.state.formValues.category,
    };
    if (this.notes && this.notes.value != null && this.notes.value.trim() != "") body.notes = this.notes.value;

    if (this.state.formValues.checked != null && this.state.formValues.checked)
      body.showExternalNotes = this.state.formValues.checked;
    if (
      this.state.formValues.checked != null &&
      this.state.formValues.checked &&
      this.externalNotes &&
      this.externalNotes.value != null &&
      this.externalNotes.value.trim() != "" &&
      this.state.formValues.checked
    )
      body.externalNotes = this.externalNotes.value;

    const finalList = this.props.selectedRowKeys;

    const patchWindowsAll = async () => {
      // initialize errorList
      let newErrorList = [];
      let newSuccessList = [];
      for (const orderId of finalList) {
        // find the orderId in the orders
        if (this.props.warningList.filter((e) => e.id === orderId).length > 0) {
          const myOrder = this.props.warningList.find((e) => e.id === orderId);
          newErrorList.push(myOrder);
          this.setState({ errorList: newErrorList });
          this.setState({ status: "exception" });
        } else {
          try {
            await patchWindows(body, orderId);
            newSuccessList.push(orderId);
            this.setState({ successList: newSuccessList });
          } catch (e) {
            if (this.props.selectedRows.find((e) => e.id === orderId)) {
              newErrorList.push(this.props.selectedRows.find((e) => e.id === orderId));
            }
            this.setState({ status: "exception" });
          } finally {
            this.setState({ errorList: newErrorList });
          }
        }
      }
      if (newErrorList.length === 0) {
        this.setState({ status: "success" });
        const args = {
          message: "Success!",
          description: "Updated windows for the selected orders",
          duration: 5,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
      }

      this.setState({ loadingTimeWindowChange: false });
      this.setState({ showForm: false });
      this.setState({ formValues: { ...this.state.formValues, fromDate: null } });
      this.setState({ formValues: { ...this.state.formValues, fromTime: null } });
      this.setState({ formValues: { ...this.state.formValues, toDate: null } });
      this.setState({ formValues: { ...this.state.formValues, toTime: null } });
      this.setState({ formValues: { ...this.state.formValues, category: null } });
      this.setState({ errorList: newErrorList });
      this.setState({ showWarningList: false });

      this.props.onOperationComplete && this.props.onOperationComplete(newSuccessList, body);
    };

    this.setState({ loadingTimeWindowChange: true });
    patchWindowsAll();
  };

  render() {
    return (
      <>
        <Button
          style={{ width: "100%" }}
          loading={this.state.loadingTimeWindowChange}
          type={"primary"}
          disabled={this.props.selectedRowKeys.length === 0}
          onClick={() => {
            this.setState({ showModal: true, showWarningList: true });
            this.setState({ showForm: true });
          }}
        >
          Change Window
        </Button>
        <Modal
          title="Change Delivery Window"
          visible={this.state.showModal}
          //onOk={this.handleTimeWindowChange}
          okText="Change Delivery Window"
          onCancel={() => {
            this.setState({ showModal: false });
            this.setState({ successList: [] });
            this.setState({ errorList: [] });
            this.setState({ status: "active" });
            this.setState({ checkDisabled: false });
          }}
          //onOk={() => this.setState({showModal: false})}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          closable={true}
        >
          <>
            {this.state.showForm && (
              <Row gutter={16} className={"search-row"}>
                <Col span={24}>Select a new delivery window for the selected orders.</Col>
              </Row>
            )}
            {this.state.showWarningList && this.props.warningList.length > 0 && (
              <Row gutter={16} className={"window-row"}>
                <Col span={24}>
                  <Alert
                    message="Warning: your selection includes orders in the ACTIVE, FAILED or COMPLETED state for which the delivery window can not be modified. The system will only process the time change for orders in a valid state. The following orders will not be processed."
                    type="warning"
                  />
                </Col>
              </Row>
            )}
            {this.state.errorList.length > 0 && this.state.showForm === false && (
              <div>
                <Row gutter={16} className={"window-row"}>
                  <Col span={24}>
                    <Alert
                      message="Error: the following orders were not updated. (Selected orders that are not in this table were updated correctly)."
                      type="error"
                    />
                  </Col>
                </Row>
                <Row gutter={16} className={"window-row"}>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      columns={this.props.errorColumns}
                      dataSource={this.state.errorList}
                      style={{ whiteSpace: "pre", width: "100%" }}
                      size={"small"}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {this.state.errorList.length === 0 && this.state.showForm === false && this.state.status === "success" && (
              <div>
                <Row gutter={16} className={"window-row"}>
                  <Col span={24}>
                    <Alert message="Success: The selected orders were updated." type="success" />
                  </Col>
                </Row>
              </div>
            )}
            <Row gutter={16} className={"window-row"}>
              <Col span={24}>
                <Progress
                  percent={(
                    (parseFloat(this.state.errorList.length + this.state.successList.length) /
                      this.props.selectedRowKeys.length) *
                    100
                  ).toFixed(0)}
                  status={this.state.status}
                ></Progress>
              </Col>
            </Row>
            {this.state.showForm && (
              <Form onFinish={(e) => this.handleTimeWindowChange(e)} layout="vertical" name="Change Delivery Window">
                <Row gutter={16} className={"window-row"}>
                  <Col span={12}>
                    <Form.Item name="From Date" rules={[{ required: true, message: "From Date is a required field" }]}>
                      <DatePicker
                        placeholder="*From Date"
                        onChange={(value) =>
                          this.setState({ formValues: { ...this.state.formValues, fromDate: value } })
                        }
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                        loading={this.state.loadingTimeWindowChange}
                        disabled={this.state.loadingTimeWindowChange}
                        value={this.state.formValues.fromDate}
                        defaultValue={this.state.formValues.fromDate}
                        defaultPickerValue={this.state.formValues.fromDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="From Time" rules={[{ required: true, message: "From Time is a required field" }]}>
                      <TimePicker
                        placeholder="*From Time"
                        onChange={(value) =>
                          this.setState({ formValues: { ...this.state.formValues, fromTime: value } })
                        }
                        style={{ width: "100%" }}
                        loading={this.state.loadingTimeWindowChange}
                        disabled={this.state.loadingTimeWindowChange}
                        defaultOpenValue={moment("00:00", "HH:mm")}
                        format="HH:mm"
                        use12Hours={true}
                        value={this.state.formValues.fromTime}
                        defaultValue={this.state.formValues.fromTime}
                        defaultPickerValue={this.state.formValues.fromTime}
                        //inputReadOnly={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className={"window-row"}>
                  <Col span={12}>
                    <Form.Item name="To Date" rules={[{ required: true, message: "To Date is a required field" }]}>
                      <DatePicker
                        placeholder="*To Date"
                        onChange={(value) => this.setState({ formValues: { ...this.state.formValues, toDate: value } })}
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                        loading={this.state.loadingTimeWindowChange}
                        disabled={this.state.loadingTimeWindowChange}
                        value={this.state.formValues.toDate}
                        defaultValue={this.state.formValues.toDate}
                        defaultPickerValue={this.state.formValues.toDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="To Time" rules={[{ required: true, message: "To Time is a required field" }]}>
                      <TimePicker
                        placeholder="*To Time"
                        onChange={(value) => this.setState({ formValues: { ...this.state.formValues, toTime: value } })}
                        style={{ width: "100%" }}
                        loading={this.state.loadingTimeWindowChange}
                        disabled={this.state.loadingTimeWindowChange}
                        defaultOpenValue={moment("0000", "HH:mm")}
                        format="HH:mm"
                        use12Hours={true}
                        value={this.state.formValues.toTime}
                        defaultValue={this.state.formValues.toTime}
                        defaultPickerValue={this.state.formValues.toTime}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className={"window-row"}>
                  <Col span={12}>
                    <Form.Item name="Category" rules={[{ required: true, message: "Category is a required field" }]}>
                      <Select
                        placeholder="*Category"
                        onChange={(value) => {
                          if (
                            value === "DRIVER_SHORTAGE" ||
                            value === "DRIVER_NO_SHOW" ||
                            value === "PACKAGE_NOT_FOUND" ||
                            value === "NOT_GIVEN_BY_RETAILER" ||
                            value === "PRODUCTION_DELAY"
                          ) {
                            this.setState({ formValues: { ...this.state.formValues, checked: true, category: value } });
                            this.setState({ checkDisabled: true });
                          } else {
                            this.setState({ checkDisabled: false });
                            this.setState({ formValues: { ...this.state.formValues, category: value } });
                          }
                        }}
                        style={{ width: "100%" }}
                        loading={this.state.loadingTimeWindowChange}
                        disabled={this.state.loadingTimeWindowChange}
                        value={this.state.formValues.category}
                        options={this.state.categoryOptions}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} className={"window-row"}>
                  <Col span={24}>
                    <Form.Item
                      name="Internal Notes"
                      rules={[{ required: true, message: "Internal Notes is a required field" }]}
                    >
                      <textarea
                        className="ant-input"
                        ref={(e) => (this.notes = e)}
                        placeholder="*Internal Notes"
                        style={{ width: "100%", margin: "auto", display: "block" }}
                        loading={this.state.loadingTimeWindowChange}
                        disabled={this.state.loadingTimeWindowChange}
                      ></textarea>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} className={"window-row"}>
                  <Col span={24}>
                    <Form.Item
                      name="Show Recipient and Retailer Notes"
                      //rules={[{required: true, message: 'Internal Notes is a required field'}]}
                    >
                      <Checkbox
                        checked={this.state.formValues.checked}
                        disabled={this.state.checkDisabled}
                        onChange={(e) =>
                          this.setState({ formValues: { ...this.state.formValues, checked: e.target.checked } })
                        }
                      >
                        Show Recipient and Retailer Notes
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                {this.state.formValues.checked && (
                  <Row gutter={16} className={"window-row"}>
                    <Col span={24}>
                      <Form.Item
                        name="Retailer and Recipient Notes"
                        rules={[
                          {
                            required: this.state.formValues.checked,
                            message: "Retailer and Recipient Notes is a required field",
                          },
                        ]}
                      >
                        <textarea
                          className="ant-input"
                          ref={(e) => (this.externalNotes = e)}
                          placeholder="*Retailer and Recipient Notes"
                          style={{ width: "100%", margin: "auto", display: "block" }}
                          loading={this.state.loadingTimeWindowChange}
                          disabled={this.state.loadingTimeWindowChange}
                        ></textarea>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={16} window-row>
                  <Col span={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loadingTimeWindowChange}
                        disabled={
                          this.state.formValues.fromDate == null ||
                          this.state.formValues.fromTime == null ||
                          this.state.formValues.toDate == null ||
                          this.state.formValues.toTime == null ||
                          this.state.formValues.category == null
                        }
                      >
                        Change Delivery Window
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </>
        </Modal>
      </>
    );
  }
}

ChangeWindow.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ChangeWindow);
