import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import featureFlagReducer from "./featureFlagReducer";

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  featureFlag: featureFlagReducer,
});
