import { Button, Card, Col, InputNumber, Popover, Row, Select, Tabs, Timeline, notification } from "antd";
import React, { Component } from "react";
import { getWorkerById, scanApp } from "../util/APIUtils";
const { TabPane } = Tabs;

export class ScanInfoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workerNames: null,
      loadingWorkerNames: true,
      showPopover: false,
      receivedLoading: false,
      numPacks: 1,
      cities: [
        { value: "montreal", label: "Montreal" },
        { value: "boltToronto", label: "BOLT - Toronto" },
        { value: "quebecCity", label: "Quebec City" },
        { value: "vancouver", label: "Vancouver" },
        { value: "ottawa", label: "Ottawa" },
      ],
      selectedCity: "montreal",
    };
  }

  componentDidMount() {
    if (this.props.otherScans == null) return [];
    let allWorkerIds = [];
    this.props.otherScans.forEach((elem) => {
      if (elem.workerId != null && !allWorkerIds.includes(elem.workerId)) allWorkerIds.push(elem.workerId);
    });

    this.getAllWorkersById(allWorkerIds);
    //this.setState({loadingWorkerNames: false})
  }

  async getAllWorkersById(allWorkerIds) {
    let myWorkerNames = [];
    for (let i = 0; i < allWorkerIds.length; i++) {
      try {
        const myWorker = await getWorkerById(allWorkerIds[i]);
        myWorkerNames.push({ workerId: allWorkerIds[i], name: myWorker.name });
      } catch (e) {
        const args = {
          message: "Error!",
          description: "Could not load driver names.",
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
      }
    }
    //alert(JSON.stringify(myWorkerNames))
    this.setState({ workerNames: myWorkerNames });
  }

  separateScanIds = () => {
    let scanIds = [];
    if (this.props.otherScans == null) return [];
    this.props.otherScans.forEach((elem) => {
      if (!scanIds.includes(elem.id)) {
        scanIds.push(elem.id);
      }
    });

    return scanIds.map((scanId) => {
      let scans = this.props.otherScans.filter((elem) => elem.id === scanId);
      return {
        id: scanId,
        scans: scans,
      };
    });
  };

  generateDriverScansLog = () => {
    let scandData = this.separateScanIds();
    return scandData.map((elem) => {
      let packageScan = elem.scans.map((e) => {
        return (
          <div>
            {new Date(e.time).toDateString()} {new Date(e.time).toLocaleTimeString()} + (
            {e.isPickup ? "pickup" : "dropoff"}) ({e.taskShortId}) by{" "}
            {this.state.workerNames != null && this.state.workerNames.find((x) => x.workerId === e.workerId) != null
              ? this.state.workerNames.find((x) => x.workerId === e.workerId).name
              : "unavailable"}{" "}
            at{" "}
            <a target="_blank" href={`https://www.google.com/maps/search/${e.location.lat},${e.location.lng}`}>
              GPS Location
            </a>
          </div>
        );
      });
      return (
        <div>
          <h4>Package {elem.id.indexOf(":") === -1 ? 1 : elem.id.substring(elem.id.indexOf(":") + 1)}:</h4>
          {packageScan}
        </div>
      );
    });
  };

  generateWarehouseScans = () => {
    let finalArr = [];
    if (this.props.scanEvents != null) {
      this.props.scanEvents.forEach((x) => {
        let finalX = { id: x.id, events: x.events.filter((y) => y.hasOwnProperty("location")) };
        if (finalX.events.length > 0) finalArr.push(finalX);
      });
      return finalArr;
    } else {
      return [];
    }
  };

  handleOnChange = (value, max) => {
    if (value > max) {
      value = max;
    }
    if (value < 1) {
      value = 1;
    }
    this.setState({ numPacks: value });
  };
  addScan = () => {
    this.setState({ receivedLoading: true });
    // let body = {scannedData: [], location: selectedCity, email: currentUser.email}
    const baseId = this.props.id;
    let packageId = null; // this.props.id
    if (this.state.numPacks === 1) {
      packageId = this.props.id;
    } else {
      packageId = this.props.id + ":" + this.state.numPacks;
    }

    let promise = scanApp(baseId, packageId, this.state.selectedCity);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          type: "success",
          description: "Order updated. Refreshing orders.",
          duration: 3,
        };
        notification.open(args);
        this.props.refreshOrdersFromEdit();
        // this.setState({receivedLoading: false})
      })
      .catch((error) => {
        console.log(error);
        const args = {
          message: "Error!",
          description: "Could not update order",
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ receivedLoading: false });
      });

    this.setState({ numPacks: 1 });
  };

  popoverContent = () => {
    if (this.props.packagesReceivedAt && this.props.packagesReceivedAt.length === this.props.packageCount) {
      return <div>All packages received.</div>;
    } else {
      return (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <div>Select the id number of the package that you want to mark as received.</div>
              <InputNumber
                min={1}
                max={this.props.packageCount}
                onChange={(value) => this.handleOnChange(value, this.props.packageCount)}
                value={this.state.numPacks}
              />
            </Col>
          </Row>

          <Row gutter={16}>&nbsp;</Row>

          <Row gutter={16}>
            <Col span={24}>
              <Select
                showSearch
                style={{ width: 150 }}
                placeholder="Choose a City"
                onSelect={(value) => this.setState({ selectedCity: value })}
                onChange={(value) => this.setState({ selectedCity: value })}
                value={this.state.selectedCity}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={this.state.cities}
              ></Select>
              <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={this.addScan} loading={this.state.receivedLoading}>
                  Add Scan
                </Button>
              </div>
            </Col>
          </Row>
        </>
      );
    }
  };
  render() {
    //if (this.state.loadingWorkerNames) return null;
    let warehouseScans = this.generateWarehouseScans();
    return (
      <Card bordered={false}>
        <Popover
          visible={this.state.showPopover}
          onVisibleChange={(visible) => this.setState({ showPopover: visible })}
          content={() => this.popoverContent()}
          trigger="click"
        >
          <Button
            disabled={
              (this.props.packagesReceivedAt && this.props.packagesReceivedAt.length === this.props.packageCount) ||
              this.props.packageCount === 0
            }
            loading={this.state.receivedLoading}
            type="primary"
          >
            Add a scan manually
          </Button>
        </Popover>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Warehouse Scans" key="1">
            {warehouseScans != null &&
              warehouseScans.length > 0 &&
              warehouseScans.map((e) => {
                return (
                  <Row>
                    <Col span={8}>
                      <h4>Package {e.id.indexOf(":") === -1 ? 1 : e.id.substring(e.id.indexOf(":") + 1)}:</h4>
                      <br />
                      <Timeline>
                        {e.events.map((x) => (
                          <Timeline.Item>
                            {new Date(x.time).toDateString()} {new Date(x.time).toLocaleTimeString()}{" "}
                            {x.location != null
                              ? "(" + x.location.charAt(0).toUpperCase() + x.location.slice(1) + ")"
                              : "(No location available)"}
                            <br />
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </Col>
                  </Row>
                );
              })}
            {(warehouseScans.length == null || warehouseScans.length === 0) && <p>No Warehouse Scans</p>}
          </TabPane>
          <TabPane tab="Driver Scans" key="2">
            {this.props.otherScans != null && this.state.workerNames != null && this.generateDriverScansLog()}
            {this.props.otherScans == null && <p>No driver scans</p>}
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default ScanInfoContent;
