import { datadogRum } from "@datadog/browser-rum";
import { Layout } from "antd";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "./App.css";
import { loadUser } from "./actions/authActions";
import AppHeader from "./components/common/AppHeader";
import Routes from "./router/Routes";
import store from "./store";
const { Content } = Layout;

const App = () => {
  const auth = store.getState()?.auth;
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  useEffect(() => {
    if (auth?.user) {
      datadogRum.setUser({
        email: auth.user,
      });
    }
  }, [auth.user]);

  return (
    <Provider store={store}>
      <Layout style={{ minHeight: "100vh" }}>
        <AppHeader />
        <Content className={"app-content"}>
          <div className={"container"}>
            <Routes />
          </div>
        </Content>
      </Layout>
    </Provider>
  );
};

export default App;
