import { Button, Col, Popover, Row, notification } from "antd";
import { PDFDocument } from "pdf-lib";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLabelByOrderId } from "../util/APIUtils";
import "./Dashboard.css";

export class PrintLabels extends Component {
  constructor(props) {
    super(props);
    this.notes = React.createRef();
    this.externalNotes = React.createRef();
    this.state = {
      orders: null,
      selectedOrder: null,
      loading: false,
      hasMore: true,
      selectedOrders: [],
      loadingLabels: false,
    };
  }

  handlePopoverVisibleChange = (visible) => {
    if (this.props.selectedRows.length > 0) {
      this.setState({ popoverVisible: visible });
    }
  };

  handlePrintLabels = async () => {
    this.setState({ errorList: [] });
    this.setState({ successList: [] });
    this.setState({ status: "active" });

    if (this.props.selectedRowKeys.length === 0) {
      const args = {
        message: "Error!",
        description: "Please select at least one order.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ loadingLabels: true });
    let filteredOrders = this.props.selectedRows;
    const mergedPdf = await PDFDocument.create();
    let errorList = [];
    for (let order of filteredOrders) {
      try {
        let existingPdfBytes = await getLabelByOrderId(order.id).then(async (res) => {
          return res;
        });
        console.log(existingPdfBytes);
        if (typeof existingPdfBytes === "string") {
          const pdf = await PDFDocument.load(existingPdfBytes);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
          });
        } else {
          errorList.push(order.id);
        }
      } catch (e) {
        errorList.push(order.id);
        console.log(e);
      }
    }
    const mergedPdfFile = await mergedPdf.save();
    let data = new Blob([mergedPdfFile], { type: "application/pdf" });
    let pdfUrl = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    tempLink.href = pdfUrl;
    tempLink.setAttribute("download", "BoxKnightLabels.pdf");
    tempLink.click();
    this.setState({ loadingLabels: false });

    if (errorList.length > 0) {
      const args = {
        message: "Error!",
        description: (
          <>
            Could not get labels for the following selected orders:{" "}
            {errorList.map((x) => (
              <li>{x}</li>
            ))}
          </>
        ),
        duration: 0,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
    }
    // catch (e) {
    //   const args = {
    //     message: 'Error!',
    //     description: 'Could not print labels',
    //     duration: 10,
    //     type: 'error',
    //     placement: 'topRight'
    //   };
    //   notification.open(args);
    //   this.setState({loadingLabels: false})
    // }
    this.setState({ loadingLabels: false });
    this.setState({ popoverVisible: false });
  };

  render() {
    const popoverContent = (
      <Col>
        <Row gutter={16} style={{ marginBottom: "1vh" }}>
          <Col>
            <p>Are you sure want to download labels for {this.props.selectedRowKeys.length} orders as a pdf?</p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Button type={"primary"} onClick={() => this.handlePrintLabels()} loading={this.state.loadingLabels}>
              Download PDF
            </Button>
          </Col>
          <Col span={8}>
            <Button loading={this.state.loadingLabels} onClick={() => this.setState({ popoverVisible: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <Popover
          content={popoverContent}
          placement="bottom"
          trigger="click"
          visible={this.state.popoverVisible}
          onVisibleChange={this.handlePopoverVisibleChange}
        >
          <div style={{ width: "100%" }}>
            <Button
              type="primary"
              disabled={this.props.selectedRowKeys.length <= 0}
              loading={this.state.loadingLabels}
              style={{ width: "100%" }}
            >
              Labels
            </Button>
          </div>
        </Popover>
      </>
    );
  }
}

PrintLabels.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PrintLabels);
