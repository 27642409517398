import { Button, Card, Typography } from "antd";
import { get, isEmpty, map } from "lodash";
import React, { Component } from "react";
import OrderTimelineRow from "./OrderTimelineRow";
import PackageTimelineRow from "./PackageTimelineRow";
import { TrackingTimelineEventsModal } from "./TrackingTimelineEventsModal";

export class GoboltTimelineInfoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModalVisible = (showModal) => {
    this.setState({ showModal });
  };

  buildTimeline = () => {
    const timelineEvents =
      get(this.props.order, "timelineEvents", []) || get(this.props.order, "goboltTrackingEvents", []);

    const packagesTimeline = {};
    const orderTimeline = { [this.props.order.id]: [] };

    for (const timelineEvent of timelineEvents) {
      const isOrderLevelEvent = timelineEvent.trackingNumber === this.props.order.id;
      if (isOrderLevelEvent) {
        orderTimeline[this.props.order.id].push(timelineEvent);
        continue;
      }

      if (packagesTimeline[timelineEvent.trackingNumber]) {
        packagesTimeline[timelineEvent.trackingNumber].push(timelineEvent);
      } else {
        packagesTimeline[timelineEvent.trackingNumber] = [timelineEvent];
      }
    }
    return { packagesTimeline, orderTimeline };
  };

  renderTimeline = () => {
    const { packagesTimeline, orderTimeline } = this.buildTimeline();

    if (isEmpty(packagesTimeline) && isEmpty(orderTimeline)) {
      return <Typography.Text>No events available</Typography.Text>;
    }

    return (
      <>
        <OrderTimelineRow events={orderTimeline[this.props.order.id]} id={this.props.order.id} />
        {map(Object.entries(packagesTimeline), (data, key) => {
          return (
            <span key={key}>
              <PackageTimelineRow events={data[1]} index={key} />
            </span>
          );
        })}
      </>
    );
  };

  render() {
    const order = this.props.order;
    const isTimelineEvents = order.timelineEvents && order.timelineEvents.length !== 0;
    const isGoboltTrackingEvents = order.goboltTrackingEvents && order.goboltTrackingEvents.length !== 0;

    // if there are no tracking events then we do not show anything
    if (!isTimelineEvents && !isGoboltTrackingEvents) return "";

    return (
      <Card
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <Typography.Text>Tracking</Typography.Text>
            <Button onClick={() => this.toggleModalVisible(true)}>View Full Timeline</Button>
          </div>
        }
        bordered={false}
      >
        {this.renderTimeline()}
        <TrackingTimelineEventsModal
          timeline={this.buildTimeline()}
          toggleVisible={this.toggleModalVisible}
          isVisible={this.state.showModal}
          orderId={this.props.order.id}
        />
      </Card>
    );
  }
}

export default GoboltTimelineInfoContent;
