import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  authenticated,
  path,
  roles = [],
  requiredRoles = [],
  location,
  ...rest
}) => {
  let myRequiredRoles = ["super_admin"];

  if (requiredRoles) myRequiredRoles = myRequiredRoles.concat(requiredRoles);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated)
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          );
        else if (authenticated && myRequiredRoles.some((r) => roles.includes(r))) {
          return <Component {...props} />;
        } else return <Redirect to={{ pathname: "/" }} />;
      }}
    />
  );
};
export default withRouter(PrivateRoute);
