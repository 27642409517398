import { SearchOutlined } from "@ant-design/icons";
import { notification, Table, Tag } from "antd";
import queryString from "query-string";
import { default as React, useCallback, useEffect, useState } from "react";
import { DEFAULT_PAGINATION_LIMIT } from "../constants/globals";
import { archiveMerchantById, getMerchants, getMerchantsNameSearch } from "../util/APIUtils";
import { INDIGO } from "../util/Colors";
import { buildToastErrorMessage, findUriParams } from "../util/merchants";
import MerchantDashboardActions from "./MerchantDashboardActions";
import MerchantDetails from "./MerchantDetails";
import MerchantNameFilterInput from "./MerchantNameFilterInput.js";
import "./ViewMerchants.css";

const ViewMerchants = ({ isMobile, location }) => {
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    handleFetchMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnOperationComplete = (merchantId, field, value) => {
    const updatedMerchants = [...merchants];
    const merchantFoundIndex = updatedMerchants.find((merchant) => merchant.id === merchantId);
    const merchantFound = merchantFoundIndex > -1;

    const newValueExistsInObject = field.includes(".");

    if (merchantFound) {
      if (newValueExistsInObject) {
        let nameOfObjectFieldOnMerchant = field.split(".")[0];
        let nameOfPropertyInObject = field.split(".")[1];
        updatedMerchants[merchantFoundIndex][nameOfObjectFieldOnMerchant][nameOfPropertyInObject] = value;
      } else {
        updatedMerchants[merchantFoundIndex][field] = value;
      }
      setMerchants(updatedMerchants);
    } else {
      const args = buildToastErrorMessage("Merchant not found in table.");
      notification.open(args);
    }
  };

  const renderNullSafeText = (string) => {
    return string || "";
  };

  const renderLocation = (record) => {
    if (!record?.merchantLocation) return "";

    let res = `${record.merchantLocation.street}, ${record.merchantLocation.city}`;

    if (!!record.merchantLocation.unit) {
      res = `${record.merchantLocation.unit}, ${res}`;
    }
    return res;
  };

  const renderProperties = (record) => {
    return (
      <>
        {!!record.shopifyShop && <Tag color="green">{record.shopifyShop}</Tag>}
        {!!record.createPickup && <Tag color="blue">Auto Pickup</Tag>}
        {!!record.signatureRequired && <Tag color="red">Signature Required</Tag>}
      </>
    );
  };

  const renderNameSearch = ({ columnName, selectedKeys, setSelectedKeys, clearFilters }) => {
    return (
      <MerchantNameFilterInput
        columnName={columnName}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        handleSearch={handleSearch}
        handleReset={handleReset}
      />
    );
  };

  const getColumnSearchProps = (columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) =>
      renderNameSearch({
        columnName,
        selectedKeys,
        setSelectedKeys,
        clearFilters,
      }),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? INDIGO : undefined }} />,
    onFilter: (value, record) => record?.[columnName]?.toString().toLowerCase()?.includes(value?.toLowerCase()) || "",
  });

  const handleSearch = (selectedKeys) => {
    setLoading(true);
    setSearchParam(selectedKeys);

    let promise = getMerchantsNameSearch(selectedKeys);
    promise
      .then((response) => {
        setMerchants(response);
        const updatedUri = findUriParams({ searchParam: selectedKeys[0] });
        window.history.replaceState({}, document.title, updatedUri);
      })
      .catch((error) => {
        const args = buildToastErrorMessage(error.message);
        notification.open(args);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = (clearFilters) => {
    setLoading(true);
    const updatedUri = findUriParams();

    if (updatedUri) {
      window.history.replaceState({}, document.title, updatedUri);
    }

    let promise = getMerchants();
    promise
      .then(() => {
        setSearchParam(null);
        clearFilters();
      })
      .catch(() => {
        const args = buildToastErrorMessage("Could not load merchants.");
        notification.open(args);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFetchMerchants = () => {
    const values = queryString.parse(location.search);
    if (values.searchParam) {
      setSearchParam(values.searchParam);
      handleSearch([values.searchParam]);
    } else {
      setLoading(true);
      let promise = getMerchants(merchants.length, DEFAULT_PAGINATION_LIMIT);
      promise
        .then((response) => {
          setMerchants(response);
        })
        .catch(() => {
          const args = buildToastErrorMessage("Could not load merchants.");
          notification.open(args);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleArchiveMerchant = async (merchantId) => {
    setLoading(true);
    let args;
    try {
      const res = await archiveMerchantById(merchantId);
      args = {
        message: "Success!",
        description: `${res.name} has been archived.`,
        duration: 5,
        type: "success",
        placement: "topRight",
      };
      let updatedUri = findUriParams();
      window.history.replaceState({}, document.title, updatedUri);
    } catch (e) {
      args = buildToastErrorMessage(e.message);
    } finally {
      setLoading(false);
      setSearchParam(null);
      handleFetchMerchants();
    }
    notification.open(args);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: renderNullSafeText,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, record) => renderLocation(record),
    },
    {
      title: "Properties",
      dataIndex: "properties",
      key: "properties",
      render: (_, record) => renderProperties(record),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "15%",
      render: (_, record) => (
        <MerchantDashboardActions
          record={record}
          searchParam={searchParam}
          handleArchiveMerchant={handleArchiveMerchant}
        />
      ),
    },
  ];

  if (searchParam) {
    columns.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderNullSafeText,
    });
  }

  const onRowChange = () => ({
    onClick: (event) => {
      const merchant = event.currentTarget.dataset.rowKey;
      const isUnselectingMerchant = merchant === selectedMerchant;
      if (isUnselectingMerchant) {
        setSelectedMerchant(null);
      } else {
        setSelectedMerchant(merchant);
      }
    },
  });

  const getSortedMerchants = useCallback(() => {
    return merchants.sort((a, b) => a.name.localeCompare(b.name));
  }, [merchants]);

  const renderTableTitle = () => (
    <>
      <h1>Merchants</h1>
      {searchParam && <h3>Showing search results for: {searchParam}</h3>}
    </>
  );

  return (
    <div className="viewMerchants-container">
      {renderTableTitle()}
      <Table
        loading={loading}
        pagination={false}
        size="small"
        columns={columns}
        dataSource={getSortedMerchants()}
        rowKey="id"
        expandedRowRender={(merchant) => (
          <MerchantDetails
            key={merchant.id}
            merchant={merchant}
            isMobile={isMobile}
            onOperationComplete={handleOnOperationComplete}
          />
        )}
        expandIconAsCell={false}
        expandIconColumnIndex={-1}
        onRow={onRowChange}
        expandedRowKeys={[selectedMerchant]}
      />
    </div>
  );
};
export default ViewMerchants;
