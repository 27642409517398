import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { completeTask } from "../util/APIUtils";
//import "./Dashboard.css";
import Modal from "antd/lib/modal/Modal";

export class CompleteTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
    };
  }

  onFinish = async (values) => {
    let orderId = this.props.id;
    let refreshOrders = this.props.refreshOrders;
    let timestamp = undefined;
    if (values.timestamp !== undefined) {
      timestamp = values.timestamp.unix() * 1000;
    }

    this.setState({ loading: true });

    await completeTask(orderId, {
      orderId: orderId,
      taskShortId: values.taskShortId,
      note: values.note,
      timestamp: timestamp,
    });
    setTimeout(() => {
      refreshOrders();
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    let orderId = this.props.id;
    let refreshOrders = this.props.refreshOrders;
    return (
      <div>
        <Button
          type={"primary"}
          onClick={() => {
            this.setState({ showModal: true });
            this.setState({ showForm: true });
          }}
        >
          Manual Complete Task
        </Button>
        <Modal
          title="Manual Complete Task"
          visible={this.state.showModal}
          //onOk={this.handleTimeWindowChange}
          okText="Complete"
          onCancel={() => {
            this.setState({ showModal: false });
            this.setState({ showForm: false });
          }}
          //onOk={() => this.setState({showModal: false})}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          closable={true}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: false }}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="onfleet shortId"
              name="taskShortId"
              rules={[{ required: false, message: "Please input the onfleet shortId!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="DatePicker" name="timestamp">
              <DatePicker showTime />
            </Form.Item>

            <Form.Item
              label="Completion notes"
              name="note"
              rules={[{ required: false, message: "Please input the notes!" }]}
            >
              <Input />
            </Form.Item>

            <Row>
              <Col span={12}>
                <Button
                  type="secondary"
                  style={{ marginLeft: "10px" }}
                  loading={this.state.loading}
                  onClick={async () => {
                    this.setState({ loading: true });
                    await completeTask(orderId, { orderId: orderId });
                    setTimeout(() => {
                      refreshOrders();
                      this.setState({ loading: false });
                    }, 1000);
                  }}
                >
                  Complete without infos
                </Button>
              </Col>
              <Col span={12}>
                <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                  <Button type="primary" htmlType="submit" loading={this.state.loading}>
                    Complete Task Using Form
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

CompleteTask.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CompleteTask);
