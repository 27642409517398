import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Typography } from "antd";
import { default as React } from "react";
import { Link } from "react-router-dom";
import {
  DELETE_SERVICE_USER,
  DELETE_USER,
  EDIT_USER,
  MERCHANT_SERVICE_USERS_TABLE_ACTIONS,
  MERCHANT_USERS_TABLE_ACTIONS,
} from "../../constants/merchants";

const TableRowActions = ({ record = {}, onDeleteUser = () => {}, isLoading = false, merchantId }) => {
  const isServiceUser = record.userGroup === "service";
  const username = record.username;

  const renderMenuItem = (item) => {
    switch (item.key) {
      case EDIT_USER:
        return (
          <Link disabled={isLoading} to={`/merchants/${merchantId}/user/${username}/edit`}>
            {item.label}
          </Link>
        );
      case DELETE_SERVICE_USER:
        return (
          <Typography.Text disabled={isLoading} onClick={() => onDeleteUser(username)} type="danger">
            {item.label}
          </Typography.Text>
        );
      case DELETE_USER:
        return (
          <Typography.Text disabled={isLoading} onClick={() => onDeleteUser(username)} type="danger">
            {item.label}
          </Typography.Text>
        );
      default:
        return null;
    }
  };

  const rowActions = isServiceUser ? MERCHANT_SERVICE_USERS_TABLE_ACTIONS : MERCHANT_USERS_TABLE_ACTIONS;
  const menuProps = (
    <Menu onClick={(e) => e.domEvent.stopPropagation()}>
      {rowActions.map((item) => (
        <Menu.Item key={item.key}>{renderMenuItem(item, record)}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuProps}>
        <Button style={{ border: 0 }} onClick={(e) => e.stopPropagation()}>
          <MoreOutlined rotate={90} />
        </Button>
      </Dropdown>
    </>
  );
};

export default TableRowActions;
