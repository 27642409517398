import { Button, Card, Col, Form, Input, notification, Radio, Row } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { getAddressComponentValue } from "../util/Helpers";
import "./AddMerchant.css";
import AddressAutoComplete from "./AddressAutoComplete";
import "./Common.css";
import useCreateMerchant from "./custom-hooks/useCreateMerchant";

const { useForm } = Form;
const geocoder = new window.google.maps.Geocoder();

const requiredRule = {
  required: true,
  message: "This is a required field.",
};

const whitespaceRule = {
  whitespace: true,
  message: "Whitespaces are not allowed",
};

const addressFieldRule = [
  requiredRule,
  whitespaceRule,
  ({ getFieldValue }) => ({
    async validator({ field }, value) {
      let testValue;
      const street = getFieldValue("street");
      const city = getFieldValue("city");
      const province = getFieldValue("province");
      const postalCode = getFieldValue("postalCode");
      const country = getFieldValue("country");

      const address = `${street}, ${city}, ${province}, ${postalCode}, ${country}`;
      if (isEmpty(street) && isEmpty(city) && isEmpty(province) && isEmpty(postalCode) && isEmpty(country)) {
        return Promise.reject("Unable to geocode address.");
      }

      await geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          const addressComponents = results[0].address_components;
          testValue = getAddressComponentValue(field, addressComponents);
        }
      });

      return value === testValue ? Promise.resolve() : Promise.reject(`Suggested value for ${field}: ${testValue}.`);
    },
  }),
];

const AddMerchant = () => {
  const [form] = useForm();
  const initialFormValues = {
    scanningRequired: false,
    signatureRequired: true,
    taskTimeCreation: "AUTO",
    rateShowingOptionType: "ALWAYSSHOWSAMEDAY",
    createPickup: true,
    dataRedactionEnabled: false,
  };

  const [{ loading }, createMerchant] = useCreateMerchant({
    onComplete: () => {
      form.resetFields();
      notification.open({
        message: "Merchant has been added successfully",
        duration: 3,
        type: "success",
        placement: "topRight",
      });
    },
    onError: () => {
      notification.open({
        message: "Merchant could not be added",
        duration: 3,
        type: "error",
        placement: "topRight",
      });
    },
  });

  const onFinish = ({
    name,
    phone,
    email,
    street,
    city,
    province,
    postalCode,
    country,
    scanningRequired,
    signatureRequired,
    taskTimeCreation,
    rateShowingOptionType,
    createPickup,
    dataRedactionEnabled,
  }) => {
    const merchantParams = {
      name,
      phone: `+1${phone.replace(/[^\d]/g, "")}`,
      email,
      merchantLocation: {
        street,
        city,
        province,
        country,
        postalCode,
      },
      scanningRequired,
      signatureRequired,
      taskTimeCreation,
      rateShowingOptionType,
      createPickup,
      dataRedactionEnabled,
      locationPricing: {
        pricingRows: [],
      },
      deliveryConstraintsByWeek: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
        exceptions: [],
      },
      pickupConstraintsByWeek: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
        exceptions: [],
      },
    };

    form.validateFields().then(() => createMerchant(merchantParams));
  };

  const onAddressAutoCompleteSelectHandler = ({ street, city, province, postalCode, country }) => {
    form.setFieldsValue({
      street,
      city,
      province,
      postalCode,
      country,
    });
  };

  const BasicInformationCard = () => (
    <Card title="Basic Information" bordered={false} className="card-header">
      <Form.Item name="name" rules={[requiredRule, whitespaceRule]}>
        <Input placeholder="Business Name" disabled={loading} />
      </Form.Item>
      <Form.Item name="phone" rules={[requiredRule, whitespaceRule]}>
        <PhoneInput
          inputClass="ant-input"
          inputComponent={Input}
          country={["us", "ca"]}
          placeholder="Phone Number"
          type="tel"
          size="small"
          specialLabel=""
          disabled={loading}
          disableCountryCode
        />
      </Form.Item>
      <Form.Item name="email" rules={[requiredRule, whitespaceRule]}>
        <Input type="email" placeholder="Email Address" disabled={loading} />
      </Form.Item>
    </Card>
  );

  const AddressInformationCard = () => (
    <Card title="Address Information" bordered={false} className="card-header">
      <AddressAutoComplete onAddressSelect={onAddressAutoCompleteSelectHandler} className="mb-24" />
      <Form.Item name="street" rules={addressFieldRule}>
        <Input placeholder="Business Address" disabled={loading} />
      </Form.Item>
      <Form.Item name="unit">
        <Input placeholder="Address Line 2 (optional)" disabled={loading} />
      </Form.Item>
      <Form.Item name="city" rules={addressFieldRule}>
        <Input placeholder="City" disabled={loading} />
      </Form.Item>
      <Form.Item name="province" rules={addressFieldRule}>
        <Input placeholder="State / Province" disabled={loading} />
      </Form.Item>
      <Form.Item name="postalCode" rules={addressFieldRule}>
        <Input placeholder="Postal Code / Zipcode" disabled={loading} />
      </Form.Item>
      <Form.Item name="country" rules={addressFieldRule}>
        <Input placeholder="Country" disabled={loading} />
      </Form.Item>
    </Card>
  );

  const MerchantSettingsCard = () => (
    <Card title="Merchant Settings" bordered={false} className="card-header">
      <Form.Item label="Scanning Required?" colon={false} name="scanningRequired">
        <Radio.Group disabled>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Signature Required?" colon={false} name="signatureRequired">
        <Radio.Group disabled={loading}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Schedule Option" colon={false} name="taskTimeCreation">
        <Radio.Group disabled={loading}>
          <Radio value="AUTO">Auto</Radio>
          <Radio value="ONPICKUPREADY">On Pick Up</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Sameday Showing" colon={false} name="rateShowingOptionType">
        <Radio.Group disabled={loading}>
          <Radio value="ALWAYSSHOWSAMEDAY">Always</Radio>
          <Radio value="SAMEDAYCUTOFF">Hide After Cutoff</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Create Automatic Pickup Task" colon={false} name="createPickup">
        <Radio.Group disabled={loading}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Redact Customer Data"
        colon={false}
        name="dataRedactionEnabled"
        tooltip="Automatically redact customer order data 90 days after order creation"
      >
        <Radio.Group disabled={loading}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item className="mb-0">
        <Button disabled={loading} loading={loading} type="primary" htmlType="submit">
          Add Merchant
        </Button>
      </Form.Item>
    </Card>
  );

  return (
    <div className="addMerchant-container m-40">
      <Form initialValues={initialFormValues} form={form} onFinish={onFinish} labelCol={{ span: 10 }} labelAlign="left">
        <Row gutter={24}>
          <Col span={12}>
            <Row className="mb-24">
              <Col span={24}>
                <BasicInformationCard />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <AddressInformationCard />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <MerchantSettingsCard />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddMerchant;
