import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Spin, notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteZone, editZone, getMerchantById } from "../util/APIUtils";
import { findDuplicatePostalCodes } from "../util/Helpers";
import "./ViewZones.css";

export class ViewZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      merchant: null,
      deleteLoadings: [],
      showModal: [],
      loadingMerchant: false,
      editing: [],
      addPostalCodeLoading: false,
      deletePostalCodeLoadings: [],
      isEditLoading: [],
      editVals: [],
      orgVals: [],
      currentZoneName: null,
      currentSearchParam: null,
    };
  }
  componentDidMount() {
    this.setState({ loadingMerchant: true });
    const values = queryString.parse(this.props.location.search);
    if (values.searchParam) this.setState({ currentSearchParam: values.searchParam });
    let promise = getMerchantById(values.merchantId);
    promise
      .then((response) => {
        this.setState({ merchant: response });
        this.setState({ loadingMerchant: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get merchant",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
  }

  handleDeleteZone = (zoneName, rowIndex, columnIndex) => {
    const newDeleteLoadings = [...this.state.deleteLoadings];
    newDeleteLoadings[rowIndex] = [];
    newDeleteLoadings[rowIndex][columnIndex] = true;
    this.setState({ deleteLoadings: newDeleteLoadings });

    let promise = deleteZone(this.state.merchant.id, zoneName);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Zone Deleted.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[rowIndex] = [];
        newDeleteLoadings[rowIndex][columnIndex] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });

        let newArr = this.state.merchant.zones;
        newArr = newArr.filter((e) => e.name !== zoneName);
        this.setState({
          merchant: {
            ...this.state.merchant,
            zones: newArr,
          },
        });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[rowIndex] = [];
        newDeleteLoadings[rowIndex][columnIndex] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });
      });
  };
  showLocationPricingModal = (rowIndex, columnIndex, newState, zoneName) => {
    const newShowModal = [...this.state.showModal];
    newShowModal[rowIndex] = [];
    newShowModal[rowIndex][columnIndex] = newState;
    this.setState({ showModal: newShowModal });
    this.setState({ currentZoneName: zoneName });
  };

  addNewPostalCode = (values, zoneName) => {
    const trimmedPostalCode = values["Postal Prefix"]
      ? values["Postal Prefix"].trim().toUpperCase()
      : values["Postal Prefix"];
    // Validate zones do not have existing postal codes
    const duplicatePostalCodes = findDuplicatePostalCodes([trimmedPostalCode], this.state.merchant.zones);
    const hasDuplicatesPostalCodes = duplicatePostalCodes.length > 0;

    if (hasDuplicatesPostalCodes) {
      const args = {
        message: "Error!",
        description: `The following postal codes already exist for the merchant: ${duplicatePostalCodes.toString()}`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }
    const price = Number(values["Price"]);
    let body = { postalPrefix: trimmedPostalCode, price: price, zoneName: zoneName };
    this.setState({ addPostalCodeLoading: true });
    let promise = editZone(body, this.state.merchant.id, "add");
    promise
      .then(() => {
        if (this.state.merchant.zones != null && this.state.merchant.zones.find((e) => e.name === zoneName) != null) {
          let myZones = this.state.merchant.zones;
          let myZoneIndex = myZones.findIndex((x) => x.name === zoneName);
          if (myZoneIndex > -1) {
            myZones[myZoneIndex].locationPricing.pricingRows.push({
              postalPrefix: trimmedPostalCode,
              price: price,
            });
            this.setState({
              merchant: {
                ...this.state.merchant,
                zones: myZones,
              },
            });
          }
        }
        const args = {
          message: "Success!",
          description: "Postal code added.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);

        this.setState({ addPostalCodeLoading: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ addPostalCodeLoading: false });
      });
  };

  deletePostalCode = (values, zoneName, index) => {
    let body = { postalPrefix: values.postalPrefix, price: Number(values.price), zoneName: zoneName };

    const newDeletePostalCodeLoadings = [...this.state.deletePostalCodeLoadings];
    newDeletePostalCodeLoadings[index] = true;
    this.setState({ deletePostalCodeLoadings: newDeletePostalCodeLoadings });
    let promise = editZone(body, this.state.merchant.id, "remove");
    promise
      .then((response) => {
        if (this.state.merchant.zones != null && this.state.merchant.zones.find((e) => e.name === zoneName) != null) {
          let myZones = this.state.merchant.zones;
          let myZoneIndex = myZones.findIndex((x) => x.name === zoneName);
          if (myZoneIndex > -1) {
            myZones[myZoneIndex].locationPricing.pricingRows = myZones[myZoneIndex].locationPricing.pricingRows.filter(
              (x) => !(x.price === values.price && x.postalPrefix === values.postalPrefix),
            );
            this.setState({
              merchant: {
                ...this.state.merchant,
                zones: myZones,
              },
            });
          }
        }
        const args = {
          message: "Success!",
          description: "Postal Code Deleted.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);

        const newDeletePostalCodeLoadings = [...this.state.deletePostalCodeLoadings];
        newDeletePostalCodeLoadings[index] = false;
        this.setState({ deletePostalCodeLoadings: newDeletePostalCodeLoadings });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[index] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });
      });
  };

  generateEditableField = (e, cell, i) => {
    return (
      <>
        <Row style={{ marginBottom: 15 }}>
          {this.state.editing[i] ? (
            <>
              {/* <Col span={6}>
                    Prefix: <Input
                      autoSize
                      type={'text'}
                      style={{ width: "50%" }}
                      ellipsis={{ rows: 3, expandable: true }}
                      value={this.state.editVals[i]['postalPrefix']}
                      onChange={(e) => this.onEditChange(e, i, 'postalPrefix')}
                    />
                  </Col> */}
              <Col span={6}>Prefix: {e.postalPrefix}</Col>
              <Col span={6}>
                Price:{" "}
                <Input
                  autoSize
                  type={"number"}
                  style={{ width: "50%" }}
                  ellipsis={{ rows: 3, expandable: true }}
                  value={this.state.editVals[i]["price"]}
                  onChange={(e) => this.onEditChange(e, i, "price")}
                />
              </Col>
              <Col span={6}>
                <Button
                  size={"small"}
                  style={{ float: "right" }}
                  onClick={() => this.clickCancelButton(e, i)}
                  icon={<CloseOutlined />}
                />
                <Button
                  size={"small"}
                  style={{ float: "right" }}
                  onClick={() => this.clickSaveButton(i, cell.name)}
                  icon={<CheckOutlined />}
                />
              </Col>
            </>
          ) : (
            <>
              <Col span={6}>Prefix: {e.postalPrefix}</Col>
              <Col span={6}>Price: {e.price}</Col>
              <Col span={6}>
                <Button
                  size={"small"}
                  style={{ float: "right" }}
                  onClick={() => this.clickEditButton(e, i)}
                  icon={<EditOutlined />}
                />
              </Col>
            </>
          )}
          <Col span={6}>
            {<Spin style={{ float: "left" }} spinning={this.state.isEditLoading[i] === true}></Spin>}
            <Button
              size={"small"}
              style={{ float: "right" }}
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => this.deletePostalCode(e, cell.name, i)}
              loading={this.state.deletePostalCodeLoadings[i]}
            />
          </Col>
        </Row>
      </>
    );
  };

  onEditChange = (e, i, field) => {
    let newEditVals = this.state.editVals;
    newEditVals[i][field] = e.target.value;
    this.setState({ editVals: newEditVals });
  };

  clickEditButton = (e, i) => {
    let newEditing = this.state.editing;
    newEditing[i] = true;
    this.setState({ editing: newEditing });
    let newVals = this.state.editVals;
    newVals[i] = { price: e.price, postalPrefix: e.postalPrefix };
    this.setState({ orgVals: newVals });
    this.setState({ editVals: newVals });
  };

  clickCancelButton = (e, i) => {
    let newEditing = this.state.editing;
    newEditing[i] = false;
    this.setState({ editing: newEditing });
    let newOrgVals = this.state.editVals;
    newOrgVals[i] = { price: this.state.orgVals[i].price, postalPrefix: this.state.orgVals[i].price };

    this.setState({ editVals: this.state.orgVals });
  };

  clickSaveButton = (i, zoneName) => {
    let myEditVals = this.state.editVals[i];
    if (
      myEditVals.postalPrefix == null ||
      myEditVals.postalPrefix === "" ||
      myEditVals.price == null ||
      myEditVals.price === ""
    ) {
      const args = {
        message: "Error!",
        description: `Fields cannot be empty!`,
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let editZoneDTO = { postalPrefix: myEditVals.postalPrefix, price: Number(myEditVals.price), zoneName: zoneName };
    let newIsEditingLoading = this.state.isEditLoading;
    newIsEditingLoading[i] = true;
    this.setState({ isEditLoading: newIsEditingLoading });
    let promise = editZone(editZoneDTO, this.state.merchant.id, "add");
    promise
      .then((response) => {
        if (this.state.merchant.zones != null && this.state.merchant.zones.find((x) => x.name === zoneName) != null) {
          let myZones = this.state.merchant.zones;
          let myZoneIndex = myZones.findIndex((x) => x.name === zoneName);
          if (myZoneIndex > -1) {
            // find my location pricing row and change it
            myZones[myZoneIndex].locationPricing.pricingRows[i] = {
              postalPrefix: myEditVals.postalPrefix,
              price: Number(myEditVals.price),
            };
            this.setState({
              merchant: {
                ...this.state.merchant,
                zones: myZones,
              },
            });
          }
        }
        const args = {
          message: "Success!",
          description: "Postal Code updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);

        let newEditing = this.state.editing;
        newEditing[i] = false;
        this.setState({ editing: newEditing });

        let newIsEditingLoading = this.state.isEditLoading;
        newIsEditingLoading[i] = false;
        this.setState({ isEditLoading: newIsEditingLoading });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Postal code. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        let newIsEditingLoading = this.state.isEditLoading;
        newIsEditingLoading[i] = false;
        this.setState({ isEditLoading: newIsEditingLoading });
      });
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    link.href =
      `/merchants/viewMerchants` +
      (this.state.currentSearchParam ? `?searchParam=${this.state.currentSearchParam}` : "");
    document.body.appendChild(link);
    link.click();
  };

  render() {
    const mapCards = (arr) => {
      const copied = Array.from(arr);
      const rows = copied.reduceRight((r, i, _, s) => (r.push(s.splice(0, 3)), r), []);
      return (
        <>
          {rows.map((row, i) => (
            <Row key={i} gutter={16} style={{ marginBottom: 10 }}>
              {row.map((cell, j) =>
                cell.addZone == null ? (
                  <Col key={j} span={8}>
                    <Card
                      bordered={false}
                      title={
                        <>
                          {cell.name}
                          <Button
                            style={{ float: "right" }}
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={() => this.handleDeleteZone(cell.name, i, j)}
                            loading={this.state.deleteLoadings[i] ? this.state.deleteLoadings[i][j] : false}
                          />
                        </>
                      }
                    >
                      <Row style={{ marginBottom: 5 }}>Facility: {cell.facility}</Row>
                      <Row style={{ marginBottom: 5 }}>Timezone: {cell.timezone}</Row>
                      <Row>
                        <Button
                          onClick={() => this.showLocationPricingModal(i, j, true, cell.name)}
                          disabled={
                            this.state.merchant.zones == null &&
                            this.state.merchant.zones == null &&
                            this.state.merchant.zones.locationPricing == null
                          }
                        >
                          View Location Pricing
                        </Button>
                      </Row>
                    </Card>
                    <Modal
                      title={`Location Pricing for: ${cell.name}`}
                      visible={this.state.showModal[i] ? this.state.showModal[i][j] : false}
                      cancelButtonProps={{ style: { display: "none" } }}
                      okButtonProps={{ style: { display: "none" } }}
                      closable={true}
                      onCancel={() => this.showLocationPricingModal(i, j, false, null)}
                      width={"50%"}
                    >
                      {cell.locationPricing.pricingRows
                        .sort((a, b) => a.postalPrefix.localeCompare(b.postalPrefix))
                        .map((e, i) => {
                          return <span key={i}>{this.generateEditableField(e, cell, i)}</span>;
                        })}

                      <Row gutter={16} className={"search-row"}>
                        &nbsp;
                      </Row>
                      <Row gutter={16} className={"search-row"}>
                        <Col span={12}>Add New Postal Codes:</Col>
                      </Row>
                      <Form
                        onFinish={(e) => this.addNewPostalCode(e, cell.name)}
                        layout="vertical"
                        name="Add New Postal Code"
                      >
                        <Row gutter={16} className={"search-row"}>
                          <Col span={8}>
                            <Form.Item
                              name="Postal Prefix"
                              rules={[
                                {
                                  required: true,
                                  message: "Postal Prefix is a required field",
                                  whitespace: true,
                                  type: "string",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Postal Prefix"
                                style={{ width: "100%" }}
                                loading={this.state.loadingRates}
                                disabled={this.state.loadingRates}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name="Price" rules={[{ required: true, message: "Price is a required field" }]}>
                              <Input
                                placeholder="*Price"
                                type="number"
                                style={{ width: "100%" }}
                                loading={this.state.loadingRates}
                                disabled={this.state.loadingRates}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item>
                              <Button type="primary" htmlType="submit" loading={this.state.addPostalCodeLoading}>
                                Add
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Modal>
                  </Col>
                ) : (
                  <Col key={j} span={8}>
                    <Card bordered={false} title={<Row style={{ marginBottom: 6 }}>Add Zone</Row>}>
                      <Row style={{ marginBottom: 5 }}>
                        <Button type="primary">
                          <a
                            href={
                              `/merchants/${this.state.merchant.name}/addZones?merchantId=${this.state.merchant.id}` +
                              (this.state.currentSearchParam ? `&searchParam=${this.state.currentSearchParam}` : "")
                            }
                          >
                            Add Zone
                          </a>
                        </Button>
                      </Row>
                      <Row>&nbsp;</Row>
                    </Card>
                  </Col>
                ),
              )}
            </Row>
          ))}
        </>
      );
    };
    return this.state.merchant == null || this.state.loadingMerchant ? (
      <div className={"viewZones-container"}>
        <Spin />
      </div>
    ) : (
      <div className={"viewZones-container"}>
        <h1>
          <Button type="primary" onClick={this.clickBackButton}>
            <ArrowLeftOutlined />
          </Button>{" "}
          Merchants/{this.state.merchant.name}/Zones
        </h1>
        <div className="zone-cards-container">
          {this.state.merchant.zones != null
            ? mapCards(this.state.merchant.zones.concat([{ addZone: true }]))
            : mapCards([{ addZone: true }])}
        </div>
      </div>
    );
  }
}

ViewZones.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ViewZones);
