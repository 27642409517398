import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Select, Tag, notification } from "antd";
import { get } from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { Component } from "react";
import ReactDataSheet from "react-datasheet";
import { connect } from "react-redux";
import { postalCodeList } from "../QuickFillPostalCodes";
import { addZone, getMerchantById } from "../util/APIUtils";
import { findDuplicatePostalCodes, findDuplicateZoneName } from "../util/Helpers";
import "./AddZones.css";

const setPathProperty = (object, path, value) =>
  path.split(".").reduce((o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}), object);

const SheetRenderer = (props) => {
  const { as: Tag, headerAs: Header, bodyAs: Body, rowAs: Row, cellAs: Cell, className, columns } = props;
  return (
    <Tag className={className}>
      <Header className="data-header">
        <Row>
          {columns.map((column) => (
            <Cell className="cell" style={{ width: column.width, textAlign: column.textAlign }} key={column.label}>
              {column.label}
            </Cell>
          ))}
        </Row>
      </Header>
      <Body className="data-body">{props.children}</Body>
    </Tag>
  );
};

const RowRenderer = (props) => {
  const { as: Tag, className } = props;
  return <Tag className={className}>{props.children}</Tag>;
};

const CellRenderer = (props) => {
  const { as: Tag, cell, row, col, columns, attributesRenderer, selected, editing, updated, style, ...rest } = props;

  // hey, how about some custom attributes on our cell?
  const attributes = cell.attributes || {};
  // ignore default style handed to us by the component and roll our own
  attributes.style = {
    width: columns[col].width,
    textAlign: columns[col].textAlign,
  };
  if (col === 0) {
    attributes.title = cell.label;
  }

  return (
    <Tag {...rest} {...attributes}>
      {props.children}
    </Tag>
  );
};

export class AddZones extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCellsChanged = this.handleCellsChanged.bind(this);

    this.sheetRenderer = this.sheetRenderer.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);

    this.state = {
      loading: false,
      merchant: "",
      formValues: {
        zoneName: null,
        timezone: null,
        facility: null,
      },
      columns: [
        { label: "Postal Prefix", width: "50%", textAlign: "left" },
        { label: "Price", width: "50%", textAlign: "left" },
      ],
      grid: [[{ value: "" }, { value: "" }]],
      unselectedTags: ["GMA", "All H", "All J", "GTA", "All M", "All L", "GTA ZONE 5", "GTA ZONE 6"],
      selectedTags: [],
      currentTag: null,
      showModal: false,
      currentPrice: null,
      currentSearchParam: null,
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.searchParam) this.setState({ currentSearchParam: values.searchParam });

    let promise = getMerchantById(values.merchantId);
    promise
      .then((response) => {
        this.setState({ merchant: response });
        this.setState({ loadingMerchant: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get merchant",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
  }

  handleSelect(e) {
    this.setState({ as: e.target.value });
  }

  addRow = () => {
    let newGrid = this.state.grid;
    newGrid.push([{ value: "" }, { value: "" }]);
    this.setState({
      grid: newGrid,
    });
  };

  handleCellsChanged(changes, additions) {
    const grid = this.state.grid.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    // paste extended beyond end, so add a new row
    additions &&
      additions.forEach(({ cell, row, col, value }) => {
        if (!grid[row]) {
          grid[row] = [{ value: "" }, { value: "" }];
        }
        if (grid[row][col]) {
          grid[row][col] = { ...grid[row][col], value };
        }
      });
    this.setState({ grid });
  }

  sheetRenderer(props) {
    const { columns } = this.state;
    return (
      <SheetRenderer
        columns={columns}
        onSelectAllChanged={this.handleSelectAllChanged}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  rowRenderer(props) {
    return <RowRenderer as="tr" cellAs="td" className="data-row" {...props} />;
  }

  cellRenderer(props) {
    return <CellRenderer as="td" columns={this.state.columns} {...props} style={{ float: "left" }} />;
  }

  onChange = (e) => {
    const { formValues } = this.state;
    // clean it if it is a phone number
    const value = e.target.name === "phone" ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value.trim();
    setPathProperty(formValues, e.target.name, value);
    this.setState({ formValues });
  };

  addZone = (e) => {
    this.setState({ loading: true });
    // Validate zone name is not already used
    const zoneAlreadyExists = findDuplicateZoneName(this.state.formValues.zoneName, this.state.merchant.zones);
    if (zoneAlreadyExists) {
      const args = {
        message: "Error!",
        description: `Zone name ${this.state.formValues.zoneName} already exists for ${get(
          this.state.merchant,
          "name",
          "the merchant",
        )}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loading: false });
      return;
    }

    const pricingRows = this.state.grid
      .filter((x) => x[0].value !== "" && x[1].value !== "")
      .map((e) => {
        return {
          price: Number(e[1].value),
          postalPrefix: e[0].value,
        };
      });

    // Validate zones do not have existing postal codes
    const postalCodesToBeAdded = pricingRows.map((row) => row.postalPrefix);
    const duplicatePostalCodes = findDuplicatePostalCodes(postalCodesToBeAdded, this.state.merchant.zones);
    const hasDuplicatesPostalCodes = duplicatePostalCodes.length > 0;

    if (hasDuplicatesPostalCodes) {
      const args = {
        message: "Error!",
        description: `The following postal codes already exist for ${get(
          this.state.merchant,
          "name",
          "the merchant",
        )}: ${duplicatePostalCodes.toString()}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loading: false });
      return;
    }

    const body = {
      name: this.state.formValues.zoneName,
      timezone: this.state.formValues.timezone,
      facility: this.state.formValues.facility,
      locationPricing: {
        pricingRows: pricingRows,
      },
    };

    if (body.locationPricing.pricingRows.length === 0) {
      const args = {
        message: "Error!",
        description: "Please add Postal Codes and pricing",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loading: false });
      return;
    }
    let promise = addZone(body, this.state.merchant.id);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Zone Added.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loading: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loading: false });
      });
  };

  addToSelected = (e) => {
    // remove from unselected
    let newUnselected = this.state.unselectedTags;
    const index = newUnselected.indexOf(this.state.currentTag);
    if (index > -1) {
      newUnselected.splice(index, 1);
    }
    this.setState({ unselectedTags: newUnselected });

    // add to selected
    let newSelected = this.state.selectedTags;
    newSelected.push(this.state.currentTag);
    this.setState({ selectedTags: newSelected });

    // add list for this tag to the grid
    let myTag = this.state.currentTag.toUpperCase().replace(/\s/g, "");

    let myEntries = postalCodeList[myTag].map((e) => [e[0], { value: this.state.currentPrice }]);

    let newArr = this.state.grid.concat(myEntries).filter((e) => e[0].value !== "" || e[1].value !== "");
    this.setState({ grid: newArr });
    this.setState({ showModal: false });
    this.setState({ currentPrice: null });
  };

  removeFromSelected = (tag) => {
    // remove from selected
    let newSelected = this.state.selectedTags;
    const index = newSelected.indexOf(tag);
    if (index > -1) {
      newSelected.splice(index, 1);
    }
    this.setState({ selectedTags: newSelected });

    // add to unselected
    let newUnselected = this.state.unselectedTags;
    newUnselected.push(tag);
    this.setState({ unselectedTags: newUnselected });

    // remove list for this tag to the grid
    let myTag = tag.toUpperCase().replace(/\s/g, "");
    let myEntries = postalCodeList[myTag].map((e) => e[0]);
    let newArr = this.state.grid;
    newArr = newArr.filter((x) => !myEntries.includes(x[0]));
    if (newArr.length === 0) newArr.push([{ value: "" }, { value: "" }]);
    this.setState({ grid: newArr });
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    link.href =
      `/merchants/${this.state.merchant.name}/viewZones?merchantId=${this.state.merchant.id}` +
      (this.state.currentSearchParam ? `&searchParam=${this.state.currentSearchParam}` : "");
    document.body.appendChild(link);
    link.click();
  };

  render() {
    const timezoneOptions = moment.tz.names().map((e) => {
      return { label: e, value: e };
    });
    const facilityOptions = [
      { label: "yyz5", value: "yyz5" },
      { label: "yul1", value: "yul1" },
      { label: "yow1", value: "yow1" },
      { label: "yvr2", value: "yvr2" },
      { label: "mia1", value: "mia1" },
      { label: "nyc1", value: "nyc1" },
      { label: "yyc1", value: "yyc1" },
      { label: "hou1", value: "hou1" },
      { label: "lax1", value: "lax1" },
    ];

    return this.state.merchant == null ? (
      <div className={"addZones-container"}></div>
    ) : (
      <div className={"addZones-container"}>
        <h1>
          <Button type="primary" onClick={this.clickBackButton}>
            <ArrowLeftOutlined />
          </Button>{" "}
          Merchants/{this.state.merchant.name}/Add Zone
        </h1>
        <Form onFinish={(e) => this.addZone(e)} layout="vertical" name="Add a Driver">
          <Row gutter={16} style={{ marginBottom: 10 }}>
            <Col span={8}>
              <Card bordered={false} title="Zone Info">
                <Form.Item
                  name="zoneName"
                  rules={[
                    {
                      required: true,
                      message: "Zone Name is a required field",
                    },
                  ]}
                  label="Zone Name"
                >
                  <Input type="text" name="zoneName" onChange={this.onChange} />
                </Form.Item>
                <Form.Item
                  name="Time Zone"
                  rules={[
                    {
                      required: true,
                      message: "Time Zone is a required field",
                    },
                  ]}
                  label="Time Zone"
                >
                  <Select
                    showSearch
                    name="timezone"
                    options={timezoneOptions}
                    onChange={(v) =>
                      this.setState({
                        formValues: { ...this.state.formValues, timezone: v },
                      })
                    }
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </Form.Item>
                <Form.Item
                  name="Facility"
                  rules={[{ required: true, message: "Facility is a required field" }]}
                  label="Facility"
                >
                  <Select
                    showSearch
                    name="facility"
                    options={facilityOptions}
                    onChange={(v) =>
                      this.setState({
                        formValues: { ...this.state.formValues, facility: v },
                      })
                    }
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} title="Postal Codes and Pricing">
                <Row gutter={16} style={{ marginBottom: 10 }}>
                  Quick Fill: &nbsp;
                  <Row>
                    {this.state.unselectedTags.map((tag) => (
                      <Col>
                        <Tag
                          color="blue"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              currentTag: tag,
                              currentPrice: null,
                            });
                          }}
                        >
                          {tag}
                        </Tag>
                      </Col>
                    ))}
                  </Row>
                </Row>
                <Row gutter={16} style={{ marginBottom: 10 }}>
                  <Col span={8}>
                    <Button onClick={this.addRow.bind(this)}>Add row</Button>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: 10 }}>
                  <Col span={8}>
                    <Row>
                      {this.state.selectedTags.map((tag) => (
                        <Col>
                          <Tag key={tag} color="red" closable onClose={() => this.removeFromSelected(tag)}>
                            {tag}
                          </Tag>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <ReactDataSheet
                  data={this.state.grid}
                  className="custom-sheet"
                  sheetRenderer={this.sheetRenderer}
                  headerRenderer={this.headerRenderer}
                  bodyRenderer={this.bodyRenderer}
                  rowRenderer={this.rowRenderer}
                  cellRenderer={this.cellRenderer}
                  onCellsChanged={this.handleCellsChanged}
                  valueRenderer={(cell) => cell.value}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                    disabled={
                      this.state.formValues.zoneName == null ||
                      this.state.formValues.timezone == null ||
                      this.state.grid.filter((e) => e[0].value !== "" && e[1].value !== "").length === 0
                    }
                  >
                    Add Zone
                  </Button>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
        {this.state.showModal && (
          <Modal
            title={`Choose a Price for ${this.state.currentTag}`}
            visible={this.state.showModal}
            onCancel={() => {
              this.setState({ showModal: false });
            }}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            closable={true}
          >
            <>
              <Form onFinish={(e) => this.addToSelected(e)} layout="vertical" name="Change Delivery Window">
                <Row gutter={16} className={"window-row"}>
                  <Col span={12}>
                    <Form.Item
                      name="price"
                      rules={[
                        {
                          required: true,
                          message: "Price is a required field",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        name="price"
                        placeholder="price"
                        value={this.state.currentPrice}
                        onChange={(e) => this.setState({ currentPrice: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} window-row>
                  <Col span={4}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {`Add ${this.state.currentTag}`}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          </Modal>
        )}
      </div>
    );
  }
}

AddZones.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AddZones);
