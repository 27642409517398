export function formatEditUserPayload(payload = {}) {
  const newPayload = [];

  if (payload.phone) {
    payload.phone = `+1${payload.phone?.replace(/[^\d]/g, "")}`;
  }

  for (const field in payload) {
    newPayload.push({
      name: field,
      value: payload[field]?.trim(),
    });
  }
  return newPayload;
}
