const EDIT_ZONES = "edit_zones";
const EDIT_SHIPPING_RULES = "edit_shipping_rules";
const ARCHIVE_MERCHANT = "archive_merchant";
const MANAGE_MERCHANT_USERS = "manage_merchant_users";
const EDIT_USER = "edit_user";
const DELETE_USER = "delete_user";
const DELETE_SERVICE_USER = "edit_service_user";

const DASHBOARD_MERCHANT_ACTIONS = [
  {
    label: "Edit zones",
    key: EDIT_ZONES,
  },
  {
    label: "Shipping rules",
    key: EDIT_SHIPPING_RULES,
  },
  {
    label: "Manager Users",
    key: MANAGE_MERCHANT_USERS,
  },
  {
    label: "Archive Merchant",
    key: ARCHIVE_MERCHANT,
    danger: true,
  },
];

const MERCHANT_USERS_TABLE_ACTIONS = [
  {
    label: "Edit User",
    key: EDIT_USER,
  },
  {
    label: "Delete User",
    key: DELETE_USER,
    danger: true,
  },
];

const MERCHANT_SERVICE_USERS_TABLE_ACTIONS = [
  {
    label: "Delete Service User",
    key: DELETE_SERVICE_USER,
    danger: true,
  },
];

export {
  ARCHIVE_MERCHANT,
  DASHBOARD_MERCHANT_ACTIONS,
  DELETE_SERVICE_USER,
  DELETE_USER,
  EDIT_SHIPPING_RULES,
  EDIT_USER,
  EDIT_ZONES,
  MANAGE_MERCHANT_USERS,
  MERCHANT_SERVICE_USERS_TABLE_ACTIONS,
  MERCHANT_USERS_TABLE_ACTIONS,
};
