import { Button, Col, Popover, Row, notification } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import "./Dashboard.css";

export class ExportOrders extends Component {
  constructor(props) {
    super(props);
    this.notes = React.createRef();
    this.externalNotes = React.createRef();
    this.state = {
      orders: null,
      selectedOrder: null,
      loading: false,
      hasMore: true,
      selectedOrders: [],
      loadingExport: false,
    };
  }

  handlePopoverVisibleChange = (visible) => {
    if (this.props.selectedRows.length > 0) {
      this.setState({ popoverVisible: visible });
    }
  };

  handleExport = () => {
    this.setState({ errorList: [] });
    this.setState({ successList: [] });
    this.setState({ status: "active" });

    if (this.props.selectedRowKeys.length === 0) {
      const args = {
        message: "Error!",
        description: "Please select at least one order.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ loadingExport: true });
    let filteredOrders = this.props.selectedRows;

    const formatCsvStringColumn = (str) => {
      if (str == null) return "";
      let newString = str;
      if (str.includes(",") || str.includes('"') || str.includes("\n")) {
        newString = newString.replace(/"/g, '""');
        newString = '"' + newString + '"';
        return newString;
      }
      return str;
    };

    const formatCsvColumn = (str) => (typeof str !== "undefined" ? str : "");
    // const formatCsvDateColumn = str => typeof str !== 'undefined' ? new Date(str).toString() : ''

    const formatCsvBooleanColumn = (boo) => (boo === true ? "yes" : "no");

    const formatCsvDateColumn = (str) => {
      if (str != null) {
        // if (typeof str !== 'undefined') {
        let myStr = str;
        if (myStr.toString().length === 10) myStr = myStr * 1000;
        return new Date(myStr).toString();
      }
      return "";
    };

    // const getDeliveredDate = tasks => {
    //   const task = tasks.find(t => t.taskType === 'DELIVERY' && t.taskStatus === 'COMPLETED')
    //   return (typeof task !== 'undefined' && task !== null) ? new Date(task.completedAt).toString() : ''
    // }
    const getDeliveredDate = (tasks) => {
      const task = tasks.find((t) => t.taskType === "DELIVERY" && t.taskStatus === "COMPLETED");
      if (typeof task !== "undefined" && task !== null) {
        let myStr = task.completedAt;
        if (myStr.toString().length === 10) myStr = myStr * 1000;
        return new Date(myStr).toString();
      } else {
        return "";
      }
    };

    const createString = (arr) => {
      let final = "";
      arr.forEach((e) => {
        final = final + " " + e;
      });
      return final;
    };

    let filteredOrdersArray = filteredOrders.map((o) => {
      let sortedPickedUpAt =
        o.otherScans && o.otherScans.length > 0
          ? o.otherScans
              .filter((e) => e.time != null)
              .sort((x, y) => {
                return x.time - y.time;
              })
          : null;
      let myPickedUpAt = sortedPickedUpAt && sortedPickedUpAt.length > 0 ? sortedPickedUpAt[0].time : null;

      // let sorted = o.packagesReceivedAt && o.packagesReceivedAt.length > 0? o.packagesReceivedAt.filter(e => e.location != null && e.receivedAt != null).sort((x, y) => {return x.receivedAt - y.receivedAt}) : null
      // let myPackagesReceivedAt = sorted && sorted.length > 0? sorted[0].receivedAt : null

      let mapped = o.scanEvents && o.scanEvents.length > 0 ? o.scanEvents.map((x) => x.events) : null; // .filter(e => e.location != null && e.receivedAt != null).sort((x, y) => {return x.receivedAt - y.receivedAt}) : null
      let merged = mapped != null ? [].concat.apply([], mapped) : null;
      let sorted =
        merged != null
          ? merged
              .filter((x) => x.location != null)
              .sort((x, y) => {
                return x.time - y.time;
              })
          : null;
      let myPackagesReceivedAt = sorted && sorted.length > 0 ? sorted[0].time : null;

      let getCompletionNotes = () => {
        if (
          (o.orderStatus === "DELIVERY_COMPLETED" || o.orderStatus === "DELIVERY_FAILED") &&
          o.tasks &&
          o.tasks.length > 0 &&
          o.tasks[0].completionNotes
        ) {
          return o.tasks[0].completionNotes;
        } else {
          return "";
        }
      };
      let myCompletionNotes = getCompletionNotes();

      let getCompletionPhotos = () => {
        if (
          (o.orderStatus === "DELIVERY_COMPLETED" || o.orderStatus === "DELIVERY_FAILED") &&
          o.tasks &&
          o.tasks.length > 0 &&
          o.tasks[0].completionPhotosUrl
        ) {
          return o.tasks[0].completionPhotosUrl.length > 0 ? o.tasks[0].completionPhotosUrl.join() : "";
        } else {
          return "";
        }
      };
      let myCompletionPhotos = getCompletionPhotos();

      return [
        formatCsvStringColumn(o.id),
        formatCsvDateColumn(o.createdAt),
        formatCsvStringColumn(o.merchantId),
        formatCsvStringColumn(o.merchantDisplayName),

        typeof o.tasks !== "undefined" && o.tasks !== null && o.tasks.length > 0
          ? formatCsvStringColumn(o.tasks[0].id)
          : "",
        typeof o.tasks !== "undefined" && o.tasks !== null && o.tasks.length > 0
          ? formatCsvStringColumn(o.tasks[0].shortId)
          : "",

        typeof o.recipient !== "undefined" ? formatCsvStringColumn(o.recipient.name) : "",
        typeof o.recipient !== "undefined" ? formatCsvStringColumn(o.recipient.phone) : "",

        typeof o.recipient !== "undefined" ? formatCsvStringColumn(o.recipient.email) : "",

        typeof o.dtoRecipientAddress !== "undefined" ? formatCsvStringColumn(o.dtoRecipientAddress.country) : "",
        typeof o.dtoRecipientAddress !== "undefined" ? formatCsvStringColumn(o.dtoRecipientAddress.province) : "",
        typeof o.dtoRecipientAddress !== "undefined" ? formatCsvStringColumn(o.dtoRecipientAddress.city) : "",
        typeof o.dtoRecipientAddress !== "undefined" ? formatCsvStringColumn(o.dtoRecipientAddress.street) : "",
        typeof o.dtoRecipientAddress !== "undefined" ? formatCsvStringColumn(o.dtoRecipientAddress.postalCode) : "",

        typeof o.dtoRecipientAddress !== "undefined"
          ? formatCsvBooleanColumn(o.dtoRecipientAddress.isBusinessAddress)
          : "",

        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.number) : "",
        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.country) : "",
        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.province) : "",
        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.city) : "",
        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.street) : "",
        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.postalCode) : "",
        typeof o.recipientAddress !== "undefined" ? formatCsvStringColumn(o.recipientAddress.sublocality) : "",
        typeof o.recipientAddress !== "undefined" &&
        typeof o.recipientAddress.location !== "undefined" &&
        typeof o.recipientAddress.location.lng !== "undefined"
          ? formatCsvColumn(o.recipientAddress.location.lng)
          : "",
        typeof o.recipientAddress !== "undefined" &&
        typeof o.recipientAddress.location !== "undefined" &&
        typeof o.recipientAddress.location.lat !== "undefined"
          ? formatCsvColumn(o.recipientAddress.location.lat)
          : "",

        formatCsvStringColumn(o.createdBy),

        typeof o.dtoOriginAddress !== "undefined" ? formatCsvStringColumn(o.dtoOriginAddress.country) : "",
        typeof o.dtoOriginAddress !== "undefined" ? formatCsvStringColumn(o.dtoOriginAddress.province) : "",
        typeof o.dtoOriginAddress !== "undefined" ? formatCsvStringColumn(o.dtoOriginAddress.city) : "",
        typeof o.dtoOriginAddress !== "undefined" ? formatCsvStringColumn(o.dtoOriginAddress.street) : "",
        typeof o.dtoOriginAddress !== "undefined" ? formatCsvStringColumn(o.dtoOriginAddress.postalCode) : "",

        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.number) : "",
        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.country) : "",
        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.province) : "",
        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.city) : "",
        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.street) : "",
        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.postalCode) : "",
        typeof o.originAddress !== "undefined" ? formatCsvStringColumn(o.originAddress.sublocality) : "",
        typeof o.originAddress !== "undefined" &&
        typeof o.originAddress.location !== "undefined" &&
        typeof o.originAddress.location.lng !== "undefined"
          ? formatCsvColumn(o.originAddress.location.lng)
          : "",
        typeof o.originAddress !== "undefined" &&
        typeof o.originAddress.location !== "undefined" &&
        typeof o.originAddress.location.lat !== "undefined"
          ? formatCsvColumn(o.originAddress.location.lat)
          : "",

        formatCsvStringColumn(o.orderStatus),
        formatCsvColumn(o.signatureRequired),
        formatCsvColumn(o.scanningRequired),
        formatCsvColumn(o.validAddress),
        formatCsvDateColumn(o.completeAfter),
        formatCsvDateColumn(o.completeBefore),
        typeof o.originalCompleteAfter !== "undefined" ? formatCsvDateColumn(o.originalCompleteAfter) : "",
        typeof o.originalCompleteBefore !== "undefined" ? formatCsvDateColumn(o.originalCompleteBefore) : "",
        formatCsvStringColumn(o.notes),

        formatCsvStringColumn(myCompletionNotes),

        typeof myCompletionPhotos !== "undefined" ? formatCsvStringColumn(myCompletionPhotos) : "", // NEED TO FIX formatCsvColumn(o.completionPhotos),

        formatCsvStringColumn(o.failureNotes),
        formatCsvDateColumn(myPickedUpAt),

        formatCsvDateColumn(myPackagesReceivedAt),
        // formatCsvDateColumn(o.packageReceivedAt),
        formatCsvDateColumn(o.outForDeliveryAt),

        typeof o.tasks !== "undefined" ? getDeliveredDate(o.tasks) : "", // formatCsvColumn(o.completedAt), // completedAt
        typeof o.tasks !== "undefined" && o.tasks.shortId !== "undefined" && o.tasks.length > 0
          ? o.tasks[0].shortId
          : "",

        formatCsvDateColumn(o.failedAt),

        // formatCsvColumn(o.packages),

        formatCsvColumn(o.packageCount),
        formatCsvStringColumn(o.refNumber),
        formatCsvStringColumn(o.freeSearch),
        formatCsvColumn(o.saveAddress),

        typeof o.rateChoice !== "undefined" ? formatCsvColumn(o.rateChoice.price) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvStringColumn(o.rateChoice.service) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvStringColumn(o.rateChoice.name) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvStringColumn(o.rateChoice.description) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvStringColumn(o.rateChoice.estimateDay) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvStringColumn(o.rateChoice.estimateFrom) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvStringColumn(o.rateChoice.estimateTo) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvDateColumn(o.rateChoice.epochEstimateFrom) : "",
        typeof o.rateChoice !== "undefined" ? formatCsvDateColumn(o.rateChoice.epochEstimateTo) : "",

        // typeof o.availableRates !== 'undefined'? createString(o.availableRates) : '',

        formatCsvColumn(o.useCoordinate),
      ];
    });

    let headers = [
      "id",
      "createdAt",
      "merchantId",
      "merchantDisplayName",

      "routingId",
      "routingShortId",

      "recipientName",
      "recipientPhone",
      "recipientEmail",

      "dtoRecipientAddressCountry",
      "dtoRecipientAddressProvince",
      "dtoRecipientAddressCity",
      "dtoRecipientAddressStreet",
      "dtoRecipientAddressPostalCode",

      "isBusinessAddress",

      "recipientAddressNumber",
      "recipientAddressCountry",
      "recipientAddressProvince",
      "recipientAddressCity",
      "recipientAddressStreet",
      "recipientAddressPostalCode",
      "recipientAddressSublocality",
      "recipientAddressLng",
      "recipientAddressLat",

      "createdBy",

      "dtoOriginAddressCountry",
      "dtoOriginAddressProvince",
      "dtoOriginAddressCity",
      "dtoOriginAddressStreet",
      "dtoOriginAddressPostalCode",

      "originAddressNumber",
      "originAddressCountry",
      "originAddressProvince",
      "originAddressCity",
      "originAddressStreet",
      "originAddressPostalCode",
      "originAddressSublocality",
      "originAddressLng",
      "originAddressLat",

      "orderStatus",
      "signatureRequired",
      "scanningRequired",
      "validAddress",
      "completeAfter",
      "completeBefore",
      "originalCompleteAfter",
      "originalCompleteBefore",
      "notes",

      "completionNotes",
      "completionPhotos",
      "failureNotes",
      "pickedUpAt",

      "packagesReceivedAt",
      // 'packageReceivedAt',
      "outForDeliveryAt",

      "completedAt",
      "shortId",

      "failedAt",

      // 'packages',

      "packageCount",
      "refNumber",
      "freeSearch",
      "saveAddress",

      "rateChoicePrice",
      "rateChoiceService",
      "rateChoiceName",
      "rateChoiceDescription",
      "rateChoiceEstimateDay",
      "rateChoiceEstimateFrom",
      "rateChoiceEstimateTo",
      "rateChoiceEpochEstimateFrom",
      "rateChoiceEpochEstimateTo",

      // 'availableRates',

      "useCoordinate",
    ];
    let csvContent =
      "data:text/csv;charset=utf-8," +
      encodeURIComponent(headers + "\n" + filteredOrdersArray.map((e) => e.join(",")).join("\n"));
    var hyperlink = document.createElement("a");
    hyperlink.setAttribute("href", csvContent);
    hyperlink.setAttribute("download", "my_orders.csv");
    document.body.appendChild(hyperlink);
    hyperlink.click();
    this.setState({ loadingExport: false });
  };

  render() {
    const popoverContent = (
      <Col>
        <Row gutter={16} style={{ marginBottom: "1vh" }}>
          <Col>
            <p>Are you sure want to export {this.props.selectedRowKeys.length} orders as a csv file?</p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Button type={"primary"} onClick={() => this.handleExport()} loading={this.state.loadingExport}>
              Export
            </Button>
          </Col>
          <Col span={8}>
            <Button loading={this.state.loadingExport} onClick={() => this.setState({ popoverVisible: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <Popover
          content={popoverContent}
          placement="bottom"
          trigger="click"
          visible={this.state.popoverVisible}
          onVisibleChange={this.handlePopoverVisibleChange}
        >
          <div style={{ width: "100%" }}>
            <Button
              type="primary"
              disabled={this.props.selectedRowKeys.length <= 0}
              loading={this.state.loadingExport}
              style={{ width: "100%" }}
            >
              Export
            </Button>
          </div>
        </Popover>
      </>
    );
  }
}

ExportOrders.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ExportOrders);
