const DELIVERY_SERVICES = {
  THRESHOLD: "THRESHOLD",
  TO_THE_DOOR: "TO_THE_DOOR",
  WHITE_GLOVE: "WHITE_GLOVE",
  ROOM_OF_CHOICE: "ROOM_OF_CHOICE",
};
const HEAVY_DELIVERY_SERVICES = [
  DELIVERY_SERVICES.THRESHOLD,
  DELIVERY_SERVICES.TO_THE_DOOR,
  DELIVERY_SERVICES.WHITE_GLOVE,
  DELIVERY_SERVICES.ROOM_OF_CHOICE,
];
const PARCEL_DELIVERY_SERVICES = [DELIVERY_SERVICES.THRESHOLD, DELIVERY_SERVICES.TO_THE_DOOR];

const DELIVERY_CLASSIFICATION = {
  PARCEL: "PARCEL",
  HEAVY: "HEAVY",
};

export { DELIVERY_CLASSIFICATION, DELIVERY_SERVICES, HEAVY_DELIVERY_SERVICES, PARCEL_DELIVERY_SERVICES };
