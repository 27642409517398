import { Button, Space, Typography } from "antd";
import React from "react";
import { LIGHT_BLUE } from "../util/Colors";

const DownloadCSVTemplateButton = (props) => {
  const { description, headers, fileName, downloadButtonTxt } = props;

  const onClick = (e) => {
    const header = [headers, []];
    const csvContent = header.map((e) => e.join(",")).join("\n");
    const prefix = "data:text/csv;charset=utf-8,";
    const encodedUri = prefix + encodeURIComponent(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    e.stopPropagation();
  };

  return (
    <Space direction="vertical">
      {description && <Typography.Paragraph>{description}</Typography.Paragraph>}
      <Button onClick={onClick} style={{ color: LIGHT_BLUE, borderColor: LIGHT_BLUE }}>
        {downloadButtonTxt || "Download CSV template"}
      </Button>
    </Space>
  );
};

export default DownloadCSVTemplateButton;
