import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Typography } from "antd";
import { default as React } from "react";
import { Link } from "react-router-dom";
import {
  ARCHIVE_MERCHANT,
  DASHBOARD_MERCHANT_ACTIONS,
  EDIT_SHIPPING_RULES,
  EDIT_ZONES,
  MANAGE_MERCHANT_USERS,
} from "../constants/merchants";
import "./ViewMerchants.css";

const MerchantDashboardActions = ({ record, searchParam, handleArchiveMerchant }) => {
  const confirmArchiveMerchant = (record) => {
    return Modal.confirm({
      title: `Are you sure you want to archive ${record.name}?`,
      content: `This action cannot be undone.`,
      onOk: (close) => handleArchiveMerchant(record.id),
    });
  };

  const renderMenuItem = (item, record) => {
    switch (item.key) {
      case EDIT_ZONES:
        return (
          <Link
            to={
              `/merchants/${record.name}/viewZones?merchantId=${record.id}` +
              (searchParam ? `&searchParam=${searchParam}` : "")
            }
          >
            {item.label}
          </Link>
        );
      case EDIT_SHIPPING_RULES:
        return (
          <Link
            to={
              `/merchants/${record.name}/viewShippingRules?merchantId=${record.id}` +
              (searchParam ? `&searchParam=${searchParam}` : "")
            }
          >
            {item.label}
          </Link>
        );
      case ARCHIVE_MERCHANT:
        return (
          <Typography.Text type="danger" onClick={() => confirmArchiveMerchant(record)}>
            {item.label}
          </Typography.Text>
        );
      case MANAGE_MERCHANT_USERS:
        return (
          <Link to={`/merchants/${record.id}/users` + (searchParam ? `&searchParam=${searchParam}` : "")}>
            {item.label}
          </Link>
        );
      default:
        return null;
    }
  };

  const menuProps = (
    <Menu onClick={(e) => e.domEvent.stopPropagation()}>
      {DASHBOARD_MERCHANT_ACTIONS.map((item) => (
        <Menu.Item key={item.key}>{renderMenuItem(item, record)}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuProps}>
        <Button style={{ border: 0 }} onClick={(e) => e.stopPropagation()}>
          <MoreOutlined rotate={90} />
        </Button>
      </Dropdown>
    </>
  );
};

export default MerchantDashboardActions;
