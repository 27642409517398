import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row, Select, Spin, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createUser, deleteUser, editUser, getUsers } from "../util/APIUtils";
import { DEFAULT_PAGINATION_LIMIT } from "../constants/globals";
import "./Dashboard.css";

export class ViewUsers extends Component {
  constructor(props) {
    super(props);
    this.notes = React.createRef();
    this.externalNotes = React.createRef();
    this.state = {
      orders: null,
      selectedBatch: null,
      loading: false,
      hasMore: true,
      selectedOrders: [],
      merchants: [],
      selectedRowKeys: [],
      showModal: false,
      loadingTimeWindowChange: false,
      formValues: {
        fromDate: null,
        fromTime: null,
        toDate: null,
        toTime: null,
        category: null,
        checked: false,
      },
      checkDisabled: false,
      showForm: false,
      errorList: [],
      successList: [],
      status: "active",
      warningList: [],
      readyPopoverVisible: false,
      loadingToggleReadyChange: false,
      loadingMerchants: false,
      currentOrderParams: null,
      limit: DEFAULT_PAGINATION_LIMIT,
      loadingUsers: false,
      users: null,
      drivers: null,
      loadingDrivers: false,
      showCreateModal: false,
      loadingCreateUser: false,
      deleteLoadings: [],
      email: null,
      roles: null,
      newEmail: null,
      newName: null,
      newRoles: null,
      loadingEditUser: false,
    };
  }

  formRef = React.createRef();

  getUsers = async () => {
    this.setState({ loadingUsers: true });
    let getUsersPromise = getUsers();
    try {
      let users = await getUsersPromise;
      console.log(users);
      this.setState({
        users: users,
      });
    } catch (err) {
      console.log(err);
    }
    this.setState({ loadingUsers: false });
  };

  async componentDidMount() {
    this.getUsers();
  }

  onChange = (e) => {
    if (e.target.name === "id" && e.target.value) this.setState({ [e.target.name]: e.target.value.trim() });
    else this.setState({ [e.target.name]: e.target.value });
  };

  handleCreateUser = (e) => {
    this.setState({ loadingCreateUser: true });
    if (
      this.state.email == null ||
      !this.state.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      const args = {
        message: "Error!",
        description: "Please make sure email is in the following format: example@boxknight.com",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loadingCreateUser: false });
      return;
    }

    if (
      this.state.email == null ||
      this.state.email.length === 0 ||
      this.state.roles == null ||
      this.state.roles.length === 0
    ) {
      const args = {
        message: "Error!",
        description: "All fields are required.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loadingCreateUser: false });
      return;
    }
    let finalBody = {
      email: this.state.email,
      roles: this.state.roles,
    };
    let promise = createUser(finalBody);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "User created.",
          duration: 5,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        this.getUsers();
        this.setState({ loadingCreateUser: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingCreateUser: false });
      });
  };

  handleDeleteUser = (record, index) => {
    const newDeleteLoadings = [...this.state.deleteLoadings];
    newDeleteLoadings[index] = true;
    this.setState({ deleteLoadings: newDeleteLoadings });
    let promise = deleteUser(record.email);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "User deleted.",
          duration: 5,
          type: "success",
        };
        notification.open(args);

        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[index] = false;
        this.getUsers();
        // this.setState({users: this.state.users.filter(object => object.email !== record.email)})
        this.setState({ deleteLoadings: newDeleteLoadings });
      })
      .catch((error) => {
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[index] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });
        const args = {
          message: "Error!",
          description: error.message,
          duration: 5,
          type: "error",
        };
        notification.open(args);
      });
  };

  handleEditUser = (e) => {
    this.setState({ loadingEditUser: true });
    if (
      this.state.newEmail == null ||
      this.state.newEmail.length === 0 ||
      this.state.newRoles == null ||
      this.state.newRoles.length === 0
    ) {
      const args = {
        message: "Error!",
        description: "All fields are required.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loadingEditUser: false });
      return;
    }
    let finalBody = {
      email: this.state.newEmail,
      roles: this.state.newRoles,
      // name: this.state.newName
    };
    // alert(JSON.stringify(finalBody))
    // this.setState({loadingEditUser: false})
    // return
    let promise = editUser(finalBody);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "User Edited.",
          duration: 5,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        this.getUsers();
        this.setState({ loadingEditUser: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingEditUser: false });
      });
  };

  render() {
    const permissionsOptions = [
      { value: "super_admin", label: "super_admin" },
      { value: "admin", label: "admin" },
      { value: "user", label: "user" },
      { value: "driver_pay_admin", label: "driver_pay_admin" },
    ];

    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => record.email,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.name,
      },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
        render: (text, record, index) => (record.roles ? record.roles.join(", ") : null),
      },
      {
        title: "Activated",
        dataIndex: "activated",
        key: "activated",
        render: (text, record) => (record.activated ? "yes" : "no"),
      },
      {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
        render: (text, record, index) => {
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  newEmail: record.email,
                  newName: record.name,
                  newRoles: record.roles,
                  showEditModal: true,
                });
                // alert(JSON.stringify(this.formRef))
                // this.formRef.current && this.formRef.current.setFieldsValue({
                //     email: record.email, name: record.name, roles: record.roles
                // });
              }}
              icon={<EditOutlined />}
            ></Button>
          );
        },
      },
      {
        title: "Delete",
        key: "delete",
        render: (text, record, index) => (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              this.handleDeleteUser(record, index);
            }}
            loading={this.state.deleteLoadings[index]}
            type="primary"
            danger
            icon={<DeleteOutlined />}
          ></Button>
        ),
      },
    ];

    return (
      <div className={"dashboard-container"}>
        <Row gutter={16} className={"search-row"}>
          <Button
            type="primary"
            htmlType="submit"
            loading={this.state.loadingCreateUser}
            onClick={() => this.setState({ showCreateModal: true })}
          >
            Create User
          </Button>
        </Row>
        <Modal
          visible={this.state.showCreateModal}
          title={`Create a new user`}
          onCancel={() => {
            this.setState({ showCreateModal: false });
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          closable={true}
          footer={[
            <Button type="primary" htmlType="submit" loading={this.state.loadingCreateUser} key="submit" form="myForm">
              Create User
            </Button>,
          ]}
        >
          <Form onFinish={(e) => this.handleCreateUser(e)} layout="vertical" name="Get Tasks" id="myForm">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="Email"
                  placeholder="*Email"
                  rules={[{ required: true, message: "Email is a required field" }]}
                >
                  <Input
                    loading={this.state.loadingCreateUser}
                    name="Email"
                    placeholder="*Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="Roles"
                  placeholder="*Roles"
                  rules={[{ required: true, message: "Roles is a required field" }]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    name="roles"
                    placeholder="*Roles"
                    onChange={(value) => this.setState({ roles: value })}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    options={permissionsOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Row>
          {this.state.loadingUsers && this.state.users == null && <Spin></Spin>}
          {this.state.users &&
            this.state.users.length > 0 &&
            !this.state.users.some(function (i) {
              return i == null;
            }) && (
              <Col span={4}>
                <Button className={"order-count-button"} type="primary" disabled>
                  users loaded: {this.state.users.length}
                </Button>
              </Col>
            )}
        </Row>
        {this.state.users &&
          this.state.users.length > 0 &&
          !this.state.users.some(function (i) {
            return i == null;
          }) && (
            <Row>
              <Col span={24}>
                <div className="infinite-container" style={{ height: "80vh", overflow: "auto" }}>
                  <Table
                    loading={this.state.loadingUsers}
                    scroll={{ x: "400" }}
                    style={{ whiteSpace: "pre" }}
                    pagination={false}
                    size={"small"}
                    columns={columns}
                    dataSource={this.state.users.sort((a, b) => a.email.localeCompare(b.email))}
                  />
                </div>
              </Col>
              {this.state.showEditModal && (
                <Modal
                  visible={this.state.showEditModal}
                  title={`Edit user: ${this.state.newEmail}`}
                  onCancel={() => {
                    this.setState({ showEditModal: false });
                  }}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                  closable={true}
                  footer={[
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loadingEditUser}
                      key="submit"
                      form="myEditForm"
                    >
                      Edit
                    </Button>,
                  ]}
                >
                  <Form
                    ref={this.formRef}
                    onFinish={(e) => this.handleEditUser(e)}
                    layout="vertical"
                    name="Edit User"
                    id="myEditForm"
                  >
                    {/* 
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        placeholder="*Name"
                                        // rules={[{required: true, message: 'Name is a required field'}]}
                                        initialValue={this.state.newName}
                                    >
                                        <Input
                                            loading={this.state.loadingEditUser}
                                            name="Name"
                                            placeholder="*Name"
                                            onChange={e => this.setState({newName: e.target.value})}
                                            value={this.state.newName}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="Roles"
                          placeholder="*Roles"
                          rules={[
                            {
                              required: true,
                              message: "Roles is a required field",
                            },
                          ]}
                          initialValue={this.state.newRoles}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            name="roles"
                            placeholder="*Roles"
                            onChange={(value) => this.setState({ newRoles: value })}
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={permissionsOptions}
                            value={this.state.newRoles}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              )}
            </Row>
          )}
      </div>
    );
  }
}

ViewUsers.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(ViewUsers);
