import { Spin, Table, Tag, notification } from "antd";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createServiceUser, deleteUserByUsername, getMerchantById, getUsersByMerchantId } from "../../util/APIUtils";
import CreateServiceUserModal from "./CreateServiceUserModal";
import DashboardHeader from "./DashboardHeader";
import TableRowActions from "./TableRowActions";

const USER_GROUP_MAP = {
  merchant_admin: { title: "User", color: "blue" },
  boxknight_admin: { title: "User", color: "blue" },
  service: { title: "Service User", color: "orange" },
  user: { title: "User", color: "blue" },
};

const MerchantUsersDashboard = () => {
  const { merchantId } = useParams();
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [isMerchantLoading, setIsMerchantLoading] = useState(true);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isServiceUserModalOpen, setIsServiceUserModalOpen] = useState(false);
  const [merchantName, setMerchantName] = useState("");
  const [users, setUsers] = useState([]);
  const [createdServiceUser, setCreatedServiceUser] = useState({});
  const isLoading = isUsersLoading || isMerchantLoading || isCreatingUser || isDeletingUser;

  const fetchMerchant = async () => {
    setIsMerchantLoading(true);
    try {
      const res = await getMerchantById(merchantId);
      setMerchantName(res.name);
      setIsMerchantLoading(false);
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not get merchant. ${e?.message || ""}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsMerchantLoading(false);
    }
  };

  const fetchUsers = async () => {
    setIsUsersLoading(true);
    try {
      const users = await getUsersByMerchantId(merchantId);
      setUsers(users);
      setIsUsersLoading(false);
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not get merchant's users. ${e?.message || ""}`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsUsersLoading(false);
    }
  };

  useEffect(() => {
    fetchMerchant();
    fetchUsers();
  }, [merchantId]);

  const onCreateServiceUser = async () => {
    setIsCreatingUser(true);
    try {
      const { username, password } = await createServiceUser(merchantId);
      setIsCreatingUser(false);
      setIsServiceUserModalOpen(true);
      setCreatedServiceUser({ username, password });
      fetchUsers();
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not create service user. ${e?.message || ""}`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsCreatingUser(false);
    }
  };

  const onResetServiceUserCreation = () => {
    setCreatedServiceUser({});
    setIsServiceUserModalOpen(false);
  };

  const onDeleteUser = async (username) => {
    setIsDeletingUser(true);
    try {
      await deleteUserByUsername(merchantId, username);
      const args = {
        message: "Success!",
        description: `Deletd user ${username || ""}`,
        duration: 3,
        type: "success",
        placement: "topRight",
      };
      notification.open(args);
      setIsDeletingUser(false);
      fetchUsers();
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not delete user ${username || ""}`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsDeletingUser(false);
    }
  };

  const renderNullSafeText = (string) => {
    return string || "-";
  };

  const columns = [
    {
      title: "Name / Username",
      dataIndex: "username",
      key: "username",
      render: (_, record) => <>{record.name || record.username}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: renderNullSafeText,
    },
    {
      title: "Group",
      dataIndex: "userGroup",
      key: "userGroup",
      render: (text) => (
        <Tag color={USER_GROUP_MAP[text] ? USER_GROUP_MAP[text].color : "grey"}>
          {USER_GROUP_MAP[text] ? USER_GROUP_MAP[text].title : "-"}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "15%",
      render: (_, record) => (
        <TableRowActions record={record} isLoading={isLoading} merchantId={merchantId} onDeleteUser={onDeleteUser} />
      ),
    },
  ];

  const renderTable = () => {
    return (
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={users}
        rowKey="id"
        expandIconAsCell={false}
      />
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div className="viewMerchants-container">
        <DashboardHeader
          merchantId={merchantId}
          isLoading={isLoading}
          merchantName={merchantName}
          onCreateServiceUser={onCreateServiceUser}
        />
        {renderTable()}
        <CreateServiceUserModal
          isServiceUserModalOpen={isServiceUserModalOpen}
          createdServiceUser={createdServiceUser}
          onResetServiceUserCreation={onResetServiceUserCreation}
        />
      </div>
    </Spin>
  );
};
export default MerchantUsersDashboard;
