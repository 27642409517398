import React from "react";
import { render } from "react-dom";
import { createStore } from "redux";
import boxknightAdminApp from "./reducers";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

const store = createStore(boxknightAdminApp);

render(<Root store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
