import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import AddEditMerchantUser from "../components/AddEditMerchantUser/AddEditMerchantUser";
import AddMerchant from "../components/AddMerchant";
import { AddShippingRules } from "../components/AddShippingRules";
import AddZones from "../components/AddZones";
import BulkPickupReady from "../components/BulkPickupReady";
import Dashboard from "../components/Dashboard";
import Login from "../components/Login";
import MerchantUsersDashboard from "../components/MerchantUsersScreen/MerchantUsersDashboard";
import Rates from "../components/Rates";
import { Register } from "../components/Register";
import SingleDriverApplicantProfile from "../components/SingleDriverApplicantProfile";
import SingleDriverProfile from "../components/SingleDriverProfile";
import SinglePayrollReport from "../components/SinglePayrollReport";
import ViewMerchants from "../components/ViewMerchants";
import ViewPickups from "../components/ViewPickups";
import ViewShippingRules from "../components/ViewShippingRules";
import ViewUsers from "../components/ViewUsers";
import ViewZones from "../components/ViewZones";
import PrivateRoute from "../components/common/PrivateRoute";

export class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" render={(props) => <Login {...props} />} />
        <Route exact path="/register" render={(props) => <Register {...props} />} />
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/dashboard"}
          component={Dashboard}
          roles={this.props.roles}
          requiredRoles={["user", "admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/orders/rates"}
          component={Rates}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/bulk_update"}
          component={BulkPickupReady}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          exact
          path={"/driverApplicants/profiles"}
          component={SingleDriverApplicantProfile}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          exact
          path={"/drivers/profiles/details"}
          component={SingleDriverProfile}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/drivers/driverpay/viewReports/details"}
          component={SinglePayrollReport}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/viewMerchants"}
          component={ViewMerchants}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/addMerchant"}
          component={AddMerchant}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:name/viewZones"}
          component={ViewZones}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:name/addZones"}
          component={AddZones}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:name/viewShippingRules"}
          component={ViewShippingRules}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:merchantId/users"}
          component={MerchantUsersDashboard}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:merchantId/user/:username/edit"}
          component={AddEditMerchantUser}
          roles={this.props.roles}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:name/addShippingRules"}
          component={AddShippingRules}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/orders/pickups"}
          component={ViewPickups}
          roles={this.props.roles}
          requiredRoles={["admin"]}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/users"}
          component={ViewUsers}
          roles={this.props.roles}
        ></PrivateRoute>
        <PrivateRoute
          authenticated={this.props.isAuthenticated}
          path={"/merchants/:merchantId/user/create"}
          component={AddEditMerchantUser}
          roles={this.props.roles}
        ></PrivateRoute>
      </Switch>
    );
  }
}

Routes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  roles: state.auth.roles,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
