import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { default as React } from "react";
import "./ViewMerchants.css";

const MerchantNameFilterInput = ({
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  columnName,
  handleSearch,
  handleReset,
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        autoFocus
        placeholder={`Search ${columnName}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => handleSearch(selectedKeys)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default MerchantNameFilterInput;
