import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Radio, Row, Spin, Tooltip, Typography, notification } from "antd";
import moment from "moment";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { addMerchantGoboltOrgId, addMerchantGoboltPickupCustomerId, editMerchant } from "../util/APIUtils";
const { Paragraph } = Typography;

export class MerchantDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: this.props.merchant,
      loadingCreateGoboltOrgId: false,
      loadingCreateGoboltPickupCustomerId: false,
      tasksDetails: [],
      detailsLoaded: false,
      isEditLoading: {
        pickupNotes: false,
        personalizedNotes: false,
        name: false,
        phone: false,
        email: false,
        ["merchantLocation.street"]: false,
        ["merchantLocation.unit"]: false,
        ["merchantLocation.postalCode"]: false,
        ["merchantLocation.city"]: false,
        ["merchantLocation.province"]: false,
        ["merchantLocation.country"]: false,
        signatureRequired: false,
        scanningRequired: false,
        dataRedactionEnabled: false,
        rateShowingOptionType: false,
        createPickup: false,
      },
      orgVals: {
        pickupNotes: "",
        personalizedNotes: "",
        name: "",
        email: "",
        phone: "",
        ["merchantLocation.street"]: "",
        ["merchantLocation.unit"]: "",
        ["merchantLocation.postalCode"]: "",
        ["merchantLocation.city"]: "",
        ["merchantLocation.province"]: "",
        ["merchantLocation.country"]: "",
        rateShowingOptionType: "",
        createPickup: "",
      },
      editVals: {
        pickupNotes: "",
        personalizedNotes: "",
        name: "",
        phone: "",
        email: "",
        ["merchantLocation.street"]: "",
        ["merchantLocation.unit"]: "",
        ["merchantLocation.postalCode"]: "",
        ["merchantLocation.city"]: "",
        ["merchantLocation.province"]: "",
        ["merchantLocation.country"]: "",
        signatureRequired: "",
        scanningRequired: "",
        dataRedactionEnabled: "",
        rateShowingOptionType: "",
        createPickup: "",
      },
      editing: {
        pickupNotes: false,
        personalizedNotes: false,
        name: false,
        phone: false,
        email: false,
        ["merchantLocation.street"]: false,
        ["merchantLocation.unit"]: false,
        ["merchantLocation.postalCode"]: false,
        ["merchantLocation.city"]: false,
        ["merchantLocation.province"]: false,
        ["merchantLocation.country"]: false,
        signatureRequired: false,
        scanningRequired: false,
        dataRedactionEnabled: false,
        rateShowingOptionType: false,
        configuration: false,
      },
    };
  }

  generateEditableField = (field) => {
    let myField = "";
    if (!field.includes(".")) {
      myField = this.state.merchant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      myField = this.state.merchant[firstField][secondField];
    }
    return (
      <>
        <Col span={12}>
          <Spin spinning={this.state.isEditLoading[field]} />
          {this.state.editing[field] ? (
            this.getEditingField(field)
          ) : (
            <Paragraph style={{ whiteSpace: "normal" }} ellipsis={{ rows: 3, expandable: true }}>
              {myField}
            </Paragraph>
          )}
        </Col>
        <Col span={4}>
          {this.state.editing[field] ? (
            <>
              <Button
                style={{ marginLeft: 25 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                icon={<CheckOutlined />}
              />
              <Button size={"small"} onClick={(e) => this.clickCancelButton(field)} icon={<CloseOutlined />} />
            </>
          ) : (
            <>
              <Button
                style={{ marginLeft: 25 }}
                size={"small"}
                onClick={(e) => this.clickEditButton(field)}
                icon={<EditOutlined />}
              />
              {(field === "merchantLocation.unit" || field === "phone") && myField != null && (
                <Button
                  style={{ marginLeft: 10 }}
                  size={"small"}
                  onClick={(e) => this.clickRemoveButton(field)}
                  icon={<DeleteOutlined />}
                  type="danger"
                />
              )}
            </>
          )}
        </Col>
      </>
    );
  };

  onRadioChange = (field, e) => {
    const val = e.target.value;
    this.setState({ editVals: { ...this.state.editVals, [field]: val } });
  };

  renderRadioButtons = (field) => {
    const { editing } = this.state;
    let myField = "";
    if (!field.includes(".")) {
      myField = this.state.merchant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      myField = this.state.merchant[firstField][secondField];
    }

    return (
      <Col span={8}>
        <Spin spinning={this.state.isEditLoading[field]}>
          <Radio.Group
            disabled={!editing[field]}
            onChange={(e) => this.onEditChange(e, field)}
            value={editing[field] ? this.state.editVals[field] : this.state.merchant[field]}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>

          {this.state.editing[field] ? (
            <>
              <Button
                style={{ marginLeft: 25 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                icon={<CheckOutlined />}
              />
              <Button size={"small"} onClick={(e) => this.clickCancelButton(field)} icon={<CloseOutlined />} />
            </>
          ) : (
            <>
              <Button
                style={{ marginLeft: 25 }}
                size={"small"}
                onClick={(e) => this.clickEditButton(field)}
                icon={<EditOutlined />}
              />
              {(field === "merchantLocation.unit" || field === "phone") && myField != null && (
                <Button
                  style={{ marginLeft: 10 }}
                  size={"small"}
                  onClick={(e) => this.clickRemoveButton(field)}
                  icon={<DeleteOutlined />}
                  type="danger"
                />
              )}
            </>
          )}
        </Spin>
      </Col>
    );
  };

  renderRateShowingOptions = (field) => {
    const { editing } = this.state;
    let myField = "";
    if (!field.includes(".")) {
      myField = this.state.merchant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      myField = this.state.merchant[firstField][secondField];
    }

    return (
      <Col span={8}>
        <Spin spinning={this.state.isEditLoading[field]}>
          <Row>
            <Radio.Group
              style={{ display: "flex", flexDirection: "column" }}
              disabled={!editing[field]}
              onChange={(e) => this.onEditChange(e, field)}
              value={editing[field] ? this.state.editVals[field] : this.state.merchant[field]}
            >
              <Radio value="ALWAYSSHOWSAMEDAY">Always</Radio>
              <Radio value="SAMEDAYCUTOFF">Hide After Cutoff</Radio>
            </Radio.Group>

            {this.state.editing[field] ? (
              <>
                <Button
                  style={{ marginLeft: 0 }}
                  size={"small"}
                  onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                  icon={<CheckOutlined />}
                />
                <Button size={"small"} onClick={(e) => this.clickCancelButton(field)} icon={<CloseOutlined />} />
              </>
            ) : (
              <>
                <Button
                  style={{ marginLeft: 0 }}
                  size={"small"}
                  onClick={(e) => this.clickEditButton(field)}
                  icon={<EditOutlined />}
                />
                {(field === "merchantLocation.unit" || field === "phone") && myField != null && (
                  <Button
                    style={{ marginLeft: 0 }}
                    size={"small"}
                    onClick={(e) => this.clickRemoveButton(field)}
                    icon={<DeleteOutlined />}
                    type="danger"
                  />
                )}
              </>
            )}
          </Row>
        </Spin>
      </Col>
    );
  };

  clickRemoveButton = (field) => {
    this.setState({
      isEditLoading: { ...this.state.isEditLoading, [field]: true },
    });
    let myField = field;
    if (myField.includes("merchantLocation")) myField = myField.split(".")[1];

    let editMerchantDTO = { [myField]: "" };

    let promise = editMerchant(editMerchantDTO, this.state.merchant.id);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Merchant Updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);

        this.setState({
          isEditLoading: { ...this.state.isEditLoading, [field]: false },
        });
        this.setState({ merchant: { ...this.state.merchant, [field]: null } });
        this.props.onOperationComplete(this.state.merchant.id, field, null);
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({
          isEditLoading: { ...this.state.isEditLoading, [field]: false },
        });
      });
  };

  getEditingField = (field) => {
    if (field === "phone") {
      return (
        <PhoneInput
          name=""
          inputClass="ant-input"
          inputComponent={Input}
          country="us"
          tabindex="-1"
          placeholder="*Phone"
          value={this.state.editVals[field]}
          onChange={(v) => this.onPhoneNumberChange({ target: { name: "phone", value: v } })}
          type={"tel"}
          size="small"
          specialLabel=""
        />
      );
    }
    return (
      <Input.TextArea
        autoSize
        type={"text"}
        style={{ whiteSpace: "normal" }}
        ellipsis={{ rows: 3, expandable: true }}
        value={this.state.editVals[field]}
        onChange={(e) => this.onEditChange(e, field)}
      />
    );
  };

  onEditChange = (e, field) => {
    this.setState({
      editVals: { ...this.state.editVals, [field]: e.target.value },
    });
  };

  onPhoneNumberChange = (e) => {
    let value = e.target.name === "phone" ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
    this.setState({
      editVals: { ...this.state.editVals, [e.target.name]: value },
    });
  };

  clickEditButton = (field) => {
    let myField = this.getField(field);
    this.setState({
      editing: { ...this.state.editing, [field]: !this.state.editing[field] },
    });
    this.setState({
      orgVals: { ...this.state.orgVals, [field]: this.state.merchant[field] },
    });
    this.setState({ editVals: { ...this.state.editVals, [field]: myField } });
  };

  clickCancelButton = (field) => {
    this.setState({
      editing: { ...this.state.editing, [field]: !this.state.editing[field] },
    });
    this.setState({
      editVals: { ...this.state.editVals, [field]: this.state.orgVals[field] },
    });
  };

  clickSaveButton = (field, value) => {
    if (value === undefined) return;
    this.setState({
      isEditLoading: { ...this.state.isEditLoading, [field]: true },
    });
    let myField = field;
    if (myField.includes("merchantLocation")) myField = myField.split(".")[1];

    if (value === null || (typeof value === "string" && value.trim() === "")) {
      const args = {
        message: "Error!",
        description: `Please make sure that the field is not empty.`,
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({
        isEditLoading: { ...this.state.isEditLoading, [field]: false },
      });
      return;
    }

    let myValue = value;
    if (myField === "phone" && value === "+") myValue = "";

    let editMerchantDTO = { [myField]: myValue };

    let promise = editMerchant(editMerchantDTO, this.state.merchant.id);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Merchant Updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);
        this.setState({
          editing: {
            ...this.state.editing,
            [field]: !this.state.editing[field],
          },
        });
        this.setState({
          isEditLoading: { ...this.state.isEditLoading, [field]: false },
        });
        this.setState({ merchant: { ...this.state.merchant, [field]: value } });
        this.props.onOperationComplete(this.state.merchant.id, field, value);
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({
          isEditLoading: { ...this.state.isEditLoading, [field]: false },
        });
      });
  };

  getField = (field) => {
    if (!field.includes(".")) {
      return this.state.merchant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      return this.state.merchant[firstField][secondField];
    }
  };

  addMerchantGoboltOrganization = () => {
    this.setState({ loadingCreateGoboltOrgId: true });
    let promise = addMerchantGoboltOrgId(this.state.merchant.id);
    promise
      .then((response) => {
        this.setState({
          merchant: {
            ...this.state.merchant,
            goboltOrgId: response.goboltOrgId,
          },
        });
        this.props.onOperationComplete(this.state.merchant.id, "goboltOrgId", response.goboltOrgId);
        const args = {
          message: "Success!",
          description: "Merchant Updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);
        this.setState({ loadingCreateGoboltOrgId: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingCreateGoboltOrgId: false });
      });
  };

  addMerchantGoboltPickupCustomer = () => {
    this.setState({ loadingCreateGoboltPickupCustomerId: true });
    let promise = addMerchantGoboltPickupCustomerId(this.state.merchant.id);
    promise
      .then((response) => {
        this.setState({
          merchant: {
            ...this.state.merchant,
            goboltPickupCustomerId: response.goboltPickupCustomerId,
          },
        });
        this.props.onOperationComplete(
          this.state.merchant.id,
          "goboltPickupCustomerId",
          response.goboltPickupCustomerId,
        );
        const args = {
          message: "Success!",
          description: "Merchant Updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);
        this.setState({ loadingCreateGoboltPickupCustomerId: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingCreateGoboltPickupCustomerId: false });
      });
  };

  renderBasicInfo = () => {
    return (
      <Card title="Basic Information">
        <Row gutter={16}>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <span className={"info-content-key"}>Name:</span>
              </Col>
              {this.generateEditableField("name")}
              <Col span={8}>
                <span className={"info-content-key"}>Id:</span>
              </Col>
              <Col span={16}>
                <span>{this.state.merchant.id}</span>
              </Col>
              <Col span={8}>
                <span className={"info-content-key"}>Task Time Creation:</span>
              </Col>
              <Col span={16}>
                <span>{this.state.merchant.taskTimeCreation}</span>
              </Col>
              <Col span={8}>
                <span className={"info-content-key"}>Pickup Notes:</span>
              </Col>
              {this.generateEditableField("pickupNotes")}
              <Col span={8}>
                <span className={"info-content-key"}>Delivery Notes:</span>
              </Col>
              {this.generateEditableField("personalizedNotes")}
            </Row>
          </Col>

          <Col span={12}>
            <Row>
              <Col span={8}>
                <span className={"info-content-key"}>Phone:</span>
              </Col>
              {this.generateEditableField("phone")}

              <Col span={8}>
                <span className={"info-content-key"}>Email:</span>
              </Col>
              {this.generateEditableField("email")}

              <Col span={8}>
                <span className={"info-content-key"}>GoBolt Organization:</span>
              </Col>
              <Col span={16}>
                <span className={"info-content-key"}>
                  {this.state.merchant.goboltOrgId ? (
                    this.state.merchant.goboltOrgId
                  ) : (
                    <Button
                      type="primary"
                      onClick={this.addMerchantGoboltOrganization}
                      loading={this.state.loadingCreateGoboltOrgId}
                    >
                      Create GoBolt Organization
                    </Button>
                  )}
                </span>
              </Col>

              <Col span={24}>
                <span className={"info-content-key"}>&nbsp;</span>
              </Col>

              <Col span={8}>
                <span className={"info-content-key"}>GoBolt Customer:</span>
              </Col>
              <Col span={16}>
                <span className={"info-content-key"}>
                  {this.state.merchant.goboltPickupCustomerId ? (
                    this.state.merchant.goboltPickupCustomerId
                  ) : (
                    <Button
                      type="primary"
                      onClick={this.addMerchantGoboltPickupCustomer}
                      loading={this.state.loadingCreateGoboltPickupCustomerId}
                    >
                      Create GoBolt Pickup Customer
                    </Button>
                  )}
                </span>
              </Col>

              <Col span={8}>
                <span className={"info-content-key"}>Last redacted at:</span>
              </Col>
              <Col span={16}>
                <span className={"info-content-key"}>
                  {this.state.merchant.lastRedactedAt ? moment(this.state.merchant.lastRedactedAt).format("LLL") : "-"}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  renderLocation = () => {
    return (
      <Col span={12}>
        <Card title="Location">
          <Row>
            <Col span={8}>
              <span className={"info-content-key"}>Street:</span>
            </Col>
            {this.generateEditableField("merchantLocation.street")}

            <Col span={8}>
              <span className={"info-content-key"}>Unit:</span>
            </Col>
            {this.generateEditableField("merchantLocation.unit")}

            <Col span={8}>
              <span className={"info-content-key"}>Postal Code:</span>
            </Col>
            {this.generateEditableField("merchantLocation.postalCode")}

            <Col span={8}>
              <span className={"info-content-key"}>Province:</span>
            </Col>
            {this.generateEditableField("merchantLocation.province")}

            <Col span={8}>
              <span className={"info-content-key"}>Country:</span>
            </Col>
            {this.generateEditableField("merchantLocation.country")}
          </Row>
        </Card>
      </Col>
    );
  };

  renderSettings = () => {
    return (
      <Col span={12}>
        <Card title="Configuration">
          <Row gutter={[0, 12]}>
            <Col span={16}>
              <span className={"info-content-key"}>Scanning Required?</span>
            </Col>
            {this.renderRadioButtons("scanningRequired")}

            <Col span={16}>
              <span className={"info-content-key"}>Signature Required?</span>
            </Col>
            {this.renderRadioButtons("signatureRequired")}

            <Col span={16}>
              <span className={"info-content-key"}>Sameday Showing?</span>
            </Col>
            {this.renderRateShowingOptions("rateShowingOptionType")}

            <Col span={16}>
              <span className={"info-content-key"}>Create Automatic Pickup Task?</span>
            </Col>
            {this.renderRadioButtons("createPickup")}

            <Col span={16}>
              <span className={"info-content-key"}>
                Redact Customer Data
                <Tooltip
                  placement="rightTop"
                  title="Automatically redact customer order data 90 days after order creation"
                >
                  <QuestionCircleOutlined style={{ margin: 2, fontSize: 12 }} />
                </Tooltip>
                ?
              </span>
            </Col>
            {this.renderRadioButtons("dataRedactionEnabled")}
          </Row>
        </Card>
      </Col>
    );
  };

  render() {
    return (
      <div style={{ background: "#ECECEC", padding: "3vmin" }}>
        <Row gutter={[18, 18]}>{this.renderBasicInfo()}</Row>
        <Row gutter={16}>
          {this.renderLocation()}
          {this.renderSettings()}
        </Row>
      </div>
    );
  }
}

export default MerchantDetails;
