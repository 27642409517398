/*
  useCreateMerchant custom hook
  Params: createParams with shape of
  merchantParams {
    name: string
    phone: string
    email: string
    merchantLocation: {
      street: string
      city: string
      province: string
      country: string
      postalCode: string
    }
    scanningRequired: boolean
    signatureRequired: boolean
    taskTimeCreation: 'AUTO' | 'ONPICKUPREADY'
    rateShowingOptionType: 'ALWAYSSHOWSAMEDAY' | 'SAMEDAYCUTOFF'
    locationPricing: {
      pricingRows: Array[]
    },
    deliveryConstraintsByWeek: {
      monday: Array[]
      tuesday: Array[]
      wednesday: Array[]
      thursday: Array[]
      friday: Array[]
      saturday: Array[]
      sunday: Array[]
      exceptions: Array[]
    },
    pickupConstraintsByWeek: {
      monday: Array[]
      tuesday: Array[]
      wednesday: Array[]
      thursday: Array[]
      friday: Array[]
      saturday: Array[]
      sunday: Array[]
      exceptions: Array[]
    }
  }
*/

import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { addMerchant } from "../../util/APIUtils";

const useCreateMerchant = ({ onComplete = () => {}, onError = () => {} }) => {
  const [responseData, setResponseData] = useState({
    data: {},
    loading: false,
    error: null,
  });

  const createMerchant = (merchantParams) => {
    if (!isEmpty(merchantParams)) {
      setResponseData({
        ...responseData,
        loading: true,
      });
      addMerchant(merchantParams)
        .then((response) => {
          if (!isEmpty(response)) {
            const data = {
              ...responseData,
              data: response,
              error: null,
            };

            setResponseData(data);
            onComplete();
          }
        })
        .catch((error) => {
          const data = {
            ...responseData,
            error: error.message,
          };

          setResponseData(data);
          onError();
        })
        .finally(() => {
          setResponseData({
            ...responseData,
            loading: false,
          });
        });
    }
  };
  return [responseData, createMerchant];
};

export default useCreateMerchant;
