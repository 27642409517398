import { notification } from "antd";

export const isValidateWeightBandsBody = (filteredWeightBandsGrid) => {
  if (filteredWeightBandsGrid.length) {
    if (Number(filteredWeightBandsGrid[0][0].value) !== 1) {
      const args = {
        message: "Error!",
        description: "Weight bands must begin at 1.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return false;
    }

    for (let i = 0; i < filteredWeightBandsGrid.length; i++) {
      const weightBandArray = filteredWeightBandsGrid[i];
      const weight = Number(weightBandArray[0].value);

      const isWeightInvalid = weight % 1 !== 0;
      if (isWeightInvalid) {
        const args = {
          message: "Error!",
          description: `${i + 1}lbs weight band must be a whole number.`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        return false;
      }

      if (weight !== i + 1) {
        const args = {
          message: "Error!",
          description: `Weight bands have an invalid interval near row ${i + 1}.`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        return false;
      }

      const price = weightBandArray[1].value;
      const dollarRegex = /^(0|[1-9]\d*)(\.\d{1,2})?$|^\.\d{1,2}$/;
      const isPriceInvalid = !price.match(dollarRegex);
      if (isPriceInvalid) {
        const args = {
          message: "Error!",
          description: `${i + 1}lbs weight band must have a valid dollar amount.`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        return false;
      }
    }
  }
  return true;
};
