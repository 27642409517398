import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Image, Input, Row, Select, Spin, Tabs, Tag, Typography } from "antd";
import lodash from "lodash";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import {
  formatAvailabilties,
  formatRequirements,
  mapDriverApplicantHoursPerWeek,
  mapDriverApplicantStatusToColour,
} from "../util/Helpers";
import "./SingleDriverApplicantProfile.css";
const { Paragraph } = Typography;
const { TabPane } = Tabs;

export class DriverApplicantInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstnameSearch: null,
      lastnameSearch: null,
      citySearch: null,
      loadingDriverApplicant: false,
      loading: false,
      drivers: [],
      deleteLoadings: [],
      searchText: "",
      searchedColumn: "",
      // driverApplicant: this.props.driverApplicant,
      allTeams: [],
      sizeOptions: [
        { value: "XS", label: "XS" },
        { value: "S", label: "S" },
        { value: "M", label: "M" },
        { value: "L", label: "L" },
        { value: "XL", label: "XL" },
      ],
      schedulersList: [
        { label: "Montreal", value: "montreal" },
        { label: "QA", value: "qa" },
        { label: "Toronto", value: "toronto" },
        { label: "Quebec City", value: "quebecCity" },
        { label: "Vancouver", value: "vancouver" },
        { label: "Montreal Vans", value: "montrealVans" },
        { label: "Toronto Vans", value: "torontoVans" },
      ],
      // cities: [{value: 'mtl', label: 'mtl'}, {value: 'to', label: 'to'}],
      cities: [
        { value: "GMA", label: "GMA" },
        { value: "GTA", label: "GTA" },
        { value: "QCC", label: "QCC" },
        { value: "GOA", label: "GOA" },
        { value: "GVA", label: "GVA" },
      ],
      types: [
        { value: "CAR", label: "CAR" },
        { value: "MOTORCYCLE", label: "MOTORCYCLE" },
        { value: "BICYCLE", label: "BICYCLE" },
        { value: "TRUCK", label: "TRUCK" },
      ],
      extraThirtyOptions: [
        { value: true, label: "true" },
        { value: false, label: "false" },
      ],
      isEditLoading: {
        firstname: false,
        lastname: false,
        phone: false,
        email: false,
        city: false,
        teams: false,
        displayName: false,
        capacity: false,
        ["vehicle.type"]: false,
        ["vehicle.make"]: false,
        ["vehicle.model"]: false,
        ["vehicle.licensePlate"]: false,
        ["vehicle.color"]: false,
        ["vehicle.size"]: false,
        extraThirty: false,
        schedulers: false,

        ["address.street"]: false,
        ["address.unit"]: false,
        ["address.postalCode"]: false,
        ["address.city"]: false,
        ["address.province"]: false,
        ["address.country"]: false,
      },
      orgVals: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        city: "",
        teams: [],
        displayName: "",
        capacity: "",
        ["vehicle.type"]: "",
        ["vehicle.make"]: "",
        ["vehicle.model"]: "",
        ["vehicle.licensePlate"]: "",
        ["vehicle.color"]: "",
        ["vehicle.size"]: "",
        extraThirty: "",
        schedulers: [],

        ["address.street"]: "",
        ["address.unit"]: "",
        ["address.postalCode"]: "",
        ["address.city"]: "",
        ["address.province"]: "",
        ["address.country"]: "",
      },
      editVals: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        city: "",
        teams: [],
        displayName: "",
        capacity: "",
        ["vehicle.type"]: "",
        ["vehicle.make"]: "",
        ["vehicle.model"]: "",
        ["vehicle.licensePlate"]: "",
        ["vehicle.color"]: "",
        ["vehicle.size"]: "",
        extraThirty: "",
        schedulers: [],

        ["address.street"]: "",
        ["address.unit"]: "",
        ["address.postalCode"]: "",
        ["address.city"]: "",
        ["address.province"]: "",
        ["address.country"]: "",
      },
      editing: {
        firstname: false,
        lastname: false,
        phone: false,
        email: false,
        city: false,
        teams: false,
        displayName: false,
        capacity: false,
        ["vehicle.type"]: false,
        ["vehicle.make"]: false,
        ["vehicle.model"]: false,
        ["vehicle.licensePlate"]: false,
        ["vehicle.color"]: false,
        ["vehicle.size"]: false,
        extraThirty: false,
        schedulers: false,

        ["address.street"]: false,
        ["address.unit"]: false,
        ["address.postalCode"]: false,
        ["address.city"]: false,
        ["address.province"]: false,
        ["address.country"]: false,
      },
      editType: {
        ["vehicle.type"]: "type",
        ["vehicle.make"]: "make",
        ["vehicle.model"]: "model",
        ["vehicle.licensePlate"]: "licensePlate",
        ["vehicle.color"]: "color",
        ["vehicle.size"]: "size",

        ["address.street"]: "street",
        ["address.unit"]: "unit",
        ["address.postalCode"]: "postalCode",
        ["address.city"]: "city",
        ["address.province"]: "province",
        ["address.country"]: "country",
      },
      driverProfileEvents: [],
      loadingDriverProfileEvents: false,
      pdfLoadings: [],
      pdfs: [],
      driverInvoices: [],
      loadingDriverInvoices: false,
      driverTickets: [],
      loadingDriverTickets: false,
      // vehiclePhoto: this.props.vehiclePhoto,
      // driversLicenseFront: this.props.driversLicenseFront,
      // driversLicenseBack: this.props.driversLicenseBack
    };
  }

  getField = (field) => {
    if (!field.includes(".")) {
      return this.props.driverApplicant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      return this.props.driverApplicant[firstField][secondField];
    }
  };

  clickEditButton = (field) => {
    let myField = this.getField(field);
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ orgVals: { ...this.state.orgVals, [field]: this.props.driverApplicant[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: myField } });
  };

  clickCancelButton = (field) => {
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: this.state.orgVals[field] } });
  };

  clickSaveButton = async (field, value) => {
    let newDriverApplicant = lodash.cloneDeep(this.props.driverApplicant);
    // if it does not include subfields just use the value
    if (!field.includes(".")) {
      if (field === "email") {
        newDriverApplicant[field] = value.toLowerCase().replace(/ /g, "");
      } else newDriverApplicant[field] = value;
    } else {
      // vehicle or address
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];

      newDriverApplicant[firstField][secondField] = value;
    }

    console.log(JSON.stringify(newDriverApplicant));
    return;
  };

  getEditingField = (field) => {
    if (field === "city") {
      return (
        <Select
          showSearch
          name="city"
          value={this.state.editVals[field]}
          loading={this.state.isEditLoading[field]}
          options={this.state.cities}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      );
    } else if (field === "vehicle.type" || field === "vehicle.size") {
      let finalOptions = this.state.types;
      if (field === "vehicle.size") {
        finalOptions = this.state.sizeOptions;
      }
      return (
        <Select
          showSearch
          name="city"
          value={this.state.editVals[field]}
          loading={this.state.isEditLoading[field]}
          options={finalOptions}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{ width: 150 }}
        />
      );
    } else if (field === "phone") {
      return (
        <PhoneInput
          name=""
          inputComponent={Input}
          country={"us"}
          countryCodeEditable={false}
          onlyCountries={["us"]}
          size={"large"}
          type={"tel"}
          inputClass="ant-input ant-input-md"
          specialLabel=""
          disabled={this.state.isEditLoading[field]}
          value={this.state.editVals[field]}
        />
      );
    } else {
      return (
        <Input
          type={field === "email" ? "email" : "text"}
          style={{ whiteSpace: "normal" }}
          ellipsis={{ rows: 3, expandable: true }}
          value={this.state.editVals[field]}
          onChange={(e) => this.onEditChange(e, field)}
          loading={this.state.isEditLoading[field]}
        />
      );
    }
  };

  generateEditableField = (field) => {
    let myField = "";
    if (!field.includes(".")) {
      myField = this.props.driverApplicant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      myField = this.props.driverApplicant[firstField][secondField];
    }
    return (
      <>
        <Col span={8}>
          <Spin spinning={this.state.isEditLoading[field]} />
          {this.state.editing[field] ? (
            this.getEditingField(field)
          ) : (
            <Paragraph style={{ whiteSpace: "normal" }} ellipsis={{ rows: 3, expandable: true }}>
              {myField}
            </Paragraph>
          )}
        </Col>
        <Col span={10}>
          {this.state.editing[field] ? (
            <>
              <Button
                style={{ marginLeft: 5 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                icon={<CheckOutlined />}
                loading={this.state.isEditLoading[field]}
              />
              <Button
                size={"small"}
                onClick={(e) => this.clickCancelButton(field)}
                icon={<CloseOutlined />}
                loading={this.state.isEditLoading[field]}
              />
            </>
          ) : (
            <>
              {Object.entries(this.state.editing).every((x) => x[1] === false) && (
                <Button
                  style={{ marginLeft: 5 }}
                  size={"small"}
                  onClick={(e) => this.clickEditButton(field)}
                  icon={<EditOutlined />}
                />
              )}
            </>
          )}
        </Col>
      </>
    );
  };

  onEditChange = (e, field) => {
    if (field !== "vehicle.size" && field !== "city" && field !== "vehicle.type") {
      // determine the type of the field if it hasn't been defined, set it to string
      const type = typeof this.state.editType[field] === "undefined" ? "string" : this.state.editType[field];
      // clean it if it is a phone number
      // const value = type === 'phone' ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
      this.setState({ editVals: { ...this.state.editVals, [field]: e.target.value } });
    } else {
      this.setState({ editVals: { ...this.state.editVals, [field]: e } });
    }
  };

  render() {
    console.log(this.state.currentPdf);
    return (
      <>
        <Spin spinning={this.state.loadingDriverApplicant}></Spin>
        {this.props.driverApplicant != null && (
          <Tabs
          // activeKey={this.props.activeTab}
          // onChange={this.props.changeTab}
          >
            <TabPane tab="Info" key="1">
              <Row gutter={16}>
                <Col span={this.props.driverApplicant.vehicle || this.props.driverApplicant.address ? 12 : 16}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <strong>Basic Information</strong>
                    </Col>

                    <Col span={6}>
                      <span className={"info-content-key"}>First Name:</span>
                    </Col>
                    <Col span={18}>
                      <span className={"info-content-key"}>{this.props.driverApplicant.firstname}</span>
                    </Col>
                    {/* {this.generateEditableField('firstname')} */}

                    <Col span={6}>
                      <span className={"info-content-key"}>Last Name:</span>
                    </Col>
                    <Col span={18}>
                      <span className={"info-content-key"}>{this.props.driverApplicant.lastname}</span>
                    </Col>
                    {/* {this.generateEditableField('lastname')} */}

                    {this.props.driverApplicant.quizScore && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Quiz Score:</span>
                        </Col>
                        <Col span={18}>
                          <span>{this.props.driverApplicant.quizScore}</span>
                        </Col>
                      </>
                    )}

                    {this.props.driverApplicant.tutorialScore && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Tutorial Score:</span>
                        </Col>
                        <Col span={18}>
                          <span>{this.props.driverApplicant.tutorialScore}</span>
                        </Col>
                      </>
                    )}

                    <Col span={6}>
                      <span className={"info-content-key"}>Status:</span>
                    </Col>
                    <Col span={18}>
                      <span>
                        <Tag color={mapDriverApplicantStatusToColour(this.props.driverApplicant.status)}>
                          {this.props.driverApplicant.status}
                        </Tag>
                      </span>
                    </Col>

                    {this.props.driverApplicant.rejectedBy && this.props.driverApplicant.status === "REJECTED" && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Rejected By:</span>
                        </Col>
                        <Col span={18}>
                          <span>{this.props.driverApplicant.rejectedBy}</span>
                        </Col>
                      </>
                    )}

                    {this.props.driverApplicant.rejectionReason && this.props.driverApplicant.status === "REJECTED" && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Rejection Reason:</span>
                        </Col>
                        <Col span={18}>
                          <span>{this.props.driverApplicant.rejectionReason}</span>
                        </Col>
                      </>
                    )}

                    <Col span={6}>
                      <span className={"info-content-key"}>Phone:</span>
                    </Col>
                    <Col span={18}>
                      <span>{this.props.driverApplicant.phone}</span>
                    </Col>
                    {/* {this.generateEditableField('phone')} */}

                    <Col span={6}>
                      <span className={"info-content-key"}>City:</span>
                    </Col>
                    <Col span={18}>
                      <span className={"info-content-key"}>{this.props.driverApplicant.city}</span>
                    </Col>
                    {/* {this.generateEditableField('city')} */}

                    <Col span={6}>
                      <span className={"info-content-key"}>Email:</span>
                    </Col>
                    <Col span={18}>
                      <span className={"info-content-key"}>{this.props.driverApplicant.email}</span>
                    </Col>
                    {/* {this.generateEditableField('email')} */}

                    <Col span={6}>
                      <span className={"info-content-key"}>Created At:</span>
                    </Col>
                    <Col span={18}>
                      <span>
                        {new Date(this.props.driverApplicant.createdAt).toDateString()}{" "}
                        {new Date(this.props.driverApplicant.createdAt).toLocaleTimeString()}
                      </span>
                    </Col>

                    <Col span={6}>
                      <span className={"info-content-key"}>Steps completed:</span>
                    </Col>
                    <Col span={18}>
                      <span>
                        {this.props.driverApplicant.steps &&
                          this.props.driverApplicant.steps.map((x) => {
                            return <Row>{x}</Row>;
                          })}
                      </span>
                    </Col>
                    {this.props.driverApplicant.tellUs && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Tell Us About Yourself:</span>
                        </Col>
                        <Col span={18}>
                          <span>{this.props.driverApplicant.tellUs}</span>
                        </Col>
                      </>
                    )}
                    {this.props.driverApplicant.hoursPerWeek && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Hours Per Week:</span>
                        </Col>
                        <Col span={18}>
                          <span>{mapDriverApplicantHoursPerWeek(this.props.driverApplicant.hoursPerWeek)}</span>
                        </Col>
                      </>
                    )}
                    {this.props.driverApplicant.availabilities && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Availabilities:</span>
                        </Col>
                        <Col span={18}>
                          <span>
                            {formatAvailabilties(this.props.driverApplicant.availabilities).map((x) => {
                              return <Row>{x}</Row>;
                            })}
                          </span>
                        </Col>
                      </>
                    )}
                    {this.props.driverApplicant.requirements && (
                      <>
                        <Col span={6}>
                          <span className={"info-content-key"}>Requirements:</span>
                        </Col>
                        <Col span={18}>
                          <span>
                            {formatRequirements(this.props.driverApplicant.requirements) ? (
                              <Tag color="green">Passed</Tag>
                            ) : (
                              <Tag color="red">Failed</Tag>
                            )}
                          </span>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={16}>
                    {this.props.driverApplicant.address && (
                      <>
                        <Col span={24}>
                          <strong>Address</strong>
                        </Col>
                        <Col span={6}>
                          <span className={"info-content-key"}>Street:</span>
                        </Col>
                        <Col span={18}>
                          <span className={"info-content-key"}>{this.props.driverApplicant.address.street}</span>
                        </Col>
                        {/* {this.generateEditableField('address.street')} */}
                        <Col span={6}>
                          <span className={"info-content-key"}>Unit:</span>
                        </Col>
                        <Col span={18}>
                          <span className={"info-content-key"}>{this.props.driverApplicant.address.unit}</span>
                        </Col>
                        {/* {this.generateEditableField('address.unit')} */}
                        <Col span={6}>
                          <span className={"info-content-key"}>Postal Code:</span>
                        </Col>
                        <Col span={18}>
                          <span className={"info-content-key"}>{this.props.driverApplicant.address.postalCode}</span>
                        </Col>
                        {/* {this.generateEditableField('address.postalCode')} */}
                        <Col span={6}>
                          <span className={"info-content-key"}>City:</span>
                        </Col>
                        <Col span={18}>
                          <span className={"info-content-key"}>{this.props.driverApplicant.address.city}</span>
                        </Col>
                        {/* {this.generateEditableField('address.city')} */}
                        <Col span={6}>
                          <span className={"info-content-key"}>Province:</span>
                        </Col>
                        <Col span={18}>
                          <span className={"info-content-key"}>{this.props.driverApplicant.address.province}</span>
                        </Col>
                        {/* {this.generateEditableField('address.province')} */}
                        <Col span={6}>
                          <span className={"info-content-key"}>Country:</span>
                        </Col>
                        <Col span={18}>
                          <span className={"info-content-key"}>{this.props.driverApplicant.address.country}</span>
                        </Col>
                        {/* {this.generateEditableField('address.country')} */}
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </TabPane>
            {this.props.driverApplicant.vehicle && (
              <TabPane tab="Vehicle" key="2">
                <Row gutter={16}>
                  <Col span={12}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <strong>Vehicle</strong>
                      </Col>
                      {/* <Col span={24}>
                            &nbsp;
                            </Col> */}
                      <Col span={6}>
                        <span className={"info-content-key"}>Type:</span>
                      </Col>
                      <Col span={18}>
                        <span className={"info-content-key"}>{this.props.driverApplicant.vehicle.type}</span>
                      </Col>
                      {/* {this.generateEditableField('vehicle.type')} */}
                      <Col span={6}>
                        <span className={"info-content-key"}>Make:</span>
                      </Col>
                      <Col span={18}>
                        <span className={"info-content-key"}>{this.props.driverApplicant.vehicle.make}</span>
                      </Col>
                      {/* {this.generateEditableField('vehicle.make')} */}
                      <Col span={6}>
                        <span className={"info-content-key"}>Model:</span>
                      </Col>
                      <Col span={18}>
                        <span className={"info-content-key"}>{this.props.driverApplicant.vehicle.model}</span>
                      </Col>
                      {/* {this.generateEditableField('vehicle.model')} */}
                      <Col span={6}>
                        <span className={"info-content-key"}>License Plate:</span>
                      </Col>
                      <Col span={18}>
                        <span className={"info-content-key"}>{this.props.driverApplicant.vehicle.licensePlate}</span>
                      </Col>
                      {/* {this.generateEditableField('vehicle.licensePlate')} */}
                      <Col span={6}>
                        <span className={"info-content-key"}>Colour:</span>
                      </Col>
                      <Col span={18}>
                        <span className={"info-content-key"}>{this.props.driverApplicant.vehicle.color}</span>
                      </Col>
                      {/* {this.generateEditableField('vehicle.color')} */}
                      <Col span={6}>
                        <span className={"info-content-key"}>Size:</span>
                      </Col>
                      <Col span={18}>
                        <span className={"info-content-key"}>{this.props.driverApplicant.vehicle.size}</span>
                      </Col>
                      {/* {this.generateEditableField('vehicle.size')} */}
                    </Row>
                  </Col>
                  {/* {alert(this.state.vehiclePhoto)} */}
                  {this.props.vehiclePhoto && (
                    <Col span={12}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Image
                            // width={200}
                            src={this.props.vehiclePhoto}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </TabPane>
            )}
            {(this.props.driversLicenseFront || this.props.driversLicenseBack) && (
              <TabPane tab="Drivers License" key="3">
                <Row gutter={16}>
                  {this.props.driversLicenseFront && (
                    <Col span={12}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Image
                            // width={200}
                            src={this.props.driversLicenseFront}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {this.props.driversLicenseBack && (
                    <Col span={12}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Image
                            // width={200}
                            src={this.props.driversLicenseBack}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </TabPane>
            )}
          </Tabs>
        )}
      </>
    );
  }
}

DriverApplicantInfo.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(DriverApplicantInfo);
