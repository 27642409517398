import queryString from "query-string";

const findUriParams = (queryParams) => {
  let url = window.location.href;
  let urlParts = url.split("?");
  let updatedUri = "";
  if (urlParts.length > 0) {
    let baseUrl = urlParts[0];
    if (queryParams) {
      baseUrl = baseUrl + `?${queryString.stringify(queryParams || {})}`;
    }
    updatedUri = baseUrl;
  }
  return updatedUri;
};

const buildToastErrorMessage = (errorMessage) => {
  return {
    message: "Error!",
    description: errorMessage,
    duration: 5,
    type: "error",
    placement: "topRight",
  };
};

export { findUriParams, buildToastErrorMessage };
