import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Table,
  TimePicker,
  Typography,
  notification,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getMerchantById, getMerchantNames, getRates, getWindows } from "../util/APIUtils";
import "./Rates.css";
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const setPathProperty = (object, path, value) =>
  path.split(".").reduce((o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}), object);

export class Rates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMerchants: false,
      loadingRates: false,
      loadingWindows: false,
      rates: null,
      windows: null,
      datasource: null,
      columns: null,
      windowSelected: [],
      merchants: [],
      currentMerchantId: null,
      currentDateTime: null,
      formValues: {
        selectedMerchantId: null,
        date: null,
        time: null,
        postalCode: null,
        originPostalCode: null,
      },
      merchant: null,
      loadingMerchant: false,
      postalCode: "",
      originPostalCode: "",
    };
  }
  formRef = React.createRef();
  componentDidMount() {
    this.setState({ loadingMerchants: true });
    let promise = getMerchantNames();
    promise
      .then((response) => {
        this.setState({
          merchants: response.map((e) => {
            return { label: e.name, value: e.id };
          }),
        });
        this.setState({ loadingMerchants: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not load merchants.",
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchants: false });
      });
  }

  getAllRates = (e) => {
    this.setState({ loadingRates: true });
    this.setState({ datasource: null, columns: null });
    const myDateTime = new Date(this.state.formValues.date);
    myDateTime.setHours(this.state.formValues.time.hours());
    myDateTime.setMinutes(this.state.formValues.time.minutes());
    myDateTime.setSeconds(this.state.formValues.time.seconds());
    myDateTime.setMilliseconds(this.state.formValues.time.milliseconds());
    const dateTime = moment(myDateTime).utc().valueOf();
    // alert(dateTime)
    // return
    //alert(dateTime)
    const body = {
      postalCode: this.state.formValues.postalCode,
      originPostalCode: this.state.formValues.originPostalCode,
      dateTime: dateTime,
    };
    // alert(JSON.stringify(body))
    // return
    let promise = getRates(this.state.formValues.selectedMerchantId, body);
    promise
      .then((response) => {
        this.setState({ rates: response });
        this.setState({ loadingRates: false });
        this.setState({ currentMerchantId: this.state.formValues.selectedMerchantId });
        this.setState({ currentDateTime: dateTime });
        this.setState({
          postalCode: this.state.formValues.postalCode,
          originPostalCode: this.state.formValues.originPostalCode,
        });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingRates: false });
      });
  };

  getWindows = (merchantId, myDateTime, postalCode, originPostalCode) => {
    this.setState({ loadingWindows: true });
    let promise = getWindows(merchantId, myDateTime, postalCode, originPostalCode);
    promise
      .then((response) => {
        this.setState({
          loadingWindows: false,
          windows: response,
          dataSource: this.generateDateSelect(response)[0],
          columns: this.generateDateSelect(response)[1],
        });
      })
      .catch((error) => {
        this.setState({ LoadingWindows: false });
      });
  };

  generateDateSelect = (windows) => {
    const separatedWindows = this.separateWindowsByDate(windows);
    const separatedHours = this.separateWindowsByHours(windows);

    //console.log(separatedWindows)
    //console.log(Object.keys(separatedWindows))
    const uniqueHours = Object.keys(separatedHours);
    uniqueHours.sort();
    const dataSource = uniqueHours.map((elem) => {
      const windows = separatedHours[elem];
      let temp = {};
      windows.forEach((window) => {
        temp[window.date] = elem;
      });
      return temp;
    });
    //console.log(dataSource)

    const columns = Object.keys(separatedWindows).map((elem) => {
      return {
        title: (
          <div style={{ fontSize: "12px" }}>
            {" "}
            {`(${separatedWindows[elem][0].weekday.slice(0, 3).toUpperCase()})`}
            <br /> {`${elem.slice(5, 10)}`}
          </div>
        ),
        dataIndex: elem,
        key: elem,
        align: "center",
        width: 200,
        render: (text) => {
          //console.log(text)
          if (text !== undefined && text !== "") {
            let dateList = elem.split("-");
            let hourList = text.split("-");
            let startHourList = hourList[0].split(":");
            let endHourList = hourList[1].split(":");
            let startTimestamp = this.toTimestamp(
              dateList[0],
              dateList[1],
              dateList[2],
              startHourList[0],
              startHourList[1],
            );
            let endTimeStamp = this.toTimestamp(dateList[0], dateList[1], dateList[2], endHourList[0], endHourList[1]);
            //console.log(startTimestamp, endTimeStamp)
            return (
              <Radio.Button
                key={`${startTimestamp}-${endTimeStamp}`}
                value={`${startTimestamp}-${endTimeStamp}`}
                size="default"
                style={{ height: "7em", width: "100%", marginBottom: "5px" }}
              >
                <p style={{ textAlign: "center", fontSize: "12px" }}>
                  <b>
                    {text.split("-")[0]}
                    <br />
                    to
                    <br />
                    {text.split("-")[1]}
                  </b>
                </p>
              </Radio.Button>
            );
          }
          return text;
        },
      };
    });
    return [dataSource, columns];
  };
  separateWindowsByDate = (windows) => {
    let separatedWindows = {};
    windows.forEach((window) => {
      if (!(window.date in separatedWindows)) {
        separatedWindows[window.date] = [];
      }
      separatedWindows[window.date].push(window);
    });
    return separatedWindows;
  };

  separateWindowsByHours = (windows) => {
    let separatedHours = {};
    windows.forEach((window) => {
      const windowTime = `${window.deliveryWindow.timeStart}-${window.deliveryWindow.timeEnd}`;
      if (!(windowTime in separatedHours)) {
        separatedHours[windowTime] = [];
      }
      separatedHours[windowTime].push(window);
    });
    return separatedHours;
  };

  toTimestamp = (year, month, day, hour, minute) => {
    var datum = new Date(year, month - 1, day, hour, minute);
    return datum.getTime();
  };
  getMyMerchantById = (v) => {
    this.setState({ loadingMerchant: true });
    let promise = getMerchantById(v);
    promise
      .then((response) => {
        // alert(JSON.stringify(response.merchantLocation.postalCode))
        this.setState({ merchant: response });
        this.setState({ loadingMerchant: false });
        if (response?.merchantLocation?.postalCode) {
          this.setState({
            formValues: { ...this.state.formValues, originPostalCode: response.merchantLocation.postalCode },
          });
          this.formRef.current.setFieldsValue({
            originPostalCode: response.merchantLocation.postalCode,
          });
        }
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get merchant",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
  };

  render() {
    return (
      <>
        <div className={"rates-container"}>
          <Form ref={this.formRef} onFinish={(e) => this.getAllRates(e)} layout="vertical" name="formValues">
            <Row gutter={16} className={"search-row"}>
              <Col span={4}>
                <Form.Item
                  name="Merchants"
                  placeholder="*Select a Merchant"
                  rules={[{ required: true, message: "Merchant is a required field" }]}
                  //initialValue={this.state.formValues.originPostalCode}
                >
                  <Select
                    loading={this.state.loadingMerchants || this.state.loadingRates || this.state.loadingMerchant}
                    showSearch
                    name="Merchant"
                    placeholder="*Merchant"
                    options={this.state.merchants}
                    onChange={(value) => {
                      this.setState({ formValues: { ...this.state.formValues, selectedMerchantId: value } });
                      this.getMyMerchantById(value);
                    }}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="Date"
                  //placeholder="*From"
                  rules={[{ required: true, message: "Date is a required field" }]}
                >
                  <DatePicker
                    placeholder="*Date"
                    onChange={(value) => this.setState({ formValues: { ...this.state.formValues, date: value } })}
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    loading={this.state.loadingRates}
                    disabled={this.state.loadingRates}
                    //inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="Time" rules={[{ required: true, message: "Time is a required field" }]}>
                  <TimePicker
                    placeholder="*Time"
                    onChange={(value) => this.setState({ formValues: { ...this.state.formValues, time: value } })}
                    style={{ width: "100%" }}
                    loading={this.state.loadingRates}
                    defaultOpenValue={moment("00:00", "HH:mm")}
                    format="HH:mm"
                    use12Hours={true}
                    disabled={this.state.loadingRates}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={4}>
                                <Form.Item
                                    name="Recipient Postal Code"
                                    rules={[{required: true, message: 'Postal Code is a required field'}]}
                                >
                                    <Input 
                                        placeholder="*Recipient Postal Code" 
                                        style={{width:'100%'}}
                                        loading={this.state.loadingRates}
                                        onChange={e => this.setState({formValues: {...this.state.formValues, postalCode: e.target.value}})}
                                        //disabled={this.state.loadingRates}
                                        disabled={this.state.loadingRates}
                                    />
                                </Form.Item>
                            </Col> */}
              <Col span={4}>
                <Form.Item
                  name={"originPostalCode"}
                  // key="originPostalCode"
                  rules={[{ required: true, message: "Origin Postal Code is a required field" }]}
                  initialValue={this.state.formValues.originPostalCode}
                  // fieldKey='originPostalCode'
                >
                  <Input
                    type="text"
                    key="originPostalCode"
                    placeholder="*Origin Postal Code"
                    style={{ width: "100%" }}
                    // loading={this.state.loadingRates}
                    onChange={(e) =>
                      this.setState({ formValues: { ...this.state.formValues, originPostalCode: e.target.value } })
                    }
                    disabled={this.state.loadingRates}
                    defaultValue={this.state.formValues.originPostalCode}
                    // value={this.state.formValues.originPostalCode}
                    //disabled={this.state.loadingRates}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="Recipient Postal Code"
                  rules={[{ required: true, message: "Postal Code is a required field" }]}
                >
                  <Input
                    placeholder="*Recipient Postal Code"
                    style={{ width: "100%" }}
                    loading={this.state.loadingRates}
                    onChange={(e) =>
                      this.setState({ formValues: { ...this.state.formValues, postalCode: e.target.value } })
                    }
                    //disabled={this.state.loadingRates}
                    disabled={this.state.loadingRates}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={this.state.loadingRates}>
                    Get Rates
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        {this.state.rates != null ? (
          <>
            <div className={"rates-container"}>
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="Rates" bordered={true}>
                    {this.state.rates.map((e) => {
                      return (
                        <div>
                          {/* {JSON.stringify(e)} */}
                          <Row>
                            {e.name}: ${e.price}
                          </Row>
                          <Row>{e.description}</Row>
                          {e.service === "SCHEDULED" && (
                            <Row>
                              <Button
                                type="primary"
                                onClick={() =>
                                  this.getWindows(
                                    this.state.currentMerchantId,
                                    this.state.currentDateTime,
                                    this.state.postalCode,
                                    this.state.originPostalCode,
                                  )
                                }
                                loading={this.state.loadingWindows}
                              >
                                View Window
                              </Button>
                            </Row>
                          )}
                          <Row>&nbsp;</Row>
                        </div>
                      );
                    })}
                    {this.state.dataSource != null && this.state.columns != null ? (
                      <div>
                        <Table
                          dataSource={this.state.dataSource}
                          bordered
                          columns={this.state.columns}
                          pagination={false}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
            {/* {this.state.windows != null?
                                    <div className={"rates-container"}>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Card title="Windows" bordered={true}>
                                                    {this.state.dataSource != null && this.state.columns != null ? 
                                                        <div>
                                                            <Table dataSource={this.state.dataSource} bordered columns={this.state.columns} pagination={false}/>
                                                        </div>
                                                    : ""}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    ''
                                } */}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

Rates.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Rates);
