import { Button, Card, Form, Input } from "antd";
import React from "react";
import { requiredRule, whitespaceRule } from "./AddEditMerchantUser";

const UserInfoCard = ({ user = {}, isEdit, buttonCopy = "", onSubmit = () => {}, isLoading = false }) => {
  if (isLoading) {
    return <Card title="Basic Information" bordered={false} className="card-header" style={{ height: 350 }} />;
  }
  return (
    <Card title="Basic Information" bordered={false} className="card-header">
      <Form.Item name="name" rules={[requiredRule, whitespaceRule]}>
        <Input placeholder="Name" disabled={isLoading} defaultValue={user.name} />
      </Form.Item>
      <Form.Item name="email" rules={[!isEdit && requiredRule, whitespaceRule]}>
        <Input placeholder="Email" disabled={isLoading || isEdit} defaultValue={user.email} />
      </Form.Item>
      <Form.Item name="phone_number" rules={[requiredRule, whitespaceRule]}>
        <Input
          placeholder="Phone Number"
          disabled={isLoading}
          rules={[requiredRule, whitespaceRule]}
          defaultValue={user.phone_number}
        />
      </Form.Item>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
        <Form.Item>
          <Button type="primary" onClick={onSubmit}>
            {buttonCopy}
          </Button>
        </Form.Item>
      </div>
    </Card>
  );
};

export default UserInfoCard;
