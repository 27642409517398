import { Select } from "antd";
import { capitalize } from "lodash";
import React from "react";
import { DELIVERY_CLASSIFICATION } from "../../constants/orders";
const { Option } = Select;

const DeliveryTypeFilter = ({ onChange }) => {
  const options = Object.values(DELIVERY_CLASSIFICATION).map((orderDeliveryType) => (
    <Option key={orderDeliveryType} value={orderDeliveryType} label={orderDeliveryType}>
      {capitalize(orderDeliveryType)}
    </Option>
  ));
  return (
    <Select allowClear style={{ width: "100%", height: "100%" }} placeholder="Delivery Type" onChange={onChange}>
      {options}
    </Select>
  );
};

export default DeliveryTypeFilter;
