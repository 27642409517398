import { Card, Col, Row, Table, Tabs } from "antd";
import React, { Component } from "react";
const { TabPane } = Tabs;

export class TimeChangeEventInfoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workerNames: null,
      loadingWorkerNames: true,
    };
  }

  render() {
    const columns = [
      {
        title: "Time",
        dataIndex: "time",
        key: "time",
        render: (text, record) =>
          record.time != null ? (
            <>
              {new Date(record.time).toDateString()} {new Date(record.time).toLocaleTimeString()}
            </>
          ) : (
            ""
          ),
      },
      {
        title: "Changed By",
        dataIndex: "changedBy",
        key: "changedBy",
        render: (text, record) => (record.changedBy != null ? record.changedBy : ""),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record) => (record.category != null ? record.category : ""),
      },
      {
        title: "Internal Notes",
        dataIndex: "notes",
        key: "notes",
        width: "25%",
        render: (text, record) => (record.notes != null ? record.notes : ""),
      },
      {
        title: "External Notes",
        dataIndex: "externalNotes",
        key: "externalNotes",
        render: (text, record) => (record.externalNotes != null ? record.externalNotes : ""),
      },
      {
        title: "Show External Notes",
        dataIndex: "showExternalNotes",
        key: "showExternalNotes",
        render: (text, record) =>
          record.showExternalNotes != null && record.showExternalNotes === true ? "Yes" : "No",
      },
      {
        title: "Original Window",
        dataIndex: "originalWindow",
        key: "originalWindow",
        render: (text, record) => (
          <>
            {new Date(record.initialCompleteAfter).toDateString()}{" "}
            {new Date(record.initialCompleteAfter).toLocaleTimeString([], { timeStyle: "short" })} -{" "}
            {new Date(record.initialCompleteBefore).toLocaleTimeString([], { timeStyle: "short" })}
          </>
        ),
      },
      {
        title: "New Window",
        dataIndex: "newWindow",
        key: "newWindow",
        render: (text, record) => (
          <>
            {new Date(record.newCompleteAfter).toDateString()}{" "}
            {new Date(record.newCompleteAfter).toLocaleTimeString([], { timeStyle: "short" })} -{" "}
            {new Date(record.newCompleteBefore).toLocaleTimeString([], { timeStyle: "short" })}
          </>
        ),
      },
    ];
    return (
      <Card bordered={false} title="Time Change Events">
        <div>
          <Row gutter={16} className={"window-row"}>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={[...this.props.timeChangeEvents.sort((a, b) => a.time - b.time)]}
                pagination={false}
                size={"large"}
                style={{ width: "100%", marginLeft: 0 }}
              ></Table>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

export default TimeChangeEventInfoContent;
