import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Modal, Row, Select, Spin, Table, Tabs, Typography, notification } from "antd";
import lodash from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import { connect } from "react-redux";
import {
  editDriver,
  getDriverById,
  getDriverInvoicePDFByInvoiceKey,
  getDriverInvoicesByDriverId,
  getDriverProfileEventsByDriverId,
  getDriverTicketsByDriverId,
  getTeams,
} from "../util/APIUtils";
import { updateQueryStringParameter } from "../util/Helpers";
import "./Profiles.css";
import "./SingleDriverProfile.css";
const { Paragraph } = Typography;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;

const setPathProperty = (object, path, value) =>
  path.split(".").reduce((o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}), object);

export class SingleDriverProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstnameSearch: null,
      lastnameSearch: null,
      citySearch: null,
      loadingDriver: false,
      loading: false,
      drivers: [],
      deleteLoadings: [],
      searchText: "",
      searchedColumn: "",
      selectedDriver: null,
      allTeams: [],
      sizeOptions: [
        { value: "XS", label: "XS" },
        { value: "S", label: "S" },
        { value: "M", label: "M" },
        { value: "L", label: "L" },
        { value: "XL", label: "XL" },
      ],
      schedulersList: [
        { label: "Montreal", value: "montreal" },
        { label: "QA", value: "qa" },
        { label: "Toronto", value: "toronto" },
        { label: "Quebec City", value: "quebecCity" },
        { label: "Vancouver", value: "vancouver" },
        { label: "Montreal Vans", value: "montrealVans" },
        { label: "Toronto Vans", value: "torontoVans" },
      ],
      // cities: [{value: 'mtl', label: 'mtl'}, {value: 'to', label: 'to'}],
      cities: [
        { value: "GMA", label: "GMA" },
        { value: "GTA", label: "GTA" },
        { value: "QCC", label: "QCC" },
        { value: "GOA", label: "GOA" },
        { value: "GVA", label: "GVA" },
      ],
      types: [
        { value: "CAR", label: "CAR" },
        { value: "MOTORCYCLE", label: "MOTORCYCLE" },
        { value: "BICYCLE", label: "BICYCLE" },
        { value: "TRUCK", label: "TRUCK" },
      ],
      extraThirtyOptions: [
        { value: true, label: "true" },
        { value: false, label: "false" },
      ],
      isEditLoading: {
        firstname: false,
        lastname: false,
        email: false,
        city: false,
        teams: false,
        displayName: false,
        capacity: false,
        ["vehicle.type"]: false,
        ["vehicle.description"]: false,
        ["vehicle.licensePlate"]: false,
        ["vehicle.color"]: false,
        ["vehicle.size"]: false,
        extraThirty: false,
        schedulers: false,

        ["address.street"]: false,
        ["address.unit"]: false,
        ["address.postalCode"]: false,
        ["address.city"]: false,
        ["address.province"]: false,
        ["address.country"]: false,
      },
      orgVals: {
        firstname: "",
        lastname: "",
        email: "",
        city: "",
        teams: [],
        displayName: "",
        capacity: "",
        ["vehicle.type"]: "",
        ["vehicle.description"]: "",
        ["vehicle.licensePlate"]: "",
        ["vehicle.color"]: "",
        ["vehicle.size"]: "",
        extraThirty: "",
        schedulers: [],

        ["address.street"]: "",
        ["address.unit"]: "",
        ["address.postalCode"]: "",
        ["address.city"]: "",
        ["address.province"]: "",
        ["address.country"]: "",
      },
      editVals: {
        firstname: "",
        lastname: "",
        email: "",
        city: "",
        teams: [],
        displayName: "",
        capacity: "",
        ["vehicle.type"]: "",
        ["vehicle.description"]: "",
        ["vehicle.licensePlate"]: "",
        ["vehicle.color"]: "",
        ["vehicle.size"]: "",
        extraThirty: "",
        schedulers: [],

        ["address.street"]: "",
        ["address.unit"]: "",
        ["address.postalCode"]: "",
        ["address.city"]: "",
        ["address.province"]: "",
        ["address.country"]: "",
      },
      editing: {
        firstname: false,
        lastname: false,
        email: false,
        city: false,
        teams: false,
        displayName: false,
        capacity: false,
        ["vehicle.type"]: false,
        ["vehicle.description"]: false,
        ["vehicle.licensePlate"]: false,
        ["vehicle.color"]: false,
        ["vehicle.size"]: false,
        extraThirty: false,
        schedulers: false,

        ["address.street"]: false,
        ["address.unit"]: false,
        ["address.postalCode"]: false,
        ["address.city"]: false,
        ["address.province"]: false,
        ["address.country"]: false,
      },
      editType: {
        ["vehicle.type"]: "type",
        ["vehicle.description"]: "description",
        ["vehicle.licensePlate"]: "licensePlate",
        ["vehicle.color"]: "color",
        ["vehicle.size"]: "size",

        ["address.street"]: "street",
        ["address.unit"]: "unit",
        ["address.postalCode"]: "postalCode",
        ["address.city"]: "city",
        ["address.province"]: "province",
        ["address.country"]: "country",
      },
      driverProfileEvents: [],
      loadingDriverProfileEvents: false,
      pdfLoadings: [],
      pdfs: [],
      driverInvoices: [],
      loadingDriverInvoices: false,
      driverTickets: [],
      loadingDriverTickets: false,
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    console.log(`values`);
    console.log(JSON.stringify(values));
    if (values.firstnameSearch) this.setState({ firstnameSearch: values.firstnameSearch });
    if (values.lastnameSearch) this.setState({ lastnameSearch: values.lastnameSearch });
    if (values.citySearch) this.setState({ citySearch: values.citySearch });
    this.setState({ loadingDriver: true });
    let promise = getDriverById(values.driver);
    promise
      .then((response) => {
        this.setState({ selectedDriver: response });
        this.setState({ loadingDriver: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingDriver: false });
      });
    this.setState({ loadingDriver: false });

    this.setState({ loading: true });
    let teamsPromise = getTeams();
    teamsPromise
      .then((response) => {
        this.setState({
          allTeams: response.map((e) => {
            return { label: e.name, value: e.id };
          }),
        });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not load teams.",
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
      });

    this.setState({ loadingDriverProfileEvents: true });
    let driverProfileEventsPromise = getDriverProfileEventsByDriverId(values.driver);
    driverProfileEventsPromise
      .then((response) => {
        this.setState({ driverProfileEvents: response });
        this.setState({ loadingDriverProfileEvents: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver profile events",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingDriverProfileEvents: false });
      });
    this.setState({ loadingDriverProfileEvents: false });

    this.setState({ loadingDriverInvoices: true });
    let driverInvoicesPromise = getDriverInvoicesByDriverId(values.driver);
    driverInvoicesPromise
      .then((driverInvoicesResponse) => {
        this.setState({ driverInvoices: driverInvoicesResponse });
        this.setState({ loadingDriverInvoices: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver invoices. " + error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingDriverInvoices: false });
      });
    this.setState({ loadingDriverInvoices: false });

    this.setState({ loadingDriverTickets: true });
    let driverTicketsPromise = getDriverTicketsByDriverId(values.driver);
    driverTicketsPromise
      .then((driverTicketsResponse) => {
        this.setState({ driverTickets: driverTicketsResponse });
        this.setState({ loadingDriverTickets: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver tickets. " + error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingDriverTickets: false });
      });
    this.setState({ loadingDriverTickets: false });
  }

  loadDriver = (id) => {
    this.setState({ loadingDriver: true });
    let promise = getDriverById(id);
    promise
      .then((response) => {
        this.setState({ selectedDriver: response });

        this.setState({ loadingDriver: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingDriver: false });
      });
    this.setState({ loadingDriverProfileEvents: true });
    let driverProfileEventsPromise = getDriverProfileEventsByDriverId(id);
    driverProfileEventsPromise
      .then((response) => {
        this.setState({ driverProfileEvents: response });
        this.setState({ loadingDriverProfileEvents: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver profile events",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingDriverProfileEvents: false });
      });
  };

  getField = (field) => {
    if (!field.includes(".")) {
      return this.state.selectedDriver[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      return this.state.selectedDriver[firstField][secondField];
    }
  };

  clickEditButton = (field) => {
    let myField = this.getField(field);
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ orgVals: { ...this.state.orgVals, [field]: this.state.selectedDriver[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: myField } });
  };

  clickCancelButton = (field) => {
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: this.state.orgVals[field] } });
  };

  clickSaveButton = async (field, value) => {
    if (field === "teams" && (value == null || value.length === 0)) {
      const args = {
        message: "Error!",
        description: `Please select at least one team`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: true } });
    let editDriverDTO = null;
    // if it does not include subfields just use the value
    if (!field.includes(".")) {
      if (field === "email") {
        editDriverDTO = { [field]: value.toLowerCase() };
      } else if (field === "capacity") {
        editDriverDTO = { [field]: Number(value) };
      } else editDriverDTO = { [field]: value };
    } else {
      // vehicle
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      // copy the driver so we can set vehicle without mutating original driver
      const copy = lodash.cloneDeep(this.state.selectedDriver); //  JSON.parse(JSON.stringify(this.state.selectedDriver))

      editDriverDTO = { [firstField]: copy[firstField] };
      editDriverDTO[firstField][secondField] = value;
    }

    // if sending in firstname or lastname, make sure to send in second part because onfleet only accepts a name field
    if (field === "firstname") editDriverDTO["lastname"] = this.state.selectedDriver.lastname;
    if (field === "lastname") editDriverDTO["firstname"] = this.state.selectedDriver.firstname;

    // always add vid
    editDriverDTO["vid"] = this.state.selectedDriver.vid;
    //alert(JSON.stringify(editDriverDTO))
    console.log(this.state.selectedDriver.id);
    console.log(JSON.stringify(editDriverDTO));
    // this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } })
    // this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: false } })
    // return
    let promise = editDriver(editDriverDTO, this.state.selectedDriver.id);
    promise
      .then((response) => {
        this.setState({ editing: { ...this.state.editing, [field]: false } });
        this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: false } });

        let newDriver = null;
        if (!field.includes(".")) {
          newDriver = {
            ...this.state.selectedDriver,
            [field]: editDriverDTO[field],
          };
        } else {
          let firstField = field.split(".")[0];
          let secondField = field.split(".")[1];
          let nested = this.state.selectedDriver[firstField];
          nested[secondField] = value;
          newDriver = {
            ...this.state.selectedDriver,
            [firstField]: nested,
          };
        }
        this.loadDriver(this.state.selectedDriver.id);
        // this.setState({selectedDriver: newDriver})
        // this.props.updateDriversOnEdit(this.state.selectedDriver.id, newDriver)

        if (response.errors.length > 0) {
          const args = {
            message: "Warning!",
            description: `Driver Edited. But the following steps failed: ${response.errors.toString()}`,
            duration: 0,
            type: "warning",
            placement: "topRight",
          };
          notification.open(args);
        } else {
          const args = {
            message: "Success!",
            description: "Driver Edited.",
            duration: 5,
            type: "success",
            placement: "topRight",
          };
          notification.open(args);
        }
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit driver. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: false } });
      });
  };

  getEditingField = (field) => {
    if (field === "city") {
      return (
        <Select
          showSearch
          name="city"
          value={this.state.editVals[field]}
          options={this.state.cities}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      );
    } else if (field === "vehicle.type" || field === "vehicle.size") {
      let finalOptions = this.state.types;
      if (field === "vehicle.size") {
        finalOptions = this.state.sizeOptions;
      }
      return (
        <Select
          showSearch
          name="city"
          value={this.state.editVals[field]}
          options={finalOptions}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{ width: 150 }}
        />
      );
    } else if (field === "extraThirty") {
      return (
        <Select
          showSearch
          name="extraThirty"
          value={this.state.editVals[field]}
          style={{ width: 150 }}
          options={this.state.extraThirtyOptions}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      );
    } else if (field === "teams" || field === "schedulers") {
      let finalOptions = this.state.allTeams;
      if (field === "schedulers") {
        finalOptions = this.state.schedulersList;
      }
      return (
        <Select
          mode="multiple"
          showSearch
          name={field}
          placeholder={field}
          style={{ width: 150 }}
          options={finalOptions}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          value={this.state.editVals[field]}
        />
      );
    } else {
      return (
        <Input
          type={field === "capacity" ? "number" : "text"}
          style={{ whiteSpace: "normal" }}
          ellipsis={{ rows: 3, expandable: true }}
          value={this.state.editVals[field]}
          onChange={(e) => this.onEditChange(e, field)}
        />
      );
    }
  };

  generateEditableField = (field) => {
    let myField = "";
    if (!field.includes(".")) {
      myField = this.state.selectedDriver[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      myField = this.state.selectedDriver[firstField][secondField];
    }
    if (field === "teams") {
      if (myField) {
        myField = myField.map((x) => {
          return this.state.allTeams && this.state.allTeams.length > 0 && this.state.allTeams.find((t) => t.value === x)
            ? this.state.allTeams.find((t) => t.value === x).label
            : x;
        });
        myField = myField.join(", ");
      }
      // myField = myField? myField.join(', ') : myField
    }
    if (field === "schedulers") {
      myField = myField ? myField.join(", ") : myField;
    }
    if (field === "extraThirty") {
      myField = myField ? myField.toString() : "N\\A";
    }
    return (
      <>
        <Col span={8}>
          <Spin spinning={this.state.isEditLoading[field]} />
          {this.state.editing[field] ? (
            this.getEditingField(field)
          ) : (
            <Paragraph style={{ whiteSpace: "normal" }} ellipsis={{ rows: 3, expandable: true }}>
              {myField}
            </Paragraph>
          )}
        </Col>
        <Col span={10}>
          {this.state.editing[field] ? (
            <>
              <Button
                style={{ marginLeft: 5 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                icon={<CheckOutlined />}
                loading={this.state.isEditLoading[field]}
              />
              <Button
                size={"small"}
                onClick={(e) => this.clickCancelButton(field)}
                icon={<CloseOutlined />}
                loading={this.state.isEditLoading[field]}
              />
            </>
          ) : (
            <>
              {Object.entries(this.state.editing).every((x) => x[1] === false) && (
                <Button
                  style={{ marginLeft: 5 }}
                  size={"small"}
                  onClick={(e) => this.clickEditButton(field)}
                  icon={<EditOutlined />}
                />
              )}
            </>
          )}
        </Col>
      </>
    );
  };

  onEditChange = (e, field) => {
    if (
      field !== "vehicle.size" &&
      field !== "schedulers" &&
      field !== "teams" &&
      field !== "city" &&
      field !== "vehicle.type" &&
      field !== "extraThirty"
    ) {
      // determine the type of the field if it hasn't been defined, set it to string
      const type = typeof this.state.editType[field] === "undefined" ? "string" : this.state.editType[field];
      // clean it if it is a phone number
      const value = type === "phone" ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
      this.setState({ editVals: { ...this.state.editVals, [field]: value } });
    } else {
      this.setState({ editVals: { ...this.state.editVals, [field]: e } });
    }
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    let newPath = `/drivers/profiles`;
    if (this.state.firstnameSearch != null)
      newPath = updateQueryStringParameter(newPath, "firstnameSearch", this.state.firstnameSearch);
    if (this.state.lastnameSearch != null)
      newPath = updateQueryStringParameter(newPath, "lastnameSearch", this.state.lastnameSearch);
    if (this.state.citySearch != null)
      newPath = updateQueryStringParameter(newPath, "citySearch", this.state.citySearch);
    console.log(this.state.firstnameSearch);
    console.log(this.state.lastnameSearch);
    console.log(newPath);
    link.href = newPath;
    document.body.appendChild(link);
    link.click();
  };

  generateDriverProfileEventsTable = () => {
    const generateValue = (obj) => {
      let result = Object.entries(obj).map((entry) => {
        const [key, value] = entry;
        if (value == null) {
          return "";
        } else if (Array.isArray(value)) {
          return (
            <Row>
              <Col>
                {key}: {value.join(",")}
              </Col>
            </Row>
          );
        } else if (typeof value === "object") {
          return Object.entries(value).map((prop) => {
            const [vehicleKey, vehicleValue] = prop;
            return (
              <Row>
                <Col>
                  {key} {vehicleKey}: {vehicleValue}
                </Col>
              </Row>
            );
          });
        } else {
          return (
            <Row>
              <Col>
                {key}: {value}
              </Col>
            </Row>
          );
        }
      });

      return <>{result}</>; // result
    };
    const driverProfileEventsColumns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (text, record) => (record.type != null ? record.type : ""),
        filters: this.state.driverProfileEvents
          ? [...new Set(this.state.driverProfileEvents.map((d) => d.type))].map((x) => {
              return { text: x, value: x };
            })
          : [],
        onFilter: (value, record) => record.type && record.type.indexOf(value) === 0,
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        // width: '25%',
        render: (text, record) => (record.message != null ? record.message : ""),
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (text, record) => (record.newValue != null ? generateValue(record.newValue) : ""),
      },
      {
        title: "Previous Value",
        dataIndex: "previousValue",
        key: "previousValue",
        render: (text, record) => (record.previousValue != null ? generateValue(record.previousValue) : ""),
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (text, record) => (record.user != null ? record.user : ""),
        filters: this.state.driverProfileEvents
          ? [...new Set(this.state.driverProfileEvents.map((d) => d.user))].map((x) => {
              return { text: x, value: x };
            })
          : [],
        onFilter: (value, record) => record.user && record.user.indexOf(value) === 0,
      },
      {
        title: "Time",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (text, record) => {
          if (record.timestamp != null) {
            let myTimestamp = record.timestamp;
            if (myTimestamp.toString().length === 10) myTimestamp = myTimestamp * 1000;
            return (
              <>
                {new Date(myTimestamp).toDateString()} {new Date(myTimestamp).toLocaleTimeString()}
              </>
            );
          } else {
            return "";
          }
        },
      },
    ];
    if (this.state.loadingDriverProfileEvents) {
      return <Spin />;
    } else if (this.state.driverProfileEvents == null || this.state.driverProfileEvents.length === 0) {
      return "";
    } else {
      return (
        <Row gutter={16} style={{ marginTop: 5 }}>
          <Col span={24}>
            {/* <Card title="Logs"> */}
            <Table
              columns={driverProfileEventsColumns}
              dataSource={this.state.driverProfileEvents.sort((a, b) => b.timestamp - a.timestamp)}
              size={"small"}
              pagination={false}
              scroll={{ y: 375, x: false }}
            />
            {/* </Card> */}
          </Col>
        </Row>
      );
    }
  };

  generateDriverInvoicesTable = () => {
    const driverInvoiceColumns = [
      // {
      //     title: "Report Id",
      //     dataIndex: "reportId",
      //     key: "reportId",
      //     render: (text, record) => record.reportId != null? record.reportId : ''
      // },
      // {
      //   title: "Invoice Key",
      //   dataIndex: "invoiceKey",
      //   key: "invoiceKey",
      //   render: (text, record) => record?.driverReport?.invoiceKey != null? record.driverReport.invoiceKey : ''
      // },
      {
        title: "Period Start",
        dataIndex: "periodDateStart",
        key: "periodDateStart",
        render: (text, record) => (record.periodDateStart != null ? record.periodDateStart : ""),
      },
      {
        title: "Period End",
        dataIndex: "periodDateEnd",
        key: "periodDateEnd",
        render: (text, record) => (record.periodDateEnd != null ? record.periodDateEnd : ""),
      },
      {
        title: "Paid At",
        dataIndex: "paidAt",
        key: "paidAt",
        render: (text, record) =>
          record.paidAt != null ? (
            <>
              {new Date(record.paidAt).toDateString()} {new Date(record.paidAt).toLocaleTimeString()}
            </>
          ) : (
            ""
          ),
      },
      {
        title: "Total Pay",
        dataIndex: "totalPay",
        key: "totalPay",
        render: (text, record) =>
          record?.driverReport?.driverPay?.totalPay != null ? record.driverReport.driverPay.totalPay : "",
      },
      {
        title: "View PDF",
        dataIndex: "totalPay",
        key: "totalPay",
        render: (text, record, index) => {
          // if (record.pdf) {
          //   // alert('here')
          //   return (
          //     <Document file={record.pdf}/>
          //   )
          // }
          // else
          return record?.driverReport?.invoiceKey != null ? (
            <Button
              type="primary"
              loading={this.state.pdfLoadings[index]}
              onClick={() => this.getPDF(record.driverReport.invoiceKey, index)}
            >
              View PDF
            </Button>
          ) : (
            ""
          );
        },
      },
    ];
    if (this.state.loadingDriverInvoices) {
      return <Spin />;
    } else if (this.state.driverInvoices == null || this.state.driverInvoices.length === 0) {
      return "";
    } else {
      return (
        <Row gutter={16} style={{ marginTop: 5 }}>
          <Col span={24}>
            {/* <Card title="Invoices"> */}
            <Table
              columns={driverInvoiceColumns}
              dataSource={this.state.driverInvoices.sort((a, b) => b.paidAt - a.paidAt)}
              size={"small"}
              pagination={false}
              scroll={{ y: 375, x: false }}
            />
            {/* </Card> */}
          </Col>
        </Row>
      );
    }
  };

  getPDF = (invoiceKey, index) => {
    const newpdfLoadings = [...this.state.pdfLoadings];
    newpdfLoadings[index] = true;
    this.setState({ pdfLoadings: newpdfLoadings });

    let pdfResponse = getDriverInvoicePDFByInvoiceKey(invoiceKey);
    pdfResponse
      .then((pdfResponse) => {
        // alert(pdfResponse.data)
        this.setState({ currentPdfInvoiceKey: invoiceKey });
        this.setState({ currentPdf: "data:application/pdf;base64," + pdfResponse.data });
        this.setState({ showModal: true });
        // const newRecords = [...this.state.driverInvoices]
        // let myIndex = this.state.driverInvoices.findIndex(x => x.driverReport.invoiceKey === invoiceKey)
        // if (myIndex >= 0) {
        //   newRecords[myIndex] = {
        //     ...newRecords[myIndex],
        //     pdf: "data:image/png;base64, " + pdfResponse
        //   }
        // }
        // // newRecords[index] = {pdf: pdfResponse, invoiceKey: invoiceKey}
        // console.log(JSON.stringify(newRecords))
        // this.setState({driverInvoices: newRecords})

        const newpdfLoadings = [...this.state.pdfLoadings];
        newpdfLoadings[index] = false;
        this.setState({ pdfLoadings: newpdfLoadings });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get driver invoice pdf. " + error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        const newpdfLoadings = [...this.state.pdfLoadings];
        newpdfLoadings[index] = false;
        this.setState({ pdfLoadings: newpdfLoadings });
      });
  };

  generateDriverTicketsTable = () => {
    const driverTicketsColumns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (record.id != null ? record.id : ""),
      },
      {
        title: "Ticket Type",
        dataIndex: "ticketType",
        key: "ticketType",
        render: (text, record) => (record.ticketType != null ? record.ticketType : ""),
      },
      {
        title: "Action At",
        dataIndex: "actionAt",
        key: "actionAt",
        render: (text, record) => (record.actionAt != null ? record.actionAt : ""),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        render: (text, record) => (record.comments != null ? record.comments : ""),
      },
      {
        title: "CreatedAt",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) =>
          record.createdAt != null ? (
            <>
              {new Date(record.createdAt).toDateString()} {new Date(record.createdAt).toLocaleTimeString()}
            </>
          ) : (
            ""
          ),
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        render: (text, record) => (record.createdBy != null ? record.createdBy : ""),
      },
      {
        title: "Points",
        dataIndex: "points",
        key: "points",
        render: (text, record) => (record.points != null ? record.points : ""),
      },
    ];

    const getSummary = (total) => {
      return (
        <Table.Summary.Row>
          {driverTicketsColumns.map((x, i) => {
            if (i === 0) {
              return (
                <Table.Summary.Cell>
                  <Text type="danger">total</Text>
                </Table.Summary.Cell>
              );
            }
            if (i === driverTicketsColumns.length - 1) {
              return (
                <Table.Summary.Cell>
                  <Text type="danger">{total}</Text>
                </Table.Summary.Cell>
              );
            } else return <Table.Summary.Cell></Table.Summary.Cell>;
          })}
        </Table.Summary.Row>
      );
    };

    if (this.state.loadingDriverDriverTickets) {
      return <Spin />;
    } else if (this.state.driverTickets == null || this.state.driverTickets.length === 0) {
      return "";
    } else {
      return (
        <Row gutter={16} style={{ marginTop: 5 }}>
          <Col span={24}>
            <Table
              columns={driverTicketsColumns}
              dataSource={this.state.driverTickets.sort((a, b) => b.createdAt - a.createdAt)}
              size={"small"}
              pagination={false}
              scroll={{ y: 375, x: false }}
              summary={() => getSummary(this.state.driverTickets.map((x) => x.points).reduce((a, b) => a + b, 0))}
            />
          </Col>
        </Row>
      );
    }
  };

  render() {
    console.log(this.state.currentPdf);
    return (
      <>
        {this.state.selectedDriver != null && !this.state.loadingDriver ? (
          <div className={"singleDriverProfile-container"}>
            <h1>
              <Button type="primary" onClick={this.clickBackButton}>
                <ArrowLeftOutlined />
              </Button>
            </h1>
            <Row gutter={16}>
              <Col span={24}>
                <Card title={`${this.state.selectedDriver.firstname} ${this.state.selectedDriver.lastname}`}>
                  <Tabs>
                    <TabPane tab="Info" key="1">
                      <Row gutter={16}>
                        <Col span={this.state.selectedDriver.vehicle || this.state.selectedDriver.address ? 12 : 16}>
                          <Row gutter={16}>
                            <Col span={24}>
                              <strong>Basic Information</strong>
                            </Col>
                            {/* <Col span={24}>
                                &nbsp;
                              </Col> */}
                            <Col span={6}>
                              <span className={"info-content-key"}>Id:</span>
                            </Col>
                            <Col span={18}>
                              <span>{this.state.selectedDriver.id}</span>
                            </Col>

                            <Col span={6}>
                              <span className={"info-content-key"}>VID:</span>
                            </Col>
                            <Col span={18}>
                              <span>{this.state.selectedDriver.vid}</span>
                            </Col>

                            <Col span={6}>
                              <span className={"info-content-key"}>First Name:</span>
                            </Col>
                            {this.generateEditableField("firstname")}

                            <Col span={6}>
                              <span className={"info-content-key"}>Last Name:</span>
                            </Col>
                            {this.generateEditableField("lastname")}

                            <Col span={6}>
                              <span className={"info-content-key"}>Phone:</span>
                            </Col>
                            <Col span={18}>
                              <span>{this.state.selectedDriver.phone}</span>
                            </Col>

                            <Col span={6}>
                              <span className={"info-content-key"}>City:</span>
                            </Col>
                            {this.generateEditableField("city")}

                            <Col span={6}>
                              <span className={"info-content-key"}>Email:</span>
                            </Col>
                            {this.generateEditableField("email")}

                            <Col span={6}>
                              <span className={"info-content-key"}>Teams:</span>
                            </Col>
                            {this.generateEditableField("teams")}

                            <Col span={6}>
                              <span className={"info-content-key"}>Schedulers:</span>
                            </Col>
                            {this.generateEditableField("schedulers")}
                            {/* <Col span={8}>
                                  <span className={"info-content-key"}>Teams:</span>
                              </Col>
                              <Col span={16}>
                                  <span>{driver.teams.join(', ')}</span>
                              </Col> */}

                            <Col span={6}>
                              <span className={"info-content-key"}>Display Name:</span>
                            </Col>
                            {this.generateEditableField("displayName")}

                            <Col span={6}>
                              <span className={"info-content-key"}>Created At:</span>
                            </Col>
                            <Col span={18}>
                              <span>
                                {new Date(this.state.selectedDriver.createdAt).toDateString()}{" "}
                                {new Date(this.state.selectedDriver.createdAt).toLocaleTimeString()}
                              </span>
                            </Col>
                            {/* {this.state.selectedDriver.extraThirty != null? 
                                <>
                                  <Col span={8}>
                                    <span className={"info-content-key"}>extraThirty:</span>
                                  </Col>
                                  {this.generateEditableField('extraThirty')}
                                </>
                              :''
                            } */}
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row gutter={16}>
                            {this.state.selectedDriver.vehicle && (
                              <>
                                <Col span={24}>
                                  <strong>Vehicle</strong>
                                </Col>
                                {/* <Col span={24}>
                                      &nbsp;
                                    </Col> */}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Type:</span>
                                </Col>
                                {this.generateEditableField("vehicle.type")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Description:</span>
                                </Col>
                                {this.generateEditableField("vehicle.description")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>License Plate:</span>
                                </Col>
                                {this.generateEditableField("vehicle.licensePlate")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Colour:</span>
                                </Col>
                                {this.generateEditableField("vehicle.color")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Capacity:</span>
                                </Col>
                                {this.generateEditableField("capacity")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Size:</span>
                                </Col>
                                {this.generateEditableField("vehicle.size")}
                              </>
                            )}
                          </Row>
                          <Row gutter={16}>
                            {this.state.selectedDriver.address && (
                              <>
                                <Col span={24}>
                                  <strong>Address</strong>
                                </Col>
                                <Col span={6}>
                                  <span className={"info-content-key"}>Street:</span>
                                </Col>
                                {this.generateEditableField("address.street")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Unit:</span>
                                </Col>
                                {this.generateEditableField("address.unit")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Postal Code:</span>
                                </Col>
                                {this.generateEditableField("address.postalCode")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>City:</span>
                                </Col>
                                {this.generateEditableField("address.city")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Province:</span>
                                </Col>
                                {this.generateEditableField("address.province")}
                                <Col span={6}>
                                  <span className={"info-content-key"}>Country:</span>
                                </Col>
                                {this.generateEditableField("address.country")}
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    {this.state.driverProfileEvents && this.state.driverProfileEvents.length > 0 && (
                      <TabPane tab="Driver Profile Events" key="2">
                        {this.generateDriverProfileEventsTable()}
                      </TabPane>
                    )}
                    {this.state.driverInvoices && this.state.driverInvoices.length > 0 && (
                      <TabPane tab="Driver Invoices" key="3">
                        {this.generateDriverInvoicesTable()}
                      </TabPane>
                    )}
                    {this.state.driverTickets && this.state.driverTickets.length > 0 && (
                      <TabPane tab="Driver Tickets" key="4">
                        {this.generateDriverTicketsTable()}
                      </TabPane>
                    )}
                  </Tabs>
                </Card>
              </Col>
            </Row>
            {/* {this.generateDriverProfileEventsTable()}
              {this.generateDriverInvoicesTable()} */}
            <Modal
              visible={this.state.showModal}
              title={this.state.currentPdfInvoiceKey}
              onCancel={() => {
                this.setState({ showModal: false });
              }}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              closable={true}
              width={700}
            >
              {this.state.currentPdf && (
                <Document file={this.state.currentPdf}>
                  <Page pageNumber={1} />
                </Document>
              )}
            </Modal>
          </div>
        ) : (
          <Spin spinning={true} />
        )}
      </>
    );
  }
}

SingleDriverProfile.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SingleDriverProfile);
