import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Select, Spin, Tabs, Typography, notification } from "antd";
import lodash from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import {
  editDriverApplicant,
  getDriverApplicantByEmail,
  getDriverApplicantDriversLicense,
  getDriverApplicantVehiclePhoto,
} from "../util/APIUtils";
import { updateQueryStringParameter } from "../util/Helpers";
import DriverApplicantInfo from "./DriverApplicantInfo";
import "./SingleDriverApplicantProfile.css";
const { Paragraph } = Typography;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;

const setPathProperty = (object, path, value) =>
  path.split(".").reduce((o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}), object);

export class SingleDriverApplicantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstnameSearch: null,
      lastnameSearch: null,
      citySearch: null,
      loadingDriverApplicant: false,
      loading: false,
      drivers: [],
      deleteLoadings: [],
      searchText: "",
      searchedColumn: "",
      driverApplicant: null,
      allTeams: [],
      sizeOptions: [
        { value: "XS", label: "XS" },
        { value: "S", label: "S" },
        { value: "M", label: "M" },
        { value: "L", label: "L" },
        { value: "XL", label: "XL" },
      ],
      schedulersList: [
        { label: "Montreal", value: "montreal" },
        { label: "QA", value: "qa" },
        { label: "Toronto", value: "toronto" },
        { label: "Quebec City", value: "quebecCity" },
        { label: "Vancouver", value: "vancouver" },
        { label: "Montreal Vans", value: "montrealVans" },
        { label: "Toronto Vans", value: "torontoVans" },
      ],
      // cities: [{value: 'mtl', label: 'mtl'}, {value: 'to', label: 'to'}],
      cities: [
        { value: "GMA", label: "GMA" },
        { value: "GTA", label: "GTA" },
        { value: "QCC", label: "QCC" },
        { value: "GOA", label: "GOA" },
        { value: "GVA", label: "GVA" },
      ],
      types: [
        { value: "CAR", label: "CAR" },
        { value: "MOTORCYCLE", label: "MOTORCYCLE" },
        { value: "BICYCLE", label: "BICYCLE" },
        { value: "TRUCK", label: "TRUCK" },
      ],
      extraThirtyOptions: [
        { value: true, label: "true" },
        { value: false, label: "false" },
      ],
      isEditLoading: {
        firstname: false,
        lastname: false,
        phone: false,
        email: false,
        city: false,
        teams: false,
        displayName: false,
        capacity: false,
        ["vehicle.type"]: false,
        ["vehicle.make"]: false,
        ["vehicle.model"]: false,
        ["vehicle.licensePlate"]: false,
        ["vehicle.color"]: false,
        ["vehicle.size"]: false,
        extraThirty: false,
        schedulers: false,

        ["address.street"]: false,
        ["address.unit"]: false,
        ["address.postalCode"]: false,
        ["address.city"]: false,
        ["address.province"]: false,
        ["address.country"]: false,
      },
      orgVals: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        city: "",
        teams: [],
        displayName: "",
        capacity: "",
        ["vehicle.type"]: "",
        ["vehicle.make"]: "",
        ["vehicle.model"]: "",
        ["vehicle.licensePlate"]: "",
        ["vehicle.color"]: "",
        ["vehicle.size"]: "",
        extraThirty: "",
        schedulers: [],

        ["address.street"]: "",
        ["address.unit"]: "",
        ["address.postalCode"]: "",
        ["address.city"]: "",
        ["address.province"]: "",
        ["address.country"]: "",
      },
      editVals: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        city: "",
        teams: [],
        displayName: "",
        capacity: "",
        ["vehicle.type"]: "",
        ["vehicle.make"]: "",
        ["vehicle.model"]: "",
        ["vehicle.licensePlate"]: "",
        ["vehicle.color"]: "",
        ["vehicle.size"]: "",
        extraThirty: "",
        schedulers: [],

        ["address.street"]: "",
        ["address.unit"]: "",
        ["address.postalCode"]: "",
        ["address.city"]: "",
        ["address.province"]: "",
        ["address.country"]: "",
      },
      editing: {
        firstname: false,
        lastname: false,
        phone: false,
        email: false,
        city: false,
        teams: false,
        displayName: false,
        capacity: false,
        ["vehicle.type"]: false,
        ["vehicle.make"]: false,
        ["vehicle.model"]: false,
        ["vehicle.licensePlate"]: false,
        ["vehicle.color"]: false,
        ["vehicle.size"]: false,
        extraThirty: false,
        schedulers: false,

        ["address.street"]: false,
        ["address.unit"]: false,
        ["address.postalCode"]: false,
        ["address.city"]: false,
        ["address.province"]: false,
        ["address.country"]: false,
      },
      editType: {
        ["vehicle.type"]: "type",
        ["vehicle.make"]: "make",
        ["vehicle.model"]: "model",
        ["vehicle.licensePlate"]: "licensePlate",
        ["vehicle.color"]: "color",
        ["vehicle.size"]: "size",

        ["address.street"]: "street",
        ["address.unit"]: "unit",
        ["address.postalCode"]: "postalCode",
        ["address.city"]: "city",
        ["address.province"]: "province",
        ["address.country"]: "country",
      },
      driverProfileEvents: [],
      loadingDriverProfileEvents: false,
      pdfLoadings: [],
      pdfs: [],
      driverInvoices: [],
      loadingDriverInvoices: false,
      driverTickets: [],
      loadingDriverTickets: false,
      vehiclePhoto: null,
      driversLicenseFront: null,
      driversLicenseBack: null,
    };
  }

  componentDidMount = async () => {
    const values = queryString.parse(this.props.location.search);
    console.log(`values`);
    console.log(JSON.stringify(values));
    if (values.firstnameSearch) this.setState({ firstnameSearch: values.firstnameSearch });
    if (values.lastnameSearch) this.setState({ lastnameSearch: values.lastnameSearch });
    if (values.citySearch) this.setState({ citySearch: values.citySearch });
    this.setState({ loadingDriverApplicant: true });
    this.loadDriverApplicant(values.driverApplicant);
  };

  loadDriverApplicant = async (email) => {
    this.setState({ loadingDriver: true });
    try {
      let response = await getDriverApplicantByEmail(email);
      this.setState({ driverApplicant: response });

      // vehicle
      if (response.vehiclePhotoKey) {
        try {
          let vehiclePhotoResp = await getDriverApplicantVehiclePhoto(response.vehiclePhotoKey);
          console.log("vehicle photo");
          console.log(vehiclePhotoResp);
          // alert(vehiclePhotoResp)
          let image = `data:${vehiclePhotoResp.type};base64,${vehiclePhotoResp.contents}`;
          this.setState({ vehiclePhoto: image });
        } catch (error) {
          const args = {
            message: "Error!",
            description: "Could not get driver applicant's vehicle photo",
            duration: 3,
            type: "error",
            placement: "topRight",
          };
          notification.open(args);
        }
      }

      // driversLicense front
      if (response.driversLicenseFrontKey) {
        try {
          let frontResp = await getDriverApplicantDriversLicense(response.driversLicenseFrontKey);
          let image = `data:${frontResp.type};base64,${frontResp.contents}`;
          this.setState({ driversLicenseFront: image });
        } catch (error) {
          const args = {
            message: "Error!",
            description: "Could not get driver applicant's drivers license front",
            duration: 3,
            type: "error",
            placement: "topRight",
          };
          notification.open(args);
        }
      }

      // driversLicense back
      if (response.driversLicenseBackKey) {
        try {
          let backResp = await getDriverApplicantDriversLicense(response.driversLicenseBackKey);
          let image = `data:${backResp.type};base64,${backResp.contents}`;
          this.setState({ driversLicenseBack: image });
        } catch (error) {
          const args = {
            message: "Error!",
            description: "Could not get driver applicant's drivers license front",
            duration: 3,
            type: "error",
            placement: "topRight",
          };
          notification.open(args);
        }
      }

      this.setState({ loadingDriverApplicant: false });
      console.log("here");
      console.log(response);
    } catch (error) {
      const args = {
        message: "Error!",
        description: "Could not get driver applicant",
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      this.setState({ loadingDriverApplicant: false });
    }

    // let promise = awaitgetDriverApplicantByEmail(email);
    // promise
    //   .then(response => {
    //     this.setState({driverApplicant: response})

    //     this.setState({loadingDriverApplicant: false})
    //   })
    //   .catch(error => {
    //     const args = {
    //       message: 'Error!',
    //       description: 'Could not get driver applicant',
    //       duration: 3,
    //       type: 'error',
    //       placement: 'topRight'
    //     };
    //     notification.open(args);
    //     this.setState({loadingDriverApplicant: false})
    //   });
  };

  getField = (field) => {
    if (!field.includes(".")) {
      return this.state.driverApplicant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      return this.state.driverApplicant[firstField][secondField];
    }
  };

  clickEditButton = (field) => {
    let myField = this.getField(field);
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ orgVals: { ...this.state.orgVals, [field]: this.state.driverApplicant[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: myField } });
  };

  clickCancelButton = (field) => {
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: this.state.orgVals[field] } });
  };

  clickSaveButton = async (field, value) => {
    let newDriverApplicant = lodash.cloneDeep(this.state.driverApplicant);
    // if it does not include subfields just use the value
    if (!field.includes(".")) {
      if (field === "email") {
        newDriverApplicant[field] = value.toLowerCase().replace(/ /g, "");
      } else newDriverApplicant[field] = value;
    } else {
      // vehicle or address
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];

      newDriverApplicant[firstField][secondField] = value;
    }

    console.log(JSON.stringify(newDriverApplicant));
    return;
    this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: true } });
    let promise = editDriverApplicant(newDriverApplicant, this.state.driverApplicant.email);
    promise
      .then((response) => {
        this.setState({ editing: { ...this.state.editing, [field]: false } });
        this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: false } });
        this.loadDriverApplicant(this.state.driverApplicant.email);

        if (response.errors.length > 0) {
          const args = {
            message: "Warning!",
            description: `Driver Applicant Edited. But the following steps failed: ${response.errors.toString()}`,
            duration: 0,
            type: "warning",
            placement: "topRight",
          };
          notification.open(args);
        } else {
          const args = {
            message: "Success!",
            description: "Driver Applicant Edited.",
            duration: 5,
            type: "success",
            placement: "topRight",
          };
          notification.open(args);
        }
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit driver applicant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ isEditLoading: { ...this.state.isEditLoading, [field]: false } });
      });
  };

  getEditingField = (field) => {
    if (field === "city") {
      return (
        <Select
          showSearch
          name="city"
          value={this.state.editVals[field]}
          loading={this.state.isEditLoading[field]}
          options={this.state.cities}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      );
    } else if (field === "vehicle.type" || field === "vehicle.size") {
      let finalOptions = this.state.types;
      if (field === "vehicle.size") {
        finalOptions = this.state.sizeOptions;
      }
      return (
        <Select
          showSearch
          name="city"
          value={this.state.editVals[field]}
          loading={this.state.isEditLoading[field]}
          options={finalOptions}
          onChange={(e) => this.onEditChange(e, field)}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{ width: 150 }}
        />
      );
    } else if (field === "phone") {
      return (
        <PhoneInput
          name=""
          inputComponent={Input}
          country={"us"}
          countryCodeEditable={false}
          onlyCountries={["us"]}
          size={"large"}
          type={"tel"}
          inputClass="ant-input ant-input-md"
          specialLabel=""
          disabled={this.state.isEditLoading[field]}
          value={this.state.editVals[field]}
        />
      );
    } else {
      return (
        <Input
          type={field === "email" ? "email" : "text"}
          style={{ whiteSpace: "normal" }}
          ellipsis={{ rows: 3, expandable: true }}
          value={this.state.editVals[field]}
          onChange={(e) => this.onEditChange(e, field)}
          loading={this.state.isEditLoading[field]}
        />
      );
    }
  };

  generateEditableField = (field) => {
    let myField = "";
    if (!field.includes(".")) {
      myField = this.state.driverApplicant[field];
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      myField = this.state.driverApplicant[firstField][secondField];
    }
    return (
      <>
        <Col span={8}>
          <Spin spinning={this.state.isEditLoading[field]} />
          {this.state.editing[field] ? (
            this.getEditingField(field)
          ) : (
            <Paragraph style={{ whiteSpace: "normal" }} ellipsis={{ rows: 3, expandable: true }}>
              {myField}
            </Paragraph>
          )}
        </Col>
        <Col span={10}>
          {this.state.editing[field] ? (
            <>
              <Button
                style={{ marginLeft: 5 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                icon={<CheckOutlined />}
                loading={this.state.isEditLoading[field]}
              />
              <Button
                size={"small"}
                onClick={(e) => this.clickCancelButton(field)}
                icon={<CloseOutlined />}
                loading={this.state.isEditLoading[field]}
              />
            </>
          ) : (
            <>
              {Object.entries(this.state.editing).every((x) => x[1] === false) && (
                <Button
                  style={{ marginLeft: 5 }}
                  size={"small"}
                  onClick={(e) => this.clickEditButton(field)}
                  icon={<EditOutlined />}
                />
              )}
            </>
          )}
        </Col>
      </>
    );
  };

  onEditChange = (e, field) => {
    if (field !== "vehicle.size" && field !== "city" && field !== "vehicle.type") {
      // determine the type of the field if it hasn't been defined, set it to string
      const type = typeof this.state.editType[field] === "undefined" ? "string" : this.state.editType[field];
      // clean it if it is a phone number
      // const value = type === 'phone' ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
      this.setState({ editVals: { ...this.state.editVals, [field]: e.target.value } });
    } else {
      this.setState({ editVals: { ...this.state.editVals, [field]: e } });
    }
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    let newPath = `/driverApplicants`;
    if (this.state.firstnameSearch != null)
      newPath = updateQueryStringParameter(newPath, "firstnameSearch", this.state.firstnameSearch);
    if (this.state.lastnameSearch != null)
      newPath = updateQueryStringParameter(newPath, "lastnameSearch", this.state.lastnameSearch);
    if (this.state.citySearch != null)
      newPath = updateQueryStringParameter(newPath, "citySearch", this.state.citySearch);
    console.log(this.state.firstnameSearch);
    console.log(this.state.lastnameSearch);
    console.log(newPath);
    link.href = newPath;
    document.body.appendChild(link);
    link.click();
  };

  render() {
    console.log(this.state.currentPdf);
    return (
      <>
        <Spin spinning={this.state.loadingDriverApplicant}></Spin>
        {this.state.driverApplicant != null && (
          <div className={"singleDriverProfile-container"}>
            <h1>
              <Button type="primary" onClick={this.clickBackButton}>
                <ArrowLeftOutlined />
              </Button>
            </h1>
            <Row gutter={16}>
              <Col span={24}>
                <Card title={`${this.state.driverApplicant.firstname} ${this.state.driverApplicant.lastname}`}>
                  <DriverApplicantInfo
                    driverApplicant={this.state.driverApplicant}
                    vehiclePhoto={this.state.vehiclePhoto}
                    driversLicenseFront={this.state.driversLicenseFront}
                    driversLicenseBack={this.state.driversLicenseBack}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

SingleDriverApplicantProfile.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SingleDriverApplicantProfile);
