import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
  notification,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import queryString from "query-string";
import React, { Component } from "react";
import ReactDataSheet from "react-datasheet";
import { connect } from "react-redux";
import { ratesDescriptionList } from "../RatesDescription";
import { deleteShippingRule, editShippingRule, getAllShippingRules, getMerchantById } from "../util/APIUtils";
import { isValidateWeightBandsBody } from "../util/shippingRules";
import "./ViewShippingRules.css";
const { Paragraph } = Typography;

const WindowCard = ({
  addRows,
  title,
  dataSource,
  sheetRenderer,
  rowRenderer,
  cellRenderer,
  onCellsChanged,
  onChangeNumRowsToAdd,
  numRowsToAdd,
}) => {
  return (
    <Card bordered={false} title={title}>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <InputNumber
              min={0}
              onChange={onChangeNumRowsToAdd}
              value={numRowsToAdd}
              style={{ width: 80, marginRight: 4 }}
            />
            <Button onClick={addRows}>Add rows</Button>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <ReactDataSheet
            data={dataSource}
            className="custom-sheet"
            sheetRenderer={sheetRenderer}
            rowRenderer={rowRenderer}
            cellRenderer={cellRenderer}
            onCellsChanged={onCellsChanged}
            valueRenderer={(cell) => cell.value}
          />
        </Col>
      </Row>
    </Card>
  );
};

const SheetRenderer = (props) => {
  const {
    as: Tag,
    headerAs: Header,
    bodyAs: Body,
    rowAs: Row,
    cellAs: Cell,
    className,
    columns,
    selections,
    onSelectAllChanged,
  } = props;
  return (
    <Tag className={className}>
      <Header className="data-header">
        <Row>
          {columns.map((column) => (
            <Cell className="cell" style={{ width: column.width, textAlign: column.textAlign }} key={column.label}>
              {column.label}
            </Cell>
          ))}
        </Row>
      </Header>
      <Body className="data-body">{props.children}</Body>
    </Tag>
  );
};

const RowRenderer = (props) => {
  const { as: Tag, cellAs: Cell, className, row, selected, onSelectChanged } = props;
  return <Tag className={className}>{props.children}</Tag>;
};

const CellRenderer = (props) => {
  const { as: Tag, cell, row, col, columns, attributesRenderer, selected, editing, updated, style, ...rest } = props;

  // hey, how about some custom attributes on our cell?
  const attributes = cell.attributes || {};
  // ignore default style handed to us by the component and roll our own
  attributes.style = { width: columns[col].width, textAlign: columns[col].textAlign, height: 26 };
  if (col === 0) {
    attributes.title = cell.label;
  }

  return (
    <Tag {...rest} {...attributes}>
      {props.children}
    </Tag>
  );
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const dayOfWeeks = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export class ViewShippingRules extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleCellsChanged = this.handleCellsChanged.bind(this);
    this.sheetRenderer = this.sheetRenderer.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);
    this.pickupSheetRenderer = this.pickupSheetRenderer.bind(this);
    this.pickupCellRenderer = this.pickupCellRenderer.bind(this);
    this.weightPricingSheetRenderer = this.weightPricingSheetRenderer.bind(this);
    this.weightPricingCellRenderer = this.weightPricingCellRenderer.bind(this);
    this.handleAddRows = this.handleAddRows.bind(this);
    this.handleNumRowsToAdd = this.handleNumRowsToAdd.bind(this);
    this.state = {
      loading: false,
      merchant: null,
      deleteLoadings: [],
      showModal: [],
      loadingMerchant: false,
      removeSpeedPricingLoadings: [],
      showAddServiceModal: false,
      speedPricing: {},
      speedPricingTypes: [
        { label: "NEXTDAY", value: "NEXTDAY" },
        { label: "SAMEDAY", value: "SAMEDAY" },
        { label: "SCHEDULED", value: "SCHEDULED" },
        { label: "CUSTOMER_BOOKING", value: "CUSTOMER_BOOKING" },
        { label: "FLEX", value: "FLEX" },
      ],
      dispatchers: [
        { label: "Onfleet", value: "ONFLEET" },
        { label: "GoBolt", value: "GOBOLT" },
      ],
      addServiceLoading: false,
      currentCell: null,

      isEditLoading: [],
      isEditLoading: [],
      orgVals: [],
      editVals: [],
      editing: [],

      deliveryWindowColumns: [
        { label: "Day", width: "20%", textAlign: "left" },
        { label: "Delivery Window Start", width: "16%", textAlign: "left" },
        { label: "Delivery Window End", width: "16%", textAlign: "left" },
        { label: "Pickup Window Start", width: "16%", textAlign: "left" },
        { label: "Pickup Window End", width: "16%", textAlign: "left" },
        { label: "Cutoff", width: "16%", textAlign: "left" },
      ],
      pickupColumns: [
        { label: "Day", width: "25%", textAlign: "left" },
        { label: "Pickup Window Start", width: "25%", textAlign: "left" },
        { label: "Pickup Window End", width: "25%", textAlign: "left" },
        { label: "Cutoff", width: "25%", textAlign: "left" },
      ],
      deliveryWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }]],
      pickupWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }]],
      scheduledWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }]],
      weightPricingGrid: [[{ value: "" }, { value: "" }]],
      showEditWindowsModal: [],
      loadingWindowChange: false,
      currentSearchParam: null,
      showWeightPricingModal: [],
      showEditWeightPricingModal: [],
      weightPricingColumns: [
        { label: "Weight Band", width: "50%", textAlign: "right" },
        { label: "Price", width: "50%", textAlign: "right" },
      ],
      loadingWeightPricingChange: false,
      numRowsToAdd: {
        deliveryWindowGrid: 1,
        pickupWindowGrid: 1,
        scheduledWindowGrid: 1,
        weightPricingGrid: 1,
      },
    };
  }

  handleSelect(e) {
    this.setState({ as: e.target.value });
  }

  handleAddRows = (grid) => {
    if (!grid || !this.state.numRowsToAdd?.[grid] || !this.state[grid]?.[0]) {
      return;
    }

    const numColumns = this.state[grid][0].length;
    const newGrid = [...this.state[grid]].filter((band) => band[0].value !== "");

    const rowTemplate = [];

    // Build columns for one row
    for (let i = 0; i < numColumns; i++) {
      let val = "";
      rowTemplate.push({ value: val });
    }

    // Take the built row and add X number of rows to grid
    for (let i = 0; i < this.state.numRowsToAdd[grid]; i++) {
      const newRow = [...rowTemplate];
      if (grid === "weightPricingGrid") {
        const lastSetNumber = Number(newGrid?.[newGrid.length - 1]?.[0]?.value) || 0;
        newRow.splice(0, 1, { value: `${lastSetNumber + 1}` });
      }
      newGrid.push(newRow);
    }

    this.setState({ [grid]: newGrid });
  };

  handleCellsChanged(myGrid, changes, additions, numCols) {
    const grid = this.state[myGrid].map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    // paste extended beyond end, so add a new row
    additions &&
      additions.forEach(({ cell, row, col, value }) => {
        // if there isn't a row yet, we create a row of the appropriate size (numCols) but leave all of them empty. (only happens in first iteration)
        if (!grid[row])
          grid[row] = Array.apply(null, Array(numCols)).map(() => {
            return { ["value"]: "" };
          });

        // if there is a an entry at [row][col] then we need to fill it out with the value in the for loop
        if (grid[row][col]) grid[row][col] = { ["value"]: value };
      });

    this.setState({ [myGrid]: grid });
  }

  sheetRenderer(props) {
    const { deliveryWindowColumns } = this.state;
    return (
      <SheetRenderer
        columns={deliveryWindowColumns}
        onSelectAllChanged={this.handleSelectAllChanged}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  pickupSheetRenderer(props) {
    const { pickupColumns } = this.state;
    return (
      <SheetRenderer
        columns={pickupColumns}
        onSelectAllChanged={this.handleSelectAllChanged}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  weightPricingSheetRenderer(props) {
    const { weightPricingColumns } = this.state;
    return (
      <SheetRenderer
        columns={weightPricingColumns}
        onSelectAllChanged={this.handleSelectAllChanged}
        as="table"
        headerAs="thead"
        bodyAs="tbody"
        rowAs="tr"
        cellAs="th"
        {...props}
      />
    );
  }

  rowRenderer(props) {
    return <RowRenderer as="tr" cellAs="td" className="data-row" {...props} />;
  }

  cellRenderer(props) {
    return <CellRenderer as="td" columns={this.state.deliveryWindowColumns} {...props} style={{ float: "left" }} />;
  }

  weightPricingCellRenderer(props) {
    return <CellRenderer as="td" columns={this.state.weightPricingColumns} {...props} style={{ float: "left" }} />;
  }

  pickupCellRenderer(props) {
    return <CellRenderer as="td" columns={this.state.pickupColumns} {...props} style={{ float: "left" }} />;
  }

  handleNumRowsToAdd = (grid, val) => {
    const newRowsData = { ...this.state.numRowsToAdd };
    newRowsData[grid] = val;
    this.setState({ numRowsToAdd: newRowsData });
  };

  generateDeliverySchedule = (data, pickupData) => {
    let allRows = [];
    allRows.push(<Divider style={{ margin: "0px" }} />);
    dayOfWeeks.forEach((day) => {
      let times = data.usuals.filter((elem) => elem.weekday === day);
      if (pickupData === undefined) {
        times.forEach((time, index) => {
          allRows.push(
            <Row style={{ width: "100%" }}>
              <Col span={4}>
                <h4>{index === 0 ? day : ""}</h4>
              </Col>
              <Col span={4} offset={2}>
                <h4>{`${time.pickupWindow.timeStart} to ${time.pickupWindow.timeEnd}`}</h4>
              </Col>
              <Col span={2} offset={1}>
                <h4>{`${time.cutoffTime}`}</h4>
              </Col>
              <Col span={4} offset={3}>
                <h4>{`${time.deliveryWindow.timeStart} to ${time.deliveryWindow.timeEnd}`}</h4>
              </Col>
            </Row>,
          );
        });
        if (times.length > 0) {
          allRows.push(<Divider style={{ margin: "0px" }} />);
        }
      } else {
        let pickupTimes = pickupData.usuals.filter((elem) => elem.weekday === day);
        let maxLength = Math.max(times.length, pickupTimes.length);
        for (let index = 0; index < maxLength; index++) {
          let dropOffTime, pickupTime;
          let rowConstruct = [];
          rowConstruct.push(
            <Col span={4}>
              <h4>{index === 0 ? day : ""}</h4>
            </Col>,
          );
          if (index < pickupTimes.length) {
            pickupTime = pickupTimes[index];
            rowConstruct.push(
              <Col span={4} offset={2}>
                <h4>{`${pickupTime.pickupWindow.timeStart} to ${pickupTime.pickupWindow.timeEnd}`}</h4>
              </Col>,
            );
            rowConstruct.push(
              <Col span={2} offset={1}>
                <h4>{`${pickupTime.cutoffTime}`}</h4>
              </Col>,
            );
          } else {
            rowConstruct.push(
              <Col span={4} offset={2}>
                <h4>{""}</h4>
              </Col>,
            );
            rowConstruct.push(
              <Col span={2} offset={1}>
                <h4>{""}</h4>
              </Col>,
            );
          }
          if (index < times.length) {
            dropOffTime = times[index];
            rowConstruct.push(
              <Col span={4} offset={3}>
                <h4>{`${dropOffTime.deliveryWindow.timeStart} to ${dropOffTime.deliveryWindow.timeEnd}`}</h4>
              </Col>,
            );
          } else {
            rowConstruct.push(
              <Col span={4} offset={3}>
                <h4>{""}</h4>
              </Col>,
            );
          }

          allRows.push(<Row style={{ width: "100%" }}>{rowConstruct}</Row>);
        }
        if (times.length > 0 || pickupTimes.length > 0) {
          allRows.push(<Divider style={{ margin: "0px" }} />);
        }
      }
    });
    return allRows;
  };

  componentDidMount() {
    this.setState({ loadingMerchant: true });
    const values = queryString.parse(this.props.location.search);
    if (values.searchParam) this.setState({ currentSearchParam: values.searchParam });
    let promise = getMerchantById(values.merchantId);
    promise
      .then((response) => {
        this.setState({ merchant: response });
        this.setState({ loadingMerchant: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: "Could not get merchant",
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
    let promiseShippingRules = getAllShippingRules(values.merchantId);
    promiseShippingRules
      .then((response) => {
        this.setState({ shippingRules: response });
        this.setState({ loadingMerchant: false });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingMerchant: false });
      });
  }

  handleDeleteShippingRule = (originZone, destinationZone, rowIndex, columnIndex) => {
    const newDeleteLoadings = [...this.state.deleteLoadings];
    newDeleteLoadings[rowIndex] = [];
    newDeleteLoadings[rowIndex][columnIndex] = true;
    this.setState({ deleteLoadings: newDeleteLoadings });

    let promise = deleteShippingRule(this.state.merchant.id, originZone, destinationZone);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Shipping Rule Deleted.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[rowIndex] = [];
        newDeleteLoadings[rowIndex][columnIndex] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });

        let newArr = this.state.shippingRules;
        newArr = newArr.filter((e) => !(e.originZone === originZone && e.destinationZone === destinationZone));
        this.setState({ shippingRules: newArr });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        const newDeleteLoadings = [...this.state.deleteLoadings];
        newDeleteLoadings[rowIndex] = [];
        newDeleteLoadings[rowIndex][columnIndex] = false;
        this.setState({ deleteLoadings: newDeleteLoadings });
      });
  };

  handleRemoveSpeedPricingType = (speedPricingRow, originZone, destinationZone, index, shippingRuleIndex) => {
    const newRemoveSpeedPricingLoadings = [...this.state.removeSpeedPricingLoadings];
    newRemoveSpeedPricingLoadings[shippingRuleIndex] = [];
    newRemoveSpeedPricingLoadings[shippingRuleIndex][index] = true;
    this.setState({ removeSpeedPricingLoadings: newRemoveSpeedPricingLoadings });

    let myData = {
      originZone: originZone,
      destinationZone: destinationZone,
      speedPricingRow: {
        ...speedPricingRow,
        price: Number(speedPricingRow.price),
      },
    };
    let promise = editShippingRule(myData, this.state.merchant.id, "remove");
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Service Removed.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
        const newRemoveSpeedPricingLoadings = [...this.state.removeSpeedPricingLoadings];
        newRemoveSpeedPricingLoadings[shippingRuleIndex][index] = false;
        this.setState({ removeSpeedPricingLoadings: newRemoveSpeedPricingLoadings });

        if (
          this.state.shippingRules != null &&
          this.state.shippingRules.find((e) => e.originZone === originZone && e.destinationZone === destinationZone) !=
            null
        ) {
          let myShippingRules = this.state.shippingRules;
          let myShippingRuleIndex = myShippingRules.findIndex(
            (x) => x.originZone === originZone && x.destinationZone === destinationZone,
          );
          if (myShippingRuleIndex > -1) {
            myShippingRules[myShippingRuleIndex].services.find(
              (x) => x.name === "speedPricing",
            ).pricingStrategy.pricingRows = myShippingRules[myShippingRuleIndex].services
              .find((x) => x.name === "speedPricing")
              .pricingStrategy.pricingRows.filter((x) => x.speedPricingType !== speedPricingRow.speedPricingType);
            this.setState({
              merchant: {
                ...this.state.merchant,
                shippingRules: myShippingRules,
              },
            });
          }
        }
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        const newRemoveSpeedPricingLoadings = [...this.state.removeSpeedPricingLoadings];
        newRemoveSpeedPricingLoadings[shippingRuleIndex][index] = false;
        this.setState({ removeSpeedPricingLoadings: newRemoveSpeedPricingLoadings });
      });
  };

  handleAddSpeedPricingType = (originZone, destinationZone, cell) => {
    if (
      this.state.speedPricing == null ||
      this.state.speedPricing.speedPricingType == null ||
      this.state.speedPricing.price == null ||
      this.state.speedPricing.price === "" ||
      this.state.speedPricing.name == null ||
      this.state.speedPricing.name === "" ||
      this.state.speedPricing.description == null ||
      this.state.speedPricing.description === "" ||
      this.state.speedPricing.locales[0].description === "" ||
      this.state.speedPricing.locales[0].description == null ||
      this.state.speedPricing.locales[0].name === "" ||
      this.state.speedPricing.locales[0].name == null ||
      this.state.speedPricing.locales[0].locale === "" ||
      this.state.speedPricing.locales[0].locale == null
    ) {
      const args = {
        message: "Error!",
        description: "Make sure to fill out all fields.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ addServiceLoading: true });
    let mySpeedPricing = { ...this.state.speedPricing, price: Number(this.state.speedPricing.price) };
    let myData = {
      originZone: originZone,
      destinationZone: destinationZone,
      speedPricingRow: mySpeedPricing,
    };
    let promise = editShippingRule(myData, this.state.merchant.id, "add");
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Service Added.",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);

        this.setState({ addServiceLoading: false });

        if (
          this.state.shippingRules != null &&
          this.state.shippingRules.find((e) => e.originZone === originZone && e.destinationZone === destinationZone) !=
            null
        ) {
          let myShippingRules = this.state.shippingRules;
          let myShippingRuleIndex = myShippingRules.findIndex(
            (x) => x.originZone === originZone && x.destinationZone === destinationZone,
          );
          if (myShippingRuleIndex > -1) {
            let myRowIndex = myShippingRules[myShippingRuleIndex].services
              .find((x) => x.name === "speedPricing")
              .pricingStrategy.pricingRows.findIndex(
                (y) => y.speedPricingType === this.state.speedPricing.speedPricingType,
              );
            if (myRowIndex < 0) {
              myShippingRules[myShippingRuleIndex].services
                .find((x) => x.name === "speedPricing")
                .pricingStrategy.pricingRows.push(this.state.speedPricing);
            } else {
              myShippingRules[myShippingRuleIndex].services.find(
                (x) => x.name === "speedPricing",
              ).pricingStrategy.pricingRows[myRowIndex] = this.state.speedPricing;
            }
            this.setState({
              shippingRules: myShippingRules,
            });
          }
        }
        this.showAddServiceModal(false, cell);
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ addServiceLoading: false });
      });
  };

  showLocationPricingModal = (rowIndex, columnIndex, newState) => {
    const newShowModal = [...this.state.showModal];
    newShowModal[rowIndex] = [];
    newShowModal[rowIndex][columnIndex] = newState;
    this.setState({ showModal: newShowModal });
  };

  showEditWindowsModal = (rowIndex, columnIndex, newState, deliveries, pickups, scheduled) => {
    if (newState === false) {
      const newShowModal = [...this.state.showEditWindowsModal];
      newShowModal[rowIndex] = [];
      newShowModal[rowIndex][columnIndex] = newState;
      this.setState({ showEditWindowsModal: newShowModal });
      this.setState({
        deliveryWindowGrid: [
          [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        ],
      });
      this.setState({
        scheduledWindowGrid: [
          [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        ],
      });
      this.setState({ pickupWindowGrid: [[{ value: "" }, { value: "" }, { value: "" }, { value: "" }]] });
      return;
    }

    // build current grid
    const sorter = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    // deliveries
    let newEntries = deliveries.usuals
      .sort((a, b) => {
        let day1 = a.weekday.toLowerCase();
        let day2 = b.weekday.toLowerCase();
        return sorter[day1] - sorter[day2];
      })
      .map((e) => {
        return [
          { value: e.weekday },
          { value: e.deliveryWindow.timeStart },
          { value: e.deliveryWindow.timeEnd },
          { value: e.pickupWindow.timeStart },
          { value: e.pickupWindow.timeEnd },
          { value: e.cutoffTime },
        ];
      });
    let newArr = this.state.deliveryWindowGrid
      .concat(newEntries)
      .filter((e) => e[0].value !== "" || e[1].value !== "" || e[2].value !== "" || e[3].value !== "");
    this.setState({ deliveryWindowGrid: newArr });

    // pickups
    let newPickupEntries = pickups.usuals
      .sort((a, b) => {
        let day1 = a.weekday.toLowerCase();
        let day2 = b.weekday.toLowerCase();
        return sorter[day1] - sorter[day2];
      })
      .map((e) => {
        return [
          { value: e.weekday },
          { value: e.pickupWindow.timeStart },
          { value: e.pickupWindow.timeEnd },
          { value: e.cutoffTime },
        ];
      });
    let newPickupsArr = this.state.pickupWindowGrid
      .concat(newPickupEntries)
      .filter((e) => e[0].value !== "" || e[1].value !== "" || e[2].value !== "" || e[3].value !== "");
    this.setState({ pickupWindowGrid: newPickupsArr });

    // scheduled
    if (scheduled) {
      let newScheduledEntries = scheduled.usuals
        .sort((a, b) => {
          let day1 = a.weekday.toLowerCase();
          let day2 = b.weekday.toLowerCase();
          return sorter[day1] - sorter[day2];
        })
        .map((e) => {
          return [
            { value: e.weekday },
            { value: e.deliveryWindow.timeStart },
            { value: e.deliveryWindow.timeEnd },
            { value: e.pickupWindow.timeStart },
            { value: e.pickupWindow.timeEnd },
            { value: e.cutoffTime },
          ];
        });
      let newScheduledArr = this.state.scheduledWindowGrid
        .concat(newScheduledEntries)
        .filter((e) => e[0].value !== "" || e[1].value !== "" || e[2].value !== "" || e[3].value !== "");
      this.setState({ scheduledWindowGrid: newScheduledArr });
    }

    const newShowModal = [...this.state.showEditWindowsModal];
    newShowModal[rowIndex] = [];
    newShowModal[rowIndex][columnIndex] = newState;
    this.setState({ showEditWindowsModal: newShowModal });
  };

  showAddServiceModal = (newState, cell) => {
    this.setState({ currentCell: cell });
    this.setState({ showAddServiceModal: newState });
    this.setState({ speedPricing: null });
    this.setState({ selectedSpeedPricingType: null });
  };

  generateAddServiceForm = (originZone, destinationZone, cell) => {
    return (
      <Form name={"speedPricing"} onFinish={() => this.handleAddSpeedPricingType(originZone, destinationZone, cell)}>
        <>
          <Row>
            Please note that adding a service that already exists will overwrite the existing service. Example: if this
            shipping rule already has NEXTDAY, adding NEXTDAY with a new price or description will overwrite the current
            entry for NEXTDAY.
          </Row>
          <Row gutter={24} style={{ marginBottom: 10 }}>
            <Col span={4}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please choose a type",
                  },
                ]}
              >
                <Select
                  showSearch
                  name="type"
                  placeholder="*Type"
                  options={this.state.speedPricingTypes}
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e) => this.speedPricingSelectType(e)}
                  value={this.state.selectedSpeedPricingType}
                />
              </Form.Item>
            </Col>
            {this.state.selectedSpeedPricingType && (
              <>
                <Col span={4}>
                  <Form.Item name={"price"}>
                    <Input type="number" placeholder="*Price" onChange={(e) => this.handleTextChange("price", e)} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={"name"}>
                    En:{" "}
                    <Input
                      type="text"
                      placeholder="*Name"
                      value={this.state.speedPricing.name}
                      style={{ width: "80%" }}
                      onChange={(e) => this.handleTextChange("name", e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name={"description"}>
                    {""}
                    <Input
                      type="text"
                      placeholder="*Description"
                      value={this.state.speedPricing.description}
                      onChange={(e) => this.handleTextChange("description", e)}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          {this.state.speedPricing != null &&
            this.state.speedPricing.speedPricingType != null &&
            this.state.speedPricing.locales.map((x) => {
              return (
                <>
                  <Row gutter={24} style={{ marginBottom: 10 }}>
                    <Col span={4}></Col>
                    <Col span={4}></Col>

                    <Col span={4}>
                      <Form.Item name={`${x.locale}Name`}>
                        {x.locale}:{" "}
                        <Input
                          type="text"
                          placeholder="*Name"
                          value={x.name}
                          style={{ width: "80%" }}
                          onChange={(e) => this.handleTextChange(`name`, e, x.locale)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Form.Item name={`${x.locale}Description`}>
                        {""}
                        <Input
                          type="text"
                          placeholder="*Description"
                          value={x.description}
                          onChange={(e) => this.handleTextChange(`description`, e, x.locale)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: 10 }}>
                    <Col span={4}></Col>
                    <Col span={4}></Col>

                    <Col span={4}>
                      <Form.Item name={`dispatcher`}>
                        <Select
                          placeholder="Dispatcher"
                          value={x.dispatcher}
                          options={this.state.dispatchers}
                          onChange={(e) => this.handleTextChange(`dispatcher`, e)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            })}
          <Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.addServiceLoading}>
                Add Service
              </Button>
            </Form.Item>
          </Row>
        </>
      </Form>
    );
  };

  handleTextChange = (fieldName, e, locale = null) => {
    let newVal = e?.target?.value ? e.target.value : e;
    if (newVal == null) newVal = "";
    if (locale == null) {
      let newSpeedPricing = this.state.speedPricing;
      newSpeedPricing[fieldName] = newVal;
      this.setState({ speedPricing: newSpeedPricing });
    } else {
      let newSpeedPricing = this.state.speedPricing;
      let foundIndex = newSpeedPricing["locales"].findIndex((x) => x.locale === locale);
      newSpeedPricing["locales"][foundIndex][fieldName] = newVal;
      this.setState({ speedPricing: newSpeedPricing });
    }
  };

  speedPricingSelectType = (type, index) => {
    if (ratesDescriptionList.find((e) => e.speedPricingType === type)) {
      this.setState({ selectedSpeedPricingType: type });
      let myObj = JSON.parse(JSON.stringify(ratesDescriptionList.find((e) => e.speedPricingType === type)));
      if (this.state.speedPricing != null) {
        myObj.price = this.state.speedPricing["price"];
      } else {
        delete myObj.price;
      }
      this.setState({ speedPricing: myObj });
    }
  };

  generateEditableField = (currentVal, field, rowIndex, columnIndex, originZone, destinationZone, fieldName) => {
    return (
      <>
        <Col span={10}>{fieldName}:</Col>+
        <Col span={6}>
          <Spin
            spinning={
              this.state.isEditLoading != null &&
              this.state.isEditLoading[rowIndex] != null &&
              this.state.isEditLoading[rowIndex][columnIndex] != null &&
              this.state.isEditLoading[rowIndex][columnIndex][field] != null
                ? this.state.isEditLoading[rowIndex][columnIndex][field]
                : false
            }
          />
          {this.state.editing &&
          this.state.editing[rowIndex] &&
          this.state.editing[rowIndex] &&
          this.state.editing[rowIndex][columnIndex] &&
          this.state.editing[rowIndex][columnIndex][field] === true ? (
            <Input
              type={"number"}
              style={{ whiteSpace: "normal" }}
              ellipsis={{ rows: 3, expandable: true }}
              value={this.state.editVals[rowIndex][columnIndex][field]}
              onChange={(e) => this.onEditChange(e, field, rowIndex, columnIndex)}
            />
          ) : (
            <Paragraph style={{ whiteSpace: "normal" }} ellipsis={{ rows: 3, expandable: true }}>
              {currentVal}
            </Paragraph>
          )}
        </Col>
        <Col span={5}>
          {this.state.editing &&
          this.state.editing[rowIndex] &&
          this.state.editing[rowIndex] &&
          this.state.editing[rowIndex][columnIndex] &&
          this.state.editing[rowIndex][columnIndex][field] === true ? (
            <>
              <Button
                style={{ marginLeft: 5 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, rowIndex, columnIndex, originZone, destinationZone)}
                icon={<CheckOutlined />}
              />
              <Button
                size={"small"}
                onClick={(e) => this.clickCancelButton(field, rowIndex, columnIndex)}
                icon={<CloseOutlined />}
              />
            </>
          ) : (
            <Button
              style={{ marginLeft: 5 }}
              size={"small"}
              onClick={(e) =>
                this.clickEditButton(currentVal, field, rowIndex, columnIndex, originZone, destinationZone)
              }
              icon={<EditOutlined />}
            />
          )}
        </Col>
      </>
    );
  };

  onEditChange = (e, field, rowIndex, columnIndex, originZone, destinationZone) => {
    let newEditVals = this.state.editVals;
    if (newEditVals[rowIndex] == null) newEditVals[rowIndex] = [];
    if (newEditVals[rowIndex][columnIndex] == null) newEditVals[rowIndex][columnIndex] = {};
    newEditVals[rowIndex][columnIndex][field] = e.target.value;

    this.setState({ editVals: newEditVals });
  };

  clickEditButton = (currentVal, field, rowIndex, columnIndex, originZone, destinationZone) => {
    let newEditing = this.state.editing;
    if (newEditing[rowIndex] == null) newEditing[rowIndex] = [];
    if (newEditing[rowIndex][columnIndex] == null) newEditing[rowIndex][columnIndex] = {};
    newEditing[rowIndex][columnIndex][field] = true;
    this.setState({ editing: newEditing });

    let newOrgVals = this.state.orgVals;
    if (newOrgVals[rowIndex] == null) newOrgVals[rowIndex] = [];
    if (newOrgVals[rowIndex][columnIndex] == null) newOrgVals[rowIndex][columnIndex] = {};
    newOrgVals[rowIndex][columnIndex][field] = currentVal;
    this.setState({ orgVals: newOrgVals });

    let newEditVals = this.state.editVals;
    if (newEditVals[rowIndex] == null) newEditVals[rowIndex] = [];
    if (newEditVals[rowIndex][columnIndex] == null) newEditVals[rowIndex][columnIndex] = {};
    newEditVals[rowIndex][columnIndex][field] = currentVal;
    this.setState({ editVals: newEditVals });
  };

  clickCancelButton = (field, rowIndex, columnIndex) => {
    let newEditing = this.state.editing;
    if (newEditing[rowIndex] == null) newEditing[rowIndex] = [];
    if (newEditing[rowIndex][columnIndex] == null) newEditing[rowIndex][columnIndex] = {};
    newEditing[rowIndex][columnIndex][field] = false;
    this.setState({ editing: newEditing });

    let newEditVals = this.state.editVals;
    if (newEditVals[rowIndex] == null) newEditVals[rowIndex] = [];
    if (newEditVals[rowIndex][columnIndex] == null) newEditVals[rowIndex][columnIndex] = {};
    newEditVals[rowIndex][columnIndex][field] = this.state.orgVals[rowIndex][columnIndex][field];
    this.setState({ editVals: newEditVals });
  };

  clickSaveButton = (field, rowIndex, columnIndex, originZone, destinationZone) => {
    let newIsEditLoading = this.state.isEditLoading;
    if (newIsEditLoading[rowIndex] == null) newIsEditLoading[rowIndex] = [];
    if (newIsEditLoading[rowIndex][columnIndex] == null) newIsEditLoading[rowIndex][columnIndex] = {};
    newIsEditLoading[rowIndex][columnIndex][field] = true;
    this.setState({ isEditLoading: newIsEditLoading });

    let myBody = {
      originZone: originZone,
      destinationZone: destinationZone,
    };
    myBody[field] = Number(this.state.editVals[rowIndex][columnIndex][field]);
    let promise = editShippingRule(myBody, this.state.merchant.id, field);
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Merchant Updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);

        let newIsEditLoading = this.state.isEditLoading;
        if (newIsEditLoading[rowIndex] == null) newIsEditLoading[rowIndex] = [];
        if (newIsEditLoading[rowIndex][columnIndex] == null) newIsEditLoading[rowIndex][columnIndex] = {};
        newIsEditLoading[rowIndex][columnIndex][field] = false;
        this.setState({ isEditLoading: newIsEditLoading });

        let newEditing = this.state.editing;
        if (newEditing[rowIndex] == null) newEditing[rowIndex] = [];
        if (newEditing[rowIndex][columnIndex] == null) newEditing[rowIndex][columnIndex] = {};
        newEditing[rowIndex][columnIndex][field] = false;
        this.setState({ editing: newEditing });

        let newShippingRules = this.state.shippingRules.map((e) => {
          if (e.originZone === originZone && e.destinationZone === destinationZone) {
            e[field] = Number(this.state.editVals[rowIndex][columnIndex][field]);
          }
          return e;
        });
        this.setState({ shippingRules: newShippingRules });
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        let newIsEditLoading = this.state.isEditLoading;
        if (newIsEditLoading[rowIndex] == null) newIsEditLoading[rowIndex] = [];
        if (newIsEditLoading[rowIndex][columnIndex] == null) newIsEditLoading[rowIndex][columnIndex] = {};
        newIsEditLoading[rowIndex][columnIndex][field] = false;
        this.setState({ isEditLoading: newIsEditLoading });
      });
  };

  handleSaveWindowChanges = (myShippingRule, i, j) => {
    // STEP 1: DELIVERY WINDOWS
    let deliveryComplete = true;
    for (const e of this.state.deliveryWindowGrid) {
      if (e.some((x) => x.value.replace(/\s/g, "") === "") && !e.every((x) => x.value.replace(/\s/g, "") === "")) {
        deliveryComplete = false;
      }
    }
    if (!deliveryComplete) {
      const args = {
        message: "Error!",
        description: "Please make sure to fill out all fields of rows in Delivery Windows",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    if (
      this.state.deliveryWindowGrid.filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "" &&
          x[4].value.replace(/\s/g, "") !== "" &&
          x[5].value.replace(/\s/g, "") !== "",
      ).length === 0 ||
      this.state.pickupWindowGrid.filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "",
      ).length === 0
    ) {
      const args = {
        message: "Error!",
        description: "Please add at least one DeliveryConstraint and one Pickup Constraint",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let mappedDeliveryConstraints = this.state.deliveryWindowGrid
      .filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "" &&
          x[4].value.replace(/\s/g, "") !== "" &&
          x[5].value.replace(/\s/g, "") !== "",
      )
      .map((e) => {
        return {
          day: e[0].value.replace(/\s/g, ""),
          deliveryTimeStart: e[1].value.replace(/\s/g, ""),
          deliveryTimeEnd: e[2].value.replace(/\s/g, ""),
          pickupTimeStart: e[3].value.replace(/\s/g, ""),
          pickupTimeEnd: e[4].value.replace(/\s/g, ""),
          cutoffTime: e[5].value.replace(/\s/g, ""),
        };
      });

    let myDeliveryExceptions = myShippingRule.deliveryConstraintsByWeek.exceptions.map((e) => {
      let myNewException = {};
      myNewException["date"] = e.date;
      myNewException["cutoffTime"] = e.cutoffTime;
      if (e.deliveryWindow != null) {
        myNewException["deliveryWindow"] = e.deliveryWindow;
      } else {
        myNewException["deliveryWindow"] = { timeEnd: "00:00", timeStart: "00:00" };
      }
      if (e.pickupWindow != null) {
        myNewException["pickupWindow"] = e.pickupWindow;
      } else {
        myNewException["pickupWindow"] = { timeEnd: "00:00", timeStart: "00:00" };
      }
      return myNewException;
    });

    let deliveryConstraints = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      exceptions: myDeliveryExceptions,
    };
    let mappedDelivery = mappedDeliveryConstraints.every((e) => {
      if (deliveryConstraints[e.day] != null) {
        deliveryConstraints[e.day].push({
          deliveryWindow: {
            timeStart: e.deliveryTimeStart,
            timeEnd: e.deliveryTimeEnd,
          },
          pickupWindow: {
            timeStart: e.pickupTimeStart,
            timeEnd: e.pickupTimeEnd,
          },
          cutoffTime: e.cutoffTime,
        });
      } else {
        return false;
      }
      return true;
    });

    if (mappedDelivery === false) {
      const args = {
        message: "Error!",
        description: "Please add make sure all weekdays in Delivery Windows are valid (format: monday, tuesday, etc.)",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    // STEP 2: PICKUP WINDOWS
    // STEP 1: DELIVERY WINDOWS
    let pickupComplete = true;
    for (const e of this.state.pickupWindowGrid) {
      if (e.some((x) => x.value.replace(/\s/g, "") === "") && !e.every((x) => x.value.replace(/\s/g, "") === "")) {
        pickupComplete = false;
      }
    }
    if (!pickupComplete) {
      const args = {
        message: "Error!",
        description: "Please make sure to fill out all fields of rows in Pickup Windows",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let mappedPickupConstraints = this.state.pickupWindowGrid
      .filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "",
      )
      .map((e) => {
        return {
          day: e[0].value.replace(/\s/g, ""),
          timeStart: e[1].value.replace(/\s/g, ""),
          timeEnd: e[2].value.replace(/\s/g, ""),
          cutoffTime: e[3].value.replace(/\s/g, ""),
        };
      });

    let myPickupExceptions = myShippingRule.pickupConstraintsByWeek.exceptions.map((e) => {
      let myNewException = {};
      myNewException["date"] = e.date;
      myNewException["cutoffTime"] = e.cutoffTime;
      if (e.pickupWindow != null) {
        myNewException["pickupWindow"] = e.pickupWindow;
      } else {
        myNewException["pickupWindow"] = { timeEnd: "00:00", timeStart: "00:00" };
      }
      return myNewException;
    });

    let pickupConstraints = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      exceptions: myPickupExceptions,
    };
    let mappedPickup = mappedPickupConstraints.every((e) => {
      if (pickupConstraints[e.day] != null) {
        pickupConstraints[e.day].push({
          pickupWindow: {
            timeStart: e.timeStart,
            timeEnd: e.timeEnd,
          },
          cutoffTime: e.cutoffTime,
        });
      } else {
        return false;
      }
      return true;
    });

    if (mappedPickup === false) {
      const args = {
        message: "Error!",
        description:
          "Please add make sure all weekdays in the Pickup Windows are valid (format: monday, tuesday, etc.)",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    // STEP 3: SCHEDULED WINDOWS
    // check if any rows are incomplete (some empty values but NOT all)
    let scheduledComplete = true;
    for (const e of this.state.scheduledWindowGrid) {
      if (e.some((x) => x.value.replace(/\s/g, "") === "") && !e.every((x) => x.value.replace(/\s/g, "") === "")) {
        scheduledComplete = false;
      }
    }
    if (!scheduledComplete) {
      const args = {
        message: "Error!",
        description:
          "In you are adding/modifying scheduled windows, please make sure to fill out all fields of rows in Scheduled Windows",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let mappedScheduledConstraints = this.state.scheduledWindowGrid
      .filter(
        (x) =>
          x[0].value.replace(/\s/g, "") !== "" &&
          x[1].value.replace(/\s/g, "") !== "" &&
          x[2].value.replace(/\s/g, "") !== "" &&
          x[3].value.replace(/\s/g, "") !== "" &&
          x[4].value.replace(/\s/g, "") !== "" &&
          x[5].value.replace(/\s/g, "") !== "",
      )
      .map((e) => {
        return {
          day: e[0].value.replace(/\s/g, ""),
          deliveryTimeStart: e[1].value.replace(/\s/g, ""),
          deliveryTimeEnd: e[2].value.replace(/\s/g, ""),
          pickupTimeStart: e[3].value.replace(/\s/g, ""),
          pickupTimeEnd: e[4].value.replace(/\s/g, ""),
          cutoffTime: e[5].value.replace(/\s/g, ""),
        };
      });

    let myScheduledExceptions = myShippingRule.scheduledDeliveryPickupConstraintsByWeek
      ? myShippingRule.scheduledDeliveryPickupConstraintsByWeek.exceptions.map((e) => {
          let myNewException = {};
          myNewException["date"] = e.date;
          myNewException["cutoffTime"] = e.cutoffTime;
          if (e.deliveryWindow != null) {
            myNewException["deliveryWindow"] = e.deliveryWindow;
          } else {
            myNewException["deliveryWindow"] = { timeEnd: "00:00", timeStart: "00:00" };
          }
          if (e.pickupWindow != null) {
            myNewException["pickupWindow"] = e.pickupWindow;
          } else {
            myNewException["pickupWindow"] = { timeEnd: "00:00", timeStart: "00:00" };
          }
          return myNewException;
        })
      : [
          {
            date: "2022-01-01",
            deliveryWindow: {
              timeStart: "00:00",
              timeEnd: "00:00",
            },
            pickupWindow: {
              timeStart: "00:00",
              timeEnd: "00:00",
            },
            cutoffTime: "00:00",
          },
        ];

    let scheduledConstraints = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      exceptions: myScheduledExceptions,
    };

    let mappedScheduled = mappedScheduledConstraints.every((e) => {
      if (scheduledConstraints[e.day] != null) {
        scheduledConstraints[e.day].push({
          deliveryWindow: {
            timeStart: e.deliveryTimeStart,
            timeEnd: e.deliveryTimeEnd,
          },
          pickupWindow: {
            timeStart: e.pickupTimeStart,
            timeEnd: e.pickupTimeEnd,
          },
          cutoffTime: e.cutoffTime,
        });
      } else {
        return false;
      }
      return true;
    });

    if (mappedScheduled === false) {
      const args = {
        message: "Error!",
        description: "Please add make sure all weekdays in Delivery Windows are valid (format: monday, tuesday, etc.)",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    let includeScheduled = false;

    for (const weekDay in scheduledConstraints) {
      if (weekDay != "exceptions" && scheduledConstraints[weekDay].length > 0) {
        includeScheduled = true;
        break;
      }
    }

    let body = {
      originZone: myShippingRule.originZone,
      destinationZone: myShippingRule.destinationZone,
      deliveryConstraintsByWeek: deliveryConstraints,
      pickupConstraintsByWeek: pickupConstraints,
    };

    if (scheduledConstraints && includeScheduled) body.scheduledDeliveryPickupConstraintsByWeek = scheduledConstraints;

    this.setState({ loadingWindowChange: true });
    let promise = editShippingRule(body, this.state.merchant.id, "windows");
    promise
      .then((response) => {
        const args = {
          message: "Success!",
          description: "Merchant Updated.",
          duration: 5,
          type: "success",
        };
        notification.open(args);

        const buildDeliveryConstraintsByWeek = (myDeliveryConstraintsByWeek) => {
          let deliveryConstraintsByWeek = {};
          if (myDeliveryConstraintsByWeek !== undefined) {
            deliveryConstraintsByWeek.usuals = [];
            for (let weekday in myDeliveryConstraintsByWeek) {
              if (weekday !== "exceptions") {
                myDeliveryConstraintsByWeek[weekday].forEach((elem) => {
                  let weekdayDeliveryConstraint = {};
                  weekdayDeliveryConstraint.weekday = weekday;
                  weekdayDeliveryConstraint.deliveryWindow = elem.deliveryWindow;
                  weekdayDeliveryConstraint.pickupWindow = elem.pickupWindow;
                  weekdayDeliveryConstraint.cutoffTime = elem.cutoffTime;

                  deliveryConstraintsByWeek.usuals.push(weekdayDeliveryConstraint);
                });
              }
            }
            deliveryConstraintsByWeek.exceptions = myDeliveryConstraintsByWeek.exceptions;
          }
          return deliveryConstraintsByWeek;
        };

        const buildPickupConstraintsByWeek = (myPickupConstraintsByWeek) => {
          let pickupConstraintsByWeek = {};
          if (myPickupConstraintsByWeek !== undefined) {
            pickupConstraintsByWeek.usuals = [];
            for (let weekday in myPickupConstraintsByWeek) {
              if (weekday !== "exceptions") {
                myPickupConstraintsByWeek[weekday].forEach((elem) => {
                  let weekdayPickupConstraint = {};
                  weekdayPickupConstraint.weekday = weekday;
                  weekdayPickupConstraint.pickupWindow = elem.pickupWindow;
                  weekdayPickupConstraint.cutoffTime = elem.cutoffTime;

                  pickupConstraintsByWeek.usuals.push(weekdayPickupConstraint);
                });
              }
            }
            pickupConstraintsByWeek.exceptions = myPickupConstraintsByWeek.exceptions;
          }
          return pickupConstraintsByWeek;
        };
        let newShippingRules = this.state.shippingRules.map((e) => {
          if (e.originZone === body.originZone && e.destinationZone === body.destinationZone) {
            e["deliveryConstraintsByWeek"] = buildDeliveryConstraintsByWeek(body.deliveryConstraintsByWeek);
            e["pickupConstraintsByWeek"] = buildPickupConstraintsByWeek(body.pickupConstraintsByWeek);
          }
          return e;
        });
        this.setState({ shippingRules: newShippingRules });

        this.setState({ loadingWindowChange: false });

        this.showEditWindowsModal(i, j, false);
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingWindowChange: false });
      });
  };

  clickBackButton = () => {
    let link = document.createElement("a");
    link.href =
      `/merchants/viewMerchants` +
      (this.state.currentSearchParam ? `?searchParam=${this.state.currentSearchParam}` : "");
    document.body.appendChild(link);
    link.click();
  };

  setShowWeightPricingModal = (rowIndex, columnIndex, newState) => {
    const newShowWeightPricingModal = [...this.state.showWeightPricingModal];
    newShowWeightPricingModal[rowIndex] = [];
    newShowWeightPricingModal[rowIndex][columnIndex] = newState;
    this.setState({ showWeightPricingModal: newShowWeightPricingModal });
  };

  setShowEditWeightPricingModal = (rowIndex, columnIndex, newState, cell, newWeightPricing = false) => {
    if (newState) {
      this.setShowWeightPricingModal(rowIndex, columnIndex, false);
    } else {
      const newShowModal = [...this.state.showEditWeightPricingModal];
      newShowModal[rowIndex] = [];
      newShowModal[rowIndex][columnIndex] = newState;
      this.setState({ showEditWeightPricingModal: newShowModal });

      this.setState({ weightPricingGrid: [[{ value: "" }, { value: "" }]] });
      return;
    }

    if (newWeightPricing) {
      this.setState({ weightPricingGrid: [[{ value: "" }, { value: "" }]] });
    } else {
      // weightPricing
      let shippingRuleWeightPricing = cell?.services?.find((x) => x.name === "weightPricing")?.pricingStrategy
        ?.pricingRows;
      let newWeightPricingEntries = shippingRuleWeightPricing.map((e) => {
        return [{ value: e.weightInPounds.toString() }, { value: e.price.toString() }];
      });
      let newWeightPricingArr = this.state.weightPricingGrid
        .concat(newWeightPricingEntries)
        .filter((e) => e[0]?.value !== "" && e[1]?.value !== "");
      this.setState({ weightPricingGrid: newWeightPricingArr });
    }

    const newShowModal = [...this.state.showEditWeightPricingModal];
    newShowModal[rowIndex] = [];
    newShowModal[rowIndex][columnIndex] = newState;
    this.setState({ showEditWeightPricingModal: newShowModal });
  };

  handleSaveWeightPricingChanges = (myShippingRule, i, j) => {
    const filteredWeightBandsGrid = this.state.weightPricingGrid
      .map((band) => [{ value: band[0].value.trim() }, { value: band[1].value.trim() }])
      .filter((band) => band[0].value || band[1].value);
    const isWeightsValid = isValidateWeightBandsBody(filteredWeightBandsGrid);
    if (!isWeightsValid) return;

    // In COPE-701, update this part to properly filter out whitespace + rows that don't one of the columsn filled out
    let mappedWeightPricing = this.state.weightPricingGrid
      .filter((x) => x[0].value.replace(/\s/g, "") !== "" && x[1].value.replace(/\s/g, "") !== "")
      .map((e) => {
        return {
          weightInPounds: e[0]?.value.replace(/\s/g, ""),
          price: e[1].value.replace(/\s/g, ""),
        };
      });

    const weightBandsPricingRows = mappedWeightPricing.map((x) => {
      return { weightInPounds: Number(x.weightInPounds), price: Number(x.price) };
    });

    let body = {
      originZone: myShippingRule.originZone,
      destinationZone: myShippingRule.destinationZone,
      weightPricing: {
        pricingRows: weightBandsPricingRows,
      },
    };

    this.setState({ loadingWeightPricingChange: true });
    let promise = editShippingRule(body, this.state.merchant.id, "weightPricing");
    promise
      .then((response) => {
        let newShippingRules = this.state.shippingRules.map((e) => {
          if (e.originZone === body.originZone && e.destinationZone === body.destinationZone) {
            e["services"] = e.services.map((x) => {
              if (x.name !== "weightPricing") return x;
              else {
                return {
                  ...x,
                  pricingStrategy: body.weightPricing,
                };
              }
            });
          }
          return e;
        });
        this.setState({ shippingRules: newShippingRules });

        const args = {
          message: "Success!",
          description: "Shipping Rule updated",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);

        this.setState({ loadingWeightPricingChange: false });
        this.setShowEditWeightPricingModal(i, j, false);
      })
      .catch((error) => {
        const args = {
          message: "Error!",
          description: `Could not edit Merchant. ${error.message}`,
          duration: 5,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        this.setState({ loadingWeightPricingChange: false });
      });
  };

  render() {
    const mapCards = (arr) => {
      const copied = Array.from(arr);
      const rows = copied.reduceRight((r, i, _, s) => (r.push(s.splice(0, 3)), r), []);
      return (
        <>
          {rows.map((row, i) => {
            return (
              <Row key={i} gutter={16} style={{ marginBottom: 10 }}>
                {row.map((cell, j) => {
                  const alreadyHasWeightPricing =
                    cell?.services?.find((x) => x.name === "weightPricing")?.pricingStrategy?.pricingRows?.length > 0;
                  return cell.addShippingRule == null ? (
                    <Col key={j} span={8}>
                      <Card
                        bordered={false}
                        title={
                          <>
                            {cell.originZone} <ArrowRightOutlined /> {cell.destinationZone}
                            <Button
                              style={{ float: "right" }}
                              type="danger"
                              icon={<DeleteOutlined />}
                              onClick={() => this.handleDeleteShippingRule(cell.originZone, cell.destinationZone, i, j)}
                              loading={this.state.deleteLoadings[i] ? this.state.deleteLoadings[i][j] : false}
                            />
                          </>
                        }
                      >
                        <Row style={{ marginBottom: 5 }}>
                          {this.generateEditableField(
                            cell.inTransitDays,
                            "inTransitDays",
                            i,
                            j,
                            cell.originZone,
                            cell.destinationZone,
                            "In Transit Days",
                          )}
                        </Row>
                        <Row style={{ marginBottom: 5 }}>
                          {this.generateEditableField(
                            cell.surcharge,
                            "surcharge",
                            i,
                            j,
                            cell.originZone,
                            cell.destinationZone,
                            "Surcharge",
                          )}
                        </Row>
                        {cell?.services?.find((e) => e.name === "speedPricing")?.pricingStrategy?.pricingRows !=
                        null ? (
                          <Row style={{ marginBottom: 5 }}>
                            <div>
                              Services:{" "}
                              {cell.services
                                .find((e) => e.name === "speedPricing")
                                .pricingStrategy.pricingRows.map((x, myIndex) => {
                                  return (
                                    <>
                                      {
                                        <p style={{ margin: "3px", marginBottom: "20px" }}>
                                          <Row>
                                            <Col span={21}>
                                              {`-${x.speedPricingType} (+${x.price}$)`}
                                              {x.dispatcher ? ` (${x.dispatcher})` : ""}
                                            </Col>
                                            <Col span={3}>
                                              <Button
                                                size={"small"}
                                                style={{ float: "right" }}
                                                type="danger"
                                                icon={<DeleteOutlined />}
                                                onClick={() =>
                                                  this.handleRemoveSpeedPricingType(
                                                    x,
                                                    cell.originZone,
                                                    cell.destinationZone,
                                                    myIndex,
                                                    j,
                                                  )
                                                }
                                                loading={
                                                  this.state.removeSpeedPricingLoadings[j] != null &&
                                                  this.state.removeSpeedPricingLoadings[j][myIndex] === true
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </p>
                                      }
                                    </>
                                  );
                                })}
                            </div>
                          </Row>
                        ) : (
                          ""
                        )}

                        <Row>
                          <Button
                            onClick={() => this.showAddServiceModal(true, cell)}
                            disabled={
                              this.state.shippingRules[j].services.find((x) => x.name === "speedPricing") == null
                            }
                          >
                            Add a new service
                          </Button>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                          <Button
                            onClick={() => this.showLocationPricingModal(i, j, true)}
                            disabled={this.state.shippingRules[j].deliveryConstraintsByWeek == null}
                          >
                            View Delivery and Pickup Windows
                          </Button>
                        </Row>

                        <Row>&nbsp;</Row>

                        <Row>
                          <Button
                            onClick={() => {
                              if (alreadyHasWeightPricing) this.setShowWeightPricingModal(i, j, true);
                              else this.setShowEditWeightPricingModal(i, j, true, cell, true);
                            }}
                          >
                            {alreadyHasWeightPricing ? "View " : "Add "}
                            Weight Pricing
                          </Button>
                        </Row>
                      </Card>

                      <Modal
                        title={
                          <>
                            Weight Pricing for: {cell.originZone} {<ArrowRightOutlined />} {cell.destinationZone}{" "}
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => this.setShowEditWeightPricingModal(i, j, true, cell)}
                            />
                          </>
                        }
                        visible={this.state.showWeightPricingModal[i] ? this.state.showWeightPricingModal[i][j] : false}
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                        closable={true}
                        style={{ minWidth: "50%" }}
                        onCancel={() => this.setShowWeightPricingModal(i, j, false)}
                      >
                        {cell?.services
                          ?.find((x) => x.name === "weightPricing")
                          ?.pricingStrategy?.pricingRows?.map((x) => {
                            return (
                              <Row gutter={4}>
                                <Col span={12}>Weight (lbs): {x.weightInPounds}</Col>
                                <Col span={12}>Price: ${x.price}</Col>
                              </Row>
                            );
                          })}
                      </Modal>

                      <Modal
                        title={
                          <>
                            Delivery Constraints By Week for: {cell.originZone} {<ArrowRightOutlined />}{" "}
                            {cell.destinationZone}{" "}
                            <Button
                              icon={<EditOutlined />}
                              onClick={() =>
                                this.showEditWindowsModal(
                                  i,
                                  j,
                                  true,
                                  cell.deliveryConstraintsByWeek,
                                  cell.pickupConstraintsByWeek,
                                  cell.scheduledDeliveryPickupConstraintsByWeek,
                                )
                              }
                            />
                          </>
                        }
                        visible={this.state.showModal[i] ? this.state.showModal[i][j] : false}
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                        closable={true}
                        style={{ minWidth: "70%" }}
                        onCancel={() => this.showLocationPricingModal(i, j, false)}
                      >
                        <Row>
                          <Col span={4} offset={6}>
                            <h4>Pickup</h4>
                          </Col>
                          <Col span={2} offset={1}>
                            <h4>Cutoff</h4>
                          </Col>
                          <Col span={4} offset={3}>
                            <h4>Dropoff</h4>
                          </Col>
                          {this.generateDeliverySchedule(cell.deliveryConstraintsByWeek, cell.pickupConstraintsByWeek)}
                        </Row>
                        {cell.scheduledDeliveryPickupConstraintsByWeek && (
                          <>
                            <Row>
                              <Col span={24} offset={6}>
                                &nbsp;
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <h3>Scheduled Windows</h3>
                              </Col>
                            </Row>

                            <Row>
                              <Col span={4} offset={6}>
                                <h4>Pickup</h4>
                              </Col>
                              <Col span={2} offset={1}>
                                <h4>Cutoff</h4>
                              </Col>
                              <Col span={4} offset={3}>
                                <h4>Dropoff</h4>
                              </Col>
                              {this.generateDeliverySchedule(cell.scheduledDeliveryPickupConstraintsByWeek)}
                            </Row>
                          </>
                        )}
                      </Modal>

                      <Modal
                        title={
                          <>
                            Edit windows for: {cell.originZone} {<ArrowRightOutlined />} {cell.destinationZone}
                          </>
                        }
                        visible={this.state.showEditWindowsModal[i] ? this.state.showEditWindowsModal[i][j] : false}
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                        closable={true}
                        style={{ minWidth: "70%" }}
                        onCancel={() => this.showEditWindowsModal(i, j, false)}
                      >
                        <Row>
                          <Col span={24}>
                            <WindowCard
                              title="Delivery Windows"
                              dataSource={this.state.deliveryWindowGrid}
                              className="custom-sheet"
                              sheetRenderer={this.sheetRenderer}
                              rowRenderer={this.rowRenderer}
                              cellRenderer={this.cellRenderer}
                              onCellsChanged={(changes, additions) => {
                                this.handleCellsChanged("deliveryWindowGrid", changes, additions, 6);
                              }}
                              addRows={() => this.handleAddRows("deliveryWindowGrid")}
                              onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("deliveryWindowGrid", num)}
                              numRowsToAdd={this.state.numRowsToAdd["deliveryWindowGrid"]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <WindowCard
                              title="Pickup Windows"
                              dataSource={this.state.pickupWindowGrid}
                              className="custom-sheet"
                              sheetRenderer={this.pickupSheetRenderer}
                              rowRenderer={this.rowRenderer}
                              cellRenderer={this.pickupCellRenderer}
                              onCellsChanged={(changes, additions) => {
                                this.handleCellsChanged("pickupWindowGrid", changes, additions, 4);
                              }}
                              addRows={() => this.handleAddRows("pickupWindowGrid")}
                              onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("pickupWindowGrid", num)}
                              numRowsToAdd={this.state.numRowsToAdd["pickupWindowGrid"]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <WindowCard
                              title="Scheduled Windows"
                              dataSource={this.state.scheduledWindowGrid}
                              className="custom-sheet"
                              sheetRenderer={this.sheetRenderer}
                              rowRenderer={this.rowRenderer}
                              cellRenderer={this.cellRenderer}
                              onCellsChanged={(changes, additions) => {
                                this.handleCellsChanged("scheduledWindowGrid", changes, additions, 6);
                              }}
                              addRows={() => this.handleAddRows("scheduledWindowGrid")}
                              onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("scheduledWindowGrid", num)}
                              numRowsToAdd={this.state.numRowsToAdd["scheduledWindowGrid"]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Button
                              loading={this.state.loadingWindowChange}
                              type="primary"
                              onClick={() => this.handleSaveWindowChanges(cell, i, j)}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Modal>

                      <Modal
                        title={
                          <>
                            Change weight pricing for: {cell.originZone} {<ArrowRightOutlined />} {cell.destinationZone}
                          </>
                        }
                        visible={
                          this.state.showEditWeightPricingModal[i] ? this.state.showEditWeightPricingModal[i][j] : false
                        }
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                        closable={true}
                        style={{ minWidth: "70%" }}
                        onCancel={() => this.setShowEditWeightPricingModal(i, j, false)}
                      >
                        <Row>
                          <Col span={24}>
                            <WindowCard
                              title="Weight Pricing"
                              dataSource={this.state.weightPricingGrid}
                              className="custom-sheet"
                              sheetRenderer={this.weightPricingSheetRenderer}
                              rowRenderer={this.rowRenderer}
                              cellRenderer={this.weightPricingCellRenderer}
                              onCellsChanged={(changes, additions) => {
                                this.handleCellsChanged("weightPricingGrid", changes, additions, 2);
                              }}
                              addRows={() => this.handleAddRows("weightPricingGrid")}
                              onChangeNumRowsToAdd={(num) => this.handleNumRowsToAdd("weightPricingGrid", num)}
                              numRowsToAdd={this.state.numRowsToAdd["weightPricingGrid"]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Button
                              loading={this.state.loadingWeightPricingChange}
                              type="primary"
                              onClick={() => this.handleSaveWeightPricingChanges(cell, i, j)}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Modal>

                      {this.state.showAddServiceModal && (
                        <Modal
                          title={
                            <>
                              Add new service to shipping rule:{" "}
                              {this.state.currentCell && this.state.currentCell.originZone} {<ArrowRightOutlined />}{" "}
                              {this.state.currentCell && this.state.currentCell.destinationZone}
                            </>
                          }
                          visible={this.state.showAddServiceModal}
                          cancelButtonProps={{ style: { display: "none" } }}
                          okButtonProps={{ style: { display: "none" } }}
                          closable={true}
                          style={{ minWidth: "80%" }}
                          onCancel={() => this.showAddServiceModal(false, cell)}
                        >
                          {this.generateAddServiceForm(
                            this.state.currentCell.originZone,
                            this.state.currentCell.destinationZone,
                            cell,
                          )}
                        </Modal>
                      )}
                    </Col>
                  ) : (
                    <Col key={j} span={8}>
                      <Card bordered={false} title={<Row style={{ marginBottom: 6 }}>Add Shipping Rule</Row>}>
                        <Row style={{ marginBottom: 5 }}>
                          <Button type="primary">
                            <a
                              href={
                                `/merchants/${this.state.merchant.name}/addShippingRules?merchantId=${this.state.merchant.id}` +
                                (this.state.currentSearchParam ? `&searchParam=${this.state.currentSearchParam}` : "")
                              }
                            >
                              Add Shipping Rule
                            </a>
                          </Button>
                        </Row>
                        <Row style={{ marginBottom: 22 }}>&nbsp;</Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </>
      );
    };
    return this.state.merchant == null || this.state.loadingMerchant ? (
      <div className={"viewShippingRules-container"}>
        <Spin />
      </div>
    ) : (
      <div className={"viewShippingRules-container"}>
        <h1>
          <Button type="primary" onClick={this.clickBackButton}>
            <ArrowLeftOutlined />
          </Button>{" "}
          Merchants/{this.state.merchant.name}/Shipping Rules
        </h1>
        {this.state.shippingRules != null
          ? mapCards(this.state.shippingRules.concat([{ addShippingRule: true }]))
          : mapCards([{ addShippingRule: true }])}
      </div>
    );
  }
}

ViewShippingRules.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ViewShippingRules);
