import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Input, Row, Spin, Tabs, notification } from "antd";
import React, { Component } from "react";
import { updateAddress } from "../util/APIUtils";
const { TabPane } = Tabs;

export class DestinationInfoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: {
        recipientAddress: false,
        originAddress: false,
      },
      orgVals: {
        recipientAddress: {
          number: "",
          street: "",
          unit: "",
          city: "",
          province: "",
          postalCode: "",
          country: "",
          isBusinessAddress: false,
        },
        originAddress: {
          number: "",
          street: "",
          unit: "",
          city: "",
          province: "",
          postalCode: "",
          country: "",
          isBusinessAddress: false,
        },
      },
      editVals: {
        recipientAddress: {
          number: this?.props?.recipientAddress?.number ? this.props.recipientAddress.number : null,
          street: this?.props?.recipientAddress?.street ? this.props.recipientAddress.street : null,
          unit: this?.props?.recipientAddress?.unit ? this.props.recipientAddress.unit : null,
          city: this?.props?.recipientAddress?.city ? this.props.recipientAddress.city : null,
          province: this?.props?.recipientAddress?.province ? this.props.recipientAddress.province : null,
          postalCode: this?.props?.recipientAddress?.postalCode ? this.props.recipientAddress.postalCode : null,
          country: this?.props?.recipientAddress?.country ? this.props.recipientAddress.country : null,
          isBusinessAddress: this?.props?.recipientAddress?.isBusinessAddress
            ? this.props.recipientAddress.isBusinessAddress
            : null,
          useCoordinate: false,
          lat: this?.props?.recipientAddress?.location?.lat ? this.props.recipientAddress.location.lat : null,
          lng: this?.props?.recipientAddress?.location?.lng ? this.props.recipientAddress.location.lng : null,
        },
        originAddress: {
          number: this?.props?.originAddress?.number ? this.props.originAddress.number : null,
          street: this?.props?.originAddress?.street ? this.props.originAddress.street : null,
          unit: this?.props?.originAddress?.unit ? this.props.originAddress.unit : null,
          city: this?.props?.originAddress?.city ? this.props.originAddress.city : null,
          province: this?.props?.originAddress?.province ? this.props.originAddress.province : null,
          postalCode: this?.props?.originAddress?.postalCode ? this.props.originAddress.postalCode : null,
          country: this?.props?.originAddress?.country ? this.props.originAddress.country : null,
          isBusinessAddress: this?.props?.originAddress?.isBusinessAddress
            ? this.props.originAddress.isBusinessAddress
            : null,
          useCoordinate: false,
          lat: this?.props?.originAddress?.location?.lat ? this.props.originAddress.location.lat : null,
          lng: this?.props?.originAddress?.location?.lng ? this.props.originAddress.location.lng : null,
        },
      },
      editing: {
        recipientAddress: false,
        originAddress: false,
      },
      editType: { ["recipient.email"]: "email", ["recipient.phone"]: "phone" },
    };
  }

  clickEditButton = (addressToEdit) => {
    this.setState({
      editing: {
        ...this.state.editing,
        [addressToEdit]: true,
      },
    });
    this.setState({
      orgVals: {
        ...this.state.orgVals,
        [addressToEdit]: this.state.editVals[addressToEdit],
      },
    });
  };

  clickCancelButton = (addressToEdit) => {
    this.setState({
      editing: {
        ...this.state.editing,
        [addressToEdit]: false,
      },
    });
    this.setState({
      editVals: {
        ...this.state.editVals,
        [addressToEdit]: this.state.orgVals[addressToEdit],
      },
    });
  };

  clickSaveButton = (addressToEdit) => {
    this.setState({ isLoading: { ...this.state.isLoading, [addressToEdit]: true } });
    let editAddressDTO = {};
    for (let [key, value] of Object.entries(this.state.editVals[addressToEdit])) {
      if (value != null && (typeof value !== "string" || value.trim().length > 0)) {
        editAddressDTO[key] = typeof value === "string" ? value.trim() : value;
      }
    }

    if (!editAddressDTO["useCoordinate"]) {
      // remove coordinates if not being used
      delete editAddressDTO["lat"];
      delete editAddressDTO["lng"];

      // if not using coordinate and a number was specified then we concat number to street
      if (editAddressDTO["number"]) {
        editAddressDTO["street"] = editAddressDTO["number"] + " " + editAddressDTO["street"];
        delete editAddressDTO["number"];
      }
    } else if (editAddressDTO["useCoordinate"] && editAddressDTO["lat"] && editAddressDTO["lng"]) {
      editAddressDTO["lat"] = Number(editAddressDTO["lat"]);
      editAddressDTO["lng"] = Number(editAddressDTO["lng"]);
    }

    delete editAddressDTO["useCoordinate"];

    console.log(JSON.stringify(editAddressDTO));
    // return
    let promise = updateAddress(this.props.orderId, editAddressDTO, addressToEdit);
    promise
      // need to check if order was successfully updated before updating state tree
      .then((response) => {
        console.log("ORDERDTO");
        console.log(editAddressDTO);
        console.log("RESPONSE");
        console.log(JSON.stringify(response));

        // this.setState({ editing: {
        //     ...this.state.editing,
        //     [addressToEdit]: false
        // }})

        // for (let [key, value] of Object.entries(this.state.editVals[addressToEdit])) {
        //     if (value == '' || !value) {
        //         this.setState({ editVals: {
        //             ...this.state.editVals,
        //             [addressToEdit]: {
        //                 ...this.state.editVals[addressToEdit],
        //                 [key]: null
        //             }
        //         }})
        //     }
        // }

        // this.setState({ isLoading: { ...this.state.isLoading, [addressToEdit]: false } })
        this.props.refreshOrdersFromEdit();
        const args = {
          message: (
            <>
              Success! <Spin spinning={true} />
            </>
          ),
          type: "success",
          description:
            "Order updated. Refreshing orders. Please double check the result of the geocoding. Do not forget to reprint the labels of any modified orders",
          duration: 3,
        };
        notification.open(args);
        // this.setState({ isLoading: { ...this.state.isLoading, [addressToEdit]: false } })
      })
      .catch((error) => {
        console.log(error);
        console.log(JSON.stringify(error));
        // console.log(error)
        const args = {
          message: "Note",
          type: "error",
          description: error.message,
          duration: 5,
        };
        notification.open(args);
        this.setState({ isLoading: { ...this.state.isLoading, [addressToEdit]: false } });
      });
  };

  openNotification = () => {
    const args = {
      message: "Note",
      type: "error",
      description: "Order could not be updated.",
      duration: 5,
    };
    notification.open(args);
  };

  onChange = (e, addressToEdit, field) => {
    // determine the type of the field if it hasn't been defined, set it to string
    const type = typeof this.state.editType[field] === "undefined" ? "string" : this.state.editType[field];
    // clean it if it is a phone number
    const value = type === "phone" ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
    this.setState({
      editVals: {
        ...this.state.editVals,
        [addressToEdit]: {
          ...this.state.editVals[addressToEdit],
          [field]: value,
        },
      },
    });
  };

  onChangeCheckBox = (e, addressToEdit, field) => {
    this.setState({
      editVals: {
        ...this.state.editVals,
        [addressToEdit]: {
          ...this.state.editVals[addressToEdit],
          [field]: e.target.checked,
        },
      },
    });
    // if (field === 'useCoordinate' && e.target.checked === false) {
    //     this.setState({
    //         editVals: {
    //             ...this.state.editVals,
    //             [addressToEdit]: {
    //                 ...this.state.editVals[addressToEdit],
    //                 [field]: e.target.checked,
    //                 lat: null,
    //                 lng: null
    //             }
    //         }
    //     })
    // }
  };

  generateEditableAddressField = (addressToEdit, field) => {
    return (
      <>
        <Col span={16}>
          {this.state.editing[addressToEdit] ? (
            <Input
              type={field === "lat" || field === "lng" ? "number" : "text"}
              style={{ whiteSpace: "normal" }}
              ellipsis={{ rows: 3, expandable: true }}
              value={this.state.editVals[addressToEdit][field]}
              onChange={(e) => this.onChange(e, addressToEdit, field)}
              disabled={
                this.state.isLoading[addressToEdit] ||
                ((field === "lat" || field === "lng") && this.state.editVals[addressToEdit]["useCoordinate"] == false)
              }
            />
          ) : (
            <span>{this.state.editVals[addressToEdit][field]}</span>
          )}
        </Col>
      </>
    );
  };

  generateEditableAddressCheckbox = (addressToEdit, field) => {
    return (
      <>
        <Col span={16}>
          {this.state.editing[addressToEdit] ? (
            <Checkbox
              checked={this.state.editVals[addressToEdit][field]}
              onChange={(e) => this.onChangeCheckBox(e, addressToEdit, field)}
              disabled={this.state.isLoading[addressToEdit]}
            ></Checkbox>
          ) : (
            <span>{this.state.editVals[addressToEdit][field] ? "yes" : "no"}</span>
          )}
        </Col>
      </>
    );
  };
  render() {
    return (
      <Card bordered={false}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Destination Address" key="1">
            {/* {this.props.recipientAddress != null && ( */}
            <Row>
              <Col span={8}>
                <span className={"info-content-key"}>Number:</span>
              </Col>
              {this.generateEditableAddressField("recipientAddress", "number")}
              <Col span={8}>
                <span className={"info-content-key"}>Street:</span>
              </Col>
              {this.generateEditableAddressField("recipientAddress", "street")}
              {((this.state.editVals.recipientAddress.unit != null && this.state.editVals.unit != "") ||
                this.state.editing["recipientAddress"]) && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>Unit:</span>
                  </Col>
                  {this.generateEditableAddressField("recipientAddress", "unit")}
                </>
              )}
              <Col span={8}>
                <span className={"info-content-key"}>City:</span>
              </Col>
              {this.generateEditableAddressField("recipientAddress", "city")}
              <Col span={8}>
                <span className={"info-content-key"}>Province:</span>
              </Col>
              {this.generateEditableAddressField("recipientAddress", "province")}
              <Col span={8}>
                <span className={"info-content-key"}>Postal Code:</span>
              </Col>
              {this.generateEditableAddressField("recipientAddress", "postalCode")}
              <Col span={8}>
                <span className={"info-content-key"}>Country:</span>
              </Col>
              {this.generateEditableAddressField("recipientAddress", "country")}
              {(this.state.editVals.recipientAddress.isBusinessAddress || this.state.editing["recipientAddress"]) && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>Business:</span>
                  </Col>
                  {this.generateEditableAddressCheckbox("recipientAddress", "isBusinessAddress")}
                </>
              )}
              {this.state.editing["recipientAddress"] && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>UseCoordinate:</span>
                  </Col>
                  {this.generateEditableAddressCheckbox("recipientAddress", "useCoordinate")}
                </>
              )}

              {this.state.editing["recipientAddress"] && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>lat:</span>
                  </Col>
                  {this.generateEditableAddressField("recipientAddress", "lat")}
                </>
              )}

              {this.state.editing["recipientAddress"] && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>lng:</span>
                  </Col>
                  {this.generateEditableAddressField("recipientAddress", "lng")}
                </>
              )}

              <Col span={24}>
                <span className={"info-content-key"}>&nbsp;</span>
              </Col>
              {!this.state.editing["originAddress"] ? (
                <>
                  {!this.state.editing["recipientAddress"] ? (
                    <Col span={8}>
                      <span className={"info-content-key"}>
                        <Button
                          type="primary"
                          onClick={() => this.clickEditButton("recipientAddress")}
                          loading={this.state.isLoading["recipientAddress"]}
                        >
                          <EditOutlined />
                        </Button>
                      </span>
                    </Col>
                  ) : (
                    <>
                      <Col span={4}>
                        <span className={"info-content-key"}>
                          <Button
                            type="primary"
                            onClick={() => this.clickSaveButton("recipientAddress")}
                            loading={this.state.isLoading["recipientAddress"]}
                          >
                            <CheckOutlined />
                          </Button>
                        </span>
                      </Col>
                      <Col span={4}>
                        <span className={"info-content-key"}>
                          <Button
                            onClick={() => this.clickCancelButton("recipientAddress")}
                            loading={this.state.isLoading["recipientAddress"]}
                          >
                            <CloseOutlined />
                          </Button>
                        </span>
                      </Col>
                    </>
                  )}
                </>
              ) : (
                "Cannot edit both addresses at the same time"
              )}
            </Row>
            {/* )} */}
            {this.props.recipientAddress == null && <p>Destination not valid or being geocoded.</p>}
          </TabPane>
          <TabPane tab="Origin Address" key="2">
            {/* {this.props.originAddress != null && ( */}
            <Row>
              <Col span={8}>
                <span className={"info-content-key"}>Number:</span>
              </Col>
              {this.generateEditableAddressField("originAddress", "number")}
              <Col span={8}>
                <span className={"info-content-key"}>Street:</span>
              </Col>
              {this.generateEditableAddressField("originAddress", "street")}
              {((this.state.editVals.originAddress.unit != null && this.state.editVals.originAddress.unit != "") ||
                this.state.editing["originAddress"]) && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>Unit:</span>
                  </Col>
                  {this.generateEditableAddressField("originAddress", "unit")}
                </>
              )}
              <Col span={8}>
                <span className={"info-content-key"}>City:</span>
              </Col>
              {this.generateEditableAddressField("originAddress", "city")}
              <Col span={8}>
                <span className={"info-content-key"}>Province:</span>
              </Col>
              {this.generateEditableAddressField("originAddress", "province")}
              <Col span={8}>
                <span className={"info-content-key"}>Postal Code:</span>
              </Col>
              {this.generateEditableAddressField("originAddress", "postalCode")}
              <Col span={8}>
                <span className={"info-content-key"}>Country:</span>
              </Col>
              {this.generateEditableAddressField("originAddress", "country")}
              {(this.state.editVals.originAddress.isBusinessAddress != null || this.state.editing["originAddress"]) && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>Business:</span>
                  </Col>
                  {this.generateEditableAddressCheckbox("originAddress", "isBusinessAddress")}
                </>
              )}

              {this.state.editing["originAddress"] && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>UseCoordinate:</span>
                  </Col>
                  {this.generateEditableAddressCheckbox("originAddress", "useCoordinate")}
                </>
              )}

              {this.state.editing["originAddress"] && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>lat:</span>
                  </Col>
                  {this.generateEditableAddressField("originAddress", "lat")}
                </>
              )}

              {this.state.editing["originAddress"] && (
                <>
                  <Col span={8}>
                    <span className={"info-content-key"}>lng:</span>
                  </Col>
                  {this.generateEditableAddressField("originAddress", "lng")}
                </>
              )}

              <Col span={24}>
                <span className={"info-content-key"}>&nbsp;</span>
              </Col>
              {!this.state.editing["recipientAddress"] ? (
                <>
                  {!this.state.editing["originAddress"] ? (
                    <Col span={8}>
                      <span className={"info-content-key"}>
                        <Button
                          type="primary"
                          onClick={() => this.clickEditButton("originAddress")}
                          loading={this.state.isLoading["originAddress"]}
                        >
                          <EditOutlined />
                        </Button>
                      </span>
                    </Col>
                  ) : (
                    <>
                      <Col span={4}>
                        <span className={"info-content-key"}>
                          <Button
                            type="primary"
                            onClick={() => this.clickSaveButton("originAddress")}
                            loading={this.state.isLoading["originAddress"]}
                          >
                            <CheckOutlined />
                          </Button>
                        </span>
                      </Col>
                      <Col span={4}>
                        <span className={"info-content-key"}>
                          <Button
                            onClick={() => this.clickCancelButton("originAddress")}
                            loading={this.state.isLoading["originAddress"]}
                          >
                            <CloseOutlined />
                          </Button>
                        </span>
                      </Col>
                    </>
                  )}
                </>
              ) : (
                "Cannot edit both addresses at the same time"
              )}
            </Row>
            {/* )} */}
            {this.props.originAddress == null && <p>Origin Address not valid or being geocoded.</p>}
          </TabPane>
          <TabPane tab="OG Destination Address" key="3">
            {this.props.dtoRecipientAddress != null && (
              <Row>
                <Col span={8}>
                  <span className={"info-content-key"}>Street:</span>
                </Col>
                <Col span={16}>
                  <span>{this.props.dtoRecipientAddress.street}</span>
                </Col>
                {this.props.dtoRecipientAddress.unit != null && this.props.dtoRecipientAddress.unit != "" && (
                  <Col span={8}>
                    <span className={"info-content-key"}>Unit:</span>
                  </Col>
                )}
                {this.props.dtoRecipientAddress.unit != null && this.props.dtoRecipientAddress.unit != "" && (
                  <Col span={16}>
                    <span>{this.props.dtoRecipientAddress.unit}</span>
                  </Col>
                )}
                <Col span={8}>
                  <span className={"info-content-key"}>City:</span>
                </Col>
                <Col span={16}>
                  <span>{this.props.dtoRecipientAddress.city}</span>
                </Col>
                <Col span={8}>
                  <span className={"info-content-key"}>Province:</span>
                </Col>
                <Col span={16}>
                  <span>{this.props.dtoRecipientAddress.province}</span>
                </Col>
                <Col span={8}>
                  <span className={"info-content-key"}>Postal Code:</span>
                </Col>
                <Col span={16}>
                  <span>{this.props.dtoRecipientAddress.postalCode}</span>
                </Col>
                <Col span={8}>
                  <span className={"info-content-key"}>Country:</span>
                </Col>
                <Col span={16}>
                  <span>{this.props.dtoRecipientAddress.country}</span>
                </Col>
              </Row>
            )}
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default DestinationInfoContent;
