import { AutoComplete } from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { getDetailFromAddressComponent } from "../util/Helpers";
import "./AddressAutoComplete.css";

const autocomplete = new window.google.maps.places.AutocompleteService();
const geocoder = new window.google.maps.Geocoder();

const AddressAutocomplete = ({ onAddressSelect, className }) => {
  const [predictedAddresses, setPredictedAddresses] = useState([]);

  const searchAddress = (value) => {
    autocomplete.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: ["ca", "us"] },
      },
      (predictions) => {
        if (predictions != null) {
          setPredictedAddresses(
            predictions.map(({ description }) => ({
              value: description,
              label: description,
            })),
          );
        }
      },
    );
  };

  const addressChangeHandler = debounce(searchAddress, 800);

  const addressSelecthandler = (address) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        const addressComponents = results[0].address_components;
        const number = getDetailFromAddressComponent("street_number", addressComponents) || "";
        const street = getDetailFromAddressComponent("route", addressComponents) || "";
        const city = getDetailFromAddressComponent("locality", addressComponents);
        const province = getDetailFromAddressComponent("administrative_area_level_1", addressComponents);
        const country = getDetailFromAddressComponent("country", addressComponents);
        const postalCode = getDetailFromAddressComponent("postal_code", addressComponents);

        onAddressSelect &&
          onAddressSelect({
            street: `${number} ${street}`,
            city,
            province,
            postalCode,
            country,
          });
      }
    });
  };

  return (
    <AutoComplete
      options={predictedAddresses}
      onChange={addressChangeHandler}
      onSelect={addressSelecthandler}
      className={`autocomplete-input ${className}`}
      placeholder="Address Search"
    />
  );
};

export default AddressAutocomplete;
