import { notification } from "antd";
import { getFeatureFlag } from "../util/APIUtils";
import { SET_ENABLE_PICKUP_CREATION_FLAG, SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG } from "./types";

export const setHeavyParcelConsolidationV1FeatureFlag = () => async (dispatch) => {
  try {
    let promise = getFeatureFlag("heavy-parcel-consolidation-v1");
    let { enabled } = await promise;
    dispatch({
      type: SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG,
      payload: { value: enabled },
    });
  } catch (error) {
    notification.error({
      placement: "topRight",
      duration: null,
      message: "Failed to grab feature flag",
      description: error.message,
    });
  }
};

export const setEnablePickupCreationFeatureFlag = () => async (dispatch) => {
  try {
    let promise = getFeatureFlag("hubble-enable-pickup-creation");
    let { enabled } = await promise;
    dispatch({
      type: SET_ENABLE_PICKUP_CREATION_FLAG,
      payload: { value: enabled },
    });
  } catch (error) {
    notification.error({
      placement: "topRight",
      duration: null,
      message: "Failed to grab feature flag",
      description: error.message,
    });
  }
};
