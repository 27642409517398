import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, Row, Tag, Tooltip, Typography, notification } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { retryGoboltOrderCreation, updateOrder } from "../util/APIUtils";
import "./InfoContent.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const { Paragraph } = Typography;

export class RecipientInfoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: { notes: false, ["recipient.email"]: false, ["recipient.phone"]: false },
      orgVals: { notes: "", ["recipient.email"]: "", ["recipient.phone"]: "" },
      editVals: {
        notes: this.props.notes,
        ["recipient.email"]: this.props.recipient?.email,
        ["recipient.phone"]: this.props.recipient?.phone,
      },
      editing: { notes: false, ["recipient.email"]: false, ["recipient.phone"]: false },
      editType: { ["recipient.email"]: "email", ["recipient.phone"]: "phone" },
      loadingRetryGoboltOrderCreation: false,
    };
  }

  openNotification = () => {
    const args = {
      message: "Note",
      type: "error",
      description: "Order could not be updated.",
      duration: 5,
    };
    notification.open(args);
  };

  onChange = (e, field) => {
    // determine the type of the field if it hasn't been defined, set it to string
    const type = typeof this.state.editType[field] === "undefined" ? "string" : this.state.editType[field];
    // clean it if it is a phone number
    const value = type === "phone" ? "+" + e.target.value.replace(/[^\d]/g, "") : e.target.value;
    this.setState({ editVals: { ...this.state.editVals, [field]: value } });
  };

  createTrakingLinkFromId = (orderId) => {
    return BASE_URL + "/tracking?orderID=" + orderId;
  };

  clickEditButton = (field) => {
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ orgVals: { ...this.state.orgVals, [field]: this.state.editVals[field] } });
  };

  clickCancelButton = (field) => {
    this.setState({ editing: { ...this.state.editing, [field]: !this.state.editing[field] } });
    this.setState({ editVals: { ...this.state.editVals, [field]: this.state.orgVals[field] } });
  };

  clickSaveButton = (field, value) => {
    if (
      field === "recipient.email" &&
      value != "" &&
      !value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      const args = {
        message: "Error!",
        description: "Please make sure email is in the following format: example@boxknight.com",
        duration: 10,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ isLoading: { ...this.state.isLoading, [field]: true } });
    // determine if orderDTO will have subfields. example: recipient.phone
    let orderDTO = null;
    // if it does not include subfields just use the value
    if (!field.includes(".")) {
      orderDTO = { [field]: value };
    } else {
      let firstField = field.split(".")[0];
      let secondField = field.split(".")[1];
      const propsCopy = { ...this.props[firstField] };
      orderDTO = { [firstField]: propsCopy };
      orderDTO[firstField][secondField] = value;
    }

    let promise = updateOrder(this.props.orderId, orderDTO);
    promise
      // need to check if order was successfully updated before updating state tree
      .then(() => {
        const args = {
          message: "Success!",
          type: "success",
          description: "Order updated. Refreshing orders. Do not forget to reprint the labels of any modified orders",
          duration: 3,
        };
        notification.open(args);
        this.props.refreshOrdersFromEdit();
      })
      .catch((error) => {
        const args = {
          message: "Note",
          type: "error",
          description: error.message,
          duration: 5,
        };
        notification.open(args);
        this.setState({ isLoading: { ...this.state.isLoading, [field]: false } });
      });
  };

  generateEditableField = (field) => {
    const chooseInput = (field) => {
      if (field === "phone") {
        return (
          <PhoneInput
            name=""
            inputClass="ant-input"
            inputComponent={Input}
            country="us"
            tabindex="-1"
            placeholder="*Phone"
            value={this.state.editVals[field]}
            onChange={(v) => this.onChange({ target: { name: "phone", value: v } }, field)}
            type={"tel"}
            size="small"
            specialLabel=""
            disabled={this.state.isLoading[field]}
          />
        );
      } else {
        return (
          <Input
            type={field === "packageCount" ? "number" : "text"}
            style={{ whiteSpace: "normal" }}
            ellipsis={{ rows: 3, expandable: true }}
            value={this.state.editVals[field]}
            onChange={(e) => this.onChange(e, field)}
            disabled={this.state.isLoading[field]}
          />
        );
      }
    };

    const isEditableButton = () => {
      if (Object.entries(this.state.editing).every((x) => x[1] === false)) {
        return (
          <Button style={{ marginLeft: 8 }} size={"small"} onClick={(e) => this.clickEditButton(field)}>
            <EditOutlined />
          </Button>
        );
      } else return "";
    };
    return (
      <>
        <Col span={12}>
          {/* <Spin spinning={this.state.isLoading[field]} /> */}
          {this.state.editing[field] ? (
            <>{chooseInput(field)}</>
          ) : (
            <Paragraph style={{ whiteSpace: "normal" }} ellipsis={{ rows: 3, expandable: true }}>
              {this.state.editVals[field]}
              {field !== "packageCount" ? "" : this.props.packageCount == 1 ? " package" : " packages"}
            </Paragraph>
          )}
        </Col>
        <Col span={3}>
          {this.state.editing[field] ? (
            <>
              <Button
                style={{ marginLeft: 8 }}
                size={"small"}
                onClick={(e) => this.clickSaveButton(field, this.state.editVals[field])}
                loading={this.state.isLoading[field]}
              >
                <CheckOutlined />
              </Button>
              <Button
                size={"small"}
                onClick={(e) => this.clickCancelButton(field)}
                loading={this.state.isLoading[field]}
              >
                <CloseOutlined />
              </Button>
            </>
          ) : (
            <>{isEditableButton()}</>
          )}
        </Col>
      </>
    );
  };

  handleRetryGoboltOrderCreation = () => {
    this.setState({ loadingRetryGoboltOrderCreation: true });
    let promise = retryGoboltOrderCreation(this.props.orderId);
    promise
      .then((response) => {
        notification.open({
          message: "Success!",
          type: "success",
          description: "Gobolt Order Creation reattempted. Please Double check sierra to see the result.",
          duration: 5,
        });
        this.setState({ loadingRetryGoboltOrderCreation: false });
      })
      .catch((error) => {
        notification.open({
          message: "Error",
          type: "error",
          description: error.message,
          duration: 5,
        });
        this.setState({ loadingRetryGoboltOrderCreation: false });
      });
  };

  render() {
    let startDate,
      endDate = undefined;
    if (this.props.completeAfter) {
      startDate = new Date(this.props.completeAfter);
    }
    if (this.props.completeBefore) {
      endDate = new Date(this.props.completeBefore);
    }

    let originalStartDate,
      originalEndDate = undefined;
    if (this.props.originalCompleteAfter) {
      originalStartDate = new Date(this.props.originalCompleteAfter);
    }
    if (this.props.originalCompleteBefore) {
      originalEndDate = new Date(this.props.originalCompleteBefore);
    }

    const columns = [
      {
        title: "RefNumber",
        dataIndex: "refNumber",
        key: "refNumber",
      },
      {
        title: "Sku",
        dataIndex: "sku",
        key: "sku",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Image",
        dataIndex: "imageUrl",
        key: "imageUrl",
        render: (text, record, index) =>
          record.imageUrl !== undefined && record.imageUrl.toUpperCase().includes("HTTP") ? (
            <img width={50} src={record.imageUrl} />
          ) : (
            ""
          ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (text, record, index) => "$" + record.unitPrice,
      },
      {
        title: "Tax Amount",
        dataIndex: "taxAmount",
        key: "taxAmount",
        render: (text, record, index) => "$" + record.taxAmount,
      },
      {
        title: "Warehouse Location",
        dataIndex: "warehouseLocation",
        key: "warehouseLocation",
      },
      {
        title: "Product Id",
        dataIndex: "productId",
        key: "productId",
      },
      {
        title: "Fulfillment Sku",
        dataIndex: "fulfillmentSku",
        key: "fulfillmentSku",
      },
      {
        title: "UPC",
        dataIndex: "upc",
        key: "upc",
      },
    ];
    const mobileColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
    ];

    const mapReadyButton = (status) => {
      if (status === "GEOCODED") return "Set Ready";
      else if (status === "PICKUP_READY") return "Set Not Ready";
    };

    const createTrackingLink = () => {
      if (this.props.dispatcher === "GOBOLT")
        return `${process.env.REACT_APP_GOBOLT_TRACKING_BASE_URL}/tracking/${this.props.orderId}`;
      else return `${process.env.REACT_APP_BOXKNIGHT_TRACKING_BASE_URL}/tracking?trackingNo=${this.props.orderId}`;
    };

    return (
      <Card bordered={false}>
        <Row>
          <Col span={8}>
            <span className={"info-content-key"}>Name:</span>
          </Col>
          <Col span={16}>
            <span>{this.props.recipient?.name}</span>
          </Col>
          <Col span={8}>
            <span className={"info-content-key"}>Phone:</span>
          </Col>
          {this.generateEditableField("recipient.phone")}
          <Col span={8}>
            <span className={"info-content-key"}>Email:</span>
          </Col>

          {this.generateEditableField("recipient.email")}

          <Col span={24}>
            <Divider style={{ marginTop: "0.7em", marginBottom: "0.7em" }} />
          </Col>

          <Col span={8}>
            <span className={"info-content-key"}>Count:</span>
          </Col>
          <Col span={16}>
            <span>
              {this.props.packageCount}
              {this.props.packageCount == 1 ? " package" : " packages"}
            </span>
          </Col>
          <Col span={8}>
            <span className={"info-content-key"}>Master Order ID:</span>
          </Col>
          <Col span={16}>
            <a href={createTrackingLink()} style={{ whiteSpace: "normal" }} target="_blank">
              {this.props.orderId}
            </a>
          </Col>
        </Row>
        <Row>
          {startDate != null && (
            <div>
              <Col span={8}>
                <span className={"info-content-key"}>Window (from):</span>
              </Col>

              <Col span={16}>
                <span style={{ whiteSpace: "normal" }}>
                  {`${startDate.toDateString()}\n${startDate.toLocaleTimeString("en-US", { timeStyle: "short" })}`}
                </span>
              </Col>
            </div>
          )}
          {endDate != null && (
            <div>
              <Col span={8}>
                <span className={"info-content-key"}>Window (to):</span>
              </Col>

              <Col span={16}>
                <span style={{ whiteSpace: "normal" }}>
                  {`${endDate.toDateString()}\n${endDate.toLocaleTimeString("en-US", { timeStyle: "short" })}`}
                </span>
              </Col>
            </div>
          )}
        </Row>
        <Row>
          {originalStartDate != null && (
            <div>
              <Col span={8}>
                <span className={"info-content-key"}>Original Window (from):</span>
              </Col>

              <Col span={16}>
                <span style={{ whiteSpace: "normal" }}>
                  {`${originalStartDate.toDateString()}\n${originalStartDate.toLocaleTimeString("en-US", {
                    timeStyle: "short",
                  })}`}
                </span>
              </Col>
            </div>
          )}
          {originalEndDate != null && (
            <div>
              <Col span={8}>
                <span className={"info-content-key"}>Original Window (to):</span>
              </Col>

              <Col span={16}>
                <span style={{ whiteSpace: "normal" }}>
                  {`${originalEndDate.toDateString()}\n${originalEndDate.toLocaleTimeString("en-US", {
                    timeStyle: "short",
                  })}`}
                </span>
              </Col>
            </div>
          )}
        </Row>
        <Row>
          <Col span={8}>
            <span className={"info-content-key"}>Notes:</span>
          </Col>
          <Col span={16}>
            <span style={{ whiteSpace: "normal" }}> {this.props.notes}</span>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <span className={"info-content-key"}>Price:</span>
          </Col>
          <Col span={16}>
            <span>${this.props.rateChoice.price}</span>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <span className={"info-content-key"}>Rate:</span>
          </Col>
          <Col span={16}>
            <span>{this.props.rateChoice.service}</span>
          </Col>
        </Row>
        {this.props.isHeavyParcelConsolidationV1 && (
          <>
            <Row>
              <Col span={8}>
                <span className={"info-content-key"}>Delivery Service:</span>
              </Col>
              <Col span={16}>
                <span>{this.props.rateChoice.deliveryService}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <span className={"info-content-key"}>Delivery Type:</span>
              </Col>
              <Col span={16}>
                <span>{this.props.deliveryType}</span>
              </Col>
            </Row>
          </>
        )}
        <Row>
          {this.props.minimumAge != null ? (
            <>
              <Col span={8}>
                <span className={"info-content-key"}>Minimum Age:</span>
              </Col>
              <Col span={16}>
                <span>{this.props.minimumAge}</span>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {this.props.source != null ? (
            <>
              <Col span={8}>
                <span className={"info-content-key"}>Source:</span>
              </Col>
              <Col span={16}>
                <span>
                  <Tag color="blue">{this.props.source}</Tag>
                </span>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {this.props.customFields != null ? (
            <>
              <Col span={8}>
                <span className={"info-content-key"}>Custom Fields:</span>
              </Col>
              {this.props.customFields.map((e, i) => {
                return (
                  <>
                    {i > 0 && (
                      <Col span={8}>
                        <span className={"info-content-key"}></span>
                      </Col>
                    )}
                    <Col span={8}>{e.name}:</Col>
                    <Col span={8}>{e.value}</Col>
                  </>
                );
              })}
            </>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col span={24}>
            <span className={"info-content-key"}>&nbsp;</span>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <span className={"info-content-key"}>Retry:</span>
          </Col>
          <Col span={16}>
            <Tooltip
              color="black"
              title={
                this.props.goboltOrderCreate
                  ? "Please note that if the order is not fixed in sierra the first time this button is clicked, then other actions might be needed before trying again (such as fixing addresses, FSAs, etc). "
                  : "This button is disabled when no attempt to create the order in sierra has already been made"
              }
            >
              <Button
                type="primary"
                disabled={!this.props.goboltOrderCreate}
                onClick={this.handleRetryGoboltOrderCreation}
                loading={this.state.loadingRetryGoboltOrderCreation}
              >
                Retry Gobolt Order Creation
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Card>
    );
  }
}

RecipientInfoContent.propTypes = {
  isHeavyParcelConsolidationV1: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isHeavyParcelConsolidationV1: state.featureFlag.heavyParcelConsolidationV1,
});

export default connect(mapStateToProps)(RecipientInfoContent);
