import { Col, Form, Row, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { createMerchantUser, editUserByUsername, getMerchantById, getUserByUsername } from "../../util/APIUtils";
import MerchantInfoCard from "./MerchantInfoCard";
import UserInfoCard from "./UserInfoCard";
import { formatEditUserPayload } from "./utils";
const { useForm } = Form;

export const requiredRule = {
  required: true,
};
export const whitespaceRule = {
  whitespace: true,
};

const AddEditMerchantUser = () => {
  const { merchantId, username } = useParams();
  const location = useLocation();
  const [form] = useForm();
  const [merchant, setMerchant] = useState({});
  const [user, setUser] = useState({});
  const [isMerchantLoading, setIsMerchantLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const isLoading = isMerchantLoading || isUserLoading || isCreatingUser || isEditingUser;
  const isEditFlow = username && location?.pathname?.includes("/");

  useEffect(async () => {
    setIsMerchantLoading(true);
    try {
      const res = await getMerchantById(merchantId);
      setMerchant(res);
      setIsMerchantLoading(false);
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not get merchant. ${e?.message || ""}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsMerchantLoading(false);
      return;
    }

    if (isEditFlow) {
      setIsUserLoading(true);
      try {
        const res = await getUserByUsername(username);
        setUser(res);
        form.setFieldsValue({ name: res.name, phone_number: res.phone_number, email: res.email });
        setIsUserLoading(false);
      } catch (e) {
        const args = {
          message: "Error!",
          description: `Could not get merchant. ${e?.message || ""}.`,
          duration: 3,
          type: "error",
          placement: "topRight",
        };
        notification.open(args);
        setIsUserLoading(false);
      }
    }
  }, [merchantId, username, location]);

  const onCreateUser = async () => {
    setIsCreatingUser(true);
    try {
      await form.validateFields();
    } catch (e) {
      const errMessage = e?.errorFields?.map((field) => field.errors);
      const args = {
        message: "Error!",
        description: `${errMessage}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsCreatingUser(false);
      return;
    }
    const formValues = form.getFieldsValue(true);
    const { name, phone_number, email } = formValues;
    try {
      await createMerchantUser(merchantId, {
        name: name.trim(),
        phone: `+1${phone_number?.replace(/[^\d]/g, "")}`,
        email: email.trim(),
        merchantId,
        merchantName: merchant.name,
      });
      const args = {
        message: "Success!",
        description: `Created a new user for ${name}.`,
        duration: 3,
        type: "succes",
        placement: "topRight",
      };
      notification.open(args);
      form.resetFields();
      setIsCreatingUser(false);
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not create a new user. ${e?.message || ""}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsCreatingUser(false);
    }
  };

  const onEditUser = async () => {
    setIsEditingUser(true);
    try {
      await form.validateFields();
    } catch (e) {
      const errMessage = e?.errorFields?.map((field) => field.errors);
      const args = {
        message: "Error!",
        description: `${errMessage}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsEditingUser(false);
      return;
    }
    const formValues = form.getFieldsValue(true);
    const { name, phone_number } = formValues;
    try {
      await editUserByUsername(username, formatEditUserPayload({ name, phone_number }));
      form.resetFields();
      setIsEditingUser(false);
      const args = {
        message: "Success!",
        description: `Saved updates for user ${name}.`,
        duration: 3,
        type: "succes",
        placement: "topRight",
      };
      notification.open(args);
    } catch (e) {
      const args = {
        message: "Error!",
        description: `Could not edit a new user. ${e?.message || ""}.`,
        duration: 3,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      setIsEditingUser(false);
    }
  };

  const onSubmit = async () => {
    if (isEditFlow) {
      await onEditUser();
    } else {
      await onCreateUser();
    }
  };

  const initialFormValues = {
    name: user.name,
    email: user.email,
    phone_number: user.phone_number,
  };

  const buttonCopy = username ? "Save User" : "Add User";

  return (
    <Spin spinning={isLoading}>
      <div className="addMerchant-container m-40">
        <Form initialValues={initialFormValues} form={form} labelCol={{ span: 10 }} labelAlign="right">
          <Row gutter={24}>
            <Col span={12}>
              <UserInfoCard
                onSubmit={onSubmit}
                buttonCopy={buttonCopy}
                isLoading={isLoading}
                isEdit={isEditFlow}
                user={user}
              />
            </Col>
            <Col span={12}>
              <MerchantInfoCard merchant={merchant} />
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default AddEditMerchantUser;
