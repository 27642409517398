export const postalCodeList = {
  GMA: [
    [{ value: "H1A" }, { value: "" }],
    [{ value: "H1B" }, { value: "" }],
    [{ value: "H1C" }, { value: "" }],
    [{ value: "H1E" }, { value: "" }],
    [{ value: "H1G" }, { value: "" }],
    [{ value: "H1H" }, { value: "" }],
    [{ value: "H1J" }, { value: "" }],
    [{ value: "H1K" }, { value: "" }],
    [{ value: "H1L" }, { value: "" }],
    [{ value: "H1M" }, { value: "" }],
    [{ value: "H1N" }, { value: "" }],
    [{ value: "H1P" }, { value: "" }],
    [{ value: "H1R" }, { value: "" }],
    [{ value: "H1S" }, { value: "" }],
    [{ value: "H1T" }, { value: "" }],
    [{ value: "H1V" }, { value: "" }],
    [{ value: "H1W" }, { value: "" }],
    [{ value: "H1X" }, { value: "" }],
    [{ value: "H1Y" }, { value: "" }],
    [{ value: "H1Z" }, { value: "" }],
    [{ value: "H2A" }, { value: "" }],
    [{ value: "H2B" }, { value: "" }],
    [{ value: "H2C" }, { value: "" }],
    [{ value: "H2E" }, { value: "" }],
    [{ value: "H2G" }, { value: "" }],
    [{ value: "H2H" }, { value: "" }],
    [{ value: "H2J" }, { value: "" }],
    [{ value: "H2K" }, { value: "" }],
    [{ value: "H2L" }, { value: "" }],
    [{ value: "H2M" }, { value: "" }],
    [{ value: "H2N" }, { value: "" }],
    [{ value: "H2P" }, { value: "" }],
    [{ value: "H2R" }, { value: "" }],
    [{ value: "H2S" }, { value: "" }],
    [{ value: "H2T" }, { value: "" }],
    [{ value: "H2V" }, { value: "" }],
    [{ value: "H2W" }, { value: "" }],
    [{ value: "H2X" }, { value: "" }],
    [{ value: "H2Y" }, { value: "" }],
    [{ value: "H2Z" }, { value: "" }],
    [{ value: "H3A" }, { value: "" }],
    [{ value: "H3B" }, { value: "" }],
    [{ value: "H3C" }, { value: "" }],
    [{ value: "H3E" }, { value: "" }],
    [{ value: "H3G" }, { value: "" }],
    [{ value: "H3H" }, { value: "" }],
    [{ value: "H3J" }, { value: "" }],
    [{ value: "H3K" }, { value: "" }],
    [{ value: "H3L" }, { value: "" }],
    [{ value: "H3M" }, { value: "" }],
    [{ value: "H3N" }, { value: "" }],
    [{ value: "H3P" }, { value: "" }],
    [{ value: "H3R" }, { value: "" }],
    [{ value: "H3S" }, { value: "" }],
    [{ value: "H3T" }, { value: "" }],
    [{ value: "H3V" }, { value: "" }],
    [{ value: "H3W" }, { value: "" }],
    [{ value: "H3X" }, { value: "" }],
    [{ value: "H3Y" }, { value: "" }],
    [{ value: "H3Z" }, { value: "" }],
    [{ value: "H4A" }, { value: "" }],
    [{ value: "H4B" }, { value: "" }],
    [{ value: "H4C" }, { value: "" }],
    [{ value: "H4E" }, { value: "" }],
    [{ value: "H4G" }, { value: "" }],
    [{ value: "H4H" }, { value: "" }],
    [{ value: "H4J" }, { value: "" }],
    [{ value: "H4K" }, { value: "" }],
    [{ value: "H4L" }, { value: "" }],
    [{ value: "H4M" }, { value: "" }],
    [{ value: "H4N" }, { value: "" }],
    [{ value: "H4P" }, { value: "" }],
    [{ value: "H4R" }, { value: "" }],
    [{ value: "H4S" }, { value: "" }],
    [{ value: "H4T" }, { value: "" }],
    [{ value: "H4V" }, { value: "" }],
    [{ value: "H4W" }, { value: "" }],
    [{ value: "H4X" }, { value: "" }],
    [{ value: "H4Y" }, { value: "" }],
    [{ value: "H4Z" }, { value: "" }],
    [{ value: "H5A" }, { value: "" }],
    [{ value: "H5B" }, { value: "" }],
    [{ value: "H7A" }, { value: "" }],
    [{ value: "H7B" }, { value: "" }],
    [{ value: "H7C" }, { value: "" }],
    [{ value: "H7E" }, { value: "" }],
    [{ value: "H7G" }, { value: "" }],
    [{ value: "H7H" }, { value: "" }],
    [{ value: "H7J" }, { value: "" }],
    [{ value: "H7K" }, { value: "" }],
    [{ value: "H7L" }, { value: "" }],
    [{ value: "H7M" }, { value: "" }],
    [{ value: "H7N" }, { value: "" }],
    [{ value: "H7P" }, { value: "" }],
    [{ value: "H7R" }, { value: "" }],
    [{ value: "H7S" }, { value: "" }],
    [{ value: "H7T" }, { value: "" }],
    [{ value: "H7V" }, { value: "" }],
    [{ value: "H7W" }, { value: "" }],
    [{ value: "H7X" }, { value: "" }],
    [{ value: "H7Y" }, { value: "" }],
    [{ value: "H8N" }, { value: "" }],
    [{ value: "H8P" }, { value: "" }],
    [{ value: "H8R" }, { value: "" }],
    [{ value: "H8S" }, { value: "" }],
    [{ value: "H8T" }, { value: "" }],
    [{ value: "H8Y" }, { value: "" }],
    [{ value: "H8Z" }, { value: "" }],
    [{ value: "H9A" }, { value: "" }],
    [{ value: "H9B" }, { value: "" }],
    [{ value: "H9C" }, { value: "" }],
    [{ value: "H9E" }, { value: "" }],
    [{ value: "H9G" }, { value: "" }],
    [{ value: "H9H" }, { value: "" }],
    [{ value: "H9J" }, { value: "" }],
    [{ value: "H9K" }, { value: "" }],
    [{ value: "H9P" }, { value: "" }],
    [{ value: "H9R" }, { value: "" }],
    [{ value: "H9S" }, { value: "" }],
    [{ value: "H9W" }, { value: "" }],
    [{ value: "H9X" }, { value: "" }],
    [{ value: "J3Y" }, { value: "" }],
    [{ value: "J3Z" }, { value: "" }],
    [{ value: "J4B" }, { value: "" }],
    [{ value: "J4G" }, { value: "" }],
    [{ value: "J4H" }, { value: "" }],
    [{ value: "J4J" }, { value: "" }],
    [{ value: "J4K" }, { value: "" }],
    [{ value: "J4L" }, { value: "" }],
    [{ value: "J4M" }, { value: "" }],
    [{ value: "J4N" }, { value: "" }],
    [{ value: "J4P" }, { value: "" }],
    [{ value: "J4R" }, { value: "" }],
    [{ value: "J4S" }, { value: "" }],
    [{ value: "J4T" }, { value: "" }],
    [{ value: "J4V" }, { value: "" }],
    [{ value: "J4W" }, { value: "" }],
    [{ value: "J4X" }, { value: "" }],
    [{ value: "J4Y" }, { value: "" }],
    [{ value: "J4Z" }, { value: "" }],
    [{ value: "J5R" }, { value: "" }],
    [{ value: "J5Y" }, { value: "" }],
    [{ value: "J5Z" }, { value: "" }],
    [{ value: "J6A" }, { value: "" }],
    [{ value: "J6V" }, { value: "" }],
    [{ value: "J6W" }, { value: "" }],
    [{ value: "J6X" }, { value: "" }],
    [{ value: "J6Y" }, { value: "" }],
    [{ value: "J6Z" }, { value: "" }],
    [{ value: "J7A" }, { value: "" }],
    [{ value: "J7B" }, { value: "" }],
    [{ value: "J7C" }, { value: "" }],
    [{ value: "J7E" }, { value: "" }],
    [{ value: "J7G" }, { value: "" }],
    [{ value: "J7H" }, { value: "" }],
    [{ value: "J7K" }, { value: "" }],
    [{ value: "J7L" }, { value: "" }],
    [{ value: "J7P" }, { value: "" }],
    [{ value: "J7R" }, { value: "" }],
  ],
  ALLH: [
    [{ value: "H1A" }, { value: "" }],
    [{ value: "H1B" }, { value: "" }],
    [{ value: "H1C" }, { value: "" }],
    [{ value: "H1E" }, { value: "" }],
    [{ value: "H1G" }, { value: "" }],
    [{ value: "H1H" }, { value: "" }],
    [{ value: "H1J" }, { value: "" }],
    [{ value: "H1K" }, { value: "" }],
    [{ value: "H1L" }, { value: "" }],
    [{ value: "H1M" }, { value: "" }],
    [{ value: "H1N" }, { value: "" }],
    [{ value: "H1P" }, { value: "" }],
    [{ value: "H1R" }, { value: "" }],
    [{ value: "H1S" }, { value: "" }],
    [{ value: "H1T" }, { value: "" }],
    [{ value: "H1V" }, { value: "" }],
    [{ value: "H1W" }, { value: "" }],
    [{ value: "H1X" }, { value: "" }],
    [{ value: "H1Y" }, { value: "" }],
    [{ value: "H1Z" }, { value: "" }],
    [{ value: "H2A" }, { value: "" }],
    [{ value: "H2B" }, { value: "" }],
    [{ value: "H2C" }, { value: "" }],
    [{ value: "H2E" }, { value: "" }],
    [{ value: "H2G" }, { value: "" }],
    [{ value: "H2H" }, { value: "" }],
    [{ value: "H2J" }, { value: "" }],
    [{ value: "H2K" }, { value: "" }],
    [{ value: "H2L" }, { value: "" }],
    [{ value: "H2M" }, { value: "" }],
    [{ value: "H2N" }, { value: "" }],
    [{ value: "H2P" }, { value: "" }],
    [{ value: "H2R" }, { value: "" }],
    [{ value: "H2S" }, { value: "" }],
    [{ value: "H2T" }, { value: "" }],
    [{ value: "H2V" }, { value: "" }],
    [{ value: "H2W" }, { value: "" }],
    [{ value: "H2X" }, { value: "" }],
    [{ value: "H2Y" }, { value: "" }],
    [{ value: "H2Z" }, { value: "" }],
    [{ value: "H3A" }, { value: "" }],
    [{ value: "H3B" }, { value: "" }],
    [{ value: "H3C" }, { value: "" }],
    [{ value: "H3E" }, { value: "" }],
    [{ value: "H3G" }, { value: "" }],
    [{ value: "H3H" }, { value: "" }],
    [{ value: "H3J" }, { value: "" }],
    [{ value: "H3K" }, { value: "" }],
    [{ value: "H3L" }, { value: "" }],
    [{ value: "H3M" }, { value: "" }],
    [{ value: "H3N" }, { value: "" }],
    [{ value: "H3P" }, { value: "" }],
    [{ value: "H3R" }, { value: "" }],
    [{ value: "H3S" }, { value: "" }],
    [{ value: "H3T" }, { value: "" }],
    [{ value: "H3V" }, { value: "" }],
    [{ value: "H3W" }, { value: "" }],
    [{ value: "H3X" }, { value: "" }],
    [{ value: "H3Y" }, { value: "" }],
    [{ value: "H3Z" }, { value: "" }],
    [{ value: "H4A" }, { value: "" }],
    [{ value: "H4B" }, { value: "" }],
    [{ value: "H4C" }, { value: "" }],
    [{ value: "H4E" }, { value: "" }],
    [{ value: "H4G" }, { value: "" }],
    [{ value: "H4H" }, { value: "" }],
    [{ value: "H4J" }, { value: "" }],
    [{ value: "H4K" }, { value: "" }],
    [{ value: "H4L" }, { value: "" }],
    [{ value: "H4M" }, { value: "" }],
    [{ value: "H4N" }, { value: "" }],
    [{ value: "H4P" }, { value: "" }],
    [{ value: "H4R" }, { value: "" }],
    [{ value: "H4S" }, { value: "" }],
    [{ value: "H4T" }, { value: "" }],
    [{ value: "H4V" }, { value: "" }],
    [{ value: "H4W" }, { value: "" }],
    [{ value: "H4X" }, { value: "" }],
    [{ value: "H4Y" }, { value: "" }],
    [{ value: "H4Z" }, { value: "" }],
    [{ value: "H5A" }, { value: "" }],
    [{ value: "H5B" }, { value: "" }],
    [{ value: "H7A" }, { value: "" }],
    [{ value: "H7B" }, { value: "" }],
    [{ value: "H7C" }, { value: "" }],
    [{ value: "H7E" }, { value: "" }],
    [{ value: "H7G" }, { value: "" }],
    [{ value: "H7H" }, { value: "" }],
    [{ value: "H7J" }, { value: "" }],
    [{ value: "H7K" }, { value: "" }],
    [{ value: "H7L" }, { value: "" }],
    [{ value: "H7M" }, { value: "" }],
    [{ value: "H7N" }, { value: "" }],
    [{ value: "H7P" }, { value: "" }],
    [{ value: "H7R" }, { value: "" }],
    [{ value: "H7S" }, { value: "" }],
    [{ value: "H7T" }, { value: "" }],
    [{ value: "H7V" }, { value: "" }],
    [{ value: "H7W" }, { value: "" }],
    [{ value: "H7X" }, { value: "" }],
    [{ value: "H7Y" }, { value: "" }],
    [{ value: "H8N" }, { value: "" }],
    [{ value: "H8P" }, { value: "" }],
    [{ value: "H8R" }, { value: "" }],
    [{ value: "H8S" }, { value: "" }],
    [{ value: "H8T" }, { value: "" }],
    [{ value: "H8Y" }, { value: "" }],
    [{ value: "H8Z" }, { value: "" }],
    [{ value: "H9A" }, { value: "" }],
    [{ value: "H9B" }, { value: "" }],
    [{ value: "H9C" }, { value: "" }],
    [{ value: "H9E" }, { value: "" }],
    [{ value: "H9G" }, { value: "" }],
    [{ value: "H9H" }, { value: "" }],
    [{ value: "H9J" }, { value: "" }],
    [{ value: "H9K" }, { value: "" }],
    [{ value: "H9P" }, { value: "" }],
    [{ value: "H9R" }, { value: "" }],
    [{ value: "H9S" }, { value: "" }],
    [{ value: "H9W" }, { value: "" }],
    [{ value: "H9X" }, { value: "" }],
  ],
  ALLJ: [
    [{ value: "J3Y" }, { value: "" }],
    [{ value: "J3Z" }, { value: "" }],
    [{ value: "J4B" }, { value: "" }],
    [{ value: "J4G" }, { value: "" }],
    [{ value: "J4H" }, { value: "" }],
    [{ value: "J4J" }, { value: "" }],
    [{ value: "J4K" }, { value: "" }],
    [{ value: "J4L" }, { value: "" }],
    [{ value: "J4M" }, { value: "" }],
    [{ value: "J4N" }, { value: "" }],
    [{ value: "J4P" }, { value: "" }],
    [{ value: "J4R" }, { value: "" }],
    [{ value: "J4S" }, { value: "" }],
    [{ value: "J4T" }, { value: "" }],
    [{ value: "J4V" }, { value: "" }],
    [{ value: "J4W" }, { value: "" }],
    [{ value: "J4X" }, { value: "" }],
    [{ value: "J4Y" }, { value: "" }],
    [{ value: "J4Z" }, { value: "" }],
    [{ value: "J5R" }, { value: "" }],
    [{ value: "J5Y" }, { value: "" }],
    [{ value: "J5Z" }, { value: "" }],
    [{ value: "J6A" }, { value: "" }],
    [{ value: "J6V" }, { value: "" }],
    [{ value: "J6W" }, { value: "" }],
    [{ value: "J6X" }, { value: "" }],
    [{ value: "J6Y" }, { value: "" }],
    [{ value: "J6Z" }, { value: "" }],
    [{ value: "J7A" }, { value: "" }],
    [{ value: "J7B" }, { value: "" }],
    [{ value: "J7C" }, { value: "" }],
    [{ value: "J7E" }, { value: "" }],
    [{ value: "J7G" }, { value: "" }],
    [{ value: "J7H" }, { value: "" }],
    [{ value: "J7K" }, { value: "" }],
    [{ value: "J7L" }, { value: "" }],
    [{ value: "J7P" }, { value: "" }],
    [{ value: "J7R" }, { value: "" }],
  ],
  GTA: [
    [{ value: "M1B" }, { value: "" }],
    [{ value: "M1C" }, { value: "" }],
    [{ value: "M1E" }, { value: "" }],
    [{ value: "M1G" }, { value: "" }],
    [{ value: "M1H" }, { value: "" }],
    [{ value: "M1J" }, { value: "" }],
    [{ value: "M1K" }, { value: "" }],
    [{ value: "M1L" }, { value: "" }],
    [{ value: "M1M" }, { value: "" }],
    [{ value: "M1N" }, { value: "" }],
    [{ value: "M1P" }, { value: "" }],
    [{ value: "M1R" }, { value: "" }],
    [{ value: "M1S" }, { value: "" }],
    [{ value: "M1T" }, { value: "" }],
    [{ value: "M1V" }, { value: "" }],
    [{ value: "M1W" }, { value: "" }],
    [{ value: "M1X" }, { value: "" }],
    [{ value: "M2H" }, { value: "" }],
    [{ value: "M2J" }, { value: "" }],
    [{ value: "M2K" }, { value: "" }],
    [{ value: "M2L" }, { value: "" }],
    [{ value: "M2M" }, { value: "" }],
    [{ value: "M2N" }, { value: "" }],
    [{ value: "M2P" }, { value: "" }],
    [{ value: "M2R" }, { value: "" }],
    [{ value: "M3A" }, { value: "" }],
    [{ value: "M3B" }, { value: "" }],
    [{ value: "M3C" }, { value: "" }],
    [{ value: "M3H" }, { value: "" }],
    [{ value: "M3J" }, { value: "" }],
    [{ value: "M3K" }, { value: "" }],
    [{ value: "M3L" }, { value: "" }],
    [{ value: "M3M" }, { value: "" }],
    [{ value: "M3N" }, { value: "" }],
    [{ value: "M4A" }, { value: "" }],
    [{ value: "M4B" }, { value: "" }],
    [{ value: "M4C" }, { value: "" }],
    [{ value: "M4E" }, { value: "" }],
    [{ value: "M4G" }, { value: "" }],
    [{ value: "M4H" }, { value: "" }],
    [{ value: "M4J" }, { value: "" }],
    [{ value: "M4K" }, { value: "" }],
    [{ value: "M4L" }, { value: "" }],
    [{ value: "M4M" }, { value: "" }],
    [{ value: "M4N" }, { value: "" }],
    [{ value: "M4P" }, { value: "" }],
    [{ value: "M4R" }, { value: "" }],
    [{ value: "M4S" }, { value: "" }],
    [{ value: "M4T" }, { value: "" }],
    [{ value: "M4V" }, { value: "" }],
    [{ value: "M4W" }, { value: "" }],
    [{ value: "M4X" }, { value: "" }],
    [{ value: "M4Y" }, { value: "" }],
    [{ value: "M5A" }, { value: "" }],
    [{ value: "M5B" }, { value: "" }],
    [{ value: "M5C" }, { value: "" }],
    [{ value: "M5E" }, { value: "" }],
    [{ value: "M5G" }, { value: "" }],
    [{ value: "M5H" }, { value: "" }],
    [{ value: "M5J" }, { value: "" }],
    [{ value: "M5K" }, { value: "" }],
    [{ value: "M5L" }, { value: "" }],
    [{ value: "M5M" }, { value: "" }],
    [{ value: "M5N" }, { value: "" }],
    [{ value: "M5P" }, { value: "" }],
    [{ value: "M5R" }, { value: "" }],
    [{ value: "M5S" }, { value: "" }],
    [{ value: "M5T" }, { value: "" }],
    [{ value: "M5V" }, { value: "" }],
    [{ value: "M5W" }, { value: "" }],
    [{ value: "M5X" }, { value: "" }],
    [{ value: "M6A" }, { value: "" }],
    [{ value: "M6B" }, { value: "" }],
    [{ value: "M6C" }, { value: "" }],
    [{ value: "M6E" }, { value: "" }],
    [{ value: "M6G" }, { value: "" }],
    [{ value: "M6H" }, { value: "" }],
    [{ value: "M6J" }, { value: "" }],
    [{ value: "M6K" }, { value: "" }],
    [{ value: "M6L" }, { value: "" }],
    [{ value: "M6M" }, { value: "" }],
    [{ value: "M6N" }, { value: "" }],
    [{ value: "M6P" }, { value: "" }],
    [{ value: "M6R" }, { value: "" }],
    [{ value: "M6S" }, { value: "" }],
    [{ value: "M7A" }, { value: "" }],
    [{ value: "M7R" }, { value: "" }],
    [{ value: "M7Y" }, { value: "" }],
    [{ value: "M8V" }, { value: "" }],
    [{ value: "M8W" }, { value: "" }],
    [{ value: "M8X" }, { value: "" }],
    [{ value: "M8Y" }, { value: "" }],
    [{ value: "M8Z" }, { value: "" }],
    [{ value: "M9A" }, { value: "" }],
    [{ value: "M9B" }, { value: "" }],
    [{ value: "M9C" }, { value: "" }],
    [{ value: "M9L" }, { value: "" }],
    [{ value: "M9M" }, { value: "" }],
    [{ value: "M9N" }, { value: "" }],
    [{ value: "M9P" }, { value: "" }],
    [{ value: "M9R" }, { value: "" }],
    [{ value: "M9V" }, { value: "" }],
    [{ value: "M9W" }, { value: "" }],
    [{ value: "L1S" }, { value: "" }],
    [{ value: "L1T" }, { value: "" }],
    [{ value: "L1V" }, { value: "" }],
    [{ value: "L1W" }, { value: "" }],
    [{ value: "L1X" }, { value: "" }],
    [{ value: "L1Z" }, { value: "" }],
    [{ value: "L3P" }, { value: "" }],
    [{ value: "L3R" }, { value: "" }],
    [{ value: "L3S" }, { value: "" }],
    [{ value: "L3T" }, { value: "" }],
    [{ value: "L4A" }, { value: "" }],
    [{ value: "L4B" }, { value: "" }],
    [{ value: "L4C" }, { value: "" }],
    [{ value: "L4E" }, { value: "" }],
    [{ value: "L4H" }, { value: "" }],
    [{ value: "L4J" }, { value: "" }],
    [{ value: "L4K" }, { value: "" }],
    [{ value: "L4L" }, { value: "" }],
    [{ value: "L4S" }, { value: "" }],
    [{ value: "L4T" }, { value: "" }],
    [{ value: "L4V" }, { value: "" }],
    [{ value: "L4W" }, { value: "" }],
    [{ value: "L4X" }, { value: "" }],
    [{ value: "L4Y" }, { value: "" }],
    [{ value: "L4Z" }, { value: "" }],
    [{ value: "L5A" }, { value: "" }],
    [{ value: "L5B" }, { value: "" }],
    [{ value: "L5C" }, { value: "" }],
    [{ value: "L5E" }, { value: "" }],
    [{ value: "L5G" }, { value: "" }],
    [{ value: "L5H" }, { value: "" }],
    [{ value: "L5J" }, { value: "" }],
    [{ value: "L5K" }, { value: "" }],
    [{ value: "L5L" }, { value: "" }],
    [{ value: "L5M" }, { value: "" }],
    [{ value: "L5N" }, { value: "" }],
    [{ value: "L5P" }, { value: "" }],
    [{ value: "L5R" }, { value: "" }],
    [{ value: "L5S" }, { value: "" }],
    [{ value: "L5T" }, { value: "" }],
    [{ value: "L5V" }, { value: "" }],
    [{ value: "L5W" }, { value: "" }],
    [{ value: "L6A" }, { value: "" }],
    [{ value: "L6B" }, { value: "" }],
    [{ value: "L6C" }, { value: "" }],
    [{ value: "L6E" }, { value: "" }],
    [{ value: "L6G" }, { value: "" }],
    [{ value: "L6P" }, { value: "" }],
    [{ value: "L6R" }, { value: "" }],
    [{ value: "L6S" }, { value: "" }],
    [{ value: "L6T" }, { value: "" }],
    [{ value: "L6V" }, { value: "" }],
    [{ value: "L6W" }, { value: "" }],
    [{ value: "L6X" }, { value: "" }],
    [{ value: "L6Y" }, { value: "" }],
    [{ value: "L6Z" }, { value: "" }],
    [{ value: "L7A" }, { value: "" }],
  ],
  ALLM: [
    [{ value: "M1B" }, { value: "" }],
    [{ value: "M1C" }, { value: "" }],
    [{ value: "M1E" }, { value: "" }],
    [{ value: "M1G" }, { value: "" }],
    [{ value: "M1H" }, { value: "" }],
    [{ value: "M1J" }, { value: "" }],
    [{ value: "M1K" }, { value: "" }],
    [{ value: "M1L" }, { value: "" }],
    [{ value: "M1M" }, { value: "" }],
    [{ value: "M1N" }, { value: "" }],
    [{ value: "M1P" }, { value: "" }],
    [{ value: "M1R" }, { value: "" }],
    [{ value: "M1S" }, { value: "" }],
    [{ value: "M1T" }, { value: "" }],
    [{ value: "M1V" }, { value: "" }],
    [{ value: "M1W" }, { value: "" }],
    [{ value: "M1X" }, { value: "" }],
    [{ value: "M2H" }, { value: "" }],
    [{ value: "M2J" }, { value: "" }],
    [{ value: "M2K" }, { value: "" }],
    [{ value: "M2L" }, { value: "" }],
    [{ value: "M2M" }, { value: "" }],
    [{ value: "M2N" }, { value: "" }],
    [{ value: "M2P" }, { value: "" }],
    [{ value: "M2R" }, { value: "" }],
    [{ value: "M3A" }, { value: "" }],
    [{ value: "M3B" }, { value: "" }],
    [{ value: "M3C" }, { value: "" }],
    [{ value: "M3H" }, { value: "" }],
    [{ value: "M3J" }, { value: "" }],
    [{ value: "M3K" }, { value: "" }],
    [{ value: "M3L" }, { value: "" }],
    [{ value: "M3M" }, { value: "" }],
    [{ value: "M3N" }, { value: "" }],
    [{ value: "M4A" }, { value: "" }],
    [{ value: "M4B" }, { value: "" }],
    [{ value: "M4C" }, { value: "" }],
    [{ value: "M4E" }, { value: "" }],
    [{ value: "M4G" }, { value: "" }],
    [{ value: "M4H" }, { value: "" }],
    [{ value: "M4J" }, { value: "" }],
    [{ value: "M4K" }, { value: "" }],
    [{ value: "M4L" }, { value: "" }],
    [{ value: "M4M" }, { value: "" }],
    [{ value: "M4N" }, { value: "" }],
    [{ value: "M4P" }, { value: "" }],
    [{ value: "M4R" }, { value: "" }],
    [{ value: "M4S" }, { value: "" }],
    [{ value: "M4T" }, { value: "" }],
    [{ value: "M4V" }, { value: "" }],
    [{ value: "M4W" }, { value: "" }],
    [{ value: "M4X" }, { value: "" }],
    [{ value: "M4Y" }, { value: "" }],
    [{ value: "M5A" }, { value: "" }],
    [{ value: "M5B" }, { value: "" }],
    [{ value: "M5C" }, { value: "" }],
    [{ value: "M5E" }, { value: "" }],
    [{ value: "M5G" }, { value: "" }],
    [{ value: "M5H" }, { value: "" }],
    [{ value: "M5J" }, { value: "" }],
    [{ value: "M5K" }, { value: "" }],
    [{ value: "M5L" }, { value: "" }],
    [{ value: "M5M" }, { value: "" }],
    [{ value: "M5N" }, { value: "" }],
    [{ value: "M5P" }, { value: "" }],
    [{ value: "M5R" }, { value: "" }],
    [{ value: "M5S" }, { value: "" }],
    [{ value: "M5T" }, { value: "" }],
    [{ value: "M5V" }, { value: "" }],
    [{ value: "M5W" }, { value: "" }],
    [{ value: "M5X" }, { value: "" }],
    [{ value: "M6A" }, { value: "" }],
    [{ value: "M6B" }, { value: "" }],
    [{ value: "M6C" }, { value: "" }],
    [{ value: "M6E" }, { value: "" }],
    [{ value: "M6G" }, { value: "" }],
    [{ value: "M6H" }, { value: "" }],
    [{ value: "M6J" }, { value: "" }],
    [{ value: "M6K" }, { value: "" }],
    [{ value: "M6L" }, { value: "" }],
    [{ value: "M6M" }, { value: "" }],
    [{ value: "M6N" }, { value: "" }],
    [{ value: "M6P" }, { value: "" }],
    [{ value: "M6R" }, { value: "" }],
    [{ value: "M6S" }, { value: "" }],
    [{ value: "M7A" }, { value: "" }],
    [{ value: "M7R" }, { value: "" }],
    [{ value: "M7Y" }, { value: "" }],
    [{ value: "M8V" }, { value: "" }],
    [{ value: "M8W" }, { value: "" }],
    [{ value: "M8X" }, { value: "" }],
    [{ value: "M8Y" }, { value: "" }],
    [{ value: "M8Z" }, { value: "" }],
    [{ value: "M9A" }, { value: "" }],
    [{ value: "M9B" }, { value: "" }],
    [{ value: "M9C" }, { value: "" }],
    [{ value: "M9L" }, { value: "" }],
    [{ value: "M9M" }, { value: "" }],
    [{ value: "M9N" }, { value: "" }],
    [{ value: "M9P" }, { value: "" }],
    [{ value: "M9R" }, { value: "" }],
    [{ value: "M9V" }, { value: "" }],
    [{ value: "M9W" }, { value: "" }],
  ],
  ALLL: [
    [{ value: "L1S" }, { value: "" }],
    [{ value: "L1T" }, { value: "" }],
    [{ value: "L1V" }, { value: "" }],
    [{ value: "L1W" }, { value: "" }],
    [{ value: "L1X" }, { value: "" }],
    [{ value: "L1Z" }, { value: "" }],
    [{ value: "L3P" }, { value: "" }],
    [{ value: "L3R" }, { value: "" }],
    [{ value: "L3S" }, { value: "" }],
    [{ value: "L3T" }, { value: "" }],
    [{ value: "L4A" }, { value: "" }],
    [{ value: "L4B" }, { value: "" }],
    [{ value: "L4C" }, { value: "" }],
    [{ value: "L4E" }, { value: "" }],
    [{ value: "L4H" }, { value: "" }],
    [{ value: "L4J" }, { value: "" }],
    [{ value: "L4K" }, { value: "" }],
    [{ value: "L4L" }, { value: "" }],
    [{ value: "L4S" }, { value: "" }],
    [{ value: "L4T" }, { value: "" }],
    [{ value: "L4V" }, { value: "" }],
    [{ value: "L4W" }, { value: "" }],
    [{ value: "L4X" }, { value: "" }],
    [{ value: "L4Y" }, { value: "" }],
    [{ value: "L4Z" }, { value: "" }],
    [{ value: "L5A" }, { value: "" }],
    [{ value: "L5B" }, { value: "" }],
    [{ value: "L5C" }, { value: "" }],
    [{ value: "L5E" }, { value: "" }],
    [{ value: "L5G" }, { value: "" }],
    [{ value: "L5H" }, { value: "" }],
    [{ value: "L5J" }, { value: "" }],
    [{ value: "L5K" }, { value: "" }],
    [{ value: "L5L" }, { value: "" }],
    [{ value: "L5M" }, { value: "" }],
    [{ value: "L5N" }, { value: "" }],
    [{ value: "L5P" }, { value: "" }],
    [{ value: "L5R" }, { value: "" }],
    [{ value: "L5S" }, { value: "" }],
    [{ value: "L5T" }, { value: "" }],
    [{ value: "L5V" }, { value: "" }],
    [{ value: "L5W" }, { value: "" }],
    [{ value: "L6A" }, { value: "" }],
    [{ value: "L6B" }, { value: "" }],
    [{ value: "L6C" }, { value: "" }],
    [{ value: "L6E" }, { value: "" }],
    [{ value: "L6G" }, { value: "" }],
    [{ value: "L6P" }, { value: "" }],
    [{ value: "L6R" }, { value: "" }],
    [{ value: "L6S" }, { value: "" }],
    [{ value: "L6T" }, { value: "" }],
    [{ value: "L6V" }, { value: "" }],
    [{ value: "L6W" }, { value: "" }],
    [{ value: "L6X" }, { value: "" }],
    [{ value: "L6Y" }, { value: "" }],
    [{ value: "L6Z" }, { value: "" }],
    [{ value: "L7A" }, { value: "" }],
  ],
  GTAZONE5: [
    [{ value: "L4T" }, { value: "" }],
    [{ value: "L4V" }, { value: "" }],
    [{ value: "L4W" }, { value: "" }],
    [{ value: "L4X" }, { value: "" }],
    [{ value: "L4Y" }, { value: "" }],
    [{ value: "L4Z" }, { value: "" }],
    [{ value: "L5A" }, { value: "" }],
    [{ value: "L5B" }, { value: "" }],
    [{ value: "L5C" }, { value: "" }],
    [{ value: "L5E" }, { value: "" }],
    [{ value: "L5G" }, { value: "" }],
    [{ value: "L5H" }, { value: "" }],
    [{ value: "L5J" }, { value: "" }],
    [{ value: "L5K" }, { value: "" }],
    [{ value: "L5L" }, { value: "" }],
    [{ value: "L5M" }, { value: "" }],
    [{ value: "L5N" }, { value: "" }],
    [{ value: "L5P" }, { value: "" }],
    [{ value: "L5R" }, { value: "" }],
    [{ value: "L5S" }, { value: "" }],
    [{ value: "L5T" }, { value: "" }],
    [{ value: "L5V" }, { value: "" }],
    [{ value: "L5W" }, { value: "" }],
    [{ value: "L6T" }, { value: "" }],
    [{ value: "L6W" }, { value: "" }],
    [{ value: "L6Y" }, { value: "" }],
    [{ value: "M5A" }, { value: "" }],
    [{ value: "M5B" }, { value: "" }],
    [{ value: "M5C" }, { value: "" }],
    [{ value: "M5E" }, { value: "" }],
    [{ value: "M5G" }, { value: "" }],
    [{ value: "M5H" }, { value: "" }],
    [{ value: "M5J" }, { value: "" }],
    [{ value: "M5K" }, { value: "" }],
    [{ value: "M5L" }, { value: "" }],
    [{ value: "M5M" }, { value: "" }],
    [{ value: "M5N" }, { value: "" }],
    [{ value: "M5P" }, { value: "" }],
    [{ value: "M5R" }, { value: "" }],
    [{ value: "M5S" }, { value: "" }],
    [{ value: "M5T" }, { value: "" }],
    [{ value: "M5V" }, { value: "" }],
    [{ value: "M5W" }, { value: "" }],
    [{ value: "M5X" }, { value: "" }],
    [{ value: "M6A" }, { value: "" }],
    [{ value: "M6B" }, { value: "" }],
    [{ value: "M6C" }, { value: "" }],
    [{ value: "M6E" }, { value: "" }],
    [{ value: "M6G" }, { value: "" }],
    [{ value: "M6H" }, { value: "" }],
    [{ value: "M6J" }, { value: "" }],
    [{ value: "M6K" }, { value: "" }],
    [{ value: "M6L" }, { value: "" }],
    [{ value: "M6M" }, { value: "" }],
    [{ value: "M6N" }, { value: "" }],
    [{ value: "M6P" }, { value: "" }],
    [{ value: "M6R" }, { value: "" }],
    [{ value: "M6S" }, { value: "" }],
    [{ value: "M7A" }, { value: "" }],
    [{ value: "M7R" }, { value: "" }],
    [{ value: "M7Y" }, { value: "" }],
    [{ value: "M8V" }, { value: "" }],
    [{ value: "M8W" }, { value: "" }],
    [{ value: "M8X" }, { value: "" }],
    [{ value: "M8Y" }, { value: "" }],
    [{ value: "M8Z" }, { value: "" }],
    [{ value: "M9A" }, { value: "" }],
    [{ value: "M9B" }, { value: "" }],
    [{ value: "M9C" }, { value: "" }],
    [{ value: "M9L" }, { value: "" }],
    [{ value: "M9M" }, { value: "" }],
    [{ value: "M9N" }, { value: "" }],
    [{ value: "M9P" }, { value: "" }],
    [{ value: "M9R" }, { value: "" }],
    [{ value: "M9V" }, { value: "" }],
    [{ value: "M9W" }, { value: "" }],
  ],
  GTAZONE6: [
    [{ value: "L6H" }, { value: "" }],
    [{ value: "L6J" }, { value: "" }],
    [{ value: "L6K" }, { value: "" }],
    [{ value: "L6M" }, { value: "" }],
    [{ value: "L6L" }, { value: "" }],
    [{ value: "L3P" }, { value: "" }],
    [{ value: "L3R" }, { value: "" }],
    [{ value: "L3S" }, { value: "" }],
    [{ value: "L3T" }, { value: "" }],
    [{ value: "L4B" }, { value: "" }],
    [{ value: "L4C" }, { value: "" }],
    [{ value: "L4H" }, { value: "" }],
    [{ value: "L4J" }, { value: "" }],
    [{ value: "L4K" }, { value: "" }],
    [{ value: "L4L" }, { value: "" }],
    [{ value: "L4S" }, { value: "" }],
    [{ value: "L6A" }, { value: "" }],
    [{ value: "L6B" }, { value: "" }],
    [{ value: "L6C" }, { value: "" }],
    [{ value: "L6E" }, { value: "" }],
    [{ value: "L6G" }, { value: "" }],
    [{ value: "L6P" }, { value: "" }],
    [{ value: "L6R" }, { value: "" }],
    [{ value: "L6S" }, { value: "" }],
    [{ value: "L6V" }, { value: "" }],
    [{ value: "L6X" }, { value: "" }],
    [{ value: "L6Z" }, { value: "" }],
    [{ value: "L7A" }, { value: "" }],
    [{ value: "M1B" }, { value: "" }],
    [{ value: "M1C" }, { value: "" }],
    [{ value: "M1E" }, { value: "" }],
    [{ value: "M1G" }, { value: "" }],
    [{ value: "M1H" }, { value: "" }],
    [{ value: "M1J" }, { value: "" }],
    [{ value: "M1K" }, { value: "" }],
    [{ value: "M1L" }, { value: "" }],
    [{ value: "M1M" }, { value: "" }],
    [{ value: "M1N" }, { value: "" }],
    [{ value: "M1P" }, { value: "" }],
    [{ value: "M1R" }, { value: "" }],
    [{ value: "M1S" }, { value: "" }],
    [{ value: "M1T" }, { value: "" }],
    [{ value: "M1V" }, { value: "" }],
    [{ value: "M1W" }, { value: "" }],
    [{ value: "M1X" }, { value: "" }],
    [{ value: "M2H" }, { value: "" }],
    [{ value: "M2J" }, { value: "" }],
    [{ value: "M2K" }, { value: "" }],
    [{ value: "M2L" }, { value: "" }],
    [{ value: "M2M" }, { value: "" }],
    [{ value: "M2N" }, { value: "" }],
    [{ value: "M2P" }, { value: "" }],
    [{ value: "M2R" }, { value: "" }],
    [{ value: "M3A" }, { value: "" }],
    [{ value: "M3B" }, { value: "" }],
    [{ value: "M3C" }, { value: "" }],
    [{ value: "M3H" }, { value: "" }],
    [{ value: "M3J" }, { value: "" }],
    [{ value: "M3K" }, { value: "" }],
    [{ value: "M3L" }, { value: "" }],
    [{ value: "M3M" }, { value: "" }],
    [{ value: "M3N" }, { value: "" }],
    [{ value: "M4A" }, { value: "" }],
    [{ value: "M4B" }, { value: "" }],
    [{ value: "M4C" }, { value: "" }],
    [{ value: "M4E" }, { value: "" }],
    [{ value: "M4G" }, { value: "" }],
    [{ value: "M4H" }, { value: "" }],
    [{ value: "M4J" }, { value: "" }],
    [{ value: "M4K" }, { value: "" }],
    [{ value: "M4L" }, { value: "" }],
    [{ value: "M4M" }, { value: "" }],
    [{ value: "M4N" }, { value: "" }],
    [{ value: "M4P" }, { value: "" }],
    [{ value: "M4R" }, { value: "" }],
    [{ value: "M4S" }, { value: "" }],
    [{ value: "M4T" }, { value: "" }],
    [{ value: "M4V" }, { value: "" }],
    [{ value: "M4W" }, { value: "" }],
    [{ value: "M4X" }, { value: "" }],
    [{ value: "M4Y" }, { value: "" }],
  ],
};
