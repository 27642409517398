import { CheckCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Space, Typography } from "antd";
import { default as React } from "react";
import { ACTION_BLUE, INDIGO, SUCCESS_GREEN } from "../../util/Colors";

const CreateServiceUserModal = ({
  isServiceUserModalOpen = false,
  onResetServiceUserCreation = () => {},
  createdServiceUser = {},
}) => {
  const onCopyText = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };
  return (
    <Modal
      closable={false}
      title={
        <div>
          <CheckCircleOutlined style={{ color: SUCCESS_GREEN, fontSize: 20, marginRight: 8 }} />
          <Typography.Text>Service user has been successfully created</Typography.Text>
        </div>
      }
      visible={isServiceUserModalOpen}
      footer={[
        <Button
          type="primary"
          key="ok"
          style={{ background: INDIGO, borderColor: INDIGO }}
          onClick={onResetServiceUserCreation}
        >
          OK
        </Button>,
      ]}
    >
      <Typography.Paragraph type="secondary">
        <Space direction="vertical">
          <div>
            <Row>
              <div style={{ padding: "5px 0 0 0" }}>
                <b>Username:</b> {createdServiceUser.username}
              </div>
              <Button type="text" onClick={() => onCopyText(createdServiceUser.username)}>
                <CopyOutlined style={{ color: ACTION_BLUE }} />
              </Button>
            </Row>
            <Row>
              <div style={{ padding: "5px 0 0 0" }}>
                <b>Password:</b> {createdServiceUser.password}
              </div>
              <Button type="text" onClick={() => onCopyText(createdServiceUser.password)}>
                <CopyOutlined style={{ color: ACTION_BLUE }} />
              </Button>
            </Row>
          </div>
          <Row>Please note these credentials down before closing this window.</Row>
        </Space>
      </Typography.Paragraph>
    </Modal>
  );
};

export default CreateServiceUserModal;
