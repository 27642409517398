import { Modal, Steps, Tabs } from "antd";
import React from "react";
import { chooseIcon, fixEventTitle, getCorrectDateFormat } from "../util/Helpers";
import { map } from "lodash";
const { Step } = Steps;
const { TabPane } = Tabs;

export const TrackingTimelineEventsModal = ({
  timeline = {},
  isVisible = false,
  toggleVisible = () => {},
  orderId,
}) => {
  const { packagesTimeline, orderTimeline } = timeline;

  const renderSteps = (events = []) => {
    // all steps shown in modal. reversed for the vertical antd steps
    const stepsReversed = [...events].reverse();
    const steps = stepsReversed.map((eachStep, index) => {
      let eventDate = getCorrectDateFormat(eachStep.timestamp);
      return (
        <Step
          key={index}
          title={fixEventTitle(eachStep.event)}
          description={`${eventDate.toDateString()} ${eventDate.toLocaleTimeString()}`}
          icon={chooseIcon(eachStep.event, index === 0)}
          status="process"
        />
      );
    });
    return steps;
  };

  const renderOrderTabPane = () => (
    <>
      <TabPane tab="Order" key={0}>
        <Steps size="small" direction="vertical">
          {renderSteps(orderTimeline[orderId])}
        </Steps>
      </TabPane>
    </>
  );

  const renderPackageTabPanes = () => (
    <>
      {map(Object.entries(packagesTimeline), (data, key) => {
        const steps = renderSteps(data[1]);
        return (
          <TabPane tab={`Package ${key + 1}`} key={key + 1}>
            <Steps size="small" direction="vertical">
              {steps}
            </Steps>
          </TabPane>
        );
      })}
    </>
  );

  return (
    <Modal
      title="Tracking Events"
      visible={isVisible}
      onOk={() => toggleVisible(false)}
      onCancel={() => toggleVisible(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={true}
      style={{ height: 5 }}
      bodyStyle={{ overflowY: "scroll", height: 400, padding: "0 24px" }}
    >
      <Tabs defaultActiveKey="0">
        {renderOrderTabPane()}
        {renderPackageTabPanes()}
      </Tabs>
    </Modal>
  );
};
