import {
  DollarOutlined,
  IdcardOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import packageJson from "../../../package.json";
import { logout } from "../../actions/authActions";
import boltLogo from "../../assets/GoBolt-Primary-for-Dark-Bg.png";
import boltLightning from "../../assets/logo-gobolt-primary-icon.svg";
import "./AppHeader.css";
const { Sider } = Layout;
const { SubMenu } = Menu;

class AppHeader extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;
    return (
      <div>
        {this.props.isAuthenticated && (
          <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} className={"sider"}>
            {this.state.collapsed ? (
              <div className={"header-logo-big"}>
                <img src={boltLightning} alt="logo" style={{ maxHeight: "100%", maxWidth: "100%" }} />
              </div>
            ) : (
              <div className={"header-logo-big"}>
                <img src={boltLogo} alt="logo" style={{ maxHeight: "100%", maxWidth: "100%" }} />
              </div>
            )}
            <div className="flex-center-wrapper">
              {!collapsed && <span style={{ color: "#FAFAFA", fontSize: 12 }}>v{packageJson?.version}</span>}
            </div>
            <Menu
              theme="dark"
              defaultSelectedKeys={[this.props.location.pathname]}
              selectedKeys={[this.props.location.pathname]}
              mode="inline"
            >
              <SubMenu key="sub1" icon={<ShoppingCartOutlined />} title="Orders">
                <Menu.Item key="/dashboard" icon={<ShoppingCartOutlined />}>
                  <Link to="/dashboard">Orders</Link>
                </Menu.Item>
                {this.props.roles &&
                  (this.props.roles.includes("super_admin") || this.props.roles.includes("admin")) && (
                    <>
                      <Menu.Item key="/orders/rates" icon={<DollarOutlined />}>
                        <Link to="/orders/rates">Rates</Link>
                      </Menu.Item>
                    </>
                  )}
                <Menu.Item key="/bulk_update" icon={<ScheduleOutlined />}>
                  <Link to="/bulk_update" style={{ fontSize: 12 }}>
                    Bulk Pickup Ready
                  </Link>
                </Menu.Item>
              </SubMenu>
              {this.props.isPickupCreationEnabled && (
                <SubMenu key="sub7" icon={<ShoppingCartOutlined />} title="Pickups">
                  <Menu.Item key="/orders/pickups" icon={<SearchOutlined />}>
                    <Link to="/orders/pickups">Manage Pickups</Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.props.roles && (this.props.roles.includes("super_admin") || this.props.roles.includes("admin")) && (
                <>
                  <SubMenu key="sub3" icon={<ShoppingCartOutlined />} title="Merchants">
                    <Menu.Item key="/merchants/viewMerchants" icon={<IdcardOutlined />}>
                      <Link to="/merchants/viewMerchants">View Merchants</Link>
                    </Menu.Item>
                    <Menu.Item key="/merchants/addMerchant" icon={<UsergroupAddOutlined />}>
                      <Link to="/merchants/addMerchant">Add Merchant</Link>
                    </Menu.Item>
                  </SubMenu>
                </>
              )}
              {this.props.roles && this.props.roles.includes("super_admin") && (
                <>
                  <SubMenu key="sub6" icon={<IdcardOutlined />} title="GoBolt Users">
                    <Menu.Item key="/users" icon={<IdcardOutlined />}>
                      <Link to="/users">View Users</Link>
                    </Menu.Item>
                  </SubMenu>
                </>
              )}
              <Menu.Item key="16" icon={<LogoutOutlined />} onClick={this.props.logout}>
                Log Out
              </Menu.Item>
            </Menu>
          </Sider>
        )}
      </div>
    );
  }
}

AppHeader.propTypes = {
  isAuthenticated: PropTypes.bool,
  isPickupCreationEnabled: PropTypes.bool,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPickupCreationEnabled: state.featureFlag.pickupCreationEnabled,
  roles: state.auth.roles,
});

export default connect(mapStateToProps, { logout })(withRouter(AppHeader));
