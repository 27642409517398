import { Alert, Button, Col, Divider, Row, Space, Spin, Tag, Typography, notification } from "antd";
import React, { useState } from "react";
import { bulkUpdateOrdersByOperation } from "../util/APIUtils";
import { PRIMARY_GREEN } from "../util/Colors";
import { parseCSV, validateOrderCsv } from "../util/Helpers";
import DownloadCSVTemplateButton from "./DownloadCSVTemplateButton";
import FileUpload from "./FileUpload";

const MAX_ORDER_NUM = 300;

const BulkPickupReady = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [orderIdsOrTrackingNumbers, setOrderIdsOrTrackingNumbers] = useState();
  const [csvFile, setCsvFile] = useState();

  const onUploadIdsForBatchPickupReady = () => {
    if (isLoading || !orderIdsOrTrackingNumbers || !csvFile) return;
    setErrors([]);
    setIsLoading(true);

    const validIds = orderIdsOrTrackingNumbers.map(({ ids }) => ids).filter(Boolean);

    let promise = bulkUpdateOrdersByOperation("pickupready", {
      ids: validIds,
    });
    promise
      .then(() => {
        const args = {
          message: "Success!",
          description: "Orders updated to Pickup Ready",
          duration: 3,
          type: "success",
          placement: "topRight",
        };
        notification.open(args);
      })
      .catch((error) => {
        notification.error({
          message: "Error!",
          description: error.message,
          duration: 3,
          type: "success",
          placement: "topRight",
        });
      })
      .finally(() => {
        setIsLoading(false);
        reset();
      });
  };

  const reset = () => {
    setErrors([]);
    setOrderIdsOrTrackingNumbers(undefined);
    setCsvFile(undefined);
  };

  const onFileUpload = (csvfile) => {
    setErrors([]);
    if (!csvfile) return;
    setIsLoading(true);

    parseCSV({
      csvfile,
      onComplete: (orderIdsOrTrackingNumbers) => {
        const errors = validateOrderCsv(orderIdsOrTrackingNumbers);
        if (errors?.length) {
          setIsLoading(false);
          return setErrors(errors);
        }
        if (orderIdsOrTrackingNumbers.length > MAX_ORDER_NUM) {
          setIsLoading(false);
          return setErrors([`You have ${orderIdsOrTrackingNumbers.length} rows while the maximum is ${MAX_ORDER_NUM}`]);
        }
        setOrderIdsOrTrackingNumbers(orderIdsOrTrackingNumbers);
        setCsvFile(csvfile);
        setIsLoading(false);
      },
      onError: (error) => {
        setErrors([error]);
        setIsLoading(false);
      },
    });
  };

  const renderHeading = () => (
    <Row align="space-between" justify="center">
      <Typography.Title level={2}>Bulk Mark as Pickup Ready</Typography.Title>
      <Button
        type="primary"
        color={PRIMARY_GREEN}
        disabled={isLoading || !orderIdsOrTrackingNumbers || !csvFile}
        onClick={onUploadIdsForBatchPickupReady}
      >
        Submit
      </Button>
    </Row>
  );

  const renderLeftColumn = () => (
    <Col sm={24} md={12}>
      <Space direction="vertical" size={24}>
        <Typography.Text>
          Below are details on entering field values properly, please do not add any dashes or brackets. You will be
          limited to bulk editing 300 orders at a time.
        </Typography.Text>
        <Typography.Text>All orders uploaded will have their status set to Pickup Ready.</Typography.Text>
        <Typography.Text>All fields are required</Typography.Text>
        <Row>
          <Col xs={6} sm={12} md={6}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Field</Typography.Text>
              <Tag>ids</Tag>
            </Space>
          </Col>
          <Col xs={6} sm={12} md={6}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Description</Typography.Text>
              <Typography.Text>Order ID</Typography.Text>
            </Space>
          </Col>
        </Row>
      </Space>
    </Col>
  );

  const renderRightColumn = () => (
    <Col sm={24} md={12}>
      <Space direction="vertical" size={24}>
        {errors.length > 0 && (
          <Alert
            message={
              <>
                {errors.map((e) => (
                  <div>{e}</div>
                ))}
              </>
            }
            type="error"
            showIcon
          />
        )}
        <DownloadCSVTemplateButton
          headers={["ids"]}
          fileName="bulk_pickup_ready.csv"
          downloadButtonTxt="Download CSV Template"
        />
        <Typography.Text>Please download our template, then upload the completed file below.</Typography.Text>
        <FileUpload
          setFile={(f) => {
            if (f) onFileUpload(f);
            else {
              reset();
            }
          }}
          file={csvFile}
          acceptFileType=".csv"
        />
      </Space>
    </Col>
  );

  return (
    <div style={{ margin: 40 }}>
      <Spin spinning={!!isLoading}>
        {renderHeading()}
        <Divider horizontal />
        <Row gutter={54}>
          {renderLeftColumn()}
          {renderRightColumn()}
        </Row>
      </Spin>
    </div>
  );
};

export default BulkPickupReady;
