import { notification } from "antd";
import jwtDecode from "jwt-decode";
import { login } from "../util/APIUtils";
import { clearErrors, returnErrors } from "./errorActions";
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS, USER_LOADED, USER_LOADING, USER_LOAD_ERROR } from "./types";

// Login the user
export const loginUser = (username, password) => async (dispatch) => {
  dispatch({ type: USER_LOADING });
  const loginData = {
    email: username,
    password: password,
  };
  let promise = login(loginData);
  try {
    dispatch({
      type: USER_LOADING,
    });
    let response = await promise;
    const token = response.token;
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        token: token,
      },
    });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    notification.error({
      placement: "topRight",
      duration: null,
      message: "Login Error",
      description: error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};
// check token and load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });
  // Get token from local storage
  const token = localStorage.getItem("token");

  try {
    if (!token) {
      const message = "There is no token set in local storage.";
      throw new Error(message);
    }
    const decodedToken = jwtDecode(token);
    const bufferTime = 40 * 60; // time that the client can sit idle in s

    if (decodedToken.exp * 1000 <= Date.now() + bufferTime * 1000 || !token) {
      throw new Error("The token in local storage has expired");
    }
    dispatch({
      type: USER_LOADED,
      payload: decodedToken,
    });
  } catch (e) {
    dispatch(returnErrors(e.message, 401));
    dispatch({
      type: USER_LOAD_ERROR,
    });
  }
};
