import { CloseOutlined, CloudUploadOutlined, InboxOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Row, Upload } from "antd";
import React from "react";
import { LIGHTEST_BLUE, PRIMARY_BLUE } from "../util/Colors";

const { Dragger } = Upload;

const FileUpload = ({ file, setFile, acceptFileType }) => {
  const renderUploadedFile = () => {
    return (
      <Row justify="space-between" style={{ background: LIGHTEST_BLUE }}>
        <Row justify="center">
          <PaperClipOutlined />
          <div>{file?.name}</div>
        </Row>
        <div onClick={() => setFile(undefined)}>
          <CloseOutlined />
        </div>
      </Row>
    );
  };

  const renderDraggerContent = () => {
    const removeFileInstruction = (
      <div>
        <InboxOutlined
          style={{
            fontSize: "52px",
            margin: "0 0 8px 0",
            color: PRIMARY_BLUE,
          }}
        />
        <div>To replace, remove the current file to upload another</div>
      </div>
    );

    const uploadFileInstruction = (
      <div>
        <CloudUploadOutlined
          style={{
            fontSize: "52px",
            margin: "0 0 8px 0",
            color: PRIMARY_BLUE,
          }}
        />
        <div>Drag file here or click to browse and upload</div>
      </div>
    );

    return <div>{file ? removeFileInstruction : uploadFileInstruction}</div>;
  };

  return (
    <div>
      <Dragger
        onChange={(data) => {
          if (data.file.status === "uploading") {
            setFile(data.file.originFileObj);
          }
        }}
        accept={acceptFileType || undefined}
        customRequest={() => {}} // disable regular functionality
        disabled={!!file}
        showUploadList={false}
      >
        {renderDraggerContent()}
      </Dragger>

      {file ? renderUploadedFile() : null}
    </div>
  );
};

export default FileUpload;
