import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Select, Spin, Table, Tooltip, Typography, notification } from "antd";
import { cloneDeep, isString } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { createAppointment, getMerchants, getPickups } from "../util/APIUtils";
import "./Dashboard.css";
const { Option } = Select;
const { Paragraph } = Typography;

export class ViewPickups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickups: null,
      merchants: null,
      loading: false,
      hasMore: true,
      completeAfter: moment().startOf("day").valueOf(),
      completeBefore: moment().endOf("day").valueOf(),
      test: moment().startOf("day"),
      currentSearchParams: {},
      pageSize: 20,
      nextCursor: null,
      pickupsQueryId: null,
      selectedPickupsIds: [],
      province: null,
    };
  }

  componentDidMount() {
    this.runPickupsRequestFlow({
      completeAfter: this.state.completeAfter,
      completeBefore: this.state.completeBefore,
      pageSize: this.state.pageSize,
    });
  }

  async runPickupsRequestFlow(params, existingPickups = []) {
    if (!params.completeAfter || !params.completeBefore) {
      const args = {
        message: "Error!",
        description: "Please specify a date for the pickup window.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }
    this.setState({
      loading: true,
      hasMore: true,
      currentSearchParams: params,
    });

    let nextCursor = "";
    let existingPickupsIds = existingPickups.map((p) => p.id);
    let pickups = [];
    let response, merchants, pickupsForActiveMerchants;

    try {
      do {
        response = await getPickups(params);
        pickups = pickups.concat(
          response.pickups.filter((p) => {
            return !existingPickupsIds.includes(p.id);
          }),
        );

        nextCursor = response.nextCursor;
        params.nextCursor = nextCursor;
        params.queryId = response.queryId;
      } while (nextCursor != null && pickups.length < this.state.pageSize);
    } catch (err) {
      this.setState({ pickups: null });
    }

    if (!this.state.merchants) {
      try {
        merchants = await getMerchants();
        this.setState({ merchants: merchants });
      } catch (err) {}
    }

    pickupsForActiveMerchants = pickups.concat(existingPickups);

    pickupsForActiveMerchants = this.mergeMerchantsIntoPickups(
      this.state.merchants || merchants,
      pickupsForActiveMerchants,
    );

    pickupsForActiveMerchants = this.filterPickupsByActiveMerchants(pickupsForActiveMerchants);

    if (pickups.length < this.state.pageSize || nextCursor == null) {
      this.setState({ hasMore: false });
    }

    this.setState({
      pickups: pickupsForActiveMerchants,
      nextCursor: nextCursor,
      pickupsQueryId: params.queryId,
      loading: false,
    });
  }

  handleMerchantChange = (value) => {
    this.setState({ merchantId: value });
  };

  search = (event) => {
    this.runPickupsRequestFlow({
      merchantId: this.state.merchantId,
      province: this.state.province,
      completeAfter: this.state.completeAfter,
      completeBefore: this.state.completeBefore,
      pageSize: this.state.pageSize,
    });
  };

  handleAppointmentCreation = async () => {
    this.setState({ loading: true });

    const pickupsForActiveMerchants = this.filterPickupsByActiveMerchants(this.state.pickups);

    let updatedPickups = [];

    for (let pickup of this.state.pickups) {
      let clonedPickup = cloneDeep(pickup);

      if (!pickup.appointmentId && this.state.selectedPickupsIds.some((id) => pickup.id === id)) {
        try {
          let response = await createAppointment(pickup.id);
          clonedPickup.appointmentId = response.appointmentId;
        } catch (error) {
          const args = {
            message: "Error!",
            description: "There was an error creating the appointment.",
            duration: 0,
            type: "error",
            placement: "topRight",
          };
          notification.open(args);
        }
      }

      updatedPickups.push(clonedPickup);
    }

    this.setState({
      pickups: updatedPickups,
      selectedPickupsIds: [],
      loading: false,
    });
  };

  mergeMerchantsIntoPickups = (merchants, pickups = []) => {
    // This is done so that each pickup has link to its corresponding merchant
    // which would help further to avoid looping through merchants every time
    // we need pickup's merchant during rendering.
    const mergedPickups = [...pickups];
    for (let pickup of mergedPickups) {
      if (!pickup.merchant) {
        pickup.merchant = merchants.filter((m) => {
          return pickup.merchantId === m.id;
        })[0];
      }
    }
    return mergedPickups;
  };

  filterPickupsByActiveMerchants = (pickups = []) => {
    return pickups.filter((pu) => pu.merchant);
  };

  handleCompleteRangeChange = (date, dateString) => {
    if (date == null) {
      this.setState({
        completeAfter: null,
        completeBefore: null,
      });
    } else {
      if (date) {
        let completeAfter = date.startOf("day").valueOf();
        let completeBefore = date.endOf("day").valueOf();

        this.setState({
          completeAfter: completeAfter,
          completeBefore: completeBefore,
        });
      }
    }
  };

  handleInfiniteOnLoad = (event) => {
    if (!this.state.loading) {
      this.runPickupsRequestFlow(
        {
          merchantId: this.state.currentSearchParams.merchantId,
          province: this.state.currentSearchParams.province,
          completeAfter: this.state.currentSearchParams.completeAfter,
          completeBefore: this.state.currentSearchParams.completeBefore,
          pageSize: this.state.pageSize,
          nextCursor: this.state.nextCursor,
          queryId: this.state.pickupsQueryId,
        },
        this.state.pickups,
      );
    }
  };

  isActionable = (row) => {
    if (row.appointmentId || !row.merchant || this.state.loading || !row.merchant?.goboltPickupCustomerId) {
      return false;
    }

    return true;
  };

  render() {
    const dateFormat = "YYYY-MM-DD";

    let merchantOptions = [
      <Option value="" label="All">
        All
      </Option>,
    ];

    if (this.state.merchants) {
      for (let merchant of this.state.merchants) {
        merchantOptions.push(
          <Option value={merchant.id} label={merchant.name}>
            {merchant.name}
          </Option>,
        );
      }
    }

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        if (!this.state.loading) {
          this.setState({ selectedPickupsIds: selectedRowKeys });
        }
      },
      selectedRowKeys: this.state.selectedPickupsIds,
      getCheckboxProps: (record) => {
        let checkboxProps = {};
        if (!this.isActionable(record)) {
          checkboxProps.disabled = true;
        }
        return checkboxProps;
      },
      renderCell(checked, record, index, node) {
        if (!isString(record, "merchant.goboltPickupCustomerId")) {
          return (
            <Tooltip title="This merchant associated with this order does not have a gobolt pickup customer. Please create one in the View Merchants page">
              {node}
            </Tooltip>
          );
        }
        return node;
      },
    };

    const columns = [
      {
        title: "Pickup ID",
        dataIndex: "pickupID",
        render: (text, record) => (
          <>
            <Paragraph
              copyable={{
                text: `${record.id}`,
              }}
            >
              {record.id.substring(0, 5)}..
            </Paragraph>
          </>
        ),
      },
      {
        title: "Merchant",
        dataIndex: "merchant.name",
        render: (text, record) => {
          return record.merchant ? record.merchant.name : "";
        },
      },
      {
        title: "Org Id",
        dataIndex: "organizationId",
        render: (text, record) => (
          <>
            {isString(record, "merchant.goboltOrgId") && (
              <Paragraph
                copyable={{
                  text: `${record.merchant.goboltOrgId}`,
                }}
              >
                {record.merchant.goboltOrgId.substring(0, 5)}..
              </Paragraph>
            )}
          </>
        ),
      },
      {
        title: "Customer Id",
        dataIndex: "pickupCustomerId",
        render: (text, record) => (
          <>
            {isString(record, "merchant.goboltPickupCustomerId") && (
              <Paragraph
                copyable={{
                  text: `${record.merchant.goboltPickupCustomerId}`,
                }}
              >
                {record.merchant.goboltPickupCustomerId.substring(0, 5)}..
              </Paragraph>
            )}
          </>
        ),
      },
      {
        title: "Pickup Window",
        dataIndex: "pickupWindowStart",
        render: (text, record) => {
          let dateStart = new Date(record.completeAfter);
          let dateEnd = new Date(record.completeBefore);
          return `${dateStart.toDateString()}\n${dateStart.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })} - ${dateEnd.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}`;
        },
      },
      {
        title: "Address",
        dataIndex: "Address",
        render: (text, record) => {
          return `${record.recipientAddress.number} ${record.recipientAddress.street}\n${record.recipientAddress.city}, ${record.recipientAddress.province} ${record.recipientAddress.postalCode}`;
        },
      },
      {
        title: "# Orders",
        dataIndex: "ordersCount",
        render: (text, record) => {
          return (record.orderIds || []).length;
        },
      },
      {
        title: "Appointment ID",
        dataIndex: "pickupAppointmentId",
        render: (text, record) => (
          <>
            {record.appointmentId && (
              <Paragraph
                copyable={{
                  text: `${record.appointmentId}`,
                }}
              >
                {record.appointmentId.substring(0, 5)}..
              </Paragraph>
            )}
          </>
        ),
      },
      {
        title: "Created Date",
        dataIndex: "createdAt",
        render: (text, record) => {
          if (!record.createdAt) return "";
          let createdAtEpoch = record.createdAt.toString().length === 10 ? record.createdAt * 1000 : record.createdAt;
          return (
            <Tooltip title="Please note that this represents the creation date in your browser's current timezone">
              {`${new Date(createdAtEpoch).toDateString()}`}
            </Tooltip>
          );
        },
      },
    ];

    const provinceOptions = [
      { label: "Quebec", value: "quebec" },
      { label: "Ontario", value: "ontario" },
      { label: "British Columbia", value: "britishColumbia" },
    ];

    return (
      <div className={"dashboard-container"}>
        <Spin spinning={!!this.state.loading}>
          <Row gutter={16} className={"search-row"}>
            <Col span={4}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%", height: "100%" }}
                placeholder="Merchant"
                optionLabelProp="label"
                onChange={this.handleMerchantChange}
                disabled={this.state.loading}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {merchantOptions}
              </Select>
            </Col>

            <Col span={4}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%", height: "100%" }}
                placeholder="Province"
                optionLabelProp="label"
                onChange={(value) => this.setState({ province: value })}
                disabled={this.state.loading}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={provinceOptions}
              ></Select>
            </Col>

            <Col span={4}>
              <DatePicker
                showClear
                style={{ width: "100%", height: "100%" }}
                placeholder={"Window Date"}
                onChange={this.handleCompleteRangeChange}
                format={dateFormat}
                disabled={this.state.loading}
                defaultValue={moment().startOf("day")}
              />
            </Col>
            <Col span={2}>
              <Button type={"primary"} disabled={this.state.loading} onClick={this.search}>
                {" "}
                Search{" "}
              </Button>
            </Col>
          </Row>
          <Row className={"search-row"}>
            <Col span={2}>
              <Button
                type={"primary"}
                onClick={this.handleAppointmentCreation}
                disabled={this.state.selectedPickupsIds.length === 0 || this.state.loading}
                icon={<PlusCircleOutlined />}
              >
                Appointment
              </Button>
            </Col>
          </Row>

          {this.state.pickups && this.state.pickups.length > 0 && (
            <Row>
              <Col span={24}>
                <div className="infinite-container" style={{ height: "80vh", overflow: "auto" }}>
                  <InfiniteScroll
                    initialLoad={false}
                    loadMore={this.handleInfiniteOnLoad}
                    hasMore={this.state.hasMore}
                    threshold={20}
                    useWindow={false}
                  >
                    <Table
                      bordered
                      pagination={false}
                      columns={columns}
                      dataSource={this.state.pickups}
                      rowKey={(pickup) => pickup.id}
                      rowSelection={rowSelection}
                    />
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

ViewPickups.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(ViewPickups);
